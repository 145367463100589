'use es6';

import { List } from 'immutable';
import { Promise } from './promise';
const BATCH_SIZE = 50;
const length = batch => List.isList(batch) ? batch.size : batch.length;
export const reduce = (data, fn, initial) => new Promise(resolve => {
  let results = initial;
  const process = offset => {
    const batch = data.slice(offset, offset + BATCH_SIZE);
    if (length(batch)) {
      results = batch.reduce(fn, results);
      window.requestAnimationFrame(process.bind(null, offset + BATCH_SIZE));
    } else {
      resolve(results);
    }
  };
  process(0);
});