import { useEffect, useState } from 'react';
import userInfo from 'hub-http/userInfo';
import { gate } from 'hub-http/gates';
const useUserHasGate = check => {
  const [loading, setLoading] = useState(true);
  const [hasGate, setHasGate] = useState(false);
  useEffect(() => {
    userInfo({
      cached: true
    }).then(authData => {
      setLoading(false);
      return setHasGate(authData.gates.includes(check));
    }).catch(() => {
      setLoading(false);
      setHasGate(false);
    });
  }, [check]);
  return {
    loading,
    hasGate
  };
};
export const useHasBusinessUnitsRebrand = () => {
  return useUserHasGate(gate('MultiAccount:BusinessUnitsRename')).hasGate;
};