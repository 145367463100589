'use es6';

import { createSelector } from 'reselect';
import fromJS from 'transmute/fromJS';
import { RequestState } from '../request/request-state';
const {
  UNINITIALIZED,
  SUCCEEDED
} = RequestState;
export const userInfoNamespace = 'userInfo';

// Action Types
// There's only one type. If the user info call fails, then the app won't load at all.
const FETCH_USER_INFO_SUCCEEDED = 'FETCH_USER_INFO_SUCCEEDED';
const FETCH_RAW_USER_INFO_SUCCEEDED = 'FETCH_RAW_USER_INFO_SUCCEEDED';

// Actions

// userInfo will be fetched prior to the app initialization
// this is because portal information is required to setup
// I18n and moment (language and timezones)
export const initializeUserInfo = userInfo => dispatch => {
  const {
    gates,
    portal,
    user
  } = userInfo;
  const {
    limits
  } = portal;
  const {
    scopes
  } = user;
  dispatch({
    type: FETCH_USER_INFO_SUCCEEDED,
    payload: fromJS({
      gates,
      limits,
      portalDetails: portal,
      scopes,
      userDetails: user
    })
  });
  dispatch({
    type: FETCH_RAW_USER_INFO_SUCCEEDED,
    payload: userInfo
  });
};

// Reducer
const initialState = fromJS({
  errorMsg: '',
  status: UNINITIALIZED,
  value: {
    gates: [],
    limits: {},
    portalDetails: {},
    scopes: [],
    userDetails: {}
  },
  rawUserInfo: {}
});
export const userInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_INFO_SUCCEEDED:
      return state.set('errorMsg', '').set('status', SUCCEEDED).set('value', action.payload);
    case FETCH_RAW_USER_INFO_SUCCEEDED:
      return state.set('rawUserInfo', action.payload);
    default:
      return state;
  }
};

// Selectors
export const selectUserInfo = state => state[userInfoNamespace].get('value');
export const selectAllGates = createSelector(selectUserInfo, userInfoMap => userInfoMap.get('gates'));
export const selectAllGatesAsSet = createSelector(selectAllGates, gates => gates.toSet());
export const selectPortalDetails = createSelector(selectUserInfo, userInfoMap => userInfoMap.get('portalDetails'));
export const selectUserDetails = createSelector(selectUserInfo, userInfoMap => userInfoMap.get('userDetails'));
export const selectAllLimits = createSelector(selectPortalDetails, portalDetailsMap => portalDetailsMap.get('limits'));
export const selectAllScopes = createSelector(selectUserInfo, userInfo => userInfo.get('scopes'));
export const selectAllScopesAsSet = createSelector(selectAllScopes, scopes => scopes.toSet());
export const selectUserId = createSelector(selectUserDetails, userDetails => userDetails.get('user_id'));
export const selectUserEmail = createSelector(selectUserDetails, userDetails => userDetails.get('email'));
export const selectRawUserInfo = state => state[userInfoNamespace].get('rawUserInfo');