import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"];
import { registerQuery, useQuery } from 'data-fetching-client';
import I18n from 'I18n';
import { httpClientWithRaven } from '../../clients/hubHttpWithRaven';
const getEarliestCTUDate = () => I18n.moment().startOf('day').subtract(7, 'days').toDate();
export const GET_USAGE_THRESHOLD = registerQuery({
  fieldName: 'usageThresholdData',
  args: ['startDate'],
  fetcher({
    startDate
  }) {
    return httpClientWithRaven.get(`subscription-experience/v1/usage-threshold`, {
      query: {
        startDate
      }
    });
  }
});
export const useUsageThresholdData = ({
  skip
}) => {
  const _useQuery = useQuery(GET_USAGE_THRESHOLD, {
      skip,
      variables: {
        startDate: getEarliestCTUDate().getTime() / 1000
      }
    }),
    {
      data
    } = _useQuery,
    rest = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  if (data) {
    const {
      firstCtuOrder,
      contactsLimit
    } = data.usageThresholdData;
    const surpassedTier = firstCtuOrder ? firstCtuOrder.surpassedTier || 0 : 0;
    const ctuOrderSubscriptionId = firstCtuOrder ? firstCtuOrder.subscriptionId : undefined;
    const limit = contactsLimit ? contactsLimit.limit || 0 : 0;
    const thresholdPercent = contactsLimit ? contactsLimit.thresholdPercent || 0 : 0;
    const hasBreachedThreshold = contactsLimit ? contactsLimit.hasBreachedThreshold : false;
    return Object.assign({
      data: {
        surpassedTier,
        ctuOrderSubscriptionId,
        limit,
        thresholdPercent,
        hasBreachedThreshold
      }
    }, rest);
  }
  return Object.assign({
    data: {
      surpassedTier: 0,
      ctuOrderSubscriptionId: undefined,
      limit: 0,
      thresholdPercent: 0,
      hasBreachedThreshold: false
    }
  }, rest);
};