import { fromJS } from 'immutable';
import { createSelector } from 'reselect';
import { fetchAIAccessControls } from '../../api/genaiApi';
import { RequestState } from 'dashboard-lib/public/request/request-state';
const {
  UNINITIALIZED,
  PENDING,
  SUCCEEDED,
  FAILED
} = RequestState;
export const aiSettingsNamespace = 'aiSettings';

// Action types
const FETCH_AI_SETTINGS_PENDING = 'FETCH_AI_SETTINGS_PENDING';
const FETCH_AI_SETTINGS_SUCCEEDED = 'FETCH_AI_SETTINGS_SUCCEEDED';
const FETCH_AI_SETTINGS_FAILED = 'FETCH_AI_SETTINGS_FAILED';
export const fetchAISettingsPending = () => ({
  type: FETCH_AI_SETTINGS_PENDING
});
export const fetchAISettingsSucceeded = payload => ({
  type: FETCH_AI_SETTINGS_SUCCEEDED,
  payload
});
export const fetchAISettingsFailed = () => ({
  type: FETCH_AI_SETTINGS_FAILED
});

// Actions
export const getAIAccessSettings = () => dispatch => {
  dispatch(fetchAISettingsPending());
  return fetchAIAccessControls().then(({
    generativeAiEnabled,
    crmPropertiesEnabled
  }) => {
    dispatch(fetchAISettingsSucceeded({
      generativeAiEnabled,
      crmPropertiesEnabled
    }));
  }).catch(() => {
    dispatch(fetchAISettingsFailed());
  });
};
// Reducers
const initialState = fromJS({
  status: UNINITIALIZED,
  settings: {
    isGenerativeAiEnabled: null,
    isCrmPropertiesEnabled: null
  }
});
export const aiSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AI_SETTINGS_PENDING:
      return state.set('status', PENDING);
    case FETCH_AI_SETTINGS_SUCCEEDED:
      return state.merge({
        status: SUCCEEDED,
        settings: {
          isGenerativeAiEnabled: action.payload.generativeAiEnabled,
          isCrmPropertiesEnabled: action.payload.crmPropertiesEnabled
        }
      });
    case FETCH_AI_SETTINGS_FAILED:
      return state.set('status', FAILED);
    default:
      return state;
  }
};

// If using at the top level

// If using within report panel reducer
const reportPanelNamespace = 'reportPanel';
const isAiSettingsNestedState = state => {
  return reportPanelNamespace in state;
};
export const selectAiSettingsRoot = state => {
  if (isAiSettingsNestedState(state)) {
    return state[reportPanelNamespace][aiSettingsNamespace];
  }
  return state[aiSettingsNamespace];
};
export const selectIsGenerativeAiEnabled = createSelector(selectAiSettingsRoot, aiSettingsRoot => aiSettingsRoot.getIn(['settings', 'isGenerativeAiEnabled']));
export const selectIsCrmPropertiesEnabled = createSelector(selectAiSettingsRoot, aiSettingsRoot => aiSettingsRoot.getIn(['settings', 'isCrmPropertiesEnabled']));