'use es6';

import I18n from 'I18n';
import { fromJS, List, Map as ImmutableMap } from 'immutable';
import { CRM_OBJECT, DEALS } from '../../constants/dataTypes';
import { MAX_NUM_OF_METRICS } from '../../constants/limits';
import { HUBSPOT_OBJECT_COORDINATES_TO_DATA_TYPE } from '../../constants/objectCoordinates';
import * as Operators from '../../constants/operators';
import { InvalidTwoDimensionMetricException, TooManyStagesException } from '../../exceptions';
import { countUniqueMetrics } from '../../lib/countUniqueMetrics';
import { Promise } from '../../lib/promise';
import { slug } from '../../lib/slug';
import { get as getPipelines } from '../../retrieve/inboundDb/pipelines';
import { getAllFilters } from '../../report/configReportGetters';
/**
 * Wildcard dealstage duration property
 *
 * @constant {string}
 * @private
 */
const PROPERTY = 'dealstages.*_duration';

/**
 * Whether to run configure step
 *
 * @param {ReportConfiguration} config Report configuration
 * @returns {boolean} Whether to run configure ste[]
 * @private
 */
const shouldConfigure = config => {
  const dataType = config.get('dataType') === CRM_OBJECT ? HUBSPOT_OBJECT_COORDINATES_TO_DATA_TYPE.get(config.get('objectTypeId'), CRM_OBJECT) : config.get('dataType');
  return dataType === DEALS && config.has('metrics') && config.get('metrics').some(metric => metric.get('property') === PROPERTY);
};

/**
 * Parse pipelines response to Map
 *
 * @param {PipelinesResponse} response Raw pipelines response
 * @returns {Map<string, Pipeline>} Mapped response with pipeline id keys
 * @private
 */
const parseResponse = response => response.reduce((memo, pipeline) => memo.set(pipeline.get('pipelineId'), pipeline), ImmutableMap());

/**
 * Pipeline stage collecting reducer
 *
 * @param {List} stages Accumulated stages
 * @param {Pipeline} pipeline Current pipeline
 * @returns {List<Stage>} Collected pipeline stages
 * @private
 */
const collectStages = (stages, pipeline) => stages.concat(pipeline.get('stages', List()));

/**
 * Create stage duration property
 *
 * @param {Stage} stage Pipeline stage
 * @returns {string} Stage duration property
 * @private
 */
const createDurationProperty = stage => `dealstages.${slug(stage.get('stageId'))}_duration`;

/**
 * Filtered pipeline stages
 *
 * @param {ReportConfiguration} config Report configuration
 * @param {Map<string, Pipeline>} pipeline Mapped pipelines
 * @returns {List<Stage>} pipeline Mapped pipelines
 * @private
 */
const getPipelineStages = (config, pipelines) => {
  const reportFilters = getAllFilters(config);
  return reportFilters.map(filter => filter.toJS()).filter(({
    property
  }) => property === 'pipeline').reduce((_, {
    operator,
    value,
    values
  }) => {
    switch (operator) {
      case Operators.EQ:
        return pipelines.getIn([value, 'stages'], List());
      case Operators.IN:
        return values.filter(pipelineId => pipelines.has(pipelineId)).map(pipelineId => pipelines.get(pipelineId)).reduce(collectStages, List());
      case Operators.NEQ:
        return pipelines.filter((pipeline, pipelineId) => value !== pipelineId).reduce(collectStages, List());
      case Operators.NOT_IN:
        return pipelines.filter((pipeline, pipelineId) => !values.includes(pipelineId)).reduce(collectStages, List());
      default:
        return List();
    }
  }, pipelines.reduce(collectStages, List()));
};
const getFilteredStages = (config, pipelines) => {
  const pipelineStages = getPipelineStages(config, pipelines);
  const reportFilters = getAllFilters(config);
  return reportFilters.map(filter => filter.toJS()).filter(({
    property
  }) => property === 'dealstage').reduce((memo, {
    operator,
    values
  }) => {
    if (operator === Operators.IN) {
      return memo.filter(stage => values.includes(stage.get('stageId')));
    }
    if (operator === Operators.NOT_IN) {
      return memo.filter(stage => !values.includes(stage.get('stageId')));
    }
    if (operator === Operators.NOT_HAS_PROPERTY) {
      return List();
    }
    return memo;
  }, pipelineStages);
};
export const configureWithStages = (config, stages, stagesProvidedFromRaasApi = false) => {
  if (!shouldConfigure(config)) {
    return config;
  }
  if (config.get('dimensions', List()).count() > 1) {
    throw new InvalidTwoDimensionMetricException({
      timeInAllStagesProperty: PROPERTY,
      propertyName: I18n.text('reporting-data.pipelinestages.timeInAllStages')
    });
  }
  if (stagesProvidedFromRaasApi) {
    stages = stages.filter(item => item.getIn(['columnGroup', 'sourceName']) === PROPERTY).sortBy(item => item.getIn(['columnGroup', 'order']));
  }
  return config.update('metrics', metrics => {
    const configuredMetrics = metrics.reduce((memo, metric) => metric.get('property') === PROPERTY ? /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    memo.concat(stages.filter(stage => {
      // When stagesProvidedFromRaasApi is true and both metricTypes and
      // aggregationType are present, we will ensure the aggregationType
      // is included in the metricTypes to resolve scenarios such as this:
      // https://issues.hubspotcentral.com/browse/RA-15543
      //
      // For backward compatibility, we will always return true if
      // either metricTypes or aggregationType is not present
      // as this was the behavior prior to this change to match
      // aggregationType to metricTypes.
      if (stagesProvidedFromRaasApi && metric.has('metricTypes') && stage.has('aggregationType')) {
        return metric.get('metricTypes').includes(stage.get('aggregationType'));
      }
      return true;
    }).map(stage => {
      return metric.set('property', stagesProvidedFromRaasApi ? stage.get('field') : createDurationProperty(stage));
    })) : memo.push(metric), List());
    if (countUniqueMetrics(configuredMetrics) > MAX_NUM_OF_METRICS) {
      throw new TooManyStagesException();
    }
    return configuredMetrics;
  });
};
/**
 * Stage duration configure step
 *
 * @param {ReportConfiguration} config Report configuration
 * @returns {ReportConfiguration} Updated report configuration
 */
export const configure = config => {
  if (!shouldConfigure(config)) {
    return Promise.resolve(config);
  }
  return getPipelines(DEALS).then(fromJS).then(parseResponse).then(pipelines => {
    const stages = getFilteredStages(config, pipelines);
    return configureWithStages(config, stages);
  });
};