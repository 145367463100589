import { MARKETING_HUB_ENTERPRISE, OPERATIONS_HUB_ENTERPRISE, SALES_HUB_ENTERPRISE, SERVICE_HUB_ENTERPRISE } from 'self-service-api/constants/ApiNames';
import { MARKETING_ENTERPRISE, MARKETING_PRO, OPERATIONS_ENTERPRISE, OPERATIONS_PROFESSIONAL, SALES_ENTERPRISE, SALES_PROFESSIONAL, SERVICE_ENTERPRISE, SERVICE_PROFESSIONAL } from 'self-service-api/constants/UpgradeProducts';
export const SURVEY_OPTIONS_BY_UPGRADE_PRODUCT = {
  [MARKETING_PRO]: ['landing-pages', 'email', 'marketing-workflows', 'content-strategy'],
  [SALES_PROFESSIONAL]: ['sequences', 'meetings', 'quotes', 'sales-workflows'],
  [MARKETING_ENTERPRISE]: ['landing-pages', 'email', 'marketing-workflows', 'content-strategy'],
  [SALES_ENTERPRISE]: ['sales-workflows', 'playbooks', 'sequences', 'quotes'],
  [SERVICE_PROFESSIONAL]: ['tickets', 'feedback', 'knowledge-base', 'service-workflows'],
  [SERVICE_ENTERPRISE]: ['tickets', 'feedback', 'knowledge-base', 'service-workflows'],
  [OPERATIONS_PROFESSIONAL]: ['custom-coded-workflow-actions', 'custom-coded-bot-actions', 'webhooks', 'data-quality-automation'],
  [OPERATIONS_ENTERPRISE]: ['datasets', 'snowflake-data-share']
};
export const NEXT_STEPS_SURVEY_API_NAMES = [MARKETING_HUB_ENTERPRISE, SALES_HUB_ENTERPRISE, SERVICE_HUB_ENTERPRISE, OPERATIONS_HUB_ENTERPRISE];