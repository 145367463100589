import { List, Map as ImmutableMap } from 'immutable';
import { Aggregations, Order } from 'reporting-visualizations/field/order';
import { FieldTypes } from '../../schema/column-records';
import { ColumnSort, Sort, SortOrders, SortTypes } from '../../schema/sort-records';
import { VisualTypes } from '../../schema/visual-records';
import { getColumnFieldFromDataset, getMeasures, isDateLikeField } from '../../utils/column-utils';
import { getReportColumnsEncodings, removeUnencodedColumns } from '../../utils/report-visual-utils';
import { shouldFieldUseBackendLabelSort, shouldPropertyUseBackendLabelSort, toLabelDependencyAlias } from './label-sort-utils';
import { DEFAULT_ORDER, reverse, SORT_ORDER_TO_DIRECTION, toSortDependencyAlias } from './sort-utils';
import { SnowflakeProperty } from '../../schema/source-records';
import { isList } from '../../../shared/lib/utility-types';
import { COLUMN_ROLES } from 'reporting-data/constants/relationalReports';
import { ReportingLogger } from 'reporting-data/monitoring/reportingLogger';
export const getValidColumnSorts = (report, column, reportMeta) => {
  const {
    visual: {
      type: visualType = VisualTypes.TABLE
    } = {}
  } = report;
  if (!column) {
    return {
      valid: List(),
      preferred: undefined
    };
  }
  const isTable = visualType === VisualTypes.TABLE;
  const isPivotTable = visualType === VisualTypes.PIVOT_TABLE;
  const isChart = !isTable && !isPivotTable;
  const field = getColumnFieldFromDataset(column, reportMeta);
  const fieldType = field && field.type;
  const isDateLike = field && isDateLikeField(field);
  const isEnumeration = fieldType === FieldTypes.ENUMERATION;
  const isMeasure = column.role === COLUMN_ROLES.MEASURE;
  const sortByValue = ColumnSort({
    priority: 0,
    type: SortTypes.VALUE,
    order: SortOrders.ASCENDING
  });
  if (isMeasure) {
    if (!isTable) {
      return {
        valid: List(),
        preferred: undefined
      };
    }
    return {
      valid: List.of(sortByValue, reverse(sortByValue)),
      preferred: sortByValue
    };
  }
  const forceContinousDates = isDateLike && isChart;
  if (forceContinousDates) {
    return {
      valid: List.of(sortByValue, reverse(sortByValue)),
      preferred: sortByValue
    };
  }
  if (field == null) {
    return {
      valid: List(),
      preferred: undefined
    };
  }
  const {
    table,
    name
  } = field;
  const property = reportMeta ? reportMeta.properties.getIn([table, name]) : null;
  const hasOptions = property && property.options && !property.options.isEmpty();
  const canUseDisplayOrderSort = isEnumeration && hasOptions;
  const sortByDisplayOrder = canUseDisplayOrderSort ? ColumnSort({
    priority: 0,
    type: SortTypes.DISPLAY_ORDER,
    order: SortOrders.ASCENDING
  }) : undefined;
  const useBackendLabelSort = shouldFieldUseBackendLabelSort(field, reportMeta);
  const sortByLabel = useBackendLabelSort || canUseDisplayOrderSort ? ColumnSort({
    priority: 0,
    type: SortTypes.LABEL,
    order: SortOrders.ASCENDING
  }) : undefined;
  const sorts = List([sortByValue, reverse(sortByValue), ...(sortByDisplayOrder ? [sortByDisplayOrder, reverse(sortByDisplayOrder)] : []), ...(sortByLabel ? [sortByLabel, reverse(sortByLabel)] : [])]);
  const baseColumns = removeUnencodedColumns(report).columns;
  const measureAliases = getMeasures(baseColumns).map(measure => measure.alias);
  const getSortByMeasure = measureAlias => ColumnSort({
    priority: 0,
    type: SortTypes.VALUE,
    order: SortOrders.ASCENDING,
    by: measureAlias
  });
  const measureSorts = measureAliases.map(getSortByMeasure).flatMap(sort => [sort, reverse(sort)]);
  return {
    valid: sorts.concat(measureSorts),
    preferred: canUseDisplayOrderSort ? sortByDisplayOrder : useBackendLabelSort ? sortByLabel : sortByValue
  };
};

/**
 * Helper function: assumes report.visual is not a table and supports per-column sorting
 */
const applyDefaultColumnSorts = (report, reportMeta) => report.update('columns', columns => columns.map(column => {
  const {
    valid,
    preferred
  } = getValidColumnSorts(report, column, reportMeta);
  const sort = column.sort && column.sort.set('priority', 0).set('nested', undefined);
  if (sort && valid.contains(sort)) {
    return column;
  }
  return column.set('sort', preferred);
}).toMap());
const applyNestedSorts = report => {
  const {
    visual: {
      type = VisualTypes.TABLE
    } = {}
  } = report;
  const nested = type === VisualTypes.PIVOT_TABLE ? true : undefined;
  const updateSort = sort => sort ? sort.set('nested', nested) : sort;
  return report.update('columns', columns => columns.map(column => column.sort ? column.update('sort', sort => updateSort(sort)) : column).toMap());
};

/**
 * `report.sort` is the UI source of truth for a table visualization (rather than `column.sort`)
 *
 * A table viz may have a `report.sort` set without the `column.sort` set.
 *
 * At query time, this function syncs the first `report.sort` to the referenced column and adds a sort to that column.
 * This step is necessary because the rest of the query-sort logic looks at the `column.sort` as the source of truth
 */
export const patchTableVizColumnSort = reportDefinition => {
  const {
    visual: {
      type
    } = {},
    sorts
  } = reportDefinition;
  const isTableViz = type === VisualTypes.TABLE;
  if (!isTableViz) {
    return reportDefinition;
  }
  const sort = sorts ? sorts.first() : undefined;
  if (!sort) {
    return reportDefinition;
  }
  const {
    column: alias,
    order,
    sortType
  } = sort;
  const columnSort = ColumnSort({
    priority: 0,
    type: sortType,
    order
  });
  return reportDefinition.update('columns', columns => columns.update(alias, column => column.set('sort', columnSort)));
};
const applyColumnSorting = (report, reportMeta) => {
  const {
    visual: {
      type = VisualTypes.TABLE
    } = {}
  } = report;
  if (type === VisualTypes.TABLE) {
    return report;
  }
  const removeSort = r => r.set('sorts', List());
  return applyNestedSorts(applyDefaultColumnSorts(removeSort(report), reportMeta));
};

/**
 * @param {TableAndPropertyMeta} [reportMeta] Only optional to support its use in our `applySorting` modifier. Should be treated as mandatory to prevent invalid display order sorts from being returned
 * @returns {List<Sort>}
 */
export const getTableSorts = (report, reportMeta) => {
  const tableEncodings = getReportColumnsEncodings(report);
  const listTableEncodings = isList(tableEncodings) ? tableEncodings : tableEncodings == null ? List() : List(tableEncodings);
  const sorts = listTableEncodings.map(encoding => {
    const column = report.columns.get(encoding.column.alias);
    const {
      field: {
        table,
        name
      }
    } = column;
    const isMeasure = column.role === COLUMN_ROLES.MEASURE;
    if (isMeasure) {
      return List.of(Sort({
        column: encoding.column.alias,
        order: SortOrders.ASCENDING,
        sortType: SortTypes.VALUE
      }));
    }
    const isEnumeration = column.field.type === FieldTypes.ENUMERATION;
    const getProperty = () => {
      if (reportMeta && reportMeta.datasetDefinition) {
        const tableName = reportMeta.datasetDefinition.getIn(['fields', name, 'expression', 'table']);
        const propertyName = reportMeta.datasetDefinition.getIn(['fields', name, 'expression', 'name']);
        return reportMeta.properties.getIn([tableName, propertyName]);
      } else if (reportMeta) {
        return reportMeta.properties.getIn([table, name]);
      } else {
        return null;
      }
    };
    const property = getProperty();
    const hasOptions = property && property.options && !property.options.isEmpty();
    const canUseDisplayOrderSort = isEnumeration && (reportMeta === undefined || hasOptions);
    const canSortByLabelUsingMeta = isEnumeration && hasOptions;
    const canUseLabelSort = reportMeta && (canSortByLabelUsingMeta || shouldFieldUseBackendLabelSort(column.field, reportMeta));
    const validSortsForEncoding = [Sort({
      column: encoding.column.alias,
      order: SortOrders.ASCENDING,
      sortType: SortTypes.VALUE
    })];
    if (canUseLabelSort) {
      const labelSort = Sort({
        column: encoding.column.alias,
        order: SortOrders.ASCENDING,
        sortType: SortTypes.LABEL
      });
      if (reportMeta) {
        validSortsForEncoding.unshift(labelSort);
      } else {
        validSortsForEncoding.push(labelSort);
      }
    }
    if (canUseDisplayOrderSort) {
      const displayOrderSort = Sort({
        column: encoding.column.alias,
        order: SortOrders.ASCENDING,
        sortType: SortTypes.DISPLAY_ORDER
      });
      if (reportMeta) {
        validSortsForEncoding.unshift(displayOrderSort);
      } else {
        validSortsForEncoding.push(displayOrderSort);
      }
    }
    return List(validSortsForEncoding);
  }).flatten(1).flatMap(sort => List([sort, reverse(sort)]));
  return {
    valid: sorts,
    preferred: sorts.first()
  };
};
const applyTableSorting = (report, reportMeta) => {
  const {
    visual: {
      type
    } = {}
  } = report;
  if (type !== VisualTypes.TABLE) {
    return report;
  }

  // Column sorts do not apply to VisualType.TABLE reports
  const next = report.update('columns', columns => columns.map(column => column.delete('sort')).toMap());
  const {
    valid,
    preferred
  } = getTableSorts(next, reportMeta);
  const {
    sorts = List()
  } = next;
  const existingSort = sorts.first();
  if (existingSort && valid.contains(existingSort)) {
    return next;
  }
  return next.set('sorts', preferred ? List.of(preferred) : List());
};
export const applySorting = (report, reportMeta) => {
  const {
    visual: {
      type = VisualTypes.TABLE
    } = {}
  } = report;
  const nextReport = type === VisualTypes.TABLE ? applyTableSorting(report, reportMeta) : applyColumnSorting(report, reportMeta);
  return nextReport;
};
const SNOWFLAKE_SORT_PAGE_ACTION = 'SnowflakeInlineTableSort';
const logSnowflakeInlineSort = (reportDefinition, sortColumn, sortOrder) => {
  const reportingLogger = new ReportingLogger();
  reportingLogger.addReportAttributes(ImmutableMap({
    reportDefinition
  }));
  reportingLogger.addAttribute('sortColumn', sortColumn);
  reportingLogger.addAttribute('sortOrder', sortOrder);
  reportingLogger.sendPageAction(SNOWFLAKE_SORT_PAGE_ACTION);
};
export const handleInlineTableSorting = (alias, reportDefinition, sorts, reportMeta) => {
  if (!reportDefinition.visual) {
    return List();
  }
  if (reportDefinition.visual.type === VisualTypes.TABLE) {
    const activeSort = sorts && sorts.get(0);
    const column = reportDefinition.columns.get(alias);
    if (!column) {
      throw new Error(`Unexpected nil column, alias = \`${alias}\`!`);
    }
    if (activeSort && activeSort.column === alias) {
      const sortOrder = activeSort.get('order') !== SortOrders.ASCENDING ? SortOrders.ASCENDING : SortOrders.DESCENDING;
      logSnowflakeInlineSort(reportDefinition, column, sortOrder);
      return List([activeSort.update('order', () => sortOrder)]);
    } else {
      const {
        valid: validTableSorts
      } = getTableSorts(reportDefinition, reportMeta);
      const tableSort = validTableSorts.find(sort => sort.column === alias);
      logSnowflakeInlineSort(reportDefinition, column, tableSort.get('order'));
      return List([tableSort]);
    }
  }
  return List();
};
const getDefinedOrderFromProperty = (sort, snowflakeProperty) => {
  const {
    order,
    type
  } = sort;
  const getDefinedLabelOrderFromOptions = options => options.sort((a, b) => {
    const aLabel = (a.get('label') || '').toLocaleLowerCase();
    const bLabel = (b.get('label') || '').toLocaleLowerCase();
    return aLabel.localeCompare(bLabel);
  }).map(option => option.get('value')).toList();
  const propertyOptions = snowflakeProperty.options;
  const defined = (() => {
    switch (type) {
      case SortTypes.DISPLAY_ORDER:
        return propertyOptions ? propertyOptions.map(option => option.get('value')).toList() : List();
      case SortTypes.LABEL:
        return propertyOptions ? getDefinedLabelOrderFromOptions(propertyOptions) : List();
      case SortTypes.VALUE:
        console.error('`getDefinedOrderFromProperty` should not be called when sort.type is', type);
        return undefined;
      default:
        console.error('`getDefinedOrderFromProperty` should not be called when sort.type is', type);
        return undefined;
    }
  })();
  if (order === SortOrders.DESCENDING && defined) {
    return defined.reverse().toList();
  }
  return defined;
};
export const toVisualizationOrder = (dimension, dataset) => {
  const {
    sort,
    alias
  } = dimension;
  if (!sort) {
    return DEFAULT_ORDER;
  }
  const {
    properties
  } = dataset;
  const snowflakeProperty = properties && properties.get(alias) && SnowflakeProperty(properties.get(alias)); // upstream `dataset.properties` uses `any` values

  const direction = SORT_ORDER_TO_DIRECTION[sort.order];

  // case: backend label sort
  const isBackendLabelSort = snowflakeProperty && shouldPropertyUseBackendLabelSort(snowflakeProperty) && sort.type === SortTypes.LABEL;
  if (isBackendLabelSort) {
    return Order({
      defined: undefined,
      direction,
      field: toLabelDependencyAlias(alias),
      aggregation: Aggregations.attribute
    });
  }

  // case: sort by column
  if (sort.by) {
    return Order({
      defined: undefined,
      direction,
      field: toSortDependencyAlias(alias),
      aggregation: Aggregations.attribute
    });
  }

  // case: sort by property.options
  const shouldSortByPropertyOptions = sort.type === SortTypes.LABEL || sort.type === SortTypes.DISPLAY_ORDER;
  if (shouldSortByPropertyOptions) {
    if (!snowflakeProperty || !snowflakeProperty.options) {
      console.warn('property.options is nil. Expected non-nil. A sort by label/display order requires property.options (for properties not sorted by the backend)! Falling back to "sorting by value".');
    } else {
      const defined = getDefinedOrderFromProperty(sort, snowflakeProperty);
      return Order({
        defined,
        direction,
        field: undefined,
        aggregation: undefined
      });
    }
  }

  // case: sort by value / fallback
  return Order({
    defined: undefined,
    direction,
    field: undefined,
    aggregation: undefined
  });
};