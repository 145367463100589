'use es6';

import { List, Map as ImmutableMap, fromJS, OrderedMap } from 'immutable';
import { FUNNEL, SEARCH } from '../../constants/configTypes';
import { toMetricKey } from './datasetMetrics';
import { formatForScientificNotation } from './utils';
const getDimensionLabels = dimension => dimension && dimension.get('buckets') ? ImmutableMap({
  [dimension.get('property')]: ImmutableMap({
    label: dimension.get('propertyLabel')
  })
}).merge(dimension.get('buckets').flatMap(bucket => getDimensionLabels(bucket.get('dimension')))) : ImmutableMap();
const readMetric = (types, key, pluck) => types.mapKeys(type => toMetricKey({
  property: key,
  type
})).map(value => pluck(value));
const readMetrics = (metrics, pluck = metric => metric.get('raw')) => metrics.toOrderedMap().flatMap((types, key) => readMetric(types, key, pluck));
const getMetricLabels = oldDataset => readMetrics(oldDataset.get('metrics', ImmutableMap()), metric => {
  let format = ImmutableMap({});
  if (metric.get('propertyMeta')) {
    format = format.set('currencyCode', metric.get('propertyMeta').currencyCode);
    format = format.set('durationUnit', metric.get('propertyMeta').durationUnit);
  }
  return ImmutableMap({
    format,
    label: metric.get('label')
  });
}).merge(oldDataset.getIn(['dimension', 'buckets'], List()).flatMap(bucket => getMetricLabels(bucket)));
const getReferenceDimensionLabels = (oldDataset, dimension) => oldDataset.getIn(['dimension', 'buckets'], List()).toMap().flatMap(bucket => oldDataset.getIn(['dimension', 'property']) === dimension ? ImmutableMap({
  [formatForScientificNotation(bucket.get('key'))]: ImmutableMap({
    label: String(bucket.get('keyLabel')),
    link: bucket.get('keyLink')
  })
}) : getReferenceDimensionLabels(bucket, dimension));
const getMetricReferenceLabels = (oldDataset, dimensionsToSkip, dimensionLevel, isSearch) => {
  const currentLevelMetricReferenceLabels = !dimensionsToSkip.includes(dimensionLevel) ? readMetrics(oldDataset.get('metrics') || ImmutableMap(), metric => {
    if (List.isList(metric.get('raw'))) {
      return metric.get('raw').reduce((acc, value, key) => {
        if (value === null) {
          return acc;
        }
        return acc.set(String(formatForScientificNotation(value)), ImmutableMap({
          label: String(metric.get('formatted').get(key))
        }));
      }, ImmutableMap());
    }
    const {
      url
    } = metric.get('propertyMeta', {});
    return metric.get('raw') !== null && metric.get('raw') !== undefined ? fromJS({
      [formatForScientificNotation(metric.get('raw'))]: {
        label: List.isList(metric.get('formatted')) ? metric.get('formatted').join(', ') : String(metric.get('formatted')),
        link: url
      }
    }) : ImmutableMap();
  }) : OrderedMap();
  return currentLevelMetricReferenceLabels.set('', 0) // https://github.com/immutable-js/immutable-js/issues/1475
  .mergeDeep(oldDataset.getIn(['dimension', 'buckets'], List()).flatMap(bucket => getMetricReferenceLabels(bucket, dimensionsToSkip, dimensionLevel + 1, isSearch))).delete(''); // https://github.com/immutable-js/immutable-js/issues/1475
};
const getMetricDimensionsToSkip = (config, isSummary) => {
  if (config.get('configType') === FUNNEL && !isSummary) {
    return [0];
  }
  return [];
};
export const upgradeLabels = (config, oldDataset, isSummary) => {
  const dimensions = config.get('dimensions').isEmpty() && oldDataset.has('dimension') ? List([oldDataset.getIn(['dimension', 'property'])]) : config.get('dimensions', List());
  const properties = getDimensionLabels(oldDataset.get('dimension')).merge(getMetricLabels(oldDataset));
  const isSearch = config.get('configType') === SEARCH;
  const metricDimesionsToSkip = getMetricDimensionsToSkip(config, isSummary);
  const references = dimensions.toMap().mapKeys((key, value) => value).map(dimension => getReferenceDimensionLabels(oldDataset, dimension)).merge(getMetricReferenceLabels(oldDataset, metricDimesionsToSkip, 0, isSearch));
  const propertiesWithReferences = properties.map((property, key) => property.set('references', references.get(key, ImmutableMap())));
  return propertiesWithReferences;
};