import { useCallback, useRef } from 'react';
import { BROADCAST_COMMENT_MESSAGE } from '../constants/CollaborationSidebarStateProperties';
import { MSG_TYPE_FETCH_COMMENTS } from '../constants/IFrameMessageTypes';
export const useForceRefreshComments = ({
  onUpdateCollaborationSidebarState,
  objectId,
  objectType
}) => {
  const isCoolingDown = useRef(false);
  return useCallback(() => {
    if (!objectId || !objectType || isCoolingDown.current) {
      return;
    }
    onUpdateCollaborationSidebarState({
      [BROADCAST_COMMENT_MESSAGE]: {
        type: MSG_TYPE_FETCH_COMMENTS
      }
    });
    isCoolingDown.current = true;
    setTimeout(() => {
      isCoolingDown.current = false;
    }, 1000);
  }, [objectId, objectType, onUpdateCollaborationSidebarState]);
};