export const EDITOR_TYPES = {
  BREAKDOWN: 'BREAKDOWN',
  DIMENSION: 'DIMENSION',
  METRIC: 'METRIC',
  TABLE_METRIC: 'TABLE_METRIC',
  PRESETS: 'PRESETS',
  QUICK_FILTER: 'QUICK_FILTER',
  USER_ADDED_QUICK_FILTER: 'USER_ADDED_QUICK_FILTER',
  SUMMARY_KPI_METRICS: 'SUMMARY_KPI_METRICS',
  OVERTIME_TOTALS: 'OVERTIME_TOTALS'
};
export function isQuickFilterEditorSchema(schema) {
  return schema.type === EDITOR_TYPES.QUICK_FILTER;
}
export function isUserAddedQuickFilterEditorSchema(schema) {
  return schema.type === EDITOR_TYPES.USER_ADDED_QUICK_FILTER;
}
export function isDimensionReportEditorSchema(schema) {
  return schema.type === EDITOR_TYPES.DIMENSION || schema.type === EDITOR_TYPES.BREAKDOWN;
}
export function isMetricReportEditorSchema(schema) {
  return schema.type === EDITOR_TYPES.METRIC;
}
export function isTableMetricReportEditorSchema(schema) {
  return schema.type === EDITOR_TYPES.TABLE_METRIC;
}
export function isPresetsReportEditorSchema(schema) {
  return schema.type === EDITOR_TYPES.PRESETS;
}
export function isSummaryKpiMetricsSchema(schema) {
  return schema.type === EDITOR_TYPES.SUMMARY_KPI_METRICS;
}
export function isOvertimeTotalsSchema(schema) {
  return schema.type === EDITOR_TYPES.OVERTIME_TOTALS;
}