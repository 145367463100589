import usePollingQuery from './usePollingQuery';
import { ALL_DASHBOARDS_WIDGET_COUNTS, FETCH_ALL_DASHBOARD_REPORT_COUNTS
// @ts-expect-error untyped module
} from '../requests/dashboards';
export const useAllDashboardReportCounts = () => {
  const {
    data,
    error,
    loading
  } = usePollingQuery(FETCH_ALL_DASHBOARD_REPORT_COUNTS);
  return {
    data: data ? data[ALL_DASHBOARDS_WIDGET_COUNTS] : undefined,
    error,
    loading
  };
};