'use es6';

import enviro from 'enviro';
import { RelationalReport } from '../../relational/schema/report-records';
import { updateTables, getTableList } from '../../relational/utils/table-utils';
import { getReportDefinition } from 'reporting-data/report/reportGetters';
const E_AD_INTERACTION_TABLE_NAME = 'e_ad_interaction';
const E_AD_INTERACTION_OBJECT_ID = enviro.isProd() ? '4-1553675' : '4-706542';
const E_AD_CLICKED_OBJECT_ID = enviro.isProd() ? '4-439199' : '4-58717';
export const hasAdsClickedEventTable = report => {
  const reportDefinition = getReportDefinition(report);
  if (!reportDefinition) {
    return false;
  }
  const relationalReport = RelationalReport(reportDefinition);
  const tableList = getTableList(relationalReport.table);
  return tableList.some(table => table.objectTypeId === E_AD_CLICKED_OBJECT_ID);
};
export const doMigration = report => {
  const reportDefintion = getReportDefinition(report);
  const relationalReport = RelationalReport(reportDefintion);
  const tableList = getTableList(relationalReport.table);
  const tableNamesThatReferenceAdClicked = tableList.filter(table => table.objectTypeId === E_AD_CLICKED_OBJECT_ID).map(table => table.name);
  const maybeReplaceColumn = column => tableNamesThatReferenceAdClicked.includes(column.field.table) ? column.setIn(['field', 'table'], E_AD_INTERACTION_TABLE_NAME) : column;
  const nextReportDefinition = relationalReport.update('table', nextTables => updateTables(nextTables, table => table.objectTypeId === E_AD_CLICKED_OBJECT_ID ? table.set('name', E_AD_INTERACTION_TABLE_NAME).set('objectTypeId', E_AD_INTERACTION_OBJECT_ID) : table)).update('columns', columns => columns.map(maybeReplaceColumn)).update('stagedColumns', stagedColumns => stagedColumns.map(maybeReplaceColumn)).update('filtering', filtering => filtering.update('groups', groups => groups.map(group => group.update('filters', filters => filters.map(filter => tableNamesThatReferenceAdClicked.includes(filter.field.table) ? filter.setIn(['field', 'table'], E_AD_INTERACTION_TABLE_NAME) : filter)))).update('stagedFilters', stagedFilters => stagedFilters.map(stagedFilter => tableNamesThatReferenceAdClicked.includes(stagedFilter.table) ? stagedFilter.set('table', E_AD_INTERACTION_TABLE_NAME) : stagedFilter)));
  return report.set('reportDefinition', nextReportDefinition);
};
export const adClickedEventDeprecation = report => {
  return Promise.resolve(hasAdsClickedEventTable(report) ? doMigration(report) : report);
};