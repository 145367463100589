'use es6';

import * as dataTypes from '../../constants/dataTypes';
import { namespaceProperty } from '../namespaceProperty';
const DEAL_AMOUNT_PROPERTIES = ['amount_in_deal_currency', 'closed_amount_in_deal_currency', 'projected_amount_in_deal_currency', 'amount', 'closedAmount', 'hs_closed_amount', 'projectedAmount', 'hs_projected_amount', 'hs_tcv', 'hs_mrr', 'hs_arr'];
export const LEGACY_DEAL_AMOUNT_PROPERTIES = ['projectedAmount', 'closedAmount'];
const LINE_ITEM_AMOUNT_PROPERTIES = ['amount', 'price', 'hs_tcv'];
export const CURRENCY_PROPERTY_NAME = 'currencyPropertyName';
export const DEAL_CURRENCY_CODE = 'deal_currency_code';
export const LINE_ITEM_CURRENCY_CODE = 'hs_line_item_currency_code';
const AMOUNT_PROPERTIES_BY_DATA_TYPE = {
  [dataTypes.DEALS]: DEAL_AMOUNT_PROPERTIES,
  [dataTypes.LINE_ITEMS]: LINE_ITEM_AMOUNT_PROPERTIES,
  [dataTypes.CROSS_OBJECT]: [...DEAL_AMOUNT_PROPERTIES.map(property => namespaceProperty(dataTypes.DEALS, property)), ...LINE_ITEM_AMOUNT_PROPERTIES.map(property => namespaceProperty(dataTypes.LINE_ITEMS, property))]
};
export const AMOUNT_PROPERTIES = Object.values(AMOUNT_PROPERTIES_BY_DATA_TYPE).reduce((acc, arr) => [/* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
...acc, ...arr], []);
export const CURRENCY_CODE_BY_DATA_TYPE = {
  [dataTypes.DEALS]: DEAL_CURRENCY_CODE,
  [dataTypes.LINE_ITEMS]: LINE_ITEM_CURRENCY_CODE
};
const SINGLE_OBJECT_AMOUNT_IN_HOME_CURRENCY_PROPERTIES = ['amount_in_home_currency', 'projectedAmountInHomeCurrency', 'hs_projected_amount_in_home_currency', 'closedAmountInHomeCurrency', 'hs_closed_amount_in_home_currency', 'deal_amount_in_home_currency', 'deal_projectedAmountInHomeCurrency', 'deal_closedAmountInHomeCurrency'];
const CROSS_OBJECT_AMOUNT_IN_HOME_CURRENCY_PROPERTIES = SINGLE_OBJECT_AMOUNT_IN_HOME_CURRENCY_PROPERTIES.map(property => namespaceProperty(dataTypes.DEALS, property));
export const AMOUNT_IN_HOME_CURRENCY_PROPERTIES = [...SINGLE_OBJECT_AMOUNT_IN_HOME_CURRENCY_PROPERTIES, ...CROSS_OBJECT_AMOUNT_IN_HOME_CURRENCY_PROPERTIES];