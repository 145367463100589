import { getConfigs } from '../multiConfigReportGetters';

/**
 * Core setter function for multi-config reports that sets a value at a specific path for all report configs
 */
export function setInMultiConfigReport(report, key, value) {
  const updatedConfigs = getConfigs(report).map(config => {
    return config.setIn(key, value);
  });
  return report.setIn(['config', ...key], value).set('reportConfigs', updatedConfigs);
}

/**
 * Sets the limit for all report configs
 */
export const setLimit = (report, limit) => setInMultiConfigReport(report, ['limit'], limit);

/**
 * Sets the offset for all report configs
 */
export const setOffset = (report, offset) => setInMultiConfigReport(report, ['offset'], offset);

/**
 * Updates the date range property for all report configs
 */
export const updateDateRangeProperty = (report, newProperty) => setInMultiConfigReport(report, ['filters', 'dateRange', 'property'], newProperty);