import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
// @ts-expect-error migrate dependency
import { useShowDialog } from 'reporting-action-components/dialog/ducks';
// @ts-expect-error migrate dependency
import { useUserRoleForDashboard } from 'reporting-action-components/utils/trackingUtils';
import { MANAGE_RECURRING_EMAILS_DIALOG } from 'reporting-action-components/dialog/dialogTypes';
// @ts-expect-error migrate dependency
import { getId } from 'dashboard-lib/public/dashboard/dashboard-schema';
export const useRecurringEmailDialogParam = dashboard => {
  const history = useHistory();
  const [showingDialog, setShowingDialog] = useState(false);
  const showDialog = useShowDialog();
  const userRole = useUserRoleForDashboard(dashboard);
  const dashboardId = getId(dashboard);
  const showRecurringEmails = history.location.search.includes('showRecurringEmails=true');
  useEffect(() => {
    if (showRecurringEmails && !showingDialog) {
      setShowingDialog(true);
      showDialog({
        dialogType: MANAGE_RECURRING_EMAILS_DIALOG,
        dialogProps: {
          dashboard,
          objectId: dashboardId,
          objectType: 'DASHBOARD',
          assetSpecificTrackingProps: {
            role: userRole
          }
        }
      });
    }
  }, [dashboard, showDialog, showRecurringEmails, showingDialog, userRole, dashboardId]);
};