import { NUMBER, STRING } from 'customer-data-objects/property/PropertyTypes';
import { hasUseIsExternalOptionsProperty } from '../permissions/gates';

/**
 * When ungated, String and Number properties that are configured to use
 * external options should be treated as an Enumeration
 */
export const isExternalOptionsEnum = field => {
  /*
   * When detecting external options properties, we don't need to check against field definitions anymore.
   * So, this method (isExternalOptionsEnum) does not need to be called anymore.
   * We want to gate this change, so always returning true when ungated for now, and
   * once the gate is at 100%, this method and all calls to it will be deleted.
   */
  if (hasUseIsExternalOptionsProperty()) {
    return true;
  }
  if (!field || typeof field.get !== 'function') {
    return false;
  }
  const isStringOrNumber = field.get('type') === STRING || field.get('type') === NUMBER;
  const configuredToUseExternalOptions = !!field.get('externalOptionsReferenceType');
  return isStringOrNumber && configuredToUseExternalOptions;
};