import { registerQuery, useQuery } from 'data-fetching-client';
import { httpClientWithRaven } from '../../clients/hubHttpWithRaven';
export const GET_ENRICHMENT_BANNER_DATA_QUERY = registerQuery({
  fieldName: 'enrichmentBannerData',
  args: [],
  fetcher() {
    return httpClientWithRaven.get('data-enrichment-app/notifications/v1/notifications/banner/display');
  }
});
export const useEnrichmentBannerData = () => {
  const {
    data,
    loading,
    error
  } = useQuery(GET_ENRICHMENT_BANNER_DATA_QUERY);
  return {
    data: data === null || data === void 0 ? void 0 : data.enrichmentBannerData,
    loading,
    error
  };
};