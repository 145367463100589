import { GOAL_TARGET } from 'reporting-data/constants/dataTypes';
import { DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES } from 'reporting-data/constants/objectCoordinates';
import { IN } from 'reporting-data/constants/operators';
import { getDataTypeFromConfig, getDisplayParamsSubAppKey } from 'reporting-data/report/configReportGetters';
import { getConfigByName, getConfigs } from 'reporting-data/report/multiConfigReportGetters';
import { isMultiConfigReport } from 'reporting-data/tsTypes/reportTypes';
import { SUB_APPS } from 'reporting-data/tsTypes/subAppTypes';
import { Map as ImmutableMap, List as ImmutableList } from 'immutable';
const SUB_APP_KEYS_WITH_GOALS = [SUB_APPS.weightedPipelineForecast];
const MAPPED_GOAL_TARGET_PROPERTIES = {
  GOAL_TARGET_ASSIGNEE_TYPE_PROPERTY: 'hs_assignee_type'
};
const PROPERTY_TO_GOAL_CRM_FILTER = {
  hubspot_owner_id: ImmutableList([ImmutableMap({
    property: MAPPED_GOAL_TARGET_PROPERTIES.GOAL_TARGET_ASSIGNEE_TYPE_PROPERTY,
    operator: IN,
    values: ['users']
  })]),
  hubspot_team_id: ImmutableList([ImmutableMap({
    property: MAPPED_GOAL_TARGET_PROPERTIES.GOAL_TARGET_ASSIGNEE_TYPE_PROPERTY,
    operator: IN,
    values: ['teams']
  })])
};
export const getReportIsSubAppWithGoals = report => {
  const subAppKey = getDisplayParamsSubAppKey(report);
  return SUB_APP_KEYS_WITH_GOALS.includes(subAppKey);
};
export const updateGoalFilterGroupsForDimension = (currentFilterGroups, dimension) => currentFilterGroups.update(filterGroups => filterGroups.map(filterGroup => filterGroup.update('filters', innerFilters => {
  const filtersWithoutGoalFilters = innerFilters.filter(filter => !Object.values(MAPPED_GOAL_TARGET_PROPERTIES).includes(filter.get('property')));
  if (Object.keys(PROPERTY_TO_GOAL_CRM_FILTER).includes(dimension)) {
    return filtersWithoutGoalFilters.concat(PROPERTY_TO_GOAL_CRM_FILTER[dimension]);
  }
  return filtersWithoutGoalFilters;
})));
export const getGoalReportConfigName = report => {
  var _secondaryConfigNames;
  if (!isMultiConfigReport(report)) return;
  const secondaryConfigNames = getConfigs(report).keySeq().toArray();
  return (_secondaryConfigNames = secondaryConfigNames.find(configName => {
    const config = getConfigByName(report, configName.toString());
    return getDataTypeFromConfig(config) === DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(GOAL_TARGET);
  })) === null || _secondaryConfigNames === void 0 ? void 0 : _secondaryConfigNames.toString();
};