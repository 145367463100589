'use es6';

import { fromJS, List, Map as ImmutableMap } from 'immutable';
import { getBackendResolveDatetimeGoalData, getDatetimeGoalData } from './goalDateTimeSeries';
import { getBackendResolveDatetimeQuotaData, getDatetimeQuotaData } from './quotaSeries';
export const CUSTOM_SERIES_DATASET_PREFIX = 'custom-series-';
const CUSTOM_SERIES_LIST = {
  'datetime.goal': getDatetimeGoalData,
  'datetime.quota': getDatetimeQuotaData
};
const CUSTOM_SERIES_NEW_DATASET_LIST = {
  'datetime.goal': getBackendResolveDatetimeGoalData,
  'datetime.quota': getBackendResolveDatetimeQuotaData
};
export function resolveV2CustomSeries(report, reportDataset, isNewDatasetFormat = false, fiscalYearMonthName) {
  const customSeriesList = report && fromJS(report.getIn(['displayParams', 'customSeries'])) || List();
  const customSeriesMapper = isNewDatasetFormat ? CUSTOM_SERIES_NEW_DATASET_LIST : CUSTOM_SERIES_LIST;
  const promises = customSeriesList.filter(series => typeof series === 'string' ? customSeriesMapper[series] : customSeriesMapper[series.get('type')]).map(series => {
    const seriesFunc = customSeriesMapper[typeof series === 'string' ? series : series.get('type')];
    return typeof series === 'string' ? seriesFunc(report, reportDataset, fiscalYearMonthName) : seriesFunc(report, reportDataset, series.toJS());
  }).toJS();
  return promises.count === 0 ? ImmutableMap() : Promise.all(promises).then(customSeriesDatasets => {
    return fromJS(customSeriesDatasets).toMap().mapKeys(k => `${CUSTOM_SERIES_DATASET_PREFIX}${k}`);
  });
}