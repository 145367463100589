'use es6';

import { List, Map as ImmutableMap, Record } from 'immutable';
import { getStage } from '../../configure/bucket/lifecyclestage';
import { SEARCH } from '../../constants/configTypes';
import { COUNT } from '../../constants/metricTypes';
import cached from '../../lib/cached';
import { debug } from '../../lib/debug';
import { shouldBeSmallScaleV1 } from '../../lib/smallScale';
import getMetricLabel from '../../public/get-metric-label';
import propertyFormatter from '../propertyFormatter';
import fetchHomeCurrency from './currency';
import * as oneOff from './one-off';
const timestamps = ['engagement.timestamp'];
const invalidMetricValueWarning = cached('invalidMetricValueWarning', property => console.error(`[reporting-data] invalid metric value for ${property}`));
const PropertyMeta = Record({
  type: null,
  currencyCode: null,
  durationUnit: 'milliseconds',
  inverseDeltas: false,
  shouldFormat: true,
  url: null
});
export default ((config = ImmutableMap(), properties = ImmutableMap(), dataset = ImmutableMap()) => {
  const dataType = config.get('dataType');
  const configType = config.get('configType');
  const frequency = config.get('frequency');
  const smallScaleMetrics = shouldBeSmallScaleV1(dataset, config, properties);
  return fetchHomeCurrency(config, properties.get(dataType, ImmutableMap())).then(getCurrencyCode => {
    const getPropertyInfo = key => {
      // note: special case lifecycle stage bucket
      if (key === 'lifecyclestage') {
        const stage = getStage(config);
        if (stage) {
          const property = `BUCKET_hs_date_entered_${stage}_enteredCount`;
          return properties.getIn([dataType, property]) || ImmutableMap();
        }
      }
      const info = properties.getIn([dataType, key]) || ImmutableMap();
      const property = info.get('name');
      if (configType === SEARCH && timestamps.includes(property)) {
        return info.set('type', 'timestamp');
      }
      return info;
    };
    const hydrateMetricValue = (value, type, property) => {
      const propertyInfo = getPropertyInfo(property);
      const oneOffLabeler = oneOff.get(property, config);
      const propertyLabel = oneOffLabeler && oneOffLabeler.format(property, config) || propertyInfo.get('label');
      if (!propertyLabel) {
        debug.once('hydrate/dataset', `missing property: ${property}`);
      }
      const currencyCode = getCurrencyCode(property, propertyInfo);
      const propertyType = propertyInfo.get('type');
      const durationUnit = propertyInfo.get('durationUnit');
      const propertyMeta = PropertyMeta({
        type: propertyType,
        currencyCode: propertyType === 'currency' ? currencyCode : undefined,
        durationUnit,
        inverseDeltas: propertyInfo.get('inverseDeltas'),
        shouldFormat: propertyInfo.get('numberDisplayHint') !== 'unformatted',
        url: propertyInfo.getIn(['options', value, 'url'])
      });
      return ImmutableMap({
        raw: value,
        formatted: propertyFormatter(value, propertyInfo, {
          currencyCode,
          frequency,
          metricType: type,
          configType,
          dataType,
          smallScale: configType === SEARCH || smallScaleMetrics.getIn([property, type], false)
        }),
        label: getMetricLabel(propertyLabel, type, property),
        propertyMeta
      });
    };
    const hydrateMetrics = (metrics, property) => {
      return metrics.map((number, type) => {
        if (ImmutableMap.isMap(number) && (number.has('raw') || number.has('formatted'))) {
          const hydrated = hydrateMetricValue(number.get('raw'), type, property);
          return hydrated.merge(number);
        }
        if (number != null && typeof number !== 'number' && typeof number !== 'string' && !List.isList(number)) {
          invalidMetricValueWarning(property);
        }
        return hydrateMetricValue(number, type, property);
      });
    };
    const hydrateDimension = dimension => dimension.withMutations(mutable => {
      const property = dimension.get('property');
      const propertyInfo = getPropertyInfo(property);
      const propertyLabel = mutable.get('propertyLabel') || propertyInfo.get('label') || property;
      mutable.set('propertyLabel', propertyLabel);
      if (dimension.has('buckets')) {
        mutable.update('buckets', buckets =>
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        buckets.map(bucket => hydrateBucket(bucket, propertyInfo)));
      }
    });
    const hydrateBucketOrDataset = mutable => {
      if (mutable.has('metrics')) {
        mutable.update('metrics', metrics => metrics.map(hydrateMetrics));
      }
      if (mutable.has('dimension')) {
        mutable.update('dimension', hydrateDimension);
      }
    };
    const hydrateBucket = (bucket, propertyInfo) => bucket.withMutations(mutable => {
      const key = mutable.get('key');
      hydrateBucketOrDataset(mutable);
      if (key || key === '') {
        const keyLabel = mutable.get('keyLabel') || propertyFormatter(key, propertyInfo, {
          frequency
        });
        const keyLink = propertyInfo.getIn(['options', key, 'url']);
        if (keyLink) {
          mutable.set('keyLink', keyLink);
        }
        mutable.set('keyLabel', keyLabel);
      }
    });
    const hydrateDataset = data => data.withMutations(mutable => {
      hydrateBucketOrDataset(mutable);
      if (mutable.has('total')) {
        mutable.update('total', total => hydrateMetricValue(total, COUNT, 'total'));
      }
    });
    return hydrateDataset(dataset);
  });
});