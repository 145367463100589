export const STRING = 'string';
export const ENUMERATION = 'enumeration';
export const DATE = 'date';
export const DATE_TIME = 'datetime';
export const TIMESTAMP = 'timestamp';
export const NUMBER = 'number';
export const PERCENT = 'percent';
export const CURRENCY = 'currency';
export const DURATION = 'duration';
export const BOOLEAN = 'bool';
export const BUCKETS = 'buckets';
export const UNKNOWN = 'UNKNOWN';
// https://git.hubteam.com/HubSpot/ReportingApi/blob/a9302c3b2ddf96848dd8b7af7fdd7e17a45179a6/documentation/reference-resolving-spec.md
export const ID = 'id';
export const OBJECT_COORDINATES = 'object_coordinates';