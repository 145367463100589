'use es6';

import { Map as ImmutableMap } from 'immutable';
export const removeConfigNulls = report => {
  // The dashboard service returns nulled values, but reporting-platform expects undefined
  // The isMap check keeps the existing behavior for report types with Record-type configs and filters
  if (!ImmutableMap.isMap(report.get('config'))) {
    return report;
  }
  return report.update('config', config => config.filter(value => value != null).update('filters', filters => filters && ImmutableMap.isMap(filters) ? filters.filter(value => value != null) : filters));
};