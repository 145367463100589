'use es6';

import { Promise } from '../../../../lib/promise';
import getContactLifecycles from '../../../../properties/partial/contacts-lifecyclestages';
import getContactEvents from '../../../../properties/partial/contacts-events';
import { CONTACTS, DEALS } from '../../../../constants/dataTypes';
import Request from '../../../../request/Request';
import transformFunnel from '../transform/funnel';
import getStagesExtractor from '../../common/extractors/stages';
import getPipelineIdExtractor from '../../common/extractors/pipeline';
import getDealStages from './deal-stages';
import buildPipelineFunnelPayload from './pipeline-funnel-payload';
import { DeprecatedPropertyException } from '../../../../exceptions';
import PortalIdParser from 'PortalIdParser';
const CRM_SEARCH_URL = 'crm-search/report/multi/beta';
const getStagesByDataType = {
  [CONTACTS]: (_, dimension) => dimension === 'hs_contact_source_event' ? getContactEvents() : getContactLifecycles(),
  [DEALS]: getDealStages
};
const pipelineIdExtractor = getPipelineIdExtractor();
export default (config => {
  const dataType = config.get('dataType');
  const dimension = config.get('dimensions').first();
  const pipelineId = pipelineIdExtractor(config);
  return Promise.resolve(getStagesByDataType[dataType](pipelineId, dimension)).then(allStageInfo => allStageInfo.map(stageInfo => stageInfo.get('value'))).then(allStages => {
    const stages = getStagesExtractor(allStages)(config);
    if (stages.isEmpty()) {
      throw new DeprecatedPropertyException('stages');
    }
    const payload = buildPipelineFunnelPayload(config, dataType, stages, pipelineId);
    return Request.post({
      url: CRM_SEARCH_URL,
      data: payload.map(part => part.set('portalId', PortalIdParser.get()).set('objectTypeId', dataType)),
      transformer: transformFunnel(stages)
    });
  });
});