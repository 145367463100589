'use es6';

import { List } from 'immutable';
import { ATTRIBUTION_TOUCH_POINTS, CONTACT_CREATE_ATTRIBUTION, ENGAGEMENT, FEEDBACK_SUBMISSIONS } from '../constants/dataTypes';
import { GLOBAL_NULL } from '../constants/defaultNullValues';
import { ASC } from '../constants/sortOrder';
import { ALPHA } from '../constants/sortType';
import { collectSubaggregationKeys, createDenseDataset } from './helpers';

/**
 * Display order sort property suffix
 *
 * @type {RegExp}
 * @constant
 */
const SUFFIX = /.displayOrder$/;

/**
 * Display order sort property suffix
 *
 * @param {string} property Property with suffix
 * @returns {string} Property without suffix
 */
const removeSuffix = property => typeof property === 'string' ? property.replace(SUFFIX, '') : null;

/**
 * Create masking function for display order
 *
 * @param {string} dataType Data type
 * @param {Immutable.Map} propertyGroups Property groups
 * @returns {function} Display order mask function
 */
const createDisplayOrderMask = (dataType, propertyGroups, sorts = List()) => property => propertyGroups.hasIn([dataType, property, 'options', 0, 'displayOrder']) || sorts.some(sortedField => sortedField.property === property) ? property : null;

/**
 * Create display order getter from property groups
 *
 * @param {string} dataType Data type
 * @param {Immutable.Map} propertyGroups Property groups
 * @returns {function} Display order getter function creator
 */
const createDisplayOrderGetter = (dataType, propertyGroups) => property => value => {
  const options = propertyGroups.getIn([dataType, property, 'options'], List());
  const found = options.find(option => value === option.get('value'));
  if (found) {
    return found.get('displayOrder');
  }
  return value === GLOBAL_NULL ? -999 : -1;
};

/**
 * Create getter based comparator
 *
 * @param {function} getter Field getter
 * @param {ASC|DESC} order Sort order
 * @returns {function} Comparator function
 */
const createComparator = (getter, order) => (first, second) => {
  const [a, b] = [first.get('key'), second.get('key')];
  const result = order === ASC ? getter(a) - getter(b) : getter(b) - getter(a);
  if (result === 0) {
    // For equal display orders, use alpha sort on keys as tiebreaker
    const orderFactor = order === ASC ? 1 : -1;
    return orderFactor * (a >= b ? 1 : -1);
  }
  return result;
};
const createAlphaGetter = (dataType, propertyGroups, property, keys) => value => {
  const options = propertyGroups.getIn([dataType, property, 'options'], List());
  const found = options.some(option => value === option.get('value')) || keys.has(value);
  const adjustedValue = value === GLOBAL_NULL ? null : value;
  return found ? adjustedValue : null;
};
const createAlphaComparator = (getter, order) => (first, second) => {
  const [a, b] = [first, second].map(value => value.get('key')).map(getter);
  const orderFactor = order === ASC ? 1 : -1;
  if (a === null) {
    return orderFactor * -1;
  }
  if (b === null) {
    return orderFactor;
  }
  return orderFactor * (a >= b ? 1 : -1);
};
const getAlphaOrder = ({
  property,
  order: defaultOrder
}, nonDisplaySorts) => {
  if (nonDisplaySorts) {
    const propertySort = nonDisplaySorts.find(sort => sort.property === property);
    if (propertySort.type === ALPHA) {
      return propertySort.order;
    }
  }
  return defaultOrder;
};

/**
 * Get non display order sorts, excluding those which aren't on dimesion or
 * metric properties
 *
 * @param {ImmutableMap} config The report config
 * @param {object[]} sorts Configuration defined sorts
 * @returns {object} Non display order sorts
 */
const getNonDisplayOrderSorts = (config, sorts) => {
  const sortableProperties = config.get('dimensions', List()).concat(config.get('metrics', List()).map(metric => metric.get('property')));
  return sorts.filter(({
    property
  }) => !SUFFIX.test(property) && sortableProperties.includes(property));
};

/**
 * Get existing dimension sorts
 *
 * @param {Iterable} dimensions Dimensions
 * @param {object[]} sorts Configuration defined sorts
 * @returns {object} Existing display order sorts
 */
const getExistingDimensionSorts = (dimensions, sorts) => dimensions.reduce((memo, dimension) => {
  const {
    order = ASC
  } = sorts.find(sort => removeSuffix(sort.property) === dimension) || {};
  return [/* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
  ...memo, {
    property: dimension,
    order
  }];
}, []);
const nonDisplayParamSortIsOnSecondDimension = (nonDisplayParamSorts, {
  property: secondDimProperty
} = {}) => {
  return secondDimProperty && nonDisplayParamSorts.length === 1 && nonDisplayParamSorts[0].property === secondDimProperty;
};
const skipSortProperties = {
  [FEEDBACK_SUBMISSIONS]: ['hs_survey_name', 'hs_survey_channel', 'hs_response_group', 'hs_survey_type'],
  [ENGAGEMENT]: ['engagement.type', 'task.status', 'task.taskType']
};
const shouldNotDisplayOrderSort = (datatype, property) => skipSortProperties[datatype] && skipSortProperties[datatype].includes(property);

/**
 * Sort data set by display orders
 *
 * @param {Immutable.Map} config Report configuration
 * @param {Immutable.Map} propertyGroups Property groups
 * @param {Immutable.Map} data Data format
 * @returns {Immutable.Map} Data format sorted by display orders
 */
export const sortByDisplayOrder = (config, propertyGroups, data) => {
  const dataType = config.get('dataType');
  const dimensions = config.get('dimensions');
  const sorts = config.get('sort', List()).toJS();
  const maskDisplayOrder = createDisplayOrderMask(dataType, propertyGroups, sorts);
  const dimensionsMask = dimensions.map(maskDisplayOrder);
  if (dimensionsMask.some(Boolean)) {
    const nonDisplayParamSorts = getNonDisplayOrderSorts(config, sorts);
    const [first, second] = getExistingDimensionSorts(dimensionsMask, sorts);
    const getDisplayOrder = createDisplayOrderGetter(dataType, propertyGroups);
    const nonDisplaySortExistsOnSecondDimension = nonDisplayParamSortIsOnSecondDimension(nonDisplayParamSorts, second);
    let sorted = data;
    if (first && first.property && (nonDisplayParamSorts.length === 0 || nonDisplaySortExistsOnSecondDimension) && !shouldNotDisplayOrderSort(dataType, first.property)) {
      const getter = getDisplayOrder(first.property);
      const comparator = createComparator(getter, first.order);
      sorted = sorted.updateIn(['dimension', 'buckets'], buckets => buckets.sort(comparator));
    }
    const secondDimensionPropertyTypeIsEnumeration = second && second.property ? propertyGroups.getIn([dataType, second.property, 'type']) === 'enumeration' : false;

    // Attribution exception @ssdavis
    if (second && second.property && ![ATTRIBUTION_TOUCH_POINTS, CONTACT_CREATE_ATTRIBUTION].includes(dataType) && secondDimensionPropertyTypeIsEnumeration) {
      const keys = collectSubaggregationKeys(sorted);
      const useDisplayOrderSort = !nonDisplaySortExistsOnSecondDimension && !shouldNotDisplayOrderSort(dataType, second.property);
      let getter;
      let comparator;
      if (useDisplayOrderSort) {
        getter = getDisplayOrder(second.property);
        comparator = createComparator(getter, second.order);
      } else {
        getter = createAlphaGetter(dataType, propertyGroups, second.property, keys);
        comparator = createAlphaComparator(getter, getAlphaOrder(second, nonDisplaySortExistsOnSecondDimension && nonDisplayParamSorts));
      }
      sorted = createDenseDataset(keys, sorted, config.getIn(['metrics', 0])).updateIn(['dimension', 'buckets'], outers => outers.map(outer => outer.updateIn(['dimension', 'buckets'], inner => inner.sort(comparator))));
    }
    return sorted;
  }
  return data;
};
export const __TESTABLE__ = {
  removeSuffix,
  createDisplayOrderMask,
  createDisplayOrderGetter,
  createComparator,
  getNonDisplayOrderSorts,
  getExistingDimensionSorts
};