import { List, fromJS } from 'immutable';
import { BAR, COLUMN, LINE, TABLE } from '../../constants/chartTypes';
import { getReportChartType } from '../reportGetters';
import { getFieldsForUpdatingSubtitleField } from './multiConfigReportDisplayParamSetter';
import { fromMetricKey } from '../../v2/dataset/datasetMetrics';
import { XY } from '../../tsTypes/visualization';
import { getFieldOptions } from '../multiConfigReportGetters';

/**
 * Adds (or substitutes if it exists already) a new field option to the report config.
 */
export const setFieldOptions = (report, configName, property, metricType, subMetricType, subLabel) => {
  const subtitleField = subMetricType || subLabel ? {
    metric: subMetricType && property ? `${subMetricType}|${property}` : null,
    propertyLabel: subLabel || null
  } : null;
  const newFieldOption = {
    field: `${metricType}|${property}`,
    dataset: configName,
    subtitleField
  };
  return report.updateIn(['visualization', 'layer', 'fieldOptions'], fieldOptions => {
    if (!fieldOptions || fieldOptions.length === 0) {
      return fromJS([newFieldOption]);
    }
    const fieldOptionDatasets = fieldOptions.map(fieldOption => fieldOption.get('dataset'));
    if (fieldOptionDatasets.includes(configName)) {
      return fieldOptions.map(fieldOption => fieldOption.get('dataset') === configName ? fromJS(newFieldOption) : fieldOption);
    }
    return fieldOptions.concat(fromJS([newFieldOption]));
  });
};

/**
 * Deletes all field options associated with a config.
 */
export const deleteFieldOptionsByConfigName = (report, configName) => {
  return report.updateIn(['visualization', 'layer', 'fieldOptions'], fieldOptions => fieldOptions && fieldOptions.filter(option => option.get('dataset') !== configName));
};

/**
 * Update the subAggregationLabel for the a specific config
 */
export const setSubAggregationLabel = (report, configName, subAggregationLabel) => {
  const {
    property,
    metricType,
    subtitleField
  } = getFieldsForUpdatingSubtitleField(report, configName);
  if (!metricType) {
    console.warn(`No metric type found for config ${configName}`);
    return report;
  }
  return setFieldOptions(report, configName, property, metricType, fromMetricKey(subtitleField.get('metric')).type, subAggregationLabel);
};

/**
 * Update the chart visualization after a multi-config dimension update.
 */
export const setChartVisualizationFromDimensionUpdate = (report, previousDimension, newDimension) => {
  if (report.getIn(['visualization', 'type']) !== XY) {
    return report;
  }
  return report.updateIn(['visualization', 'layers'], layers => {
    if (layers) {
      return layers.map(layer => layer.map(axis => {
        if (axis.get && axis.get('field')) {
          return axis.get('field') !== previousDimension ? axis : axis.set('field', newDimension);
        }
        return axis;
      }));
    }
    return layers;
  }).updateIn(['displayParams', 'dataColumns'], dataColumns => dataColumns.map(dataColumn => dataColumn !== previousDimension ? dataColumn : newDimension));
};

/**
 * Update the table visualization after a multi-config dimension update.
 */
export const setTableVisualizationFromDimensionUpdate = (report, previousDimension, newDimension) => report.updateIn(['visualization', 'columns'], columns => columns.map(column => column.get('field') !== previousDimension ? column : column.set('field', newDimension)));

/**
 * Sets the dimensions for each config of the report. Currently, this setter will only affect table reports,
 * but it is designed to be extensible for other chart types in the future.
 */
export const setDimensions = (report, previousDimensions, newDimensions) => {
  // Multi-config reports only support one dimension, and each dimension needs to be the same for each config.
  if ([TABLE, BAR, LINE, COLUMN].includes(getReportChartType(report))) {
    const firstPreviousDimension = previousDimensions.get(0);
    const firstNewDimension = newDimensions.get(0);
    const reportWithNewDimensions = report.update('reportConfigs', reportConfigs => reportConfigs.map(config => config.set('dimensions', List([firstNewDimension]))));
    if (getReportChartType(report) === TABLE) {
      return setTableVisualizationFromDimensionUpdate(reportWithNewDimensions, firstPreviousDimension, firstNewDimension);
    } else {
      return setChartVisualizationFromDimensionUpdate(reportWithNewDimensions, firstPreviousDimension, firstNewDimension);
    }
  }
  return report;
};

/**
 * Updates the order of configs in a multi-config report by reordering the fieldOptions array.
 * The new order is determined by the provided array of config names (datasets).
 * This is used to support drag-and-drop reordering of configs in the UI.
 */
export const setFieldOptionsOrder = (report, newOrder) => {
  // re-sort fieldOptions array based on the newOrder array
  const fieldOptions = getFieldOptions(report);
  const sortedFieldOptions = fieldOptions.sort((a, b) => {
    const indexA = newOrder.indexOf((a === null || a === void 0 ? void 0 : a.get('dataset')) || '');
    const indexB = newOrder.indexOf((b === null || b === void 0 ? void 0 : b.get('dataset')) || '');
    return indexA - indexB;
  });
  return report.setIn(['visualization', 'layer', 'fieldOptions'], sortedFieldOptions);
};