'use es6';

import { List, fromJS } from 'immutable';
import invariant from '../../lib/invariant';
import { matchCreated } from '../../configure/bucket/created';
const precondition = config => {
  const dimensions = config.get('dimensions') || List();
  invariant(matchCreated(config), `expected dimensions to be created bucket property, but got %s`, dimensions);
};
export default (({
  dataConfig,
  dataset
}) => {
  precondition(dataConfig);
  return dataset.set('dimension', fromJS({
    property: 'BUCKET_createdate_enteredCount',
    buckets: [{
      key: 'YES',
      metrics: dataset.get('metrics')
    }]
  }));
});