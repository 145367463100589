import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["children", "error", "formatter", "parser", "onChange", "maxFractionDigits", "value", "formatStyle", "onRawValueChange"];
import { isNaN } from '../../utils/color';
import { Component } from 'react';
import devLogger from 'react-utils/devLogger';
import { syntheticEvent } from '../../utils/SyntheticEvent';
import { callIfPossible } from '../../utils/functions';
import { parseNumber, formatPercentage, formatNumber } from 'I18n/utils/internal/light/numberFormatters';
const BLANK_REGEX = /^\s*$/;
const MINUS_SIGN = '\u2212';
const INVALID_CHARS_REGEX = new RegExp(`[^0-9.,${MINUS_SIGN}-]`, 'g');
const formatAbstractPercentage = (value, {
  precision
}) => formatPercentage(value, {
  precision: value % 1 === 0 ? 0 : precision || 2
});
const NUMBER_TYPES = {
  DECIMAL: 'decimal',
  PERCENTAGE: 'percentage'
};
const isScientificNotation = value => {
  const strToNum = Number(value);
  return strToNum >= Math.pow(10, 21) || strToNum <= -Math.pow(10, 21);
};
const getParsedValue = (rawValue, parser, parserOptions) => {
  if (parser) {
    const parserResult = parser(rawValue);
    if (parserResult != null && typeof parserResult !== 'number') {
      if (process.env.NODE_ENV !== 'production') {
        devLogger.warn({
          message: `AbstractNumberInput: Expected \`parser(value)\` to return a number or null, but ` + `it returned \`${parserResult}\`.`,
          key: 'AbstractNumberInput: bad parser result'
        });
      }
    } else {
      return parserResult;
    }
  }
  let valueToParse = String(rawValue);
  // Ignore extraneous characters if the value is less than or greater than 1e+21.
  // This is to ensure that exceedlingly large numbers that convert to scientific notation are not negatively impacted.

  if (!isScientificNotation(rawValue)) {
    valueToParse = valueToParse.replace(INVALID_CHARS_REGEX, '');
  }
  const parsedValue = BLANK_REGEX.test(valueToParse) ? null : parseNumber(valueToParse, parserOptions && parserOptions.separator ? {
    parts: {
      decimal: parserOptions.separator
    }
  } : undefined);
  return parsedValue;
};
const getValueIsValid = value => value == null || value === '' || !isNaN(parseNumber(value));
const getFormattedNumber = (value, formatStyle, formatter, formatterOptions) => {
  if (formatter) {
    let formatterResult;
    if (!isScientificNotation(String(value))) {
      // try catch to prevent the sandbox from crashing when writing out `formatter=`
      try {
        formatterResult = formatter(value, formatterOptions);
      } catch (error) {
        formatterResult = String(value);
      }
    } else {
      formatterResult = String(value);
    }
    if (typeof formatterResult !== 'string') {
      if (process.env.NODE_ENV !== 'production') {
        devLogger.warn({
          message: `AbstractNumberInput: Expected \`formatter(value)\` to return a string, but ` + `it returned \`${formatterResult}\`.`,
          key: 'AbstractNumberInput: bad formatter result'
        });
      }
    } else {
      return formatterResult;
    }
  }
  if (value == null || String(value) === '') {
    return '';
  }
  if (!getValueIsValid(value)) {
    return String(value);
  }
  if (formatStyle === NUMBER_TYPES.PERCENTAGE) {
    return formatAbstractPercentage(value, Object.assign({}, formatterOptions));
  }
  if (!isScientificNotation(String(value))) {
    return formatNumber(value, formatterOptions);
  }
  return String(value);
};
const getStateOnValueReset = (value, formatStyle, formatter, formatterOptions) => {
  const isValid = getValueIsValid(value);
  return {
    isValid,
    lastValidValue: isValid ? value : null,
    displayedValue: getFormattedNumber(value, formatStyle, formatter, formatterOptions)
  };
};
export class AbstractNumberInput extends Component {
  constructor(props) {
    super(props);
    this.handleChange = evt => {
      this.triggerOnChange(evt.target.value);
    };
    this.triggerOnChange = rawValue => {
      // Trigger onChange with the numeric value, not the raw string
      const {
        onChange,
        onRawValueChange,
        value,
        min,
        max,
        parser,
        separator
      } = this.props;
      if (onRawValueChange) {
        const parserOptions = {
          separator
        };
        const parsedValue = getParsedValue(rawValue, parser, parserOptions);
        const isValid = parsedValue !== null && !isNaN(parsedValue);
        onRawValueChange(rawValue, isValid, parsedValue);
      }
      this.setState({
        displayedValue: rawValue
      });
      const parserOptions = {
        separator
      };

      // Prevent formatting while the user is typing
      this.isTyping = true;
      const parsedValue = getParsedValue(rawValue, parser, parserOptions);
      if (value != null && parsedValue == null) {
        callIfPossible(onChange, syntheticEvent(null));
        return;
      }
      if (parsedValue !== null && parsedValue !== value && !isNaN(parsedValue)) {
        const fencedMin = min == null ? parsedValue : Math.max(parsedValue, min);
        const fencedValue = max == null ? fencedMin : Math.min(fencedMin, max);
        callIfPossible(onChange, syntheticEvent(fencedValue));
      }
    };
    this.handleBlur = evt => {
      const {
        onBlur,
        min,
        max,
        parser,
        separator
      } = this.props;
      this.handleConfirmedInput();
      this.isTyping = false;
      const rawValue = evt.target.value;
      const parsedValue = getParsedValue(rawValue, parser, {
        separator
      });
      if (parsedValue === null) {
        callIfPossible(onBlur, syntheticEvent(null));
        return;
      }
      if (!isNaN(parsedValue)) {
        const fencedMin = min == null ? parsedValue : Math.max(parsedValue, min);
        const fencedValue = max == null ? fencedMin : Math.min(fencedMin, max);
        callIfPossible(onBlur, syntheticEvent(fencedValue));
      }
    };
    this.handleFocus = evt => {
      const {
        onFocus
      } = this.props;
      // If displayedValue is invalid, clear it on focus
      const {
        isValid
      } = this.state;
      if (!isValid) {
        this.setState({
          isValid: true,
          displayedValue: ''
        });
      }
      callIfPossible(onFocus, evt);
    };
    this.handleConfirmedInput = () => {
      // Format whatever the user typed for display (e.g. "1234" -> "1,234")
      const {
        formatStyle,
        formatter,
        maxFractionDigits,
        precision,
        separator,
        value
      } = this.props;
      const isValid = getValueIsValid(value);
      const formattedNumber = value && value > 1e21 ? value.toString() : getFormattedNumber(value, formatStyle, formatter, {
        maxFractionDigits,
        precision,
        separator
      });

      // call onChange if the formatter has changed the value
      if (this.state.displayedValue !== formattedNumber) {
        this.triggerOnChange(formattedNumber);
      }
      this.setState({
        isValid,
        displayedValue: formattedNumber
      });
    };
    this.handleKeyUp = evt => {
      const {
        onChange,
        onKeyUp,
        value
      } = this.props;
      const {
        lastValidValue
      } = this.state;
      const {
        key
      } = evt;

      // If ESC is pressed, reset to lastValidValue
      if (key === 'Escape' && lastValidValue !== value) {
        callIfPossible(onChange, syntheticEvent(lastValidValue));
        this.escKeyUpTriggered = true;
      }
      // If Enter or Up/Down is pressed, update the displayed value
      if (key === 'Enter' || key === 'ArrowUp' || key === 'ArrowDown') {
        this.handleConfirmedInput();
      }
      callIfPossible(onKeyUp, evt);
    };
    const {
      value: _value,
      formatStyle: _formatStyle,
      formatter: _formatter,
      maxFractionDigits: _maxFractionDigits,
      precision: _precision,
      separator: _separator
    } = props;
    this.state = getStateOnValueReset(_value, _formatStyle, _formatter, {
      maxFractionDigits: _maxFractionDigits,
      precision: _precision,
      separator: _separator
    });
    this.escKeyUpTriggered = false;
    this.isTyping = false;
  }
  componentDidUpdate(prevProps) {
    const {
      value,
      formatStyle,
      formatter
    } = prevProps;
    // Uses this flag to determine if the last change was triggered by the `ESC` key.
    // This logic is separate from the following logic since value and this.props.value
    // could be equal, and you would still want this reset to trigger. (1000 vs 1,000)
    if (this.escKeyUpTriggered) {
      this.escKeyUpTriggered = false;
      this.setState(getStateOnValueReset(this.props.value, this.props.formatStyle, this.props.formatter, {
        maxFractionDigits: this.props.maxFractionDigits,
        precision: this.props.precision
      }));
      return;
    }

    // Ignore changes from one invalid value (NaN) to another
    const valueChanged = value !== this.props.value && !(isNaN(value) && isNaN(this.props.value));
    const formatStyleChanged = formatStyle !== this.props.formatStyle;
    const formatterChanged = formatter !== this.props.formatter;
    if (valueChanged || formatStyleChanged || formatterChanged) {
      if (!this.isTyping) {
        this.setState(getStateOnValueReset(this.props.value, this.props.formatStyle, this.props.formatter, {
          maxFractionDigits: this.props.maxFractionDigits,
          precision: this.props.precision,
          separator: this.props.separator
        }));
      }
    }
  }
  render() {
    const _this$props = this.props,
      {
        children,
        error
      } = _this$props,
      rest = _objectWithoutPropertiesLoose(_this$props, _excluded);
    return children(Object.assign({}, rest, {
      error,
      'aria-invalid': typeof error === 'boolean' ? error : !this.state.isValid,
      onChange: this.handleChange,
      onBlur: this.handleBlur,
      onFocus: this.handleFocus,
      onKeyUp: this.handleKeyUp,
      value: this.state.displayedValue,
      onKeyDown: this.props.onKeyDown
    }));
  }
}