import * as storage from '../../common/storageUtils';
// @ts-expect-error ts-migrate(2307)
import * as Operators from 'customer-data-filters/filterQueryFormat/operator/Operators';
import { validateRollingDateConfig } from 'customer-data-filters/filterQueryFormat/rollingDates/RollingDateConfig';
import PortalIdParser from 'PortalIdParser';
import { isFrameworkDataSource } from './dataSourceHelpers';
const STORAGE_KEY = 'Reporting:PlatformDashboard:DashboardFilters';
const portalKey = `portal-${PortalIdParser.get()}`;
const validateFilterData = filterData => {
  const {
    dataSource,
    dataSourceName,
    propertyName,
    operator,
    value,
    highValue
  } = filterData;
  if (!dataSource || !propertyName || !operator) {
    return false;
  }
  if (isFrameworkDataSource(dataSource) && !dataSourceName) {
    return false;
  }
  const Operator = Operators[operator];
  if (!Operator) {
    return false;
  }
  if (![Operators.Known, Operators.NotKnown].includes(Operator) && value == null) {
    return false;
  }
  if (Operator === Operators.InRange && !highValue) {
    return false;
  }
  if ([Operators.In, Operators.NotIn].includes(Operator) && !Array.isArray(value)) {
    return false;
  }
  if (Operator === Operators.InRollingDateRange && !(typeof value === 'object' && validateRollingDateConfig(value))) {
    return false;
  }
  return true;
};
const getAllPersistedDashboardFilters = () => {
  if (storage.get(STORAGE_KEY) === null) {
    return {};
  }
  return JSON.parse(storage.get(STORAGE_KEY));
};
const getPersistedDashboardFiltersForPortal = () => {
  const allPersistedFilters = getAllPersistedDashboardFilters();
  return allPersistedFilters[portalKey] || {};
};
const setPersistedDashboardFiltersForPortal = portalFilters => {
  storage.set(STORAGE_KEY, JSON.stringify(Object.assign({}, getAllPersistedDashboardFilters(), {
    [portalKey]: portalFilters
  })));
};
export const persistDashboardFilters = (dashboardId, filters) => {
  setPersistedDashboardFiltersForPortal(Object.assign({}, getPersistedDashboardFiltersForPortal(), {
    [dashboardId]: filters
  }));
};
export const clearPersistedDashboardFilters = () => {
  setPersistedDashboardFiltersForPortal({});
};
export const getPersistedFiltersForSingleDashboard = dashboardId => {
  const portalPersistedFilters = getPersistedDashboardFiltersForPortal();
  const dashboardPersistedFilters = portalPersistedFilters[dashboardId] || [];
  const validPersistedFilters = dashboardPersistedFilters.filter(validateFilterData);
  if (dashboardPersistedFilters.length !== validPersistedFilters.length) {
    persistDashboardFilters(dashboardId, validPersistedFilters);
  }
  return validPersistedFilters;
};