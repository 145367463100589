'use es6';

import { Map as ImmutableMap } from 'immutable';
import { CURRENCY, NUMBER } from '../../constants/property-types';
import { LINE_ITEMS } from '../../constants/dataTypes/inboundDb';
import { HUBSPOT_OBJECT_COORDINATES_TO_DATA_TYPE } from '../../constants/objectCoordinates';
import { getDataTypeFromConfig } from '../../report/reportGetters';
export const CUSTOM_PRECISION_META_BY_DATA_TYPE = ImmutableMap({
  [LINE_ITEMS]: 7
});
const maybeGetAggregationType = value => value.get('aggregationType');
const maybeGetCustomPrecision = (dataType, format) => [NUMBER, CURRENCY].includes(format.get('type')) && CUSTOM_PRECISION_META_BY_DATA_TYPE.get(HUBSPOT_OBJECT_COORDINATES_TO_DATA_TYPE.get(dataType));
const buildPropertyMeta = (dataType, value) => {
  const format = value.get('format', ImmutableMap());
  const aggregationType = maybeGetAggregationType(value);
  const customPrecision = maybeGetCustomPrecision(dataType, format);
  return ImmutableMap(Object.assign({}, aggregationType && {
    aggregationType
  }, customPrecision && {
    customPrecision
  }));
};
export const getDatasetWithPropertyMetaFromConfig = (config, reportingApiDataset) => {
  const dataType = getDataTypeFromConfig(config);
  return reportingApiDataset.update('header', header => header.map(value => {
    const propertyMeta = buildPropertyMeta(dataType, value);
    return propertyMeta.isEmpty() ? value : value.set('propertyMeta', propertyMeta);
  }));
};