'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import getBucketMetrics from './bucketMetrics';
export default ((config, response) => {
  const firstDimension = config.getIn(['dimensions', 0]);
  const secondDimension = config.getIn(['dimensions', 1]);
  const aggregations = response.getIn(['aggregations', firstDimension], List());
  return ImmutableMap({
    dimension: ImmutableMap({
      property: firstDimension,
      buckets: aggregations.map(breakdown => ImmutableMap({
        key: breakdown.get('key'),
        metrics: getBucketMetrics(config, breakdown),
        dimension: ImmutableMap({
          property: secondDimension,
          buckets: breakdown.getIn(['aggregations', secondDimension], List()).map(innerBreakdown => ImmutableMap({
            key: innerBreakdown.get('key'),
            metrics: getBucketMetrics(config, innerBreakdown)
          }))
        })
      }))
    }),
    metrics: getBucketMetrics(config, response),
    total: aggregations.count() || 0
  });
});