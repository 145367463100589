'use es6';

import { fromJS, Map as ImmutableMap, List } from 'immutable';
import * as http from '../request/http';
import { summarize } from '../dataset/summarize';
const getTeamGroupings = () => {
  return http.get('app-users/v1/teams');
};
const findById = (bucket, id, andDelete = false) => {
  if (!bucket.dimension) {
    return false;
  }
  if (bucket.key === String(id)) {
    return bucket;
  }
  const child = bucket.dimension.buckets.reduce((found, node) => {
    return found || findById(node, id, andDelete);
  }, null);
  if (child && andDelete) {
    const cloneChild = Object.assign({}, child);
    bucket.dimension.buckets = bucket.dimension.buckets.filter(node => node.key !== String(id));
    return cloneChild;
  }
  return child;
};
const createIfNotFound = (node, id, bucket, andAttach = false) => {
  if (!node) {
    node = {
      dimension: {
        buckets: []
      },
      key: String(id)
    };
    if (andAttach) {
      bucket.dimension.buckets.unshift(node);
    }
  }
  return node;
};
const connectParentToChild = (parent, child) => {
  parent.dimension.buckets.unshift(child);
};
const groupTeamsByLevel = (data, teams) => {
  const dataObject = data.toJS();
  teams.forEach(team => {
    const parentId = team.get('parentTeamId');
    if (parentId) {
      const parent = createIfNotFound(findById(dataObject, parentId), parentId, dataObject, true);
      delete parent.metrics;
      const child = createIfNotFound(findById(dataObject, team.get('id'), true), team.get('id'), dataObject);
      child.keyLabel = team.get('name');
      connectParentToChild(parent, child);
    }
  });
  return fromJS(dataObject);
};
const sort = (config, bucket) => {
  if (!bucket.has('dimension')) {
    return bucket;
  }
  return bucket.updateIn(['dimension', 'buckets'], buckets => {
    return buckets.map(subbucket => sort(config, subbucket)).sort((b1, b2) => {
      const {
        property,
        order
      } = config.getIn(['sort', 0]).toJS();
      const getVal = b => b.getIn(['metrics', property], ImmutableMap()).first();
      const diff = getVal(b1) - getVal(b2);
      return order === 'DESC' ? diff * -1 : diff;
    });
  });
};
const filter = bucket => {
  return bucket.updateIn(['dimension', 'buckets'], List(), buckets => {
    return buckets.filterNot(subbucket => subbucket.get('metrics').isEmpty()).map(filter);
  });
};
export default (({
  dataset,
  dataConfig
}) => {
  return getTeamGroupings().then(teams => filter(sort(dataConfig, summarize(groupTeamsByLevel(dataset, teams)))));
});