'use es6';

import I18n from 'I18n';
import { fromJS, List, Map as ImmutableMap, OrderedMap, Range, Seq } from 'immutable';
import { getFilterByProperty } from '../../../config/filters/functions';
import { QUOTAS } from '../../../constants/dataTypes';
import { DEFAULT_NULL_VALUES } from '../../../constants/defaultNullValues';
import * as Frequency from '../../../constants/frequency';
import { getQuotaReportingData } from '../../../customSeries/quota';
import { summarize } from '../../../dataset/summarize';
import makeDateRangeByType from '../../../lib/makeDateRangeByType';
import zeroFill from '../../../lib/zeroFill';
import { userInfo as getUserInfo } from '../../../request/user-info';
import { QUOTA_DATE_PROPERTY, QUOTA_OWNER_PROPERTY, QUOTA_PIPELINE_PROPERTY, QUOTA_TYPE_PROPERTY, QUOTA_VALUE_PROPERTY } from './constants';

/* These are the _ONLY_ supported dimensions */
const DIMENSIONS = {
  SUMMARY: List([]),
  DATE: List([QUOTA_DATE_PROPERTY]),
  DATE_OWNERS: List([QUOTA_DATE_PROPERTY, QUOTA_OWNER_PROPERTY]),
  OWNERS: List([QUOTA_OWNER_PROPERTY])
};
const DATE_FORMAT = 'YYYY-MM-DD';
const frequencyToPeriodMap = {
  [Frequency.DAY]: 'day',
  [Frequency.WEEK]: 'isoWeek',
  [Frequency.MONTH]: 'month',
  [Frequency.QUARTER]: 'quarter',
  [Frequency.YEAR]: 'year'
};
const getMonthAndYear = date => {
  const m = I18n.moment(date);
  return {
    month: m.month() + 1,
    year: m.year()
  };
};
const pad = number => `00${number}`.slice(-2);
const getData = ({
  quotaType,
  startDate,
  endDate,
  filters
}) => {
  const monthlyStartDate = I18n.moment(startDate).startOf('month').format(DATE_FORMAT);
  return getUserInfo().then(userInfo => {
    const {
      user: {
        scopes
      }
    } = userInfo;
    const requestData = Object.assign({
      dateRange: {
        startDate: getMonthAndYear(monthlyStartDate),
        endDate: getMonthAndYear(endDate)
      }
    }, filters);
    return getQuotaReportingData(scopes, requestData, quotaType);
  }).then(data => data.map((value, date) => ImmutableMap({
    key: date,
    value
  })).toList().flatMap(v => {
    const date = I18n.moment(v.get('key'), DATE_FORMAT);
    const daysInMonth = date.daysInMonth();
    return Range(1, daysInMonth + 1).map(day => I18n.moment(date.format(`YYYY-MM-${pad(day)}`)).format(DATE_FORMAT)).map(day => ImmutableMap({
      key: day,
      value: v.get('value').map(b => b.update('sum', sum => sum / daysInMonth))
    }));
  }));
};
const rebucket = ({
  dailyQuotas,
  frequency
}) => {
  const period = frequencyToPeriodMap[frequency];
  return dailyQuotas.map(q => q.set('bucket', I18n.moment(q.get('key')).startOf(period).format(DATE_FORMAT))).groupBy(q => q.get('bucket')).map(b => b.flatMap(q => q.get('value')).groupBy(q => q.get('breakdown')).map(g => g.reduce((memo, q) => memo + q.get('sum'), 0)));
};
const toDateOwnerDataset = data => fromJS({
  dimension: {
    property: QUOTA_DATE_PROPERTY,
    buckets: data.map((bucket, key) => ({
      key,
      dimension: {
        property: QUOTA_OWNER_PROPERTY,
        buckets: bucket.map((b, k) => ({
          key: k,
          metrics: {
            [QUOTA_VALUE_PROPERTY]: {
              SUM: b
            }
          }
        })).toList().toJS()
      }
    })).toList().toJS()
  }
});
const toOwnerDataset = data => fromJS({
  dimension: {
    property: QUOTA_OWNER_PROPERTY,
    buckets: data.map((bucket, key) => ({
      key,
      metrics: {
        [QUOTA_VALUE_PROPERTY]: {
          SUM: bucket
        }
      }
    })).toList().toJS()
  }
});
export const retrieve = (config, __debug, __runtimeOptions) => {
  const dimensions = config.get('dimensions');
  const type = Seq(DIMENSIONS).find(dimensionList => dimensionList.equals(dimensions));
  if (!type) {
    throw new Error(`Unsupported dimensions for quota report ${dimensions}`);
  }
  const quotaTypeFilter = getFilterByProperty(config, QUOTA_TYPE_PROPERTY) || undefined;
  const quotaType = quotaTypeFilter.get('value');
  if (!quotaType) {
    throw new Error(`Unsupported quota type ${quotaType} for quota report`);
  }
  const dateRangeFilter = config.getIn(['filters', 'dateRange']);
  if (!dateRangeFilter) {
    throw new Error('Quota reports require a date range filter');
  }
  const frequency = config.get('frequency') || Frequency.MONTH;
  const {
    startDate,
    endDate
  } = makeDateRangeByType(dateRangeFilter.get('value').toJS());
  const ownersFilter = getFilterByProperty(config, QUOTA_OWNER_PROPERTY) || undefined;
  const assignees = ownersFilter && ownersFilter.get('values').filter(owner => owner !== DEFAULT_NULL_VALUES.ENUMERATION);
  const assigneesOperator = ownersFilter && ownersFilter.get('operator');
  const pipelineFilter = getFilterByProperty(config, QUOTA_PIPELINE_PROPERTY) || undefined;
  const pipelines = pipelineFilter && pipelineFilter.get('values');
  const pipelinesOperator = 'IN';
  const filters = {
    assignees,
    assigneesOperator,
    pipelines,
    pipelinesOperator
  };
  return getData({
    quotaType,
    startDate,
    endDate,
    filters
  }).then(unfilteredDailyQuotas => {
    const dailyQuotas = unfilteredDailyQuotas.skipUntil(q => I18n.moment(q.get('key')).isSameOrAfter(I18n.moment(startDate))).update(dq => dq.takeUntil((_, i) => {
      if (i === 0) {
        return false;
      }
      return I18n.moment(dq.getIn([i - 1, 'key'])).isSameOrAfter(I18n.moment(endDate));
    }));
    const data = rebucket({
      dailyQuotas,
      frequency
    });
    if (type === DIMENSIONS.DATE_OWNERS) {
      const dataset = toDateOwnerDataset(data);
      return summarize(dataset);
    }
    if (type === DIMENSIONS.DATE) {
      const dataset = toDateOwnerDataset(data);
      return summarize(dataset).updateIn(['dimension', 'buckets'], buckets => buckets.map(b => b.delete('dimension')));
    }
    if (type === DIMENSIONS.SUMMARY) {
      const dataset = toDateOwnerDataset(data);
      return summarize(dataset).delete('dimension');
    }
    if (type === DIMENSIONS.OWNERS) {
      const owners = {};
      data.forEach(date => date.forEach((sum, owner) => {
        owners[owner] = owners[owner] ? owners[owner] + sum : sum;
      }));
      const ownersData = OrderedMap(owners).sort();
      const dataset = toOwnerDataset(ownersData);
      return summarize(dataset);
    }
    throw new Error('Unsupported quota report');
  }).then(dataset => ({
    dataConfig: config,
    dataset: zeroFill(config, dataset)
  }));
};
export const match = dataConfig => dataConfig.get('dataType') === QUOTAS;

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = module.exports;
}