'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { Column, FieldSources } from '../../relational/schema/column-records';
import { ASSOCIATION_FIELD_NAME } from '../../relational/utils/association-utils';
import { Filter, FilterRef, FilterRefTypes } from '../../relational/schema/filter-records';
import { getTableDescriptionMeta } from '../../relational/metadata/table-description-meta';
import { TableDescription } from '../../relational/schema/table-records';
import { getReportDefinition } from 'reporting-data/report/reportGetters';
const OLD_ASSOCIATION_FIELD_NAME = 'association';
const maybeUpdateColumn = column => {
  const {
    field: {
      source,
      name
    }
  } = Column(column);
  const needsMigration = source === FieldSources.ASSOCIATION && name !== ASSOCIATION_FIELD_NAME;
  if (!needsMigration) {
    return column;
  }
  return column.setIn(['field', 'name'], ASSOCIATION_FIELD_NAME);
};
const maybeUpdateFilter = filter => {
  const {
    field: {
      source,
      name
    },
    filter: filterObject
  } = Filter(filter);
  const needsMigration = source === FieldSources.ASSOCIATION && (name !== ASSOCIATION_FIELD_NAME || filterObject.property !== ASSOCIATION_FIELD_NAME);
  if (!needsMigration) {
    return filter;
  }
  return filter.setIn(['field', 'name'], ASSOCIATION_FIELD_NAME).setIn(['filter', 'property'], ASSOCIATION_FIELD_NAME);
};
const maybeUpdateStagedFilter = (filterRef, properties) => {
  const {
    type,
    table,
    property
  } = FilterRef(filterRef);
  const maybeTable = properties.get(table);
  const hasPropertyNamedAssociation = !!(maybeTable && maybeTable.get(OLD_ASSOCIATION_FIELD_NAME));
  const needsMigration = type === FilterRefTypes.PROPERTY && property === OLD_ASSOCIATION_FIELD_NAME && !hasPropertyNamedAssociation;
  if (!needsMigration) {
    return filterRef;
  }
  return filterRef.set('property', ASSOCIATION_FIELD_NAME);
};
export default (report => {
  const reportDefinition = getReportDefinition(report);
  const tableDescription = TableDescription(reportDefinition.get('table'));
  return getTableDescriptionMeta(tableDescription).then(({
    properties
  }) => {
    const nextReportDefinition = reportDefinition.update('columns', columns => (columns || ImmutableMap()).map(maybeUpdateColumn)).update('stagedColumns', columns => (columns || List()).map(maybeUpdateColumn)).update('filtering', filtering => filtering ? filtering.update('groups', groups => (groups || List()).map(group => group.update('filters', filters => filters.map(maybeUpdateFilter)))).update('stagedFilters', stagedFilters => (stagedFilters || List()).map(stagedFilter => maybeUpdateStagedFilter(stagedFilter, properties))) : undefined);
    return report.set('reportDefinition', nextReportDefinition);
  });
});