import * as checked from 'reporting-data/lib/checked';
import * as ValueFormattingOptionsModule from 'reporting-data/tsTypes/visualization/formatting';
export const NegativeNumberTypes = ValueFormattingOptionsModule.NegativeNumberTypes;
const _negativeSymbol = checked.symbol(ValueFormattingOptionsModule.NegativeNumberTypes, 'NegativeSymbol');
const negativeSymbol = _negativeSymbol.defaultValue(ValueFormattingOptionsModule.NegativeNumberTypes.AUTO);
const customPrecision = checked.number().defaultValue(2);
const currencyCode = checked.string().optional();
const useThousandsSeparator = checked.boolean().defaultValue(true);
const duration = checked.string().optional();
const ConditionalFormatting = checked.record({
  min: checked.number().optional(),
  mid: checked.number().optional(),
  max: checked.number().optional(),
  minColor: checked.string().optional(),
  midColor: checked.string().optional(),
  maxColor: checked.string().optional(),
  palette: checked.string().optional(),
  useConditionalFormatting: checked.boolean().optional()
}, 'ConditionalFormatting');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ConditionalFormattingTypeCheck = ConditionalFormatting;
export const NumberFormattingType = ValueFormattingOptionsModule.FormatTypes.NUMBER;
export const PercentFormattingType = ValueFormattingOptionsModule.FormatTypes.PERCENTAGE;
export const CurrencyFormattingType = ValueFormattingOptionsModule.FormatTypes.CURRENCY;
export const AutoFormattingType = ValueFormattingOptionsModule.FormatTypes.AUTO;
export const FormatTypes = ValueFormattingOptionsModule.FormatTypes;
export const FormatType = checked.symbol(ValueFormattingOptionsModule.FormatTypes, 'FormatType');
export const AutoFormatting = checked.record({
  type: FormatType.always(ValueFormattingOptionsModule.FormatTypes.AUTO),
  negativeSymbol,
  customPrecision,
  useThousandsSeparator,
  duration,
  conditionalFormatting: ConditionalFormatting.optional()
}, 'AutoFormatting');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AutoFormattingTypeCheck = AutoFormatting;
export const NumberFormatting = checked.record({
  type: FormatType.always(ValueFormattingOptionsModule.FormatTypes.NUMBER),
  negativeSymbol: negativeSymbol.defaultValue(ValueFormattingOptionsModule.NegativeNumberTypes.AUTO),
  customPrecision,
  useThousandsSeparator,
  duration,
  conditionalFormatting: ConditionalFormatting.optional()
}, 'NumberFormatting');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NumberFormattingTypeCheck = NumberFormatting;
export const PercentFormatting = checked.record({
  type: FormatType.always(ValueFormattingOptionsModule.FormatTypes.PERCENTAGE),
  negativeSymbol: negativeSymbol.defaultValue(ValueFormattingOptionsModule.NegativeNumberTypes.AUTO),
  customPrecision,
  useThousandsSeparator,
  duration,
  conditionalFormatting: ConditionalFormatting.optional()
}, 'PercentFormatting');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PercentFormattingTypeCheck = PercentFormatting;
export const CurrencyFormatting = checked.record({
  type: FormatType.always(ValueFormattingOptionsModule.FormatTypes.CURRENCY),
  negativeSymbol: negativeSymbol.defaultValue(ValueFormattingOptionsModule.NegativeNumberTypes.AUTO),
  customPrecision,
  currencyCode,
  useThousandsSeparator,
  duration,
  conditionalFormatting: ConditionalFormatting.optional()
}, 'CurrencyFormatting');
export const DefaultCurrencyFormatting = CurrencyFormatting({
  type: FormatTypes.CURRENCY
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CurrencyFormattingTypeCheck = CurrencyFormatting;