'use es6';

import { BUCKETS, ENUMERATION, ID, NUMBER } from '../../constants/property-types';
import { getPropertyTypeFromDisplayHint } from '../../hydrate/propertyFormatter';
const ENUM_TO_ID_PROPERTIES = ['hs_product_id', 'hs_ticket_id', 'hs_object_id', 'listMemberships.listId', 'engagement.id', 'engagement.createdBy'];
const NUMBER_TO_ID_PROPERTIES = ['associatedcompanyid', 'hs_object_id', 'hs_created_by_user_id'];
export const upgradeProperty = property => {
  if (!property) {
    return property;
  }
  const propertyType = property.get('type');
  const propertyName = property.get('name');
  if (propertyType === BUCKETS) {
    return property.set('type', ENUMERATION);
  }
  if (ENUM_TO_ID_PROPERTIES.includes(propertyName) && propertyType === ENUMERATION) {
    return property.set('type', ID);
  }
  if (NUMBER_TO_ID_PROPERTIES.includes(propertyName) && propertyType === NUMBER) {
    return property.set('type', ID);
  }
  const numberDisplayHint = property.get('numberDisplayHint');
  if (propertyType === NUMBER && numberDisplayHint) {
    return property.set('type', getPropertyTypeFromDisplayHint(numberDisplayHint));
  }
  return property;
};