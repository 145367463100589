'use es6';

import * as checked from '../../lib/checked';
export const Filter = checked.record({
  operator: undefined,
  property: undefined,
  value: undefined,
  values: undefined,
  highValue: undefined,
  dateTimeFormat: undefined,
  timeUnit: undefined,
  timeUnitCount: undefined,
  rollForward: undefined,
  inclusive: undefined,
  propertySuffix: undefined,
  useFiscalYear: undefined,
  rollingOffset: undefined,
  rollingOffsetUnit: undefined,
  direction: undefined
}, 'Filter').fromJS();
const FilterList = checked.list(Filter).defaultValue([]);
export const Filters = checked.record({
  dateRange: checked.any().fromJS(),
  custom: FilterList,
  filterGroups: checked.list(checked.map(FilterList)).optional(),
  query: checked.string().optional()
}, 'Filters').defaultValue({});