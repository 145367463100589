import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["dataSource", "dataSourceName"];
import { stringify } from 'hub-http/helpers/params';
import { parseQueryString } from 'dashboard-ui-components/lib/queryUtils';
import { setQuery } from '../historyUtils';
import { SavedDashboardFilterSetFrequency } from '../savedDashboardFilters';
export const APPLIED_ADHOC_FILTERS_PARAM = 'appliedFilters';
export const FILTER_SET_QUERY_PARAM = 'filterId';
export const FREQUENCY_QUERY_PARAM = 'dbFrequency';
export const FREQUENCY_FISCAL_YEAR = 'freqUseFiscalYear';

/**
 * Map of filters by the data source id to save on characters serialized into the query string
 */

const dashboardFiltersToSerializedDashboardFilters = (dashboardFilters, dashboardId) => {
  const groupedByDataSource = dashboardFilters.reduce((next, dashboardFilter) => {
    const {
        dataSource,
        dataSourceName
      } = dashboardFilter,
      restOfDashboardFilter = _objectWithoutPropertiesLoose(dashboardFilter, _excluded);
    const filterMapForDataSource = dataSource in next ? next[dataSource] : {
      filters: [],
      dataSourceName
    };
    filterMapForDataSource.filters = [...filterMapForDataSource.filters, restOfDashboardFilter];
    next[dataSource] = filterMapForDataSource;
    return next;
  }, {});
  return {
    [dashboardId]: groupedByDataSource
  };
};
const serializableFiltersToDashboardFilters = serializedDashboardFilters => {
  const dashboardFilters = Object.keys(serializedDashboardFilters).map(dataSource => {
    const filterMap = serializedDashboardFilters[dataSource];
    if (filterMap) {
      const {
        filters,
        dataSourceName
      } = filterMap;
      return filters.map(filterConfig => {
        return Object.assign({
          dataSource,
          dataSourceName
        }, filterConfig);
      });
    }
    return [];
  }).flat();
  return dashboardFilters;
};
const encodeSerializedFilters = serializableFilters => {
  try {
    return btoa(encodeURIComponent(JSON.stringify(serializableFilters)));
  } catch (error) {
    console.error(error);
    return '';
  }
};
const decodeSerializedFilters = uriComponent => {
  try {
    const decoded = decodeURIComponent(atob(uriComponent));
    if (decoded) {
      return JSON.parse(decoded);
    }
  } catch (error) {
    console.error(error);
  }
  return {};
};
const dashboardFiltersToEncodedString = (dashboardFilters, dashboardId) => {
  const serializableFilters = dashboardFiltersToSerializedDashboardFilters(dashboardFilters, dashboardId);
  return encodeSerializedFilters(serializableFilters);
};
export const buildDashboardFiltersQueryString = (dashboardId, dashboardFilters, frequency) => {
  const appliedFiltersParamValue = dashboardFiltersToEncodedString(dashboardFilters, dashboardId);
  const queryParamsObj = {
    [APPLIED_ADHOC_FILTERS_PARAM]: appliedFiltersParamValue,
    [FREQUENCY_QUERY_PARAM]: frequency
  };
  return stringify(queryParamsObj);
};
export const getAdhocFiltersParam = ({
  history,
  dashboardId
}) => {
  const queryParams = parseQueryString(history.location.search);
  if (APPLIED_ADHOC_FILTERS_PARAM in queryParams && queryParams[APPLIED_ADHOC_FILTERS_PARAM]) {
    const serializableFiltersByDashboardId = decodeSerializedFilters(queryParams[APPLIED_ADHOC_FILTERS_PARAM]);
    if (dashboardId in serializableFiltersByDashboardId) {
      return serializableFiltersToDashboardFilters(serializableFiltersByDashboardId[dashboardId]);
    }
  }
  return [];
};
export const clearDashboardFiltersQueryParams = ({
  history
}) => {
  const queryParams = parseQueryString(history.location.search);
  delete queryParams[APPLIED_ADHOC_FILTERS_PARAM];
  delete queryParams[FILTER_SET_QUERY_PARAM];
  delete queryParams[FREQUENCY_QUERY_PARAM];
  delete queryParams[FREQUENCY_FISCAL_YEAR];
  setQuery(stringify(queryParams), history);
};
export const setAdhocFiltersParam = ({
  history,
  dashboardFilters,
  dashboardId
}) => {
  const queryParams = parseQueryString(history.location.search);
  if (dashboardFilters.length > 0) {
    queryParams[APPLIED_ADHOC_FILTERS_PARAM] = dashboardFiltersToEncodedString(dashboardFilters, dashboardId);
  } else {
    delete queryParams[APPLIED_ADHOC_FILTERS_PARAM];
  }
  setQuery(stringify(queryParams), history);
};
export const getFrequencyQueryParam = ({
  history
}) => {
  const queryParams = parseQueryString(history.location.search);
  const allowedFrequencyValues = Object.values(SavedDashboardFilterSetFrequency);
  if (FREQUENCY_QUERY_PARAM in queryParams && allowedFrequencyValues.includes(queryParams[FREQUENCY_QUERY_PARAM])) {
    return queryParams[FREQUENCY_QUERY_PARAM];
  }
  return undefined;
};
export const setFrequencyQueryParam = ({
  history,
  frequency
}) => {
  const queryParams = parseQueryString(history.location.search);
  if (frequency) {
    queryParams[FREQUENCY_QUERY_PARAM] = frequency;
  } else {
    delete queryParams[FREQUENCY_QUERY_PARAM];
  }
  setQuery(stringify(queryParams), history);
};
export const getFrequencyFiscalYearQueryParam = ({
  history
}) => {
  const queryParams = parseQueryString(history.location.search);
  const rawUseFiscalYearValue = queryParams[FREQUENCY_FISCAL_YEAR];
  return rawUseFiscalYearValue ? queryParams[FREQUENCY_FISCAL_YEAR] === 'true' : undefined;
};
export const setFrequencyFiscalYearQueryParam = ({
  history,
  useFiscalYear
}) => {
  const queryParams = parseQueryString(history.location.search);
  if (useFiscalYear != null) {
    queryParams[FREQUENCY_FISCAL_YEAR] = useFiscalYear;
  } else {
    delete queryParams[FREQUENCY_FISCAL_YEAR];
  }
  setQuery(stringify(queryParams), history);
};
export const getSavedFilterSetIdQueryParam = ({
  history
}) => {
  const queryParams = parseQueryString(history.location.search);
  return FILTER_SET_QUERY_PARAM in queryParams && !isNaN(Number(queryParams[FILTER_SET_QUERY_PARAM])) ? Number(queryParams[FILTER_SET_QUERY_PARAM]) : undefined;
};
export const setSavedFilterSetIdQueryParam = ({
  history,
  filterSetId
}) => {
  const queryParams = parseQueryString(history.location.search);
  if (filterSetId != null) {
    queryParams[FILTER_SET_QUERY_PARAM] = filterSetId;
  } else {
    delete queryParams[FILTER_SET_QUERY_PARAM];
  }
  setQuery(stringify(queryParams), history);
};
export const __TESTABLE__ = {
  dashboardFiltersToEncodedString,
  dashboardFiltersToSerializedDashboardFilters,
  serializableFiltersToDashboardFilters
};