'use es6';

import * as sandbox from './lib/sandbox';
import { Config } from './config';
import resolveFn from './resolve';
import { isOnlySupportedViaBackendResolve } from './v2/reportingApi/isSupportedForBackendResolve';
import { reportingApiResolve } from './v2/reportingApi/resolve';
import { UNIFIED_DATA_TYPES, UNIFIED_EVENTS } from './constants/dataTypes';
import { INBOUND_DB_DATA_TYPES } from './constants/dataTypes';
import { resolveMultiConfigReport } from './v2/reportingApi/resolveMultiConfigReport';
import { fiscalYearFetcher } from './lib/fiscalYearUtils';
import { ReportingLogger } from './monitoring/reportingLogger';
export const RUNTIME_OPTIONS = {
  batchRequests: false,
  v2Datasets: true,
  useBackendResolve: false,
  useLocalReportingApis: false,
  validationResolve: false,
  runtimeMockResolvers: undefined
};
const REPORTING_DATA_MAIN_RESOLVE = 'ReportingDataMainResolve';
const REPORTING_DATA_MAIN_RESOLVE_PATH = 'reportingDataMainResolvePath';
const REPORTING_DATA_MAIN_RESOLVE_RUNTIMEOPTIONS_V2DATASETS = 'reportingDataMainRuntimeOptionsV2Datasets';
const REPORTING_DATA_RESOLVE_DATA_SYSTEM = 'reportingDataDataSystem';
const CRM_SEARCH_DATA_SYSTEM = 'CRM Search';
const UNIFIED_ANALYTICS_DATA_SYSTEM = 'Unified Analytics';
const UNIFIED_EVENTS_DATA_SYSTEM = 'Unified Events';
const OTHER_DATA_SYSTEM = 'Other Data System';
const getReportDataSystem = dataType => {
  if (!dataType) {
    return undefined;
  }
  if (INBOUND_DB_DATA_TYPES.includes(dataType)) {
    return CRM_SEARCH_DATA_SYSTEM;
  } else if (UNIFIED_DATA_TYPES.includes(dataType)) {
    return UNIFIED_ANALYTICS_DATA_SYSTEM;
  } else if (dataType === UNIFIED_EVENTS) {
    return UNIFIED_EVENTS_DATA_SYSTEM;
  }
  return OTHER_DATA_SYSTEM;
};
const logResolve = (report, runtimeOptionsWithDefaults, resolvePath, err) => {
  const reportingLogger = new ReportingLogger();
  reportingLogger.addReportAttributes(report);
  reportingLogger.addAttribute(REPORTING_DATA_MAIN_RESOLVE_RUNTIMEOPTIONS_V2DATASETS, runtimeOptionsWithDefaults.v2Datasets);
  if (err) {
    reportingLogger.addErrorAttributes(err);
  }
  reportingLogger.addAttribute(REPORTING_DATA_MAIN_RESOLVE_PATH, resolvePath);
  reportingLogger.addAttribute(REPORTING_DATA_RESOLVE_DATA_SYSTEM, getReportDataSystem(report.getIn(['config', 'dataType'])));
  reportingLogger.sendPageAction(REPORTING_DATA_MAIN_RESOLVE);
};
export const resolve = (report, runtimeOptions = {}) => {
  sandbox.register(report);
  const runtimeOptionsWithDefaults = Object.assign({
    report
  }, RUNTIME_OPTIONS, runtimeOptions);
  if (report.get('reportConfigs')) {
    return resolveMultiConfigReport(report);
  }
  if (runtimeOptions.useFrontendResolve) {
    return resolveFn(Config(report.get('config')), runtimeOptionsWithDefaults);
  }
  const onlySupportedViaBackendResolve = isOnlySupportedViaBackendResolve(report);
  if ((onlySupportedViaBackendResolve || runtimeOptions.useBackendResolve) && runtimeOptionsWithDefaults.v2Datasets) {
    logResolve(report, runtimeOptionsWithDefaults, 'BackendResolveShortCircuit');
    return reportingApiResolve(report, runtimeOptionsWithDefaults);
  }
  logResolve(report, runtimeOptionsWithDefaults, 'FrontendResolve');
  return fiscalYearFetcher().then(fiscalYearMonthName => resolveFn(Config(report.get('config')), Object.assign({}, runtimeOptionsWithDefaults, {
    fiscalYearMonthName
  })));
};
export const runtimeOptions = RUNTIME_OPTIONS;

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = module.exports;
}