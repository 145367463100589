'use es6';

import { LINE_ITEMS } from '../../../../constants/dataTypes';
import { DEFAULT_NULL_VALUES } from '../../../../constants/defaultNullValues';
import { NUMBER } from '../../../../constants/property-types';
const fixLineItemAggregations = aggregations => aggregations.map(aggregation => aggregation.update('subAggregations', subAggregations => subAggregations && fixLineItemAggregations(subAggregations)));
export const fixProductIdRequest = dataType => request => {
  if (dataType === LINE_ITEMS && request.has('aggregations')) {
    return request.update('aggregations', fixLineItemAggregations);
  }
  return request;
};
const fixNumericMissingBucket = (aggregations, properties) => {
  return aggregations.map((buckets, property) => buckets.map(bucket => bucket.update('key', key => {
    if (properties.getIn([property, 'type']) !== NUMBER && !properties.getIn([property, 'reportingOverwrittenNumericType'], false)) {
      return key;
    }
    const parsed = parseFloat(key);
    return parsed === DEFAULT_NULL_VALUES.NUMBER ? DEFAULT_NULL_VALUES.ENUMERATION : String(parsed);
  }).update('aggregations', subAggregations => subAggregations && fixNumericMissingBucket(subAggregations, properties))));
};
export const fixNumericBucketResponse = (response, properties) => {
  if (response.has('aggregations')) {
    return response.update('aggregations', aggregations => fixNumericMissingBucket(aggregations, properties));
  }
  return response;
};