import * as http from '../../request/http';
// @ts-expect-error Untyped dependency
import { formatRaaSAPIResponse } from './resolve';
import { Map as ImmutableMap } from 'immutable';
import { getConfigsIncludingPrimary } from '../../report/multiConfigReportGetters';
const URL = 'reporting/v2/multidataset';
function translateConfigName(configName) {
  return configName === 'primaryConfig' ? 'primary' : configName;
}
export const resolveMultiConfigReport = report => {
  return http.post(URL, {
    data: {
      config: report.get('config'),
      reportConfigs: report.get('reportConfigs'),
      displayParams: report.get('displayParams')
    }
  }).then(response => {
    const dataSets = response.get('dataSets');
    const configs = getConfigsIncludingPrimary(report);
    const formattedDataset = dataSets.map((dataSet, configName) => {
      const config = configs.get(translateConfigName(configName));
      return formatRaaSAPIResponse(ImmutableMap({
        config
      }), dataSet, false);
    });
    return formattedDataset.reduce((acc, data, configName) => {
      const key = translateConfigName(configName);
      const compareKey = configName === 'primaryConfig' ? 'compare' : `${configName}_compare`;
      if (data.has('compare')) {
        acc = acc.set(compareKey, data.get('compare'));
      }
      return acc.set(key, data.get('primary'));
    }, ImmutableMap());
  }).catch(error => {
    throw new Error('Resolving multiConfig dataset failed', error);
  });
};