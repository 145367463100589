'use es6';

import { fromJS, List } from 'immutable';
import { SEARCH } from '../../../constants/configTypes';
import { getPaginationApiSettings } from '../../../lib/paginate';
import { isSupportedUEReport } from '../../../lib/unifiedEvents/utils';
export const getPaginatedResponse = (report, response) => {
  const dimensions = report.getIn(['config', 'dimensions']) || List();
  const configType = report.getIn(['config', 'configType']);
  const limit = report.getIn(['config', 'limit']);
  const offset = report.getIn(['config', 'offset']) || 0;
  if (!limit) {
    return response;
  }
  if (configType === SEARCH) {
    return response.update('data', [], data => data.take(limit));
  }
  const data = response.get('data', List());
  if (dimensions.size <= 1) {
    const isPaginatedUEReport = isSupportedUEReport(report) && response.hasIn(['pagination', 'total']);
    const total = isPaginatedUEReport ? response.getIn(['pagination', 'total']) : data.size;
    const apiLimit = getPaginationApiSettings(report).get('limit');
    const dataOffset = isPaginatedUEReport ? offset - Math.floor(offset / apiLimit) * apiLimit : offset;
    return response.set('data', data.skip(dataOffset).take(limit)).set('pagination', fromJS({
      total,
      offset: Math.min(total, limit + offset)
    }));
  }
  const pivotDimension = dimensions.first();
  const [uniqueDimensions, limitedData] = data.reduce(([uniques, limited], datum) => {
    const dim = datum.get(pivotDimension);
    const updatedUniques = uniques.includes(dim) ? uniques : [...uniques, dim];
    const dimPos = updatedUniques.indexOf(dim);
    const updatedDims = dimPos >= offset && dimPos < limit + offset ? [...limited, datum] : limited;
    return [updatedUniques, updatedDims];
  }, [[], []]);
  const total = uniqueDimensions.length;
  return response.set('data', List(limitedData)).set('pagination', fromJS({
    total,
    offset: Math.min(total, limit + offset)
  }));
};