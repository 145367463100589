'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { TableDescription } from '../../relational/schema/table-records';
import { DataSourceTypes } from '../../relational/schema/source-records';
import { getTableDescriptionMeta } from '../../relational/metadata/table-description-meta';
import { updateTables } from '../../relational/utils/table-utils';
import { getReportDefinition } from 'reporting-data/report/reportGetters';
export default (report => {
  const reportDefinition = getReportDefinition(report);
  const tableDescription = TableDescription(reportDefinition.get('table'));
  if (tableDescription.type === DataSourceTypes.HUBSPOT_DATASET || tableDescription.type === DataSourceTypes.HUBSPOT_GLOBAL_DATASET) {
    return report;
  }
  return getTableDescriptionMeta(tableDescription).then(({
    tables
  }) => {
    const mapOfTableNameToFullyQualifiedName = tables.filter(table => table.metaDefinition != null).mapKeys((__, table) => table.metaDefinition.name).map(table => table.metaDefinition.fullyQualifiedName);
    const maybeUpgradeName = tableName => {
      const hasTableName = mapOfTableNameToFullyQualifiedName.has(tableName);
      if (!hasTableName) {
        return tableName;
      }
      return mapOfTableNameToFullyQualifiedName.get(tableName);
    };
    const nextReportDefinition = reportDefinition.update('table', table => updateTables(table, t => t.update('name', maybeUpgradeName))).update('columns', columns => (columns || ImmutableMap()).map(column => column.updateIn(['field', 'table'], maybeUpgradeName))).update('stagedFields', stagedFields => stagedFields ? stagedFields.map(column => column.update('table', maybeUpgradeName)) : undefined).update('stagedColumns', columns => (columns || List()).map(column => column.updateIn(['field', 'table'], maybeUpgradeName))).update('filtering', filtering => filtering ? filtering.update('groups', groups => (groups || List()).map(group => group.update('filters', filters => filters.map(filter => filter.updateIn(['field', 'table'], maybeUpgradeName))))).update('stagedFilters', stagedFilters => (stagedFilters || List()).map(stagedFilter => stagedFilter.update('table', maybeUpgradeName))) : undefined);
    return report.set('reportDefinition', nextReportDefinition);
  });
});