'use es6';

import { fromJS, Map as ImmutableMap } from 'immutable';
import { get as getPipelines } from '../../pipelines';
import { DEALS } from '../../../../constants/dataTypes';
import { getDealCreateStage } from '../../../../properties/data-type/deals';
import { InvalidPipelineException } from '../../../../exceptions';
export default (pipelineId => getPipelines(DEALS).then(pipelines => {
  const pipeline = pipelines.find(({
    pipelineId: id
  }) => id === pipelineId);
  if (!pipeline) {
    throw new InvalidPipelineException(pipelineId);
  }
  return fromJS(pipeline).get('stages').map(stageInfo => ImmutableMap({
    label: stageInfo.get('label'),
    value: stageInfo.get('stageId'),
    dateEnteredStagePropertyName: stageInfo.getIn(['metadata', 'dateEnteredStagePropertyName'])
  })).unshift(ImmutableMap(getDealCreateStage()));
}));