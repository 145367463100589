'use es6';

import I18n from 'I18n';
import { List, Map as ImmutableMap } from 'immutable';
import { has } from '../../../lib/has';
import { UNIFIED_FEEDBACK_TRENDS } from '../../../constants/dataTypes';
const translate = key => () => I18n.text(`reporting-data.properties.unified-feedback-trends.${key}`);
const customerTypes = {
  detractorsCount: {
    NPS: translate('npsDetractorsCount'),
    CES: translate('cesDetractorsCount'),
    CSAT: translate('csatDetractorsCount')
  },
  passivesCount: {
    NPS: translate('npsPassivesCount'),
    CES: translate('cesPassivesCount'),
    CSAT: translate('csatPassivesCount')
  },
  promotersCount: {
    NPS: translate('npsPromotersCount'),
    CES: translate('cesPromotersCount'),
    CSAT: translate('csatPromotersCount')
  }
};
export const precondition = (property, config) => config.get('dataType') === UNIFIED_FEEDBACK_TRENDS && has(customerTypes, property);
export const format = (property, config) => {
  const surveyTypes = customerTypes[property] || {};
  const filters = config.getIn(['filters', 'custom'], List());
  const surveyType = filters.find(filter => filter.get('property') === 'surveyType', null, ImmutableMap()).get('value');
  return surveyType && has(surveyTypes, surveyType) ? surveyTypes[surveyType]() : null;
};