'use es6';

import { fromJS, Map as ImmutableMap, List } from 'immutable';
import I18n from 'I18n';
import { ACCUMULATE } from 'reporting-data/constants/processorTypes';
import { TIME_SERIES } from 'reporting-data/constants/configTypes';
import { ANALYTICS_SOURCES } from 'reporting-data/constants/dataTypes';
import makeDateRangeByType from 'reporting-data/lib/makeDateRangeByType';
import PortalIdParser from 'PortalIdParser';
import { stringify } from 'hub-http/helpers/params';
import { THEME } from 'reporting-visualizations/color/themes';
import { getPropertyLabel } from 'reporting-data/v2/dataset/datasetRecords';
const SOURCES_PATH = `/analytics/${PortalIdParser.get()}/sources`;
const PROPERTY_COLORS = {
  visits: ImmutableMap({
    theme: THEME.ORANGES
  }),
  contacts: ImmutableMap({
    theme: THEME.AQUAS
  }),
  customers: ImmutableMap({
    theme: THEME.PURPLES
  })
};
export const DEFAULT_GOALS = fromJS([{
  property: 'visits',
  goal: 5000
}, {
  property: 'contacts',
  goal: 50
}, {
  property: 'customers',
  goal: 5
}]);
export const chartDisplayParams = fromJS({
  stacked: false,
  xAxis: {
    title: {
      enabled: false
    }
  },
  yAxis: {
    title: {
      enabled: false
    }
  },
  hideLegend: true,
  hideDataLabels: true
});
export const getCustomOption = (report, key) => {
  return report.getIn(['displayParams', 'customWidget', 'options', key]);
};
const getFilters = performanceReport => {
  const customFilters = performanceReport.getIn(['config', 'filters', 'custom']).toJS();
  if (!getCustomOption(performanceReport, 'showOfflineSources')) {
    customFilters.push({
      property: 'excludes',
      values: ['offline']
    });
  }
  return fromJS({
    dateRange: performanceReport.getIn(['config', 'filters', 'dateRange']).set('property', 'sessionDate'),
    custom: customFilters
  });
};
export const createExportableSessionsReport = performanceReport => {
  const frequency = performanceReport.getIn(['config', 'frequency']);
  const config = {
    configType: TIME_SERIES,
    dataType: ANALYTICS_SOURCES,
    frequency,
    filters: getFilters(performanceReport),
    dimensions: ['sessionDate'],
    metrics: ['visits', 'contacts', 'customers'].map(property => ({
      property,
      metricTypes: ['SUM']
    }))
  };
  return fromJS({
    chartType: 'AREA',
    displayParams: {},
    config
  });
};
export const createSessionsReport = (property, performanceReport, onDrilldown) => {
  const frequency = performanceReport.getIn(['config', 'frequency']);
  const compare = property !== 'customers' ? performanceReport.getIn(['config', 'compare']) : undefined;
  const goals = getCustomOption(performanceReport, 'goals') || DEFAULT_GOALS;
  const customSeries = getCustomOption(performanceReport, 'showGoals') ? fromJS([{
    type: 'datetime.goal',
    timeUnit: 'MONTH',
    name: I18n.text(`reporting-ui-components.customWidgets.performanceWidget.propertyGoalLabel.${property}`),
    goal: goals.find(goal => goal.get('property') === property).get('goal')
  }]) : List();
  const config = {
    configType: TIME_SERIES,
    dataType: ANALYTICS_SOURCES,
    frequency,
    compare,
    filters: getFilters(performanceReport),
    dimensions: ['sessionDate'],
    metrics: [{
      property,
      metricTypes: ['SUM']
    }],
    processors: [ACCUMULATE]
  };
  return fromJS({
    chartType: 'AREA',
    displayParams: chartDisplayParams.set('customSeries', customSeries).set('colors', PROPERTY_COLORS[property]).set('onChartClick', keys => onDrilldown(fromJS(config), keys)),
    config
  });
};
export const createConversionReport = performanceReport => {
  const compare = performanceReport.getIn(['config', 'compare']);
  return fromJS({
    chartType: 'LINE',
    displayParams: {},
    config: {
      configType: TIME_SERIES,
      dataType: ANALYTICS_SOURCES,
      frequency: 'MONTH',
      compare,
      filters: getFilters(performanceReport),
      sort: [],
      dimensions: ['sessionDate'],
      metrics: [{
        property: 'visits',
        metricTypes: ['SUM']
      }, {
        property: 'contacts',
        metricTypes: ['SUM']
      }, {
        property: 'customers',
        metricTypes: ['SUM']
      }]
    }
  });
};
export const getLastMonthReport = performanceReport => createConversionReport(performanceReport.setIn(['config', 'filters', 'dateRange', 'value'], ImmutableMap({
  rangeType: 'THIS_MONTH'
})));
const getMetricPropertyLabels = data => {
  let labels = ImmutableMap();
  data.getIn(['config', 'metrics']).forEach(metric => {
    const propertyName = metric.get('property');
    labels = labels.set(propertyName, getPropertyLabel(data.get('data'), `SUM|${propertyName}`));
  });
  return labels;
};
const calculateMetricSum = (data, metricPropertyName) => {
  return data.getIn(['data', 'data']).reduce((sum, dataObj) => {
    return sum + dataObj.get(`SUM|${metricPropertyName}`);
  }, 0);
};
const getMetricSums = data => {
  let metrics = ImmutableMap();
  data.getIn(['config', 'metrics']).forEach(metric => {
    const propertyName = metric.get('property');
    metrics = metrics.set(propertyName, calculateMetricSum(data, propertyName));
  });
  return metrics;
};
const getMetrics = data => {
  return data.getIn(['primary', 'data', 'data', 0]);
};
export const getSourcePerformancePath = (performanceReport, metric) => {
  const config = performanceReport.get('config');
  const filters = config.get('filters');
  const frequency = config.get('frequency');
  const dateRange = filters.getIn(['dateRange', 'value']);
  const excludeOffline = !getCustomOption(performanceReport, 'showOfflineSources');
  const params = Object.assign({
    excludeOffline,
    metric,
    frequency
  }, makeDateRangeByType(dateRange.toJS(), 'YYYYMMDD'));
  return `${SOURCES_PATH}?${stringify(params)}`;
};
const getConversionRate = (numerator, denominator) => denominator === 0 ? 0 : numerator / denominator;
export const getStages = (data, performanceReport, onDrilldown) => {
  const reportData = data.get('primary');
  const compareData = data.get('compare');
  const titles = getMetricPropertyLabels(reportData);
  const metricSums = getMetricSums(reportData);
  const compareMetricSums = compareData && getMetricSums(compareData);
  const createStage = (property, nextProperty) => {
    const deltaRate = property !== 'customers' && compareMetricSums ? metricSums.get(property, 0) / compareMetricSums.get(property, 0) - 1 : null;
    const getTotal = propertyName => calculateMetricSum(reportData, propertyName);
    const conversionRate = nextProperty != null ? getConversionRate(getTotal(nextProperty), getTotal(property)) : null;
    return {
      property,
      report: createSessionsReport(property, performanceReport, onDrilldown),
      conversionRate,
      count: metricSums.get(property),
      deltaRate,
      title: titles.get(property),
      sourcesPath: getSourcePerformancePath(performanceReport, property)
    };
  };
  return [createStage('visits', 'contacts'), createStage('contacts', 'customers'), createStage('customers')];
};
export const isLow = lastMonthGoals => {
  return DEFAULT_GOALS.reduce((belowAllDefaultGoals, goal) => {
    const property = goal.get('property');
    const defaultValue = goal.get('goal');
    const actual = lastMonthGoals.find(lastMonthGoal => lastMonthGoal.get('property') === property);
    if (actual) {
      belowAllDefaultGoals = belowAllDefaultGoals && actual.get('goal') < defaultValue;
    }
    return belowAllDefaultGoals;
  }, true);
};
export const getGoalsFromData = data => {
  const metrics = getMetrics(data);
  return List(['SUM|visits', 'SUM|contacts', 'SUM|customers']).filter(property => metrics && metrics.includes(property)).map(property => ImmutableMap({
    property,
    goal: metrics.get(property)
  }));
};
export const getGoalsFromReport = report => {
  return getCustomOption(report, 'goals');
};
export const setGoalsOnReport = (report, goals) => {
  const showGoals = goals != null;
  return report.setIn(['displayParams', 'customWidget', 'options', 'goals'], goals).setIn(['displayParams', 'customWidget', 'options', 'showGoals'], showGoals);
};
export const applyTitleLinkToReport = report => {
  return report.setIn(['displayParams', 'origin', 'pathname'], getSourcePerformancePath(report, 'visits'));
};
export const setCustomOption = (report, key, value) => {
  return report.setIn(['displayParams', 'customWidget', 'options', key], value);
};