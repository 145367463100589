'use es6';

import { fromJS, List } from 'immutable';
import * as DataTypes from '../../../constants/dataTypes';
import { extractPropertyNamespace, getObjectId, namespaceProperty } from '../../../properties/namespaceProperty';
const SORT_ID_PROPERTIES = {
  [DataTypes.CONTACTS]: 'vid',
  [DataTypes.COMPANIES]: 'company-id',
  [DataTypes.DEALS]: 'dealId',
  [DataTypes.TICKETS]: 'hs_ticket_id'
};
const SORT_PROPERTY_MAP = fromJS({
  [DataTypes.CONTACTS]: {
    vid: ['lastname', 'firstname', 'email', 'hs_object_id'],
    hs_object_id: ['lastname', 'firstname', 'email', 'hs_object_id'],
    [getObjectId(DataTypes.CONTACTS)]: ['lastname', 'firstname', 'email', 'hs_object_id']
  },
  [DataTypes.COMPANIES]: {
    ['company-id']: ['name', 'hs_object_id'],
    hs_object_id: ['name', 'hs_object_id'],
    [getObjectId(DataTypes.COMPANIES)]: ['name', 'hs_object_id']
  },
  [DataTypes.DEALS]: {
    dealId: ['dealname', 'hs_object_id'],
    hs_object_id: ['dealname', 'hs_object_id'],
    [getObjectId(DataTypes.DEALS)]: ['dealname', 'hs_object_id'],
    dealstage: ['dealstage.displayOrder', 'dealstage']
  },
  [DataTypes.TICKETS]: {
    hs_object_id: ['subject', 'hs_object_id'],
    ['hs_ticket_id']: ['subject', 'hs_object_id'],
    [getObjectId(DataTypes.TICKETS)]: ['subject', 'hs_object_id']
  }
});
const getMappedProperty = (dataType, property) => SORT_PROPERTY_MAP.getIn([dataType, property], List([property])).toJS();
const mapCrossObjectProperty = property => {
  const {
    dataType,
    propertyName
  } = extractPropertyNamespace(property);
  return getMappedProperty(dataType, propertyName).map(mappedProperty => namespaceProperty(dataType, mappedProperty === 'hs_object_id' ? SORT_ID_PROPERTIES[dataType] : mappedProperty));
};
export default ((config, sorts) => {
  const dataType = config.get('dataType');
  const isCrossObject = dataType === DataTypes.CROSS_OBJECT;
  let updatedSorts = [];
  sorts.forEach(({
    property,
    order
  }) => {
    const mappedProperties = isCrossObject ? mapCrossObjectProperty(property) : getMappedProperty(dataType, property);
    updatedSorts = updatedSorts.concat(mappedProperties.map(mappedProperty => ({
      property: mappedProperty,
      order
    })));
  });
  return updatedSorts;
});