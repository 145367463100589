'use es6';

import { List } from 'immutable';
import responseTooLarge from './responseTooLarge';
import tooManyBreakdowns from './tooManyBreakdowns';
const PRECONDITIONS = {
  generate: [],
  transform: [responseTooLarge, tooManyBreakdowns]
};
const check = (input, preconditions) => Promise.all(preconditions.map(precondition => precondition(input)));
export const generate = (config, preconditions = PRECONDITIONS.generate) => check(config, preconditions);
export const transform = (response, preconditions = PRECONDITIONS.transform) => List.isList(response) ? response.forEach(dataset => check(dataset, preconditions)) : check(response, preconditions);