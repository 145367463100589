import { fromJS } from 'immutable';
import { fromMetricKey } from '../../v2/dataset/datasetMetrics';
import { setReportConfigMetricByIndex } from '../configReportSetters';
import { PRIMARY_CONFIG_NAME, getConfigByName, getSubtitleField } from '../multiConfigReportGetters';
import { setConfigByName } from './multiConfigReportConfigManagementSetter';
import { getFieldsForUpdatingSubtitleField, setColumnAlias, setFieldOptions } from './multiConfigReportDisplayParamSetter';

/**
 * Sets a new metric property in the metrics array of the report config of the right config
 * and updates the column alias to match the new property.
 */
export const setMetricPropertyByConfigName = (report, configName, property, metricType, order = 0, columnAlias) => {
  let newReport = report;
  const newMetric = fromJS({
    property,
    metricTypes: fromJS([metricType])
  });
  if (configName === PRIMARY_CONFIG_NAME) {
    newReport = newReport.mergeIn(['config', 'metrics', order], newMetric);
  } else {
    newReport = newReport.mergeIn(['reportConfigs', configName, 'metrics', order], newMetric);
  }

  // in case there are submetric type and sublabel defined, we need to keep this information
  const subtitleField = getSubtitleField(report, configName);
  let secondaryMetricType = undefined;
  let secondaryMetricLabel = undefined;
  if (subtitleField) {
    secondaryMetricType = fromMetricKey(subtitleField === null || subtitleField === void 0 ? void 0 : subtitleField.get('metric')).type;
    secondaryMetricLabel = subtitleField === null || subtitleField === void 0 ? void 0 : subtitleField.get('propertyLabel');
  }
  newReport = setFieldOptions(newReport, configName, property, metricType, secondaryMetricType, secondaryMetricLabel);
  newReport = setColumnAlias(newReport, configName, property, metricType, columnAlias || '');
  return newReport;
};

/**
 * Update the subAggregationMetric for the a specific config
 */
export const setSubAggregationMetric = (report, configName, subAggregationMetric) => {
  const config = getConfigByName(report, configName);
  const {
    property,
    metricType,
    subtitleField
  } = getFieldsForUpdatingSubtitleField(report, configName);
  if (!metricType) {
    console.warn(`No metric type found for config ${configName}`);
    return report;
  }

  // we need to update the metricTypes array at index 1 (index of the secondary metric)
  const metricTypes = fromJS({
    property,
    metricTypes: [subAggregationMetric]
  });
  const updatedConfig = setReportConfigMetricByIndex(config, metricTypes, 1);
  const updatedReport = setConfigByName(report, updatedConfig, configName);
  return setFieldOptions(updatedReport, configName, property, metricType, subAggregationMetric, subtitleField === null || subtitleField === void 0 ? void 0 : subtitleField.get('propertyLabel'));
};

/**
 * Clears metrics for a specific config in a multi-config report or clears all metrics for the config if order is not provided
 * @param report - The multi-config report to update
 * @param configName - The name of the config to clear metrics from (either PRIMARY_CONFIG_NAME or a secondary config name)
 * @param order - Optional index of specific metric to clear. If not provided, clears all metrics for the config
 * @returns A new report with the specified metrics cleared
 */
export const clearMetricsByConfigName = (report, configName, index) => {
  if (!index) {
    if (configName === PRIMARY_CONFIG_NAME) {
      return report.setIn(['config', 'metrics'], fromJS([]));
    }
    return report.setIn(['reportConfigs', configName, 'metrics'], fromJS([]));
  }
  if (configName === PRIMARY_CONFIG_NAME) {
    return report.deleteIn(['config', 'metrics', index]);
  }
  return report.deleteIn(['reportConfigs', configName, 'metrics', index]);
};