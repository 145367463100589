'use es6';

import { fromJS } from 'immutable';
import { SHOW_BANNER } from 'reporting-data/constants/deprecate/deprecationTypes';
import { isCampaignsContactAttributionReport } from 'reporting-data/properties/isCampaignsContactAttributionReport';
export const campaignsContactAttribution = report => {
  return isCampaignsContactAttributionReport(report) ? report.set('deprecation', fromJS({
    isDeprecated: true,
    type: SHOW_BANNER,
    message: 'campaignsContactAttribution'
  })) : report;
};
export default campaignsContactAttribution;