'use es6';

import { AsyncAddReportToDashboardDialog as AddReportToDashboardDialog } from '../reportingActions/addReportToDashboard/dialog/AsyncAddReportToDashboardDialog';
import ChangeReportOwnerModal from '../reportingActions/changeReportOwner/modal/ChangeReportOwnerModal';
import AsyncCreateDashboardDialogContainer from '../reportingActions/createDashboard/dialog/AsyncCreateDashboardDialogContainer';
import DeleteDashboardModal from '../reportingActions/deleteDashboard/modal/DeleteDashboardModal';
import DeleteReportModal from '../reportingActions/deleteReport/modal/DeleteReportModal';
import EditGoalsModal from '../reportingActions/editGoals/modal/EditGoalsModal';
import EmailReportDialogContainer from '../reportingActions/emailReport/dialog/EmailReportDialogContainer';
import ManageRecurringShareAssetsDialog from '../reportingActions/manageRecurringEmails/dialog/ManageRecurringShareAssetsDialog';
import ManageReportAccessDialogContainer from '../reportingActions/manageReportAccess/modal/ManageReportAccessDialogContainer';
import MoveReportDialog from '../reportingActions/moveReport/modal/MoveReportDialog';
import RenameReportModal from '../reportingActions/renameReport/modal/RenameReportModal';
import RestoreReportModal from '../reportingActions/restoreReport/modal/RestoreReportModal';
import SetDefaultDashboardModal from '../reportingActions/setDefaultDashboard/modal/SetDefaultDashboardModal';
import SetAssetBusinessUnitModal from '../reportingActions/setReportBusinessUnit/modal/SetAssetBusinessUnitModal';
import ShareReportDialogContainer from '../reportingActions/shareAsset/dialog/ShareReportDialogContainer';
import ShareViaDialogContainer from '../reportingActions/shareVia/dialog/ShareViaDialogContainer';
import AsyncDashboardDetailsPanel from '../reportingActions/dashboardDetailsAction/dialog/AsyncDashboardDetailsPanel';
import AddSectionHeaderToDashboardDialogContainer from '../reportingActions/addSectionHeaderToDashboard/dialog/AddSectionHeaderToDashboardDialogContainer';
import MarketingPerformanceEditGoalsModal from '../reportingActions/customWidget/marketingPerformance/modal/MarketingPerformanceEditGoalsModal';
import SalesPerformanceCustomizeModal from '../reportingActions/customWidget/salesPerformance/modal/SalesPerformanceCustomizeModal';
import { ADD_TO_DASHBOARD_DIALOG, CHANGE_REPORT_OWNER_DIALOG, CREATE_DASHBOARD_DIALOG, DASHBOARD_ACTION_SET_DEFAULT, DASHBOARD_SET_DEFAULT, DELETE_DASHBOARD_DIALOG, DELETE_REPORT_DIALOG, EDIT_GOALS_DIALOG, EMAIL_REPORT_DIALOG, MANAGE_RECURRING_EMAILS_DIALOG, MANAGE_REPORT_ACCESS_DIALOG, MOVE_REPORT_DIALOG, RENAME_REPORT_DIALOG, RESTORE_REPORT_DIALOG, SET_BUSINESS_UNIT_DIALOG, SHARE_ASSET_DIALOG, SHARE_VIA_DIALOG, VIEW_DASHBOARD_DETAILS, ADD_SECTION_HEADER_TO_DASHBOARD_DIALOG, MARKETING_PERFORMANCE_DIALOG, CUSTOMIZE_DIALOG } from './dialogTypes';
export const REPORT_ACTION_DIALOGS = {
  [EDIT_GOALS_DIALOG]: EditGoalsModal,
  [DELETE_REPORT_DIALOG]: DeleteReportModal,
  [RENAME_REPORT_DIALOG]: RenameReportModal,
  [EMAIL_REPORT_DIALOG]: EmailReportDialogContainer,
  [SHARE_ASSET_DIALOG]: ShareReportDialogContainer,
  [SHARE_VIA_DIALOG]: ShareViaDialogContainer,
  [MANAGE_RECURRING_EMAILS_DIALOG]: ManageRecurringShareAssetsDialog,
  [RESTORE_REPORT_DIALOG]: RestoreReportModal,
  [CHANGE_REPORT_OWNER_DIALOG]: ChangeReportOwnerModal,
  [MANAGE_REPORT_ACCESS_DIALOG]: ManageReportAccessDialogContainer,
  [ADD_TO_DASHBOARD_DIALOG]: AddReportToDashboardDialog,
  [SET_BUSINESS_UNIT_DIALOG]: SetAssetBusinessUnitModal,
  [MOVE_REPORT_DIALOG]: MoveReportDialog,
  [MARKETING_PERFORMANCE_DIALOG]: MarketingPerformanceEditGoalsModal,
  [CUSTOMIZE_DIALOG]: SalesPerformanceCustomizeModal
};
export const DASHBOARD_ACTION_DIALOGS = {
  [CREATE_DASHBOARD_DIALOG]: AsyncCreateDashboardDialogContainer,
  [DELETE_DASHBOARD_DIALOG]: DeleteDashboardModal,
  [DASHBOARD_SET_DEFAULT]: SetDefaultDashboardModal,
  [DASHBOARD_ACTION_SET_DEFAULT]: SetDefaultDashboardModal,
  [VIEW_DASHBOARD_DETAILS]: AsyncDashboardDetailsPanel,
  [ADD_SECTION_HEADER_TO_DASHBOARD_DIALOG]: AddSectionHeaderToDashboardDialogContainer
};