'use es6';

import { List } from 'immutable';
import { AGGREGATION, TIME_SERIES } from '../../constants/configTypes';
import { EQ, IN } from '../../constants/operators';
import { Promise } from '../../lib/promise';
import { toUpperCase } from '../../lib/string';
import { CURRENCY_PROPERTY_NAME, LEGACY_DEAL_AMOUNT_PROPERTIES } from '../../properties/constants/multiCurrencyProperties';
import { getCurrencyInformation } from '../../public/currency';
import { getFilterByProperty } from '../../config/filters/functions';
import { matchCurrencyProcessor, getCurrencyCodeProcessorValueIfExists } from '../../process';
const FALLBACK = 'USD';
const getCurrencyCode = (config, homeCurrency) => (property, propertyInfo) => {
  const configType = config.get('configType');
  const currencyPropertyName = propertyInfo.get(CURRENCY_PROPERTY_NAME);
  const currencyFilter = getFilterByProperty(config, currencyPropertyName);
  if ([AGGREGATION, TIME_SERIES].includes(configType) && (currencyPropertyName || LEGACY_DEAL_AMOUNT_PROPERTIES.includes(property))) {
    if (currencyFilter) {
      if (currencyFilter.get('operator') === EQ) {
        return toUpperCase(currencyFilter.get('value'));
      } else if (currencyFilter.get('operator') === IN && currencyFilter.get('values', List()).size === 1) {
        return toUpperCase(currencyFilter.get('values', List()).first());
      }
    }

    // This scenario is if we are attempting to aggregate on a property that represents multiple currencies, we generally recommend using a property in home currenccies as long as the downstream reporting systems do not do currency conversion
    return null;
  }
  return homeCurrency;
};
export default ((config, properties) => {
  const metrics = config.get('metrics');
  const hasCurrencyProperty = metrics.some(metric => {
    const property = metric.get('property');
    return properties.getIn([property, 'type']) === 'currency';
  });
  if (!hasCurrencyProperty) {
    return Promise.resolve(() => null);
  }
  const processors = config.get('processors', List());
  const currencyProcessorCode = getCurrencyCodeProcessorValueIfExists(processors.find(matchCurrencyProcessor));
  return currencyProcessorCode ? Promise.resolve(() => currencyProcessorCode) : getCurrencyInformation().then(({
    homeCurrency: {
      currencyCode = FALLBACK
    }
  }) => {
    return getCurrencyCode(config, currencyCode);
  });
});