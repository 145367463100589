import { fromJS } from 'immutable';
import { createSelector } from 'reselect';
export const reportPanelUiNamespace = 'ui';

// Action types
const SET_REPORT_PANEL_VIEW = 'SET_REPORT_PANEL_VIEW';
const SET_REPORT_PANEL_DRAG_ENABLED = 'SET_REPORT_PANEL_DRAG_ENABLED';
const SET_REPORT_PANEL_DASHBOARD_ID = 'SET_REPORT_PANEL_DASHBOARD_ID';
const SET_DASHBOARD_GRID_SIZE = 'SET_DASHBOARD_GRID_SIZE';
export let ReportPanelView;
(function (ReportPanelView) {
  ReportPanelView["TABLE"] = "TABLE";
  ReportPanelView["CARD"] = "CARD";
})(ReportPanelView || (ReportPanelView = {})); // Define UI state interface
// Initial state
const initialUiState = fromJS({
  dragEnabled: false,
  view: ReportPanelView.CARD,
  dashboardId: null,
  dashboardGridSize: null
});

// UI reducer
export const reportPanelUiReducer = (state = initialUiState, action) => {
  switch (action.type) {
    case SET_REPORT_PANEL_DRAG_ENABLED:
      return state.set('dragEnabled', action.payload);
    case SET_REPORT_PANEL_VIEW:
      return state.set('view', action.payload);
    case SET_REPORT_PANEL_DASHBOARD_ID:
      return state.set('dashboardId', action.payload);
    case SET_DASHBOARD_GRID_SIZE:
      return state.set('dashboardGridSize', action.payload);
    default:
      return state;
  }
};

// Actions
export const setReportPanelDragEnabled = enabled => ({
  type: SET_REPORT_PANEL_DRAG_ENABLED,
  payload: enabled
});
export const setReportPanelView = view => ({
  type: SET_REPORT_PANEL_VIEW,
  payload: view
});
export const setReportPanelDashboardId = dashboardId => ({
  type: SET_REPORT_PANEL_DASHBOARD_ID,
  payload: dashboardId
});
export const setDashboardGridSize = gridSize => ({
  type: SET_DASHBOARD_GRID_SIZE,
  payload: gridSize
});

// Selectors
const reportPanelNamespace = 'reportPanel';
export const selectReportPanelUiRoot = state => state[reportPanelNamespace][reportPanelUiNamespace];
export const selectReportPanelDragEnabled = createSelector(selectReportPanelUiRoot, reportPanelUiRoot => reportPanelUiRoot.get('dragEnabled'));
export const selectReportPanelView = createSelector(selectReportPanelUiRoot, reportPanelUiRoot => reportPanelUiRoot.get('view'));
export const selectReportPanelDashboardId = createSelector(selectReportPanelUiRoot, reportPanelUiRoot => reportPanelUiRoot.get('dashboardId'));
export const selectDashboardGridSize = createSelector(selectReportPanelUiRoot, reportPanelUiRoot => reportPanelUiRoot.get('dashboardGridSize'));