import { Map as ImmutableMap, Set as ImmutableSet, List } from 'immutable';
import { isListFilter, removeFiltersWithPredicate } from '../relational/modify/filters/filter-utils';
import { Conditions, FilterRefTypes, Filtering } from '../relational/schema/filter-records';
import { DATASET_TABLE, TableDescription } from '../relational/schema/table-records';
import { Contexts } from './context-records';
// import { checkExpressionContext } from './context-utils';
import { DataSourceTypes } from '../relational/schema/source-records';
import { DatasetField, DatasetProperty, RelationalDataset } from './dataset-records';
import { DefaultEditor } from './editor-records';
import { withStaticTypeDependencies } from './expression-meta';
import { Property } from './expression-records';
import { expressionToList, expressionToString, fieldsToGraph, getVertexDependencies, getVertexOrder, isCyclic, isFieldExpression, parseExpression, toFieldNameExpression, transposeGraph } from './expression-utils';
import { UNDEFINED_TYPE, createPropertyType } from './type-records';
import { checkExpressionType, toPropertyBasicType } from './type-utils';
import { CircularDependencyViolation,
// DimensionFieldViolation,
PersistedTypeDiscrepancyViolation, SyntaxViolation, ViolationLevels } from './violation-records';
const FORBIDDEN_CHARACTERS = {
  '.': '.',
  '[': '[',
  ']': ']'
};
const REPLACEMENT_CHARACTERS = {
  '.': ',',
  '[': '(',
  ']': ')'
};

/**
 * @deprecated use `reporting-snowflake/dataset/dataset-property-utils` instead
 */
export const toDatasetProperty = (tableName, snowflakeProperty) => DatasetProperty({
  name: snowflakeProperty.name,
  table: tableName,
  type: snowflakeProperty.type
});
export const getFieldNames = dataset => dataset.fields.map(({
  name
}) => name).toSet();
export const getFieldLabels = dataset => dataset.fields.map(({
  label
}) => label).toSet();
export const getNextFieldName = reservedNames => {
  const fieldNumbers = reservedNames.map(name => Number(name));
  return String(fieldNumbers.isEmpty() ? 1 : fieldNumbers.max() + 1);
};

// translate
export const getDefaultFieldLabel = name => `Field ${name}`;

// translate
export const getSanitizedFieldLabel = desiredLabel => desiredLabel.split('').map(char => FORBIDDEN_CHARACTERS[char] ? REPLACEMENT_CHARACTERS[char] : char).join('').trim();
export const getAvailableFieldLabel = (desiredLabel, reservedLabels) => {
  reservedLabels = ImmutableSet(reservedLabels);
  const sanitizedLabel = getSanitizedFieldLabel(desiredLabel);
  let count = 2;
  const find = next => !reservedLabels.contains(next) ? next : find(`${sanitizedLabel} ${count++}`);
  return find(sanitizedLabel);
};

// translate
export const getDuplicatedFieldLabel = (desiredLabel, reservedLabels) => {
  reservedLabels = ImmutableSet(reservedLabels);
  const sanitizedLabel = getSanitizedFieldLabel(desiredLabel);
  const duplicateLabel = `Copy of ${sanitizedLabel}`;
  let count = 2;
  const find = next => !reservedLabels.contains(next) ? next : find(`${duplicateLabel} ${count++}`);
  return find(duplicateLabel);
};
export const createField = dataset => {
  const name = getNextFieldName(getFieldNames(dataset));
  return DatasetField({
    name,
    label: getDefaultFieldLabel(name),
    derived: true,
    hidden: false,
    valid: true,
    input: '',
    expression: {
      type: 'NULL_LITERAL',
      value: null
    },
    type: UNDEFINED_TYPE,
    context: Contexts.UNDEFINED,
    editor: DefaultEditor({
      editorState: {}
    })
  });
};
export const createFieldFromProperty = (dataset, tableName, snowflakeProperty) => {
  const name = getNextFieldName(getFieldNames(dataset));
  const expression = Property({
    table: tableName,
    name: snowflakeProperty.name
  });
  return DatasetField({
    name,
    label: getAvailableFieldLabel(snowflakeProperty.label || snowflakeProperty.name, getFieldLabels(dataset)),
    derived: false,
    hidden: false,
    valid: true,
    input: expressionToString(expression),
    expression,
    type: createPropertyType(toDatasetProperty(tableName, snowflakeProperty).type),
    context: Contexts.ROW_LEVEL
  });
};
export const createDuplicatedField = (dataset, field) => {
  const name = getNextFieldName(getFieldNames(dataset));
  return DatasetField({
    name,
    label: getDuplicatedFieldLabel(field.label, getFieldLabels(dataset)),
    derived: field.derived,
    hidden: field.hidden,
    valid: field.valid,
    input: field.input,
    expression: field.expression,
    type: field.type,
    context: field.context
  });
};
export const createDefaultDataset = () => RelationalDataset({
  table: TableDescription({
    name: 'CONTACT',
    objectTypeId: '0-1',
    type: DataSourceTypes.HUBSPOT_OBJECT
  }),
  properties: ImmutableMap(),
  fields: ImmutableMap(),
  fieldOrder: List(),
  derivedFieldOrder: List(),
  unifiedFieldOrder: List(),
  filtering: Filtering({
    condition: Conditions.AND,
    groups: List(),
    logic: undefined,
    stagedFilters: List()
  })
});
export const getPropertyKey = datasetProperty => `${datasetProperty.table}.${datasetProperty.name}`;
export const getPropertyLabel = (property, snowflakeProperty) => {
  if (!snowflakeProperty) {
    return property.name;
  }
  return snowflakeProperty.label;
};
export const getDependentFieldNames = (dataset, field) => {
  const graph = fieldsToGraph(dataset.fields);
  return getVertexDependencies(transposeGraph(graph), field.name);
};
const fieldWithViolations = (field, ...violationsArr) => {
  const violationsList = List(violationsArr);
  return {
    field: field.update('valid', valid => valid && violationsList.isEmpty()),
    violations: violationsList
  };
};
const checkFieldInput = (field, dependencies) => {
  try {
    const expression = toFieldNameExpression(parseExpression(field.input), dependencies.fields);
    const partiallyCheckedField = field.set('expression', expression);
    return fieldWithViolations(partiallyCheckedField);
  } catch (error) {
    const expression = parseExpression('null');
    const partiallyCheckedField = field.set('expression', expression);
    return field.input.trim() === '' ? fieldWithViolations(partiallyCheckedField) : fieldWithViolations(partiallyCheckedField, SyntaxViolation({
      level: ViolationLevels.ERROR,
      input: field.input,
      syntaxError: error
    }));
  }
};
const checkFieldForCircularDependencies = (field, dependencies) => {
  if (!field.expression) {
    return fieldWithViolations(field);
  }
  if (expressionToList(field.expression).some(exp => isFieldExpression(exp))) {
    const fieldsWithNewField = dependencies.fields.set(field.name, field);
    const fieldGraph = fieldsToGraph(fieldsWithNewField);
    if (isCyclic(fieldGraph, field.name)) {
      return fieldWithViolations(field, CircularDependencyViolation({
        level: ViolationLevels.ERROR,
        expression: field.expression
      }));
    }
  }
  return fieldWithViolations(field);
};
const checkFieldExpressionType = (field, dependencies) => {
  const {
    type,
    violations
  } = checkExpressionType(field.expression, dependencies);
  const partiallyCheckedField = field.set('type', type);
  return fieldWithViolations(partiallyCheckedField, ...violations.toArray());
};
// enable when we release aggregations feature

// const checkFieldExpressionContext = (
//   field: DatasetField,
//   dependencies: TypeDependencies
// ): { field: DatasetField; violations: List<AnyViolation> } => {
//   const { context, violations } = checkExpressionContext(
//     field.expression,
//     dependencies
//   );

//   const partiallyCheckedField = field.set('context', context);

//   if (context === Contexts.AGGREGATE) {
//     return fieldWithViolations(
//       partiallyCheckedField,
//       DimensionFieldViolation({ level: ViolationLevels.ERROR }),
//       ...violations.toArray()
//     );
//   }

//   return fieldWithViolations(partiallyCheckedField, ...violations.toArray());
// };

const checkFieldPersistedPropertyType = field => {
  if (field.hasPropertyMapping && field.persistedType) {
    const currentFieldBasicType = toPropertyBasicType(field.type);
    const persistedPropertyType = toPropertyBasicType(field.persistedType);
    if (currentFieldBasicType !== persistedPropertyType) {
      return fieldWithViolations(field, PersistedTypeDiscrepancyViolation({
        level: ViolationLevels.ERROR,
        currentType: field.type,
        persistedType: field.persistedType
      }));
    }
  }
  return fieldWithViolations(field);
};
export const checkField = (field, dependencies) => {
  field = field.set('valid', true);
  return [checkFieldInput, checkFieldForCircularDependencies, checkFieldExpressionType,
  // enable when we release aggregations feature
  // checkFieldExpressionContext,
  checkFieldPersistedPropertyType].reduce(({
    field: currentField,
    violations: currentViolations
  }, next) => {
    const result = next(currentField, dependencies);
    return fieldWithViolations(result.field, ...currentViolations.toArray(), ...result.violations.toArray());
  }, fieldWithViolations(field));
};

/**
 * Warning:
 * This is an oddly low-level operation.
 * Running this with a list of fields
 * is probably not what you want to do.
 * The order in which fields are checked
 * will always be relevant.
 */
// @ts-expect-error add annotations
export const checkFields = (dataset, fields) => fields.reduce(
// @ts-expect-error add annotations
(nextDataset, fieldName) => nextDataset.updateIn(['fields', fieldName],
// @ts-expect-error add annotations
dependentField => checkField(dependentField, withStaticTypeDependencies({
  fields: nextDataset.fields,
  properties: nextDataset.properties
})).field), dataset);

// @ts-expect-error add annotations
export const checkAllFields = dataset => checkFields(dataset, getVertexOrder(fieldsToGraph(dataset.fields), true));

// @ts-expect-error add annotations
export const checkDependentFields = (dataset, field) => checkFields(dataset, getDependentFieldNames(dataset, field));
export const getInvalidFilters = dataset => {
  if (!dataset.filtering) {
    return List();
  }
  return dataset.filtering.groups.flatMap(group => group.filters.filter(filter => {
    if (filter.field.table !== DATASET_TABLE) {
      return false;
    }
    if (isListFilter(filter)) {
      return false;
    }
    const datasetField = dataset.fields.get(filter.field.name);
    return !datasetField || !datasetField.valid || toPropertyBasicType(datasetField.type) !== filter.field.type;
  }));
};
export const hasInvalidFilters = dataset => !getInvalidFilters(dataset).isEmpty();

// TODO: stagedFilter was undefined in recent sentry, but should never be undefined.
// Look into why this happened, and maybe update removeFilters to get rid of undefined values,
// so we can potentially prevent bugs elsewhere
export const removeFilters = (dataset, isStagedFilterMatch, isFilterMatch) => {
  if (!dataset.filtering) {
    return dataset;
  }
  const filtering = dataset.filtering;
  return dataset.set('filtering', removeFiltersWithPredicate(filtering, isStagedFilterMatch, isFilterMatch));
};
export const removeAllFieldFilters = (dataset, fieldName) => removeFilters(dataset, stagedFilter => !!stagedFilter && stagedFilter.type === FilterRefTypes.DATASET_FIELD && stagedFilter.field === fieldName, filter => filter.field.table === DATASET_TABLE && filter.field.name === fieldName);
export const removeInvalidFilters = dataset => {
  const invalidFilters = getInvalidFilters(dataset).toSet();
  return removeFilters(dataset, () => false, maybeInvalidFilter => invalidFilters.contains(maybeInvalidFilter));
};
export const removeInvalidFields = dataset => {
  const nextDataset = dataset.update('fields', fields => fields.filter(field => field.valid));
  return nextDataset.update('fieldOrder', order => order.filter(fieldName => nextDataset.fields.has(fieldName))).update('derivedFieldOrder', order => order.filter(fieldName => nextDataset.fields.has(fieldName))).update('unifiedFieldOrder', order => {
    if (!order) {
      return List();
    }
    return order.filter(fieldName => nextDataset.fields.has(fieldName));
  });
};