import enviro from 'enviro';
import Raven from 'raven-js';
export const validateNumber = (value, upgradeKey, paramName) => {
  if (Number.isInteger(value)) {
    return value;
  }
  const errorMessage = `UIUsageLimitCounter: Invalid value passed into ${paramName} for upgradeKey ${upgradeKey}. Value ${value} should be an integer.`;
  Raven.captureException(new Error(errorMessage));
  if (enviro.isQa()) {
    console.warn(errorMessage);
  }
  return 0;
};