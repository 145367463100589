import { ANALYTICS_FUNNEL } from 'reporting-data/constants/dataTypes';
import { getReportDataSources, isSnowflake
// @ts-expect-error ts-migrate(2307)
} from 'reporting-action-components/lib/report-logic';
// @ts-expect-error ts-migrate(2307)
import { getReportDataType } from 'reporting-action-components/data/schemas/reportSchema';
// @ts-expect-error ts-migrate(2307)
import Widgets from 'reporting-ui-components/components/customWidgets/constants/widgets';
import { BOOLEAN, DATE, DATE_TIME } from 'customer-data-objects/property/PropertyTypes';
// @ts-expect-error ts-migrate(2307)
import Known from 'customer-data-filters/filterQueryFormat/operator/Known';
// @ts-expect-error ts-migrate(2307)
import Equal from 'customer-data-filters/filterQueryFormat/operator/Equal';
// @ts-expect-error ts-migrate(2307)
import Less from 'customer-data-filters/filterQueryFormat/operator/Less';
// @ts-expect-error ts-migrate(2307)
import InRollingDateRange from 'customer-data-filters/filterQueryFormat/operator/InRollingDateRange';
// @ts-expect-error ts-migrate(2307)
import { In } from 'customer-data-filters/filterQueryFormat/operator/Operators';
// @ts-expect-error ts-migrate(2307)
import { get as isUnified } from 'reporting-data/retrieve/unified/supported';
import { CONVERSATIONS, GOAL_TARGET } from 'reporting-data/constants/dataTypes/inboundDb';
import { DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES } from 'reporting-data/constants/objectCoordinates';
import { isGenericDateRangeProperty } from './dateRangeHelpers';
import { getName, getType } from './schema/propertySchema';
import { fromJS } from 'immutable';
import { getCustomWidgetType } from './schema/reportSchema';
import { isListMembershipProperty } from './listMembershipFilterUtils';

// Used to identify framework data sources, which would have a data source key like "0-11"
// whereas unified data types, for example, would have a key like "FORMS_ANALYTICS"
export const objectTypeIdRegex = /\d+-\d+/;

// List of custom widgets to block from Dashboard filters
const customWidgetBlockList = [
// SALES_PERFORMANCE relies on multiple date sources not listed in the report config. Dashboard filters can
// conflict among various data-sources (https://git.hubteam.com/HubSpot/report-management-issues/issues/1727)
Widgets.SALES_PERFORMANCE];

// Mapping of data source to list of property names in which
// the reporting definition of the property is used instead of their PUMA counterparts.
const REPORTING_DATA_SOURCE_PROPERTY_OVERRIDES_MAP = {
  [DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(GOAL_TARGET)]: ['hs_assignee_user_id', 'hs_assignee_team_id']
};
export const REPORTING_DATA_TYPE_DATA_SOURCE_OVERRIDE = [GOAL_TARGET, CONVERSATIONS];
export const getReportingDataSourcePropertiesOverrideList = objectTypeId => {
  return REPORTING_DATA_SOURCE_PROPERTY_OVERRIDES_MAP[objectTypeId] || [];
};
export const excludeFromDashboardFilters = report => {
  const convertedReport = fromJS(report);
  if (isSnowflake(convertedReport)) {
    return false;
  }
  const dataType = getReportDataType(convertedReport);

  // Explicitly exclude ANALYTICS_FUNNEL custom data source.
  if (dataType === ANALYTICS_FUNNEL) {
    return true;
  }
  const customWidgetType = getCustomWidgetType(report);
  if (!customWidgetType) {
    return false;
  }
  return customWidgetBlockList.includes(customWidgetType);
};
export const getFilterableReportDataSources = report => {
  const convertedReport = fromJS(report);
  const filterableReportDataSources = excludeFromDashboardFilters(convertedReport) ? [] : getReportDataSources({
    report: convertedReport
  });
  return filterableReportDataSources;
};
export const getReportDataSourceUsages = ({
  reports
}) => reports.reduce((dataSourceReports, report) => {
  const reportDataSources = getFilterableReportDataSources(report);
  for (const dataSource of reportDataSources) {
    const currentSource = dataSourceReports[dataSource];
    if (currentSource) currentSource.push(report);else dataSourceReports[dataSource] = [report];
  }
  return dataSourceReports;
}, {});
export const isFrameworkDataSource = dataSource => objectTypeIdRegex.test(dataSource);
export const isDateProperty = propertyType => [DATE, DATE_TIME].includes(propertyType);
export const getDefaultOperatorForProperty = (dataSource, property) => {
  const propertyType = getType(property);
  const propertyName = getName(property);
  if (propertyType === BOOLEAN) {
    return Equal.of(property);
  }
  if (isGenericDateRangeProperty(propertyName) || isDateProperty(propertyType)) {
    return InRollingDateRange.of(property);
  }
  if (isUnified(dataSource)) {
    return isDateProperty(propertyType) ? Less.of(property) : Equal.of(property);
  }
  if (isListMembershipProperty(propertyName)) {
    return In.of(property);
  }
  return Known.of(property);
};