import http from 'hub-http/clients/apiClient';
import { fromJS } from 'immutable';
import { stringify } from 'hub-http/helpers/params';
import { registerQuery } from 'data-fetching-client';
const TRANSIENT_DASHBOARD_APPLY_FILTERS_URL = 'dashboard/v2/dashboard/apply-filter';
export const createDashboard = ({
  dashboardName,
  userId,
  dashboardPermissionConfig,
  widgets = [],
  template,
  filterGroups
}) => http.post('dashboard/v2/dashboard', {
  data: {
    name: dashboardName,
    type: 'CUSTOM',
    ownerId: userId,
    dashboardPermissionConfig,
    widgets,
    template,
    filterGroups
  }
});
export const fetchDashboardById = (dashboardId, query = {}) => http.get(`dashboard/v2/dashboard/${dashboardId}`, {
  query
});
export const fetchDashboardsById = async dashboardIds => {
  try {
    const responses = await Promise.allSettled(dashboardIds.map(id => fetchDashboardById(id)));
    return responses.map(res => res.status === 'fulfilled' ? res.value : undefined).filter(res => !!res);
  } catch (error) {
    return [];
  }
};
export const fetchDashboards = (query = {}) => http.get('dashboard/v2/dashboard', {
  query
});
const isApplyAdhocFiltersParams = params => 'filters' in params;
export const fetchDashboardWithFiltersApplied = (dashboardId, params) => {
  const getDashboardWithOverriddenReportsPromise = () => {
    if (isApplyAdhocFiltersParams(params)) {
      return http.post(`dashboard/v2/dashboard/${dashboardId}/apply-filter`, {
        data: {
          dashboardId,
          name: 'adhoc-filters',
          // Needed for api request to succeed
          filters: params.filters || [],
          frequency: params.frequency,
          useFiscalYear: params.useFiscalYear
        },
        query: {
          embeddedAppId: params.embeddedDashboardAppKey
        }
      });
    }
    const queryParams = stringify(params);
    return http.get(`dashboard/v2/dashboard/${dashboardId}?${queryParams}`, {
      query: {
        embeddedAppId: params.embeddedDashboardAppKey
      }
    });
  };
  return getDashboardWithOverriddenReportsPromise().then(response => {
    return fromJS(response); // next dashboard object
  }).catch(error => {
    console.error(error);
    throw error;
  });
};
export const fetchTransientDashboardWithFiltersApplied = ({
  dashboardAndMetaData,
  filterParams
}) => {
  const getTransientDashboardWithFilterPromise = () => {
    return http.post(TRANSIENT_DASHBOARD_APPLY_FILTERS_URL, {
      data: {
        dashboardAndMetaData,
        dashboardFiltersRequest: {
          name: 'adhoc-filters',
          // Needed for api request to succeed
          filters: filterParams.filters || [],
          frequency: filterParams.frequency,
          useFiscalYear: filterParams.useFiscalYear || false
        }
      }
    });
  };
  return getTransientDashboardWithFilterPromise().then(response => {
    return fromJS(response);
  }).catch(error => {
    console.error(error);
    throw error;
  });
};
export const FETCH_DASHBOARDS_BY_IDS = registerQuery({
  fieldName: 'dashboardsByIds',
  args: ['dashboardIds'],
  fetcher({
    dashboardIds
  }) {
    return fetchDashboardsById(dashboardIds);
  }
});
export const FETCH_EMBEDDABLE_DASHBOARDS = registerQuery({
  fieldName: 'embeddableDashboards',
  args: [],
  fetcher() {
    return fetchDashboards({
      sortBy: 'UPDATED_AT'
    });
  }
});
export const __TESTABLE__ = {
  TRANSIENT_DASHBOARD_APPLY_FILTERS_URL
};