import { List, fromJS } from 'immutable';
import { MONTH } from '../constants/frequency';
import { getCustomFilters, getFilterByPropertyName, getMetrics, getReportProcessors } from './configReportGetters';
import { DATETIME_GOAL } from '../constants/customSeries';

/**
 * Applies filters to a list of filter groups.  Each filters needs to be added to
 * each filter group in the list.
 * @param filterGroups
 * @param filters
 * @returns
 */
export const applyFiltersToFilterGroups = (filterGroups, filters) => {
  return filterGroups.reduce((acc, filterGroup) => {
    const updatedFilterGroup = filterGroup.update('filters', currentFilters => {
      return currentFilters.concat(filters);
    });
    return acc.push(updatedFilterGroup);
  }, List());
};
export const setCustomFilters = (config, customFilters) => {
  return config.setIn(['filters', 'custom'], customFilters);
};
export const setFilters = (config, filters) => {
  return config.setIn(['filters'], filters);
};
export const setFilterGroups = (config, filterGroups) => {
  return config.setIn(['filters', 'filterGroups'], filterGroups);
};
export const setDateRangeFilterProperty = (config, property) => {
  return config.setIn(['filters', 'dateRange', 'property'], property);
};
export const setCompare = (config, compare) => {
  return config.set('compare', compare);
};
export const setUseFiscalYearInAggregation = (config, useFiscalYearInAggregation) => {
  return config.set('useFiscalYearInAggregation', useFiscalYearInAggregation);
};
export const setUseFiscalYearInDateRangeFilter = (report, useFiscalYear) => {
  return report.setIn(['config', 'filters', 'dateRange', 'useFiscalYear'], useFiscalYear);
};
export const setFrequency = (config, frequency) => {
  return config.set('frequency', frequency);
};
export const setDisplayParamsQuickFilterProperties = (report, quickFilters) => {
  return report.setIn(['displayParams', 'quickFilterProperties'], quickFilters);
};
export const setDimensions = (config, dimensions) => config.set('dimensions', dimensions);
export const setQuery = (config, query) => config.setIn(['filters', 'query'], query);
export const setDisplayParamsStacking = (report, stackedType) => {
  if (!stackedType || stackedType === true) {
    return report.setIn(['displayParams', 'stacked'], stackedType).setIn(['displayParams', 'stackingType'], undefined);
  } else if (stackedType === 'percent') {
    return report.setIn(['displayParams', 'stacked'], true).setIn(['displayParams', 'stackingType'], 'percent');
  } else {
    return report;
  }
};
export const setProcessorType = (report, processerType) => {
  const processors = getReportProcessors(report);
  return !processors.includes(processerType) ? report.setIn(['config', 'processors'], processors.push(processerType)) : report.setIn(['config', 'processors'], processors.delete(processors.indexOf(processerType)));
};
export const setDisplayParamsIncludeDateLine = (report, dateLineParam) => {
  return report.setIn(['displayParams', 'includeDateLine'], dateLineParam);
};
export const setDisplayParamsHideDataLabels = (report, isSet) => {
  return report.setIn(['displayParams', 'hideDataLabels'], isSet);
};
export const setDisplayParamsHideMarkers = (report, isSet) => {
  return report.setIn(['displayParams', 'hideMarkers'], isSet);
};
export const setDisplayParamsShowTotals = (report, isSet) => {
  return report.setIn(['displayParams', 'showTotals'], isSet);
};
export const setDisplayParamsShowTotalsColumn = (report, isSet) => {
  return report.setIn(['displayParams', 'showTotalsColumn'], isSet);
};
export const setDisplayParamsIntraperiod = (report, period) => {
  return report.setIn(['displayParams', 'customWidget', 'options', 'intraperiod'], period);
};
export const setConfigReportLimit = (report, limit) => {
  return report.setIn(['config', 'limit'], limit);
};
export const setSort = (report, sort) => {
  return report.setIn(['config', 'sort'], sort);
};

/**
 * @deprecated Use setReportConfigMetricByIndex instead.
 */
export const setMetricByIndex = (report, metric, index = 0) => {
  var _getMetrics;
  if (((_getMetrics = getMetrics(report)) === null || _getMetrics === void 0 ? void 0 : _getMetrics.size) < index) {
    throw new Error('setMetricByIndex index out of bounds exception on report metrics');
  }
  return report.setIn(['config', 'metrics', index], metric);
};
export const setReportConfigMetricByIndex = (reportConfig, metric, index = 0) => {
  return reportConfig.setIn(['metrics', index], metric);
};
export const setConfigReportMetricByIndex = (report, metric, index = 0) => {
  var _getMetrics2;
  if (((_getMetrics2 = getMetrics(report)) === null || _getMetrics2 === void 0 ? void 0 : _getMetrics2.size) < index) {
    throw new Error('setConfigReportMetricByIndex index out of bounds exception on report metrics');
  }
  return report.set('config', setReportConfigMetricByIndex(report.get('config'), metric, index));
};
export const setConfigType = (report, configType) => {
  return report.setIn(['config', 'configType'], configType);
};
export const setConfigReportChartType = (report, chartType) => report.set('chartType', chartType);
export const setConfigReportMetrics = (report, metrics) => {
  return report.setIn(['config', 'metrics'], metrics);
};
export const setDataColumns = (report, dataColumns) => report.setIn(['displayParams', 'dataColumns'], List(dataColumns));
export const setStaticGoal = (report, goal) => {
  if (typeof goal !== 'number') {
    return report.setIn(['displayParams', 'customSeries'], fromJS([]));
  }
  return report.setIn(['displayParams', 'customSeries'], fromJS([{
    type: DATETIME_GOAL,
    timeUnit: MONTH,
    goal
  }]));
};
export const updateCustomFiltersByIndex = (config, filterIndex, filter) => {
  const updatedFiltersWithNewFilter = getCustomFilters(config).set(filterIndex, filter);
  return updatedFiltersWithNewFilter;
};

/**
 * update filter value or values in custom filters of report after searching for it by specified
 * property and operator
 *
 * @param {ReportConfig} config
 * @param {'value' | 'values'} filterValueType : tells function to update value or values in filter
 * @param {{property: string, operator: string, value?: string, values?: List<string>}} filter : information
 * on filter we want to update
 * @returns {ReportConfig}
 */

const updateFilterValueOrValuesInReport = (config, filterValueType, {
  property,
  operator,
  value,
  values
}) => {
  var _getFilterByPropertyN;
  const setValues = filterValueType === 'values';
  const isFilterInReport = ((_getFilterByPropertyN = getFilterByPropertyName(config, property)) === null || _getFilterByPropertyN === void 0 ? void 0 : _getFilterByPropertyN.get('operator')) === operator;
  if (isFilterInReport) {
    const filterIndex = getCustomFilters(config).findIndex(filter => (filter === null || filter === void 0 ? void 0 : filter.get('property')) === property);
    const updatedFilter = getCustomFilters(config).get(filterIndex).set(setValues ? 'values' : 'value', setValues ? values : value);
    const updatedFiltersWithNewFilter = updateCustomFiltersByIndex(config, filterIndex, updatedFilter);
    const updatedConfig = setCustomFilters(config, updatedFiltersWithNewFilter);
    return updatedConfig;
  } else {
    let updatedFiltersWithNewFilter;
    if (setValues) {
      updatedFiltersWithNewFilter = getCustomFilters(config).push(fromJS({
        property,
        operator,
        values
      }));
    } else {
      updatedFiltersWithNewFilter = getCustomFilters(config).push(fromJS({
        property,
        operator,
        value
      }));
    }
    const updatedConfig = setCustomFilters(config, updatedFiltersWithNewFilter);
    return updatedConfig;
  }
};
export const updateFilterValuesInReportByFilterPropertyAndOperator = (config, {
  property,
  operator,
  values
}) => {
  return updateFilterValueOrValuesInReport(config, 'values', {
    property,
    operator,
    values
  });
};
export const updateFilterValueInReportByFilterPropertyAndOperator = (config, {
  property,
  operator,
  value
}) => {
  return updateFilterValueOrValuesInReport(config, 'value', {
    property,
    operator,
    value
  });
};
export const setReportConfig = (report, config) => {
  return report.set('config', config);
};