import { is } from 'immutable';
import { useEffect, useRef, useState } from 'react';
// @ts-expect-error untyped dependency
import useReportTypeProblem from 'reporting-action-components/reportingActions/shared/problemHooks/useReportTypeProblem';
// @ts-expect-error untyped dependency
import normalizeReport from 'reporting-reports/normalizeReport';
function usePrevious(value) {
  const ref = useRef(undefined);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
function useNormalizedReport({
  onError,
  report
}) {
  const [normalizedReport, setNormalizedReport] = useState(null);
  const previousReport = usePrevious(report);
  const reportTypeProblem = useReportTypeProblem({
    report
  });
  useEffect(() => {
    let cancelNormalization = false;
    if (reportTypeProblem === 'LOADING') {
      // skip effect if we don't have everything we need to determine whether to perform normalization
      return () => {};
    }
    if (is(report, previousReport) && normalizedReport != null) {
      // skip effect; report value did not change
      return () => {};
    }

    // if a portal loses access to a type of report, it may fail to normalize, so avoid attempting it
    if (reportTypeProblem) {
      setNormalizedReport(report);
      return () => {};
    }
    setNormalizedReport(null);
    normalizeReport(report).then(updatedReport => {
      if (!cancelNormalization) {
        setNormalizedReport(updatedReport);
      }
    }).catch(error => {
      if (!cancelNormalization) {
        onError(error);
      }
    });
    return () => {
      cancelNormalization = true;
    };
  }, [report, normalizedReport, onError, previousReport, reportTypeProblem]);
  return normalizedReport;
}
export default useNormalizedReport;