'use es6';

import { Map as ImmutableMap, List, Set as ImmutableSet } from 'immutable';
import { TooManyBreakdownsException } from '../../../../exceptions';
const DISTINCT_BREAKDOWN_LIMIT = 100;
const countBreakdowns = data => {
  const firstDimension = data.get('aggregations', ImmutableMap());
  const distinctValues = firstDimension.reduce((acc, bucket) => acc.union(bucket.reduce((set, datapoint) => {
    datapoint.get('aggregations', ImmutableMap()).forEach(secondDimension => {
      secondDimension.forEach(breakdown => {
        set = set.add(breakdown.get('key'));
      });
    });
    return set;
  }, new ImmutableSet())), new ImmutableSet());
  return distinctValues;
};
const precondition = response => {
  const distinctValues = List.isList(response) ? response.reduce((set, data) => set.union(countBreakdowns(data)), new ImmutableSet()) : countBreakdowns(response);
  if (distinctValues.count() > DISTINCT_BREAKDOWN_LIMIT) {
    throw new TooManyBreakdownsException();
  }
};
export default precondition;