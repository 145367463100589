import * as MetricTypes from '../../constants/metricTypes';
export const METRIC_DELIMITER = '|';
const DEFAULT_METRIC_TYPE = MetricTypes.SUM;
export const isMetricWithType = possibleMetric => possibleMetric.includes(METRIC_DELIMITER);
export const toMetricKey = ({
  property,
  type = DEFAULT_METRIC_TYPE
}) => [type, property].join(METRIC_DELIMITER);
export const fromMetricKey = (metric = '') => {
  const [first, second] = String(metric).split(METRIC_DELIMITER).filter(str => str !== '');
  return second ? {
    property: second,
    type: first
  } : {
    property: first,
    type: DEFAULT_METRIC_TYPE
  };
};