'use es6';

import addEngagementTypeFilter from '../../common/add-engagement-type-filter';
import { dataTypeToEngagementType } from '../../common/engagement-types';
import { search } from '../dao';
import { generate } from '../generate';
import { transform } from '../transform';
export default ((spec, config) => {
  const engagementType = dataTypeToEngagementType.get(spec.get('dataType'));
  return generate(addEngagementTypeFilter(config, engagementType), spec.properties.idProperty).then(payload => search(spec.search.url, payload)).then(response => transform(config, spec, response));
});