/* eslint-disable no-console */
import enviro from 'enviro';
import { Map as ImmutableMap } from 'immutable';
import PortalIdParser from 'PortalIdParser';
import { safe } from './safe';
import * as global from './global';

/**
 * Report cache
 *
 * @constant {Map}
 * @private
 */
let cache = ImmutableMap();

/**
 * Create base URL
 *
 * @param {boolean} local Local variant
 * @returns {string} Base URL
 * @private
 */
const createBase = local => {
  const portal = PortalIdParser.get();
  const subdomain = local ? 'local' : 'app';
  const environment = enviro.isQa() ? 'qa' : '';
  return [`https://${subdomain}.hubspot${environment}.com`, `reporting-sandbox/${portal}/build/playground`].join('/');
};

/**
 * Create sandbox URL
 *
 * @param {Map} report Report to link
 * @returns {object} Sandbox links
 * @private
 */
// @ts-expect-error need to create typed version of a report
const createUrl = report => {
  const encoded = btoa(JSON.stringify(report, null, 2));
  const query = `encoded=${encoded}`;
  return {
    local: `${createBase(true)}?${query}`,
    deployed: `${createBase(false)}?${query}`
  };
};

/**
 * Create sensible name from report
 *
 * @param {Map} report Report to name
 * @returns {string} Sandbox links
 * @private
 */
// @ts-expect-error need to create typed version of a report
const createName = report => {
  const name = report.get('name');
  const chartType = report.get('chartType');
  const dataType = report.getIn(['config', 'dataType']);
  const configType = report.getIn(['config', 'configType']);
  return [...(name ? [name] : [dataType, configType]), chartType].filter(Boolean).join(' | ');
};

/**
 * Report setter
 *
 * @param {Map} report Report to process
 * @returns {object} Processed report information
 * @private
 */
const process = safe(report => ({
  name: createName(report),
  sandbox: createUrl(report),
  timestamp: new Date(),
  input: report
}));

/**
 * Report setter
 *
 * @param {Map} report Report to set
 * @returns {void}
 */
// @ts-expect-error need to create typed version of a report
export const register = report => {
  cache = cache.set(report, process(report));
};

/**
 * Global access to sandbox helper
 *
 * @constant {object}
 */
global.set('sandbox', {
  show: clear => {
    if (clear) {
      console.clear();
    }
    const style = 'font-weight: bold; font-size: 1.6rem; color: #fea58e';
    console.log('%cSandbox Links', style);
    cache.filter(({
      error
    }) => error == null).forEach(({
      name,
      sandbox,
      timestamp,
      input
    }) => {
      console.groupCollapsed(name);
      console.log('Local:', sandbox.local);
      console.log('Deployed:', sandbox.deployed);
      console.log('Timestamp:', timestamp);
      console.log('Input:', input);
      console.groupEnd();
    });
    return cache;
  }
});