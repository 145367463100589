import { Map as ImmutableMap, List } from 'immutable';
import { MEDIAN } from '../../constants/metricTypes';
const percentileMapping = ImmutableMap({
  50: MEDIAN
});
const isMappedPercentileMetric = metric => {
  const metricTypes = metric.get('metricTypes');
  const isPercentile = metricTypes ? metricTypes.includes('PERCENTILES') : false;
  const hasMappedPercentile = percentileMapping.has(String(metric.getIn(['percentiles', 0])));
  return isPercentile && hasMappedPercentile;
};
const shouldConfigure = config => {
  const metrics = config.get('metrics');
  return metrics && metrics.some(isMappedPercentileMetric);
};
const configure = config => config.updateIn(['metrics'], metrics => metrics.map(metric => {
  if (isMappedPercentileMetric(metric)) {
    const percentile = metric.getIn(['percentiles', 0]);
    const met = metric.setIn(['metricTypes', 0], percentileMapping.get(String(percentile))).setIn(['percentiles'], List([]));
    return met;
  }
  return metric;
}));
export const configurePercentile = config => shouldConfigure(config) ? configure(config) : config;
export const __TESTABLE__ = {
  shouldConfigure
};