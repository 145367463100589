'use es6';

import { fromJS, List, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { configure } from '../configure';
import { get as getOwners } from '../references/owner/owners';
import { get as getTeams } from '../references/team/teams';
import * as http from '../request/http';
import { getAllFilters } from '../report/configReportGetters';
const goalsReportingEndpoint = 'performance/v1/goals/reports/';
const quotaAccessScopes = {
  'sales-goal-assignment': true,
  'advanced-sales-goal-assignment': true,
  'basic-sales-goal-manager': true,
  'advanced-sales-goal-manager': true,
  'legacy-quotas-access': true,
  'legacy-sales-goal-assignment': true
};
export const MIGRATED_GOAL_TYPES = ['AVERAGE_TICKET_RESOLUTION_TIME', 'AVERAGE_TICKET_RESPONSE_TIME', 'CALLS_MADE', 'DEALS_CREATED', 'MEETINGS_BOOKED', 'REVENUE', 'TICKETS_CLOSED'];
export const quotaGates = ImmutableMap(quotaAccessScopes).keySeq().toArray();
export const hasGoalsAccess = userInfo => {
  const {
    user: {
      scopes = []
    } = {}
  } = userInfo;
  return scopes.reduce((hasAccess, scope) => {
    return hasAccess || Boolean(quotaAccessScopes[scope]);
  }, false);
};
export const getQuotaReportingData = (scopes, requestData, quotaType) => {
  const quotaTypeForGoalsReportingEndpoint = quotaType !== 'REVENUE' ? quotaType : '';
  if (scopes.includes('goals-migration-kpi-gate-access')) {
    return http.post(`${goalsReportingEndpoint}${quotaTypeForGoalsReportingEndpoint}`, {
      data: requestData
    });
  }
  return Promise.resolve([]);
};
export const hasQuotaSeries = report => report.getIn(['displayParams', 'customSeries'], List()).some(cs => cs === 'datetime.quota');
const isDealOwnersFilter = filter => {
  return filter.get('property') === 'hubspot_owner_id' || filter.get('property') === 'engagement.ownerId';
};
const isDealTeamFilter = filter => {
  return filter.get('property') === 'hubspot_team_id' || filter.get('property') === 'engagement.teamId';
};
const getTeamsFilter = config => {
  const filters = getAllFilters(config);
  return filters.find(isDealTeamFilter);
};
const getOwnersFilter = config => {
  const filters = getAllFilters(config);
  return filters.find(isDealOwnersFilter);
};
const ownerInSet = set => owner => {
  return set.has(owner.get('ownerId'));
};
const remoteInSet = set => owner => {
  const remotes = owner.get('remoteList');
  return remotes.some(remote => set.has(remote.get('remoteId')));
};
const getSelectedOwners = (report, owners, teams) => {
  // TODO once reporting-data supports __hs__ME and __hs__MY_TEAM we need to handle that case
  const ownersFilter = getOwnersFilter(report);
  const teamsFilter = getTeamsFilter(report);
  const owneresFromFilter = ownersFilter && (ownersFilter.get('values') || [ownersFilter.get('value')]).map(Number);
  const uniqueFilterOwners = owneresFromFilter ? ImmutableSet(owneresFromFilter) : ImmutableSet();
  let selectedOwners = owners.filter(v => v != null).toSet().union(uniqueFilterOwners.map(ownerId => ImmutableMap({
    ownerId,
    remoteList: List()
  })));
  if (ownersFilter != null) {
    if (ownersFilter.get('operator') === 'IN') {
      selectedOwners = selectedOwners.filter(ownerInSet(uniqueFilterOwners));
    } else if (ownersFilter.get('operator') === 'NOT_IN') {
      selectedOwners = selectedOwners.filterNot(ownerInSet(uniqueFilterOwners));
    }
  }
  if (teamsFilter != null) {
    const teamFilterRemotes = teamsFilter.get('values', List()).map(Number).map(teamId => {
      const found = teams.find(team => team.get('id') === teamId) || ImmutableMap();
      return found.get('userIds').map(String);
    }).flatten(1).toSet();
    if (teamsFilter.get('operator') === 'IN') {
      selectedOwners = selectedOwners.filter(remoteInSet(teamFilterRemotes));
    } else if (teamsFilter.get('operator') === 'NOT_IN') {
      selectedOwners = selectedOwners.filterNot(remoteInSet(teamFilterRemotes));
    }
  }
  return selectedOwners;
};
export const getReportOwners = report => Promise.all([configure(report.get('config')), getOwners(), getTeams()]).then(([configured, owners, teams]) => {
  const ownersFilter = getOwnersFilter(configured);
  const teamsFilter = getTeamsFilter(configured);
  return ownersFilter || teamsFilter ? getSelectedOwners(configured, fromJS(owners), fromJS(teams)) : null;
});