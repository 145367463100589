import I18n from 'I18n';
import getIn from 'transmute/getIn';
import { fromJS } from 'immutable';
// @ts-expect-error ts-migrate(2307)
import FilterOperatorBoolInput from 'customer-data-filters/components/operator/FilterOperatorBoolInput';
// @ts-expect-error ts-migrate(2307)
import FilterOperatorDateInput from 'customer-data-filters/components/operator/FilterOperatorDateInput';
// @ts-expect-error ts-migrate(2307)
import FilterOperatorDurationInput from 'customer-data-filters/components/operator/FilterOperatorDurationInput';
// @ts-expect-error ts-migrate(2307)
import FilterOperatorElasticsearchTextQueryInput from 'customer-data-filters/components/operator/FilterOperatorElasticsearchTextQueryInput';
// @ts-expect-error ts-migrate(2307)
import FilterOperatorEnumInput from 'customer-data-filters/components/operator/FilterOperatorEnumInput';
// @ts-expect-error ts-migrate(2307)
import FilterOperatorMultiStringInput from 'customer-data-filters/components/operator/FilterOperatorMultiStringInput';
// @ts-expect-error ts-migrate(2307)
import FilterOperatorNumberInput from 'customer-data-filters/components/operator/FilterOperatorNumberInput';
// @ts-expect-error ts-migrate(2307)
import FilterOperatorOwnerInput from 'customer-data-filters/components/operator/FilterOperatorOwnerInput';
// @ts-expect-error ts-migrate(2307)
import FilterOperatorPercentageInput from 'customer-data-filters/components/operator/FilterOperatorPercentageInput';
// @ts-expect-error ts-migrate(2307)
import FilterOperatorTeamInput from 'customer-data-filters/components/operator/FilterOperatorTeamInput';
// @ts-expect-error ts-migrate(2307)
import FilterOperatorTextInput from 'customer-data-filters/components/operator/FilterOperatorTextInput';
// @ts-expect-error ts-migrate(2307)
import * as Operators from 'customer-data-filters/filterQueryFormat/operator/Operators';
import { isValidOperator } from 'customer-data-filters/filterQueryFormat/operator/Operator';
import * as DisplayTypes from 'customer-data-filters/filterQueryFormat/DisplayTypes';
import { NotWildCardEqual, WildCardEqual
// @ts-expect-error ts-migrate(2307)
} from 'customer-data-filters/filterQueryFormat/operator/Operators';
import * as ExternalOptionTypes from 'customer-data-objects/property/ExternalOptionTypes';
import { EMAIL_SUBSCRIPTION, SITE_CONTENT, SURVEY_QUESTION
// @ts-expect-error ts-migrate(2307)
} from 'reference-resolvers/constants/ReferenceObjectTypes';
import { getPropertyResolver } from 'reference-resolvers-lite/utils/getPropertyResolver';
import { buildSpecialOptionsByReferenceType, getLabelString, isSupportedExternalOption
// @ts-expect-error ts-migrate(2307)
} from 'reporting-snowflake/relational/modify/filters/xo-filter-utils';
import { PROPERTY_TYPES, TYPES
// @ts-expect-error ts-migrate(2307)
} from 'reporting-snowflake/relational/metadata/references/external/external-mapping';
// @ts-expect-error ts-migrate(2307)
import { unifiedPropertyResolvers } from 'reporting-enablement/components/reportEditors/UnifiedFilterEditor/utils';
import { getExternalOptionsReferenceType, getName, getReferencedObjectType, getType } from './schema/propertySchema';
// @ts-expect-error migrate upstream types
import { canUseReferenceResolverLiteForFilterInput } from 'reporting-snowflake/relational/modify/filters/xo-filter-utils';
import { HUBSPOT_OBJECT_COORDINATES_TO_DATA_TYPE } from 'reporting-data/constants/objectCoordinates';
import { dataTypeSettings } from 'reporting-data/retrieve/inboundDb/common/dataTypes';
import { getReferenceTypeForListMembershipProperty } from './listMembershipFilterUtils';
import * as OBJECT_TYPE_IDS from 'reporting-snowflake/relational/metadata/objectTypes';
import { isExternalDataType } from 'reporting-data/report/externalReportGetters';
const getReferenceTypeForReportingProperties = ({
  dataSource,
  property
}) => {
  const dataType = HUBSPOT_OBJECT_COORDINATES_TO_DATA_TYPE.get(dataSource) || dataSource;
  const propertyName = getName(property);
  const reportingPropertyReferenceType = getIn([dataType, 'propertyReferenceType', propertyName], dataTypeSettings);
  return reportingPropertyReferenceType;
};

//based on getReferenceType & getReferenceTypeForResolverLite from reporting-snowflake's external-mapping file
const getReferenceType = ({
  dataSource,
  property
}) => {
  const propertyName = getName(property);
  const referencedObjectType = getReferencedObjectType(property);
  const externalOptionsReferenceType = getExternalOptionsReferenceType(property);
  return referencedObjectType || TYPES.getIn([dataSource, propertyName]) || PROPERTY_TYPES.get(propertyName) || getIn([dataSource, propertyName], unifiedPropertyResolvers) || getReferenceTypeForReportingProperties({
    dataSource,
    property
  }) || getReferenceTypeForListMembershipProperty({
    dataSource,
    property
  }) || externalOptionsReferenceType;
};
const getFamilyValueResolver = ({
  dataSource,
  property,
  resolvers,
  gates
}) => {
  const referenceResolverLite = isSupportedExternalOption(dataSource, property, gates) && canUseReferenceResolverLiteForFilterInput(property) ? getPropertyResolver({
    property: property,
    objectTypeId: dataSource
  }) : null;
  return referenceResolverLite || resolvers[getReferenceType({
    dataSource,
    property
  })] || null;
};
export const getSharedFilterOperatorProps = ({
  currencyCode,
  dataSource,
  property,
  resolvers,
  userId,
  gates
}) => {
  return {
    currencyCode,
    filterFamily: dataSource,
    getFamilyValueResolver: () => getFamilyValueResolver({
      dataSource: isExternalDataType(dataSource) ? OBJECT_TYPE_IDS.CONTACT : dataSource,
      property,
      resolvers,
      gates
    }),
    getLabelString: () => getLabelString(property),
    getReferencedObjectType: () => getReferenceType({
      dataSource,
      property
    }),
    getSpecialOptionsForReferenceType: buildSpecialOptionsByReferenceType(getType(property), userId),
    isXoEnabled: true
  };
};

// copied from reporting-snowflake's xo-filter-utils file
const INPUT_OVERRIDES = fromJS({
  '0-4': {
    hs_attachment_ids: FilterOperatorMultiStringInput
  }
});
function getInputComponentFromReferencedObjectType(dataSource, property) {
  const referencedObjectType = getReferenceType({
    dataSource,
    property
  });
  if (referencedObjectType === ExternalOptionTypes.TEAM) {
    return FilterOperatorTeamInput;
  }
  if (referencedObjectType === ExternalOptionTypes.OWNER) {
    return FilterOperatorOwnerInput;
  }
  if (referencedObjectType === ExternalOptionTypes.USER) {
    return FilterOperatorEnumInput;
  }
  return null;
}
function getInputComponentFromFieldType(operator) {
  const field = operator.field;
  const fieldType = (field === null || field === void 0 ? void 0 : field.displayType) || (field === null || field === void 0 ? void 0 : field.type);
  if (fieldType === DisplayTypes.StringDisplayType && [WildCardEqual, NotWildCardEqual].includes(operator.constructor)) {
    return FilterOperatorElasticsearchTextQueryInput;
  }

  // HACK: This is for a special case in ListSegClassic and should not be
  // relied upon to remain the same.
  if (fieldType === DisplayTypes.StringDisplayType && operator.constructor.isIterableField('value')) {
    return FilterOperatorMultiStringInput;
  }
  switch (fieldType) {
    case DisplayTypes.BooleanDisplayType:
      return FilterOperatorBoolInput;
    case DisplayTypes.DateDisplayType:
    case DisplayTypes.DatetimeDisplayType:
      return FilterOperatorDateInput;
    case DisplayTypes.EnumerationDisplayType:
    case SITE_CONTENT:
    case SURVEY_QUESTION:
    case EMAIL_SUBSCRIPTION:
      return FilterOperatorEnumInput;
    case DisplayTypes.NumberDisplayType:
      return FilterOperatorNumberInput;
    case DisplayTypes.PercentageDisplayType:
      return FilterOperatorPercentageInput;
    default:
      return FilterOperatorTextInput;
  }
}

// based on getInputComponent in reporting-snowflake's xo-filter-utils file
export function getInputComponent(dataSource, property, operator) {
  const {
    name
  } = property;
  const maybeOverriddenInput = INPUT_OVERRIDES.getIn([dataSource, name]);
  if (maybeOverriddenInput) {
    return maybeOverriddenInput;
  }
  const referencedObjectTypeInputComponent = getInputComponentFromReferencedObjectType(dataSource, property);
  if (referencedObjectTypeInputComponent !== null) {
    return referencedObjectTypeInputComponent;
  }
  const field = operator.field;
  if (field && field.type === 'number' && field.numberDisplayHint === 'duration') {
    return FilterOperatorDurationInput;
  }
  return getInputComponentFromFieldType(operator);
}
export const validateFilterOperator = operator => {
  if (!operator || !isValidOperator(operator)) {
    return {
      isValid: false,
      message: I18n.text('platformDashboard.filterOperator.validationMessages.invalidOperator')
    };
  }
  const Operator = Operators[operator.name];
  if (Operator === Operators.InRange) {
    const lowValue = operator.value;
    const highValue = operator.highValue;
    if (lowValue > highValue) {
      return {
        isValid: false,
        message: I18n.text('platformDashboard.filterOperator.validationMessages.invalidDateRange')
      };
    }
  }
  return {
    isValid: true
  };
};
export const getIsUngated = gates => gate => gates.includes(gate);