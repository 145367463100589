/* hs-eslint ignored failing-rules */
/* eslint-disable react-hooks/exhaustive-deps */

import { isReportTemplate, revertOptimisticGridChanges, handleSavedReportDropSuccess, createWidgetFromDroppedReport, handleReportTemplateDropSuccess, createLayoutItemFromDroppedReport } from './dragAndDropUtils.ts';
import { fromJS } from 'immutable';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLayoutFromWidgets } from '../../lib/LayoutHelpers';
import { useReportPanelDropHandlers } from './useReportPanelDropHandlers';
// @ts-expect-error Untyped import
import { isReadOnlyDemo } from 'dashboard-lib/public/dashboard/dashboard-logic';
import { selectHasDashboardPanelAccess } from 'dashboard-lib/public/userInfo/gates';
// @ts-expect-error Untyped import
import { selectHasDashboardDemoWriteScope } from 'dashboard-lib/public/userInfo/scopes';
import { setDashboardGridSize } from 'reporting-discover-components/redux/report-panel/ui';
export const useReportPanelDnD = ({
  widgets,
  gridRef,
  dashboard,
  canEditLayout,
  isSmallScreen,
  displayedLayout,
  setDisplayedLayout
}) => {
  var _gridRef$current3;
  const dispatch = useDispatch();
  const [dndActive, setDndActive] = useState(false);
  const [optimisticWidgets, setOptimisticWidgets] = useState(widgets);
  const {
    handleSavedReportDrop,
    handleReportTemplateDrop
  } = useReportPanelDropHandlers();
  const hasDashboardPanelAccess = useSelector(selectHasDashboardPanelAccess);
  const canWriteDemoDashboard = useSelector(selectHasDashboardDemoWriteScope);
  const isReadOnlyDemoDashboard = isReadOnlyDemo(canWriteDemoDashboard, dashboard);
  useEffect(() => {
    var _gridRef$current;
    if (hasDashboardPanelAccess && gridRef !== null && gridRef !== void 0 && (_gridRef$current = gridRef.current) !== null && _gridRef$current !== void 0 && _gridRef$current.offsetHeight) {
      var _gridRef$current2;
      dispatch(setDashboardGridSize(gridRef === null || gridRef === void 0 || (_gridRef$current2 = gridRef.current) === null || _gridRef$current2 === void 0 ? void 0 : _gridRef$current2.offsetHeight));
    }
  }, [gridRef === null || gridRef === void 0 || (_gridRef$current3 = gridRef.current) === null || _gridRef$current3 === void 0 ? void 0 : _gridRef$current3.offsetHeight, hasDashboardPanelAccess]);
  useEffect(() => {
    if (hasDashboardPanelAccess && !dndActive) {
      setOptimisticWidgets(widgets);
    }
  }, [widgets, hasDashboardPanelAccess]);
  useEffect(() => {
    if (hasDashboardPanelAccess && !dndActive) {
      setDisplayedLayout(getLayoutFromWidgets({
        widgets: fromJS(optimisticWidgets),
        canEditWidgets: canEditLayout,
        isSmallScreen
      }));
    }
  }, [canEditLayout, isSmallScreen, optimisticWidgets, hasDashboardPanelAccess]);
  const handleDrop = (layout, layoutItem, event) => {
    const droppedReport = JSON.parse(event.dataTransfer.getData('report'));
    const newWidget = createWidgetFromDroppedReport(droppedReport, dashboard, layoutItem);
    const newLayoutItem = createLayoutItemFromDroppedReport(droppedReport, layoutItem);
    const previousLayout = displayedLayout;
    const tempId = droppedReport.id.toString();
    const newLayout = layout.map(item => item.i === '__dropping-elem__' ? newLayoutItem : item);
    setDndActive(true);
    setDisplayedLayout(newLayout);
    setOptimisticWidgets(currentWidgets => currentWidgets.push(newWidget));
    const revertChanges = error => {
      revertOptimisticGridChanges({
        error,
        tempId,
        previousLayout,
        setDisplayedLayout,
        setOptimisticWidgets
      });
    };
    if (!isReportTemplate(droppedReport)) {
      handleSavedReportDrop({
        report: fromJS(droppedReport),
        onSuccess: () => handleSavedReportDropSuccess({
          newLayout,
          dashboard,
          setDndActive,
          setDisplayedLayout,
          isReadOnlyDemoDashboard
        }),
        onReject: revertChanges,
        onError: revertChanges
      });
    } else {
      handleReportTemplateDrop({
        reportTemplate: fromJS(droppedReport),
        dashboard,
        onSuccess: report => handleReportTemplateDropSuccess({
          report,
          tempId,
          newLayout,
          setOptimisticWidgets,
          setDisplayedLayout,
          dashboard,
          isReadOnlyDemoDashboard,
          setDndActive
        }),
        onError: revertChanges
      });
    }
  };
  return {
    dndActive,
    handleDrop,
    optimisticWidgets
  };
};