'use es6';

import { fromJS } from 'immutable';
import { ATTRIBUTION } from '../../constants/dataTypes';
import { load } from '../../dataTypeDefinitions';
export default {
  match: config => config.get('dataType') === ATTRIBUTION,
  retrieve: config => load(ATTRIBUTION).then(module => module.get('use')(config)).then(fromJS).then(nested => ({
    dataConfig: config,
    dataset: nested,
    response: null
  }))
};