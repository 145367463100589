import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// @ts-expect-error migrate upstream
import { selectIsFreePortal } from 'dashboard-lib/public/userInfo/scopes';
import { FTS0003_TREATMENT_KEY, GROUP_PARAMETER_KEY, GROUP_PARAMETER_OPTIONS, PARAMETERS } from '../constants/ExperimentKeys';
import { laboratoryClient } from '../data/laboratoryClient';
export const logFTS0003Exposure = () => {
  laboratoryClient.logExposure(FTS0003_TREATMENT_KEY);
};

// https://tools.hubteam.com/laboratory/experiments/edit/fts-0003?tab=workflow&step=0
export const useIsFTS0003Variant = () => {
  const [isFTS0003Variant, setIsFTS0003Variant] = useState(false);
  const [isFTS0003Fetched, setIsFTS0003Fetched] = useState(false);
  const isFreePortal = useSelector(selectIsFreePortal);
  useEffect(() => {
    if (isFreePortal && !isFTS0003Fetched) {
      laboratoryClient.resolve(treatments => {
        if (Object.keys(treatments).length) {
          const treatment = treatments[FTS0003_TREATMENT_KEY];
          const cohort = treatment[PARAMETERS][GROUP_PARAMETER_KEY];
          const isVariant = cohort === GROUP_PARAMETER_OPTIONS.variant;
          setIsFTS0003Variant(isVariant);
        }
        setIsFTS0003Fetched(true);
        logFTS0003Exposure();
      });
    }
  }, [isFTS0003Fetched, isFreePortal]);
  return {
    isFTS0003Variant,
    isFTS0003Fetched
  };
};