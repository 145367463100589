'use es6';

import { List } from 'immutable';
import { search } from '../dao';
import { generate } from '../generate';
import { transform } from '../transform';
import PortalIdParser from 'PortalIdParser';
import { convertToSearchProperties } from '../../common/mapToSearchProperties';
import { dataTypeToEngagementType } from '../../common/engagement-types';
import addEngagementTypeFilter from '../../common/add-engagement-type-filter';
export default ((spec, config) => {
  const dataType = spec.get('dataType');
  return generate(dataTypeToEngagementType.has(dataType) ? addEngagementTypeFilter(config, dataTypeToEngagementType.get(dataType)) : config, spec.properties.idProperty).then(payload => {
    return payload.set('objectTypeId', spec.get('objectTypeId')).set('portalId', PortalIdParser.get()).set('requestOptions', {
      properties: convertToSearchProperties(spec.getIn(['search', 'properties'], List()), dataType)
    }).set('associationPreviews', spec.get('associationPreviews'));
  }).then(payload => search(spec.search.url, payload)).then(response => transform(config, spec, response));
});