'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { DURATION, NUMBER, PERCENT, CURRENCY } from '../../../../constants/property-types';
import { metricToCamelCase } from '../../../../lib/metricToCamelCase';
const getValue = (response, property, type) => {
  const responseValue = response.getIn(['metrics', property.get('name'), metricToCamelCase(type)]);
  if ([DURATION, NUMBER, PERCENT, CURRENCY].includes(property.get('type')) && isNaN(responseValue)) {
    return 0;
  }
  return responseValue;
};
export default ((config, response, properties) => {
  const metrics = config.get('metrics', List());
  return ImmutableMap({
    total: response.get('count'),
    metrics: metrics.reduce((buckets, metric) => metric.get('metricTypes', List()).reduce((transformed, type) => {
      const property = metric.get('property');
      return transformed.setIn([property, type], property === 'count' ? response.get('count') : getValue(response, properties.get(property), type));
    }, buckets), ImmutableMap())
  });
});