'use es6';

import { List } from 'immutable';
import withUserInfo from '../lib/withUserInfo';
import { DEALS } from 'reporting-data/constants/dataTypes';
import { usesLegacyLifecycleStages, dealStageCalculatedProperties, dealStageCalculatedPropertiesV2 } from './legacyLifecycleStageBannerGenerator';
import { crmScpDealsAccess } from '../constants/scopes';
import { LOCK_DOWN_REPORT } from 'reporting-data/constants/deprecate/deprecationTypes';
const gatesAndScopeCheck = userInfo => {
  const scopes = userInfo && userInfo.getIn(['user', 'scopes'], List());
  return !scopes.includes(crmScpDealsAccess);
};
export const freeAndStarterUsesDealSCP = (report, userInfo) => {
  const affectedDealProperties = dealStageCalculatedProperties.concat(dealStageCalculatedPropertiesV2);
  return usesLegacyLifecycleStages(report, DEALS, 'usesDealSCPsFreeStarter', gatesAndScopeCheck(userInfo), affectedDealProperties, LOCK_DOWN_REPORT);
};
export default withUserInfo(freeAndStarterUsesDealSCP);