import { INTL_DEFAULT_MIN_FRACTIONAL_DIGITS, getLocale, getOptions, alternativeFractionCurrencies } from './intlFormatters';
const NUMERAL_OR_SIGN_OR_E = /\d|\+|-|e/i;
export function formatNumber(number, options) {
  return new Intl.NumberFormat(getLocale(options), getOptions(options)).format(number);
}
export function formatPercentage(number, options) {
  const opts = Object.assign({
    style: 'percent'
  }, getOptions(options));
  return new Intl.NumberFormat(getLocale(options), opts).format(number / 100);
}
export const parseNumber = function parseNumber(string, options) {
  var _options$parts, _parts$find, _options$parts2, _parts$find2;
  /* Backwards compatiable version of I18n.parseNumber */
  // Use the getLocale utility
  const locale = getLocale(options);

  // Use getOptions to retrieve options like precision, currencyDisplay, etc.
  const opts = getOptions(options);

  // Handle fractional digits based on alternative currencies
  if (opts.currency && alternativeFractionCurrencies[opts.currency]) {
    opts.maximumFractionDigits = alternativeFractionCurrencies[opts.currency];
  } else if (typeof (options === null || options === void 0 ? void 0 : options.precision) !== 'number') {
    opts.maximumFractionDigits = INTL_DEFAULT_MIN_FRACTIONAL_DIGITS;
  }

  // If the input is already a number, return it
  if (typeof string === 'number') {
    return string;
  } else if (typeof string !== 'string') {
    return NaN;
  }

  // Remove all whitespace and replace MINUS SIGN with HYPHEN MINUS
  string = string.replace(/\s/g, '').replace('−', '-');

  // Parse number based on locale-specific separators
  const formatter = new Intl.NumberFormat(locale, opts);
  const parts = formatter.formatToParts(12345.6); // Use formatToParts for locale-specific parts
  const decimalSeparator = (options === null || options === void 0 || (_options$parts = options.parts) === null || _options$parts === void 0 ? void 0 : _options$parts.decimal) || ((_parts$find = parts.find(part => part.type === 'decimal')) === null || _parts$find === void 0 ? void 0 : _parts$find.value);
  const groupSeparator = (options === null || options === void 0 || (_options$parts2 = options.parts) === null || _options$parts2 === void 0 ? void 0 : _options$parts2.group) || ((_parts$find2 = parts.find(part => part.type === 'group')) === null || _parts$find2 === void 0 ? void 0 : _parts$find2.value) || ',';

  // Iterate through characters and parse
  let buffer = '';
  for (const char of Array.from(string)) {
    if (char.match(NUMERAL_OR_SIGN_OR_E)) {
      buffer += char;
    } else if (char === decimalSeparator) {
      buffer += '.';
    } else if (char === groupSeparator) {
      continue; // Ignore group separators
    } else {
      return NaN;
    }
  }

  // Parse final string into a float
  return parseFloat(buffer);
};
export function FormattedPercentage(props) {
  return formatPercentage(props.value, props);
}