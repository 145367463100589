'use es6';

import { List } from 'immutable';
import invariant from '../../lib/invariant';
import { getStage, PROPERTY } from '../../configure/bucket/lifecyclestage';
import reprocess from './reprocess';
const precondition = config => {
  const dimensions = config.get('dimensions') || List();
  invariant(dimensions.includes(PROPERTY), `expected ${PROPERTY} in dimensions, but got %s`, dimensions);
};
const oneDimensional = (config, dataset) => {
  const stage = getStage(config);
  const groupBy = bucket => ['create', bucket.get('key')].includes(stage) ? 'YES' : 'NO';
  return reprocess(groupBy)(config, dataset);
};
const twoDimensional = (config, dataset) => dataset.updateIn(['dimension', 'buckets'], aggregations => aggregations.reduce((grouped, aggregation) => grouped.push(oneDimensional(config, aggregation)), List()));
export default (({
  dataConfig,
  dataset
}) => {
  precondition(dataConfig);
  const dimensions = dataConfig.get('dimensions');
  return dimensions.indexOf(PROPERTY) === 0 ? oneDimensional(dataConfig, dataset) : twoDimensional(dataConfig, dataset);
});