import * as UpgradeProducts from 'self-service-api/constants/UpgradeProducts';
export const TRIAL_STATUS_TYPES = {
  ACTIVE: 'ACTIVE',
  AUTOMATION_ACTION_PROMPT: 'AUTOMATION_ACTION_PROMPT',
  EXPIRED: 'EXPIRED',
  EXPIRING_SOON: 'EXPIRING_SOON'
};
export const TRIAL_STANDARD_DURATION = 14;

// This is a map of trial products that conflict with each other.
// If the user has a trial of one of these products, they cannot activate a trial of the other.
// For example, if the user has a trial of MARKETING_PLUS_ENTERPRISE, they cannot activate a trial of CMS_ENTERPRISE or MARKETING_ENTERPRISE.
export const CONFLICTING_TRIALS = {
  [UpgradeProducts.MARKETING_PLUS_ENTERPRISE]: [UpgradeProducts.MARKETING_ENTERPRISE, UpgradeProducts.CMS_ENTERPRISE],
  [UpgradeProducts.MARKETING_PLUS_PROFESSIONAL]: [UpgradeProducts.MARKETING_PRO, UpgradeProducts.CMS_PROFESSIONAL],
  [UpgradeProducts.MARKETING_PRO]: [UpgradeProducts.MARKETING_PLUS_PROFESSIONAL],
  [UpgradeProducts.MARKETING_ENTERPRISE]: [UpgradeProducts.MARKETING_PLUS_ENTERPRISE],
  [UpgradeProducts.CMS_ENTERPRISE]: [UpgradeProducts.MARKETING_PLUS_ENTERPRISE],
  [UpgradeProducts.CMS_PROFESSIONAL]: [UpgradeProducts.MARKETING_PLUS_PROFESSIONAL]
};