'use es6';

import * as inboundDbDataTypes from '../constants/dataTypes/inboundDb';
import * as supported from '../retrieve/unified/supported';
import { has } from '../lib/has';
import { ReportingLogger } from '../monitoring/reportingLogger';
const LOAD_DATA_TYPE_DEFINITIONS_PAGE_ACTION = 'ReportingDataLoadDataTypeDefinitions';
const LOAD_DATA_TYPE_DEFINITIONS_DATA_TYPE_ATTRIBUTE = 'reportingDataLoadDataTypeDefinitionsDataType';
const LOAD_DATA_TYPE_DEFINITIONS_MODULE_ATTRIBUTE = 'reportingDataLoadDataTypeDefinitionsModule';
const SUPPORTED_MODULE = 'supported';
const INBOUNDDB_MODULE = 'inbounddb';
const LEGACY_MODULE = 'legacy';
const defaultLoad = dataType => {
  const reportingLogger = new ReportingLogger();
  reportingLogger.addAttribute(LOAD_DATA_TYPE_DEFINITIONS_DATA_TYPE_ATTRIBUTE, dataType);
  if (supported.get(dataType)) {
    reportingLogger.addAttribute(LOAD_DATA_TYPE_DEFINITIONS_MODULE_ATTRIBUTE, SUPPORTED_MODULE);
    reportingLogger.sendPageAction(LOAD_DATA_TYPE_DEFINITIONS_PAGE_ACTION);
    return import( /* webpackChunkName: "reporting-data__unified" */'./unified/').then(({
      default: modules
    }) => {
      return modules[dataType];
    });
  } else if (has(inboundDbDataTypes, dataType)) {
    reportingLogger.addAttribute(LOAD_DATA_TYPE_DEFINITIONS_MODULE_ATTRIBUTE, INBOUNDDB_MODULE);
    reportingLogger.sendPageAction(LOAD_DATA_TYPE_DEFINITIONS_PAGE_ACTION);
    return import( /* webpackChunkName: "reporting-data__inboundDb" */'./inboundDb/').then(({
      default: modules
    }) => {
      return modules[dataType];
    });
  }
  reportingLogger.addAttribute(LOAD_DATA_TYPE_DEFINITIONS_MODULE_ATTRIBUTE, LEGACY_MODULE);
  reportingLogger.sendPageAction(LOAD_DATA_TYPE_DEFINITIONS_PAGE_ACTION);
  return import( /* webpackChunkName: "reporting-data__custom" */'./legacy/').then(({
    default: modules
  }) => {
    return modules[dataType];
  });
};
export const load = defaultLoad;