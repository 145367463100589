// @ts-expect-error: no types
import useUserInfo from '../../useUserInfo';
import { PUBLIC_APPS_SHARE_WITH_COMPONENT } from '../gates';
import { gate } from 'hub-http/gates';
export const useHasGate = gateName => {
  const {
    data,
    loading
  } = useUserInfo(false);
  if (loading) {
    return null;
  }
  const gates = (data === null || data === void 0 ? void 0 : data.gates) || [];
  return gates.includes(gateName);
};
export const useIsUngatedForPublicAppsShareWithComponent = () => useHasGate(PUBLIC_APPS_SHARE_WITH_COMPONENT);
export const useIsUngatedForBreezeAIBranding = () => useHasGate(gate('BreezeAI:Rebranding'));