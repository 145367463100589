import { createSlice } from '@reduxjs/toolkit';
export let AppStatus;
(function (AppStatus) {
  AppStatus[AppStatus["UNINITIALIZE"] = 0] = "UNINITIALIZE";
  AppStatus[AppStatus["INITIALIZED"] = 1] = "INITIALIZED";
  AppStatus[AppStatus["CLOSED"] = 2] = "CLOSED";
})(AppStatus || (AppStatus = {}));
const initialState = {
  appStatus: AppStatus.UNINITIALIZE,
  resourceId: null,
  appTracker: null,
  forceRenderCount: 0
};
const reportViewSlice = createSlice({
  name: 'reportView',
  initialState,
  reducers: {
    initializeApp(state, action) {
      const {
        resourceId
      } = action.payload;
      state.resourceId = resourceId;
    },
    incrementForceRenderCount(state) {
      state.forceRenderCount += 1;
    }
  }
});
export const selectAppState = state => state.reportView.appStatus;
export const selectAppTracker = state => state.reportView.appTracker;
export const selectForceRenderCount = ({
  reportView
}) => {
  return reportView.forceRenderCount;
};
export const {
  initializeApp,
  incrementForceRenderCount: increment
} = reportViewSlice.actions;
export default reportViewSlice.reducer;