'use es6';

/* This will become the source of truth for integration code
  Retrieve is responsible for the following:
  a) picking the integration to use
  b) generating the request from config
  c) transforming data to our format
  d) optionally hydrating any references into the reference store

  The return value of retrieve should always be a promise of a dataset,
  but for now the return value is Promise<{
    dataConfig,
    dataset,
    response
  }> to maintain backwards compatibility with existing code.
*/
import { List } from 'immutable';
import { RETRIEVE } from '../constants/phases';
import invariant from '../lib/invariant';
import { connectedPhase } from '../redux/resolve/connected';
import * as baseRetrieve from './baseRetrieve';
import addQuotaColumn from './custom/addQuotaColumn/addQuotaColumnRetrieve';
import attribution from './custom/attributionRetrieve';
import * as quotas from './custom/quotas/quotasRetrieve';
import unified2dCombination from './custom/unified2dCombinationRetrieve';
import * as legacyRetrieve from './legacyRetrieve';
import { ReportingLogger } from '../monitoring/reportingLogger';
const RETRIEVE_PAGE_ACTION = 'ReportingDataRetrieve';
const RETRIEVE_INTEGRATION_ATTRIBUTE = 'reportingDataRetrieveIntegration';
const integrations = List([{
  module: unified2dCombination,
  trackingName: 'unified2dCombination'
}, {
  module: attribution,
  trackingName: 'attribution'
}, {
  module: quotas,
  trackingName: 'quotas'
}, {
  module: addQuotaColumn,
  trackingName: 'addQuotaColumn'
}, {
  module: baseRetrieve,
  trackingName: 'baseRetrieve'
}, {
  module: legacyRetrieve,
  trackingName: 'legacyRetrieve'
}]);
export const match = dataConfig => integrations.find(integration => integration.module.match(dataConfig));
export const retrieve = (dataConfig, debug, runtimeOptions) => {
  const integration = match(dataConfig);
  invariant(integration, 'expected integration but no matches were found for the given dataConfig');
  const reportingLogger = new ReportingLogger();
  reportingLogger.addReportConfigAttributes(dataConfig);
  reportingLogger.addAttribute(RETRIEVE_INTEGRATION_ATTRIBUTE, integration.trackingName);
  reportingLogger.sendPageAction(RETRIEVE_PAGE_ACTION);
  return integration.module.retrieve(dataConfig, debug, runtimeOptions);
};
export const connectedRetrieve = connectedPhase(RETRIEVE, retrieve);