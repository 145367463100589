/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import { fromJS } from 'immutable';
import { capture } from 'dashboard-lib/public/sentry/report-management-sentry';
import { DASHBOARD_FETCH_FAILURE } from 'dashboard-lib/public/sentry/sentry-event-id';
import Dispatcher from 'dispatcher/dispatcher';
import PortalIdParser from 'PortalIdParser';
import emptyFunction from 'react-utils/emptyFunction';
import * as DashboardActionTypes from '../constants/DashboardActionTypes';
import * as DashboardDAO from '../data/DashboardDAO';
import { fromResponse } from '../data/DashboardDAOHelper';
import * as DashboardRouter from '../lib/dashboard-router';
import DashboardStore from '../stores/DashboardStore';
import { getTemplateName } from 'dashboard-lib/public/report/report-schema';
import { getDescription, getName, getOwnerId, getId, getShowDescription, getBusinessUnit, getPinnedProperties } from 'dashboard-lib/public/dashboard/dashboard-schema';
import { getReport } from 'dashboard-lib/public/widget/widget-schema';
import { isNotNil } from 'dashboard-lib/private/js-util';
import { mockAsyncRequest } from '../lib/MockRequest';
import bust from 'reporting-data/bust';
import { refresh as refreshSnowflakeReport } from 'reporting-snowflake/relational/resolve/refresh';
import { refresh as refreshJourneyReport } from 'reporting-snowflake/funnel/resolve/refresh';
import { getWidgetWithReportId } from 'dashboard-lib/public/dashboard/dashboard-logic';
import Raven from 'raven-js';
import { getJourneyQuery, getReportDefinition } from 'reporting-action-components/data/schemas/reportSchema';
import { isSnowflake, isJourney } from 'reporting-action-components/lib/report-logic';
import { deleteReportCache } from 'reporting-action-components/data/requests/report';
/**
 * HACK - getting many different immutable types (Map, OrderedMap, Record, etc.).
 * Force everything to be Maps and Lists for the compares/merges to work correctly.
 */
const normalizeReport = report => {
  return fromJS(report.toJS());
};
const updateReport = (dashboardId, reportId, report) => {
  const dashboard = DashboardStore.get(['dashboards', dashboardId]);
  const widget = getWidgetWithReportId(dashboard, reportId);
  const oldReport = getReport(widget);
  const templateKey = getTemplateName(oldReport);

  // HACK - onDrilldown shouldn't be on the report config at this point. Issue with createWidgetWithDrilldown.
  let updatedReport = normalizeReport(report);
  if (updatedReport.get('config') != null) {
    updatedReport = updatedReport.setIn(['config', 'template'], templateKey);
  }
  updatedReport = updatedReport.deleteIn(['displayParams', 'onDrilldown']).deleteIn(['displayParams', 'origin']).merge({
    oldConfig: oldReport.get('oldConfig'),
    oldDisplaySettings: oldReport.get('oldDisplaySettings'),
    oldReportId: oldReport.get('oldReportId'),
    oldReportType: oldReport.get('oldReportType')
  });
  const requestPromise = DashboardDAO.updateReport(reportId, updatedReport);
  requestPromise.then(savedReport => {
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.REPORT_UPDATE_SUCCESS,
      data: {
        report: savedReport
      }
    });
  }, error => {
    console.error(error);
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.REPORT_UPDATE_FAILURE
    });
  });
  return requestPromise;
};
const updateReportOnDashboards = report => {
  Dispatcher.dispatch({
    actionType: DashboardActionTypes.REPORT_UPDATE_SUCCESS,
    data: {
      report
    }
  });
};
const deleteReport = report => {
  const requestPromise = DashboardDAO.deleteReport(report.get('id'));
  requestPromise.then(() => {
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.REPORT_DELETE_SUCCESS,
      data: {
        report
      }
    });
  });
  return requestPromise;
};
const updateDashboardsUponDeleteReportSuccess = report => {
  Dispatcher.dispatch({
    actionType: DashboardActionTypes.REPORT_DELETE_SUCCESS,
    data: {
      report
    }
  });
};
const removeReportFromDashboard = (dashboardId, reportId) => {
  const requestPromise = DashboardDAO.deleteWidget(dashboardId, reportId);
  requestPromise.then(() => {
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.REPORT_REMOVE_SUCCESS,
      data: {
        dashboardId,
        reportId
      }
    });
  });
  return requestPromise;
};
const fetchDashboardCounts = () => {
  DashboardDAO.fetchDashboards({
    limit: 0
  }).then(fromJS).then(res => {
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.DASHBOARD_COUNT_FETCH_SUCCESS,
      data: {
        allDashboardsCount: res.get('allDashboards')
      }
    });
  }).catch(responseError => {
    console.error(responseError);
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.DASHBOARD_COUNT_FETCH_FAILURE
    });
    Raven.captureException(responseError);
  });
};
const fetchDashboards = () => {
  Dispatcher.dispatch({
    actionType: DashboardActionTypes.DASHBOARDS_FETCH
  });
  const onFailure = e => {
    console.error(e);
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.DASHBOARDS_FETCH_FAILURE
    });
    Raven.captureException(e);
  };
  DashboardDAO.fetchDashboards({
    hydrate: 'USER_PERMISSION_LEVEL'
  }).then(response => {
    const allDashboardsCount = response.get('allDashboards');
    const dashboards = response.get('dashboards').map(fromResponse);
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.DASHBOARDS_FETCH_SUCCESS,
      data: {
        dashboards,
        allDashboardsCount
      }
    });
  }).catch(onFailure);
};
const fetchDashboard = (dashboardId, params = {}) => {
  Dispatcher.dispatch({
    actionType: DashboardActionTypes.DASHBOARD_FETCH,
    data: {
      id: dashboardId
    }
  });
  DashboardDAO.fetchDashboard(dashboardId, params).then(dashboard => {
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.DASHBOARD_FETCH_SUCCESS,
      data: {
        dashboard
      }
    });
  }).catch(responseError => {
    console.error(responseError);
    const {
      status
    } = responseError;
    const shouldDelete = status === 403 || status === 404;
    const isForbidden = status === 403;
    let errorType;
    try {
      const errorDetails = JSON.parse(responseError.data);
      if (isForbidden) {
        const {
          subCategory: errorEnum
        } = errorDetails;
        errorType = errorEnum.replace('DashboardPermissionError.', '');
      }
    } catch (parsingError) {
      console.error('There was an error when parsing the response error data: ', responseError);
    }
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.DASHBOARD_FETCH_FAILURE,
      data: {
        shouldDelete,
        dashboardId,
        errorType,
        isForbidden
      }
    });

    /*
     * Why use sentry instead of new relic here:
     * We want new relic to have minimal noise so we can easily monitor
     * abnormal behavior. Logging noisier errors like this one using sentry
     * could reduce the noise in NR. In sentry, we should set an error
     * threshold for these errors.
     */
    capture(DASHBOARD_FETCH_FAILURE, {
      error: responseError,
      extra: {
        portalId: PortalIdParser.get()
      },
      fingerprint: [DASHBOARD_FETCH_FAILURE]
    });
  });
};
const cloneDashboard = (name, options = {}, parentDashboard, history, shouldCloneReports) => {
  const {
    redirect = true,
    permission,
    dashboardPermissionConfigs
  } = options;
  const payload = {
    dashboardId: getId(parentDashboard),
    name,
    dashboardPermissionConfigs,
    dashboardPermissionConfig: permission,
    shouldCloneReports
  };
  const requestPromise = DashboardDAO.cloneDashboard(payload);
  requestPromise.then(dashboard => {
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.DASHBOARD_FETCH_SUCCESS,
      data: {
        dashboard
      }
    });
    if (redirect) {
      DashboardRouter.routeToDashboard(getId(dashboard), null, history);
    }
    fetchDashboardCounts();
  }, error => {
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.DASHBOARD_CLONE_FAILURE
    });
    Raven.captureException(error);
  });
  return requestPromise;
};
const deleteDashboard = (dashboardId, options = {}) => {
  const onFailure = e => {
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.DASHBOARD_DELETE_FAILURE
    });
    Raven.captureException(e);
  };
  const requestPromise = DashboardDAO.deleteDashboard(dashboardId, options);
  requestPromise.then(() => {
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.DASHBOARD_DELETE_SUCCESS,
      data: {
        dashboardId
      }
    });
    fetchDashboardCounts();
  }, onFailure);
  return requestPromise;
};
const updateDashboardLayout = (dashboardId, layout, isReadOnlyDemo) => {
  const widgetLayouts = fromJS(layout.map(({
    x,
    y,
    h: height,
    w: width,
    i: id,
    isResizable
  }) => {
    return {
      reportId: Number(id),
      layoutV2: {
        height,
        width,
        x,
        y,
        isResizable
      }
    };
  }));

  //fake successful response if read only demo dashboard
  if (isReadOnlyDemo) {
    return mockAsyncRequest(emptyFunction);
  }
  return DashboardDAO.updateDashboardLayout(dashboardId, widgetLayouts).then(() => {
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.DASHBOARD_LAYOUT_UPDATE_SUCCESS,
      data: {
        dashboardId,
        widgetLayouts
      }
    });
    return widgetLayouts;
  }, error => {
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.DASHBOARD_UPDATE_FAILURE
    });
    console.error(error);
  });
};
const updateDashboardMeta = (dashboardId, {
  name,
  businessUnit,
  ownerId,
  demo,
  description,
  showDescription,
  pinnedProperties
}) => {
  const dashboard = DashboardStore.get().getIn(['dashboards', dashboardId]);
  let updatedDashboard = dashboard.set('name', name || getName(dashboard)).set('description', isNotNil(description) ? description : getDescription(dashboard)).set('ownerId', ownerId || getOwnerId(dashboard)).set('showDescription', isNotNil(showDescription) ? showDescription : getShowDescription(dashboard)).set('businessUnitId', businessUnit || getBusinessUnit(dashboard)).set('pinnedProperties', !pinnedProperties ? getPinnedProperties(dashboard) : fromJS(pinnedProperties));
  if (demo !== undefined) {
    updatedDashboard = updatedDashboard.set('demo', demo);
  }
  return DashboardDAO.updateDashboardMeta(dashboardId, updatedDashboard).then(() => {
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.DASHBOARD_UPDATE_SUCCESS,
      data: {
        dashboardId,
        dashboard: updatedDashboard
      }
    });
    return updatedDashboard;
  }, error => {
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.DASHBOARD_UPDATE_FAILURE
    });
    throw error;
  });
};
const updateContext = ({
  dashboardId,
  context,
  history
}) => {
  Dispatcher.dispatch({
    actionType: DashboardActionTypes.DASHBOARD_CONTEXT_UPDATE,
    data: {
      dashboardId,
      context,
      history
    }
  });
};
const getReportCounts = () => {
  DashboardDAO.getReportCounts().then(fromJS).then(res => {
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.REPORT_COUNT_FETCH_SUCCESS,
      data: res
    });
  }).catch(responseError => {
    console.error(responseError);
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.REPORT_COUNT_FETCH_FAILURE
    });
    Raven.captureException(responseError);
  });
};
const getReportRefreshPromise = report => {
  const reportId = report.get('id');
  if (isSnowflake(report)) {
    const reportDefinition = getReportDefinition(report);
    return deleteReportCache(reportId).then(() => refreshSnowflakeReport(reportDefinition, reportId));
  } else if (isJourney(report)) {
    const journeyQuery = getJourneyQuery(report);
    return refreshJourneyReport(journeyQuery, reportId);
  }
  return Promise.resolve();
};
const refreshAll = ({
  dashboardId
}) => {
  const dashboard = DashboardStore.get(['dashboards', dashboardId]);
  Dispatcher.dispatch({
    actionType: DashboardActionTypes.REFRESH_ALL,
    data: {
      dashboardId
    }
  });
  bust();
  Promise.all(dashboard.get('widgets').map(getReport).map(report => getReportRefreshPromise(report)).toArray()).then(() => {
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.REFRESH_ALL_SUCCESS,
      data: {
        dashboardId
      }
    });
  }, responseError => {
    console.error(responseError);
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.REFRESH_ALL_FAILURE,
      data: {
        dashboardId,
        refreshError: responseError
      }
    });
    Raven.captureException(responseError);
  }).catch(console.error);
};
const refreshReportStart = ({
  reportId,
  dashboardId
}) => {
  Dispatcher.dispatch({
    actionType: DashboardActionTypes.REFRESH_REPORT,
    data: {
      dashboardId,
      reportId
    }
  });
};
const refreshReportSuccess = ({
  reportId,
  dashboardId
}) => {
  Dispatcher.dispatch({
    actionType: DashboardActionTypes.REFRESH_REPORT_SUCCESS,
    data: {
      dashboardId,
      reportId
    }
  });
};
const refreshReportFailure = ({
  reportId,
  dashboardId,
  error
}) => {
  Dispatcher.dispatch({
    actionType: DashboardActionTypes.REFRESH_REPORT_FAILURE,
    data: {
      dashboardId,
      reportId,
      refreshError: error
    }
  });
};
const saveWidget = (report, dashboardId) => {
  const requestPromise = DashboardDAO.createWidget(dashboardId, report);
  requestPromise.then(() => {
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.REPORT_SAVE_SUCCESS
    });
    fetchDashboard(dashboardId);
  }, error => {
    Dispatcher.dispatch({
      actionType: DashboardActionTypes.REPORT_SAVE_FAILURE
    });
    console.error(error);
  });
  return requestPromise;
};
export { updateReport, updateReportOnDashboards, deleteReport, updateDashboardsUponDeleteReportSuccess, removeReportFromDashboard, fetchDashboards, fetchDashboard, cloneDashboard, deleteDashboard, updateDashboardMeta, updateDashboardLayout, updateContext, getReportCounts, fetchDashboardCounts, refreshAll, refreshReportStart, refreshReportSuccess, refreshReportFailure, saveWidget };