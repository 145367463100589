'use es6';

import { Map as ImmutableMap } from 'immutable';
import { Config } from '../../../config';
import { getFilterByProperty } from '../../../config/filters/functions';
import * as ConfigTypes from '../../../constants/configTypes';
import { QUOTAS } from '../../../constants/dataTypes';
import * as Operators from '../../../constants/operators';
import extractUniqueValues from '../../../dataset/extract-unique-values';
import { mergeDatasets } from '../../../dataset/mergeDatasets';
import { Promise } from '../../../lib/promise';
import { retrieve as quotaRetrieve } from '../quotas/quotasRetrieve';
import { QUOTA_DATE_PROPERTY, QUOTA_OWNER_PROPERTY, QUOTA_PIPELINE_PROPERTY, QUOTA_TYPE_PROPERTY, QUOTA_VALUE_PROPERTY } from '../quotas/constants';
import invariant from '../../../lib/invariant';
const fill = ({
  dataset,
  metric
}) => {
  const property = metric.get('property');
  const emptyMetric = metric.get('metricTypes').toMap().mapKeys((k, v) => v).map(() => 0);
  dataset = dataset.get('metrics') ? dataset.update('metrics', metrics => metrics.has(property) ? metrics : metrics.set(property, emptyMetric)) : dataset;
  return dataset.get('dimension') ? dataset.updateIn(['dimension', 'buckets'], buckets => buckets.map(bucket => fill({
    dataset: bucket,
    metric
  }))) : dataset;
};
const parseQuotaMetric = quotaMetric => {
  // QUOTAS.{PERIOD}.{TYPE}.{?PIPELINE}
  const quotaProperty = quotaMetric.get('property');
  const bits = quotaProperty.split('.');
  invariant(bits.length === 3, 'Invalid quota metric property');
  const [, quotaPeriod, quotaType] = bits;
  return {
    quotaProperty,
    quotaPeriod,
    quotaType
  };
};
export const buildQuotaConfig = ({
  primaryConfig,
  dataset,
  ownerProperty,
  quotaMetric
}) => {
  const dateFilter = primaryConfig.getIn(['filters', 'dateRange']).set('property', QUOTA_DATE_PROPERTY);
  const {
    quotaType
  } = parseQuotaMetric(quotaMetric);
  const customFilters = [{
    operator: Operators.EQ,
    property: QUOTA_TYPE_PROPERTY,
    value: quotaType
  }];
  const dimensions = primaryConfig.get('configType') === ConfigTypes.TIME_SERIES ? [QUOTA_DATE_PROPERTY] : [];
  const byOwner = primaryConfig.get('dimensions').contains(ownerProperty);
  if (byOwner) {
    dimensions.push(QUOTA_OWNER_PROPERTY);
    //if (primaryConfig.getIn(['filters', 'owner']))
    const owners = extractUniqueValues(ownerProperty, dataset);
    customFilters.push({
      operator: 'IN',
      property: QUOTA_OWNER_PROPERTY,
      values: owners.toList()
    });
  }
  const pipelineFilter = getFilterByProperty(primaryConfig, 'pipeline');
  if (pipelineFilter) {
    customFilters.push(pipelineFilter.set('property', QUOTA_PIPELINE_PROPERTY));
  }
  return Config({
    configType: primaryConfig.get('configType'),
    frequency: primaryConfig.get('frequency'),
    dataType: QUOTAS,
    filters: {
      dateRange: dateFilter,
      custom: customFilters
    },
    dimensions,
    metrics: [quotaMetric.set('property', QUOTA_VALUE_PROPERTY)]
  });
};
export const getQuotaData = ({
  primaryConfig,
  dataset,
  quotaMetric
}) => {
  const ownerProperty = QUOTA_OWNER_PROPERTY;
  const quotaMetricProperty = quotaMetric.get('property');
  const quotasConfig = buildQuotaConfig({
    primaryConfig,
    dataset,
    ownerProperty,
    quotaMetric
  });

  /* Don't request if no owners available, but still fill empty metrics */
  if (quotasConfig.get('dimensions').contains(QUOTA_OWNER_PROPERTY) && getFilterByProperty(quotasConfig, QUOTA_OWNER_PROPERTY).get('values').count() === 0) {
    return Promise.resolve(fill({
      dataset,
      metric: quotaMetric
    }));
  }
  return quotaRetrieve(quotasConfig, () => {}).then(({
    dataset: quotasDataset
  }) => {
    quotasDataset = quotasDataset.update('metrics', metrics => metrics.set(quotaMetricProperty, ImmutableMap({
      SUM: metrics.getIn(['hs_value', 'SUM'], 0)
    })).delete('hs_value'));
    const dimensionCount = quotasConfig.get('dimensions').count();
    if (dimensionCount === 1) {
      quotasDataset = quotasDataset.updateIn(['dimension', 'buckets'], buckets => buckets.map(bucket => bucket.update('metrics', metrics => metrics.set(quotaMetricProperty, metrics.get('hs_value')).delete('hs_value')))).setIn(['dimension', 'property'], primaryConfig.getIn(['dimensions', 0]));
    }
    if (dimensionCount === 2) {
      quotasDataset = quotasDataset.updateIn(['dimension', 'buckets'], buckets => buckets.map(bucket => bucket.updateIn(['dimension', 'buckets'], innerBuckets => innerBuckets.map(innerBucket => innerBucket.update('metrics', metrics => metrics.set(quotaMetricProperty, metrics.get('hs_value')).delete('hs_value')))).setIn(['dimension', 'property'], primaryConfig.getIn(['dimensions', 1])))).setIn(['dimension', 'property'], primaryConfig.getIn(['dimensions', 0]));
    }
    quotasDataset = mergeDatasets(quotasDataset, dataset);
    quotasDataset = fill({
      dataset: quotasDataset,
      metric: quotaMetric
    });
    return quotasDataset;
  });
};