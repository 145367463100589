import { setRecentlyUsedProperties } from '../filter/utils/recentlyUsedProperties';
import { addDashboardFilter, addGroupDashboardFilter, removeCurrentDashboardFilters, removeDashboardFilter, removeDashboardFilterByName, removeGroupDashboardFilter, selectDashboardFilters, updateDashboardFilter } from './dashboardFiltersSlice';
import { persistDashboardFilters } from '../filter/utils/persistedDashboardFilters';
const persistFilters = (dashboardId, state) => {
  const dashboardFilters = selectDashboardFilters(dashboardId)(state);
  persistDashboardFilters(dashboardId, dashboardFilters);
};
export const applyReduxListeners = listenerMiddleware => {
  listenerMiddleware.startListening({
    actionCreator: addDashboardFilter,
    effect: (action, {
      getState
    }) => {
      const {
        dataSource,
        property,
        dashboardId
      } = action.payload;
      persistFilters(dashboardId, getState());
      if (dataSource && property) {
        setRecentlyUsedProperties({
          dataSource,
          property
        });
      }
    }
  });
  listenerMiddleware.startListening({
    actionCreator: updateDashboardFilter,
    effect: (action, {
      getState
    }) => {
      persistFilters(action.payload.dashboardId, getState());
    }
  });
  listenerMiddleware.startListening({
    actionCreator: addGroupDashboardFilter,
    effect: (action, {
      getState
    }) => {
      persistFilters(action.payload.dashboardId, getState());
    }
  });
  listenerMiddleware.startListening({
    actionCreator: removeDashboardFilter,
    effect: (action, {
      getState
    }) => {
      persistFilters(action.payload.dashboardId, getState());
    }
  });
  listenerMiddleware.startListening({
    actionCreator: removeDashboardFilterByName,
    effect: (action, {
      getState
    }) => {
      persistFilters(action.payload.dashboardId, getState());
    }
  });
  listenerMiddleware.startListening({
    actionCreator: removeGroupDashboardFilter,
    effect: (action, {
      getState
    }) => {
      persistFilters(action.payload.dashboardId, getState());
    }
  });
  listenerMiddleware.startListening({
    actionCreator: removeCurrentDashboardFilters,
    effect: (action, {
      getState
    }) => {
      persistFilters(action.payload.dashboardId, getState());
    }
  });
  return listenerMiddleware;
};