'use es6';

import { List, Map as ImmutableMap, OrderedMap } from 'immutable';
import { COUNT } from '../../../../constants/metricTypes';
import { currency as formatCurrency } from '../../../../hydrate/numberFormatter';
import indexBy from '../../../../lib/indexBy';
import { CURRENCY_PROPERTY_NAME, LEGACY_DEAL_AMOUNT_PROPERTIES, CURRENCY_CODE_BY_DATA_TYPE } from '../../../../properties/constants/multiCurrencyProperties';
import { extractPropertyNamespace, getObjectId, namespaceProperty } from '../../../../properties/namespaceProperty';
import { getCurrencyInformation } from '../../../../public/currency';
import getCrossObjectProperties from '../../aggregate/cross-object/properties';
import { CROSS_OBJECT } from '../../../../constants/dataTypes/inboundDb';
const isIdProperty = (dataTypes, property) => dataTypes.map(getObjectId).includes(property);
const getObjectIdValue = (objectIds, property) => {
  const namespace = extractPropertyNamespace(property).namespace.toUpperCase();
  return objectIds.get(namespace);
};
const getValue = (objectIds, objects, property, multiCurrencyEnabled, crossObjectProperties) => {
  const {
    propertyName,
    namespace,
    dataType
  } = extractPropertyNamespace(property);
  const objectType = namespace.toUpperCase();
  const objectId = objectIds.get(objectType);
  const value = objects.getIn([objectType, objectId, 'properties', propertyName, 'value']);
  const currencyPropertyName = crossObjectProperties.getIn([CROSS_OBJECT, namespaceProperty(dataType, propertyName), CURRENCY_PROPERTY_NAME]);
  const currencyCode = currencyPropertyName ? objects.getIn([objectType, objectId, 'properties', currencyPropertyName, 'value']) : CURRENCY_CODE_BY_DATA_TYPE[dataType] && objects.getIn([objectType, objectId, 'properties', CURRENCY_CODE_BY_DATA_TYPE[dataType], 'value']);
  return multiCurrencyEnabled && (currencyPropertyName || LEGACY_DEAL_AMOUNT_PROPERTIES.includes(propertyName)) ? ImmutableMap({
    raw: value,
    formatted: formatCurrency(value, {
      currencyCode
    }),
    propertyMeta: {
      type: 'currency',
      currencyCode
    }
  }) : value;
};
export const transform = (config, response) => Promise.all([getCurrencyInformation(), getCrossObjectProperties(config)]).then(([information, crossObjectProperties]) => {
  const {
    hasMulticurrencyActive
  } = information || {};
  const dataTypes = config.getIn(['crossObject', 'dataTypes']);
  const objectIndex = response.get('objects').map(objects => indexBy(object => object.get('objectId'), objects));
  return ImmutableMap({
    total: response.get('total'),
    dimension: ImmutableMap({
      property: 'associationId',
      buckets: response.get('crossObjectAssociations').map(association => {
        const objectIds = association.get('objectTypeAndIds').reduce((acc, item) => acc.set(item.get('objectType'), item.get('objectId')), ImmutableMap());
        return ImmutableMap({
          key: objectIds.map(id => id).join('__'),
          keyLabel: objectIds.map((id, type) => type).join('__'),
          metrics: OrderedMap(config.get('metrics').map(metric => {
            const property = metric.get('property');
            return List([property, ImmutableMap({
              [COUNT]: isIdProperty(dataTypes, property) ? getObjectIdValue(objectIds, property) : getValue(objectIds, objectIndex, property, hasMulticurrencyActive, crossObjectProperties)
            })]);
          }))
        });
      })
    })
  });
});