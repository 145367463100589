import { fromJS } from 'immutable';
import http from 'hub-http/clients/apiClient';
const REPORT_TEMPLATES_BASE_URL = 'reporting-discovery/v1/report-template';
export const getReportTemplates = (query = {}) => {
  return http.get(REPORT_TEMPLATES_BASE_URL, {
    query
  }).then(fromJS);
};
export const getReportTemplateByKey = templateKey => {
  return http.get(`${REPORT_TEMPLATES_BASE_URL}/template-key/${templateKey}`).then(fromJS);
};