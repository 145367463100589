/* deprecated, use spec */

'use es6';

import { Map as ImmutableMap } from 'immutable';
import * as DataTypes from '../../../constants/dataTypes';
import { SEARCH } from '../../../constants/configTypes';
const getEngagementUrl = serviceType => `contacts/search/v1/${serviceType}/engagements`;
const getCrossObjectUrl = (configType, crossObjectConfig) => {
  const serviceType = configType === SEARCH ? 'search' : 'aggregate';
  const deduplicationMode = crossObjectConfig.get('deduplicate') ? 'PER_BUCKET' : 'NONE';
  const reportId = crossObjectConfig.get('reportId');
  return `cross-object-reports/v1/reports/${reportId}/${serviceType}?deduplicationMode=${deduplicationMode}`;
};
const getCrmSearchUrl = serviceType => `crm-search/${serviceType}/beta`;
const getUrlByDataType = ImmutableMap({
  [DataTypes.ATTRIBUTION_TOUCH_POINTS]: serviceType => `crm-search/${serviceType}`,
  [DataTypes.CALLS]: getEngagementUrl,
  [DataTypes.COMPANIES]: serviceType => `contacts/search/v1/${serviceType}/companies`,
  [DataTypes.CONTACTS]: serviceType => `contacts/search/v1/${serviceType}/contacts`,
  [DataTypes.CONTACT_CREATE_ATTRIBUTION]: serviceType => `crm-search/${serviceType}`,
  [DataTypes.CONVERSATIONS]: getEngagementUrl,
  [DataTypes.DEALS]: serviceType => `contacts/search/v1/${serviceType}/deals`,
  [DataTypes.ENGAGEMENT]: getEngagementUrl,
  [DataTypes.ENGAGEMENT_EMAILS]: getEngagementUrl,
  [DataTypes.FEEDBACK]: getEngagementUrl,
  [DataTypes.FEEDBACK_SUBMISSIONS]: serviceType => `crm-search/${serviceType}`,
  [DataTypes.GOAL_TARGET]: serviceType => `crm-search/${serviceType}`,
  [DataTypes.LINE_ITEMS]: serviceType => `contacts/search/v1/${serviceType}/lineitems`,
  [DataTypes.MEETINGS]: getEngagementUrl,
  [DataTypes.NOTES]: getEngagementUrl,
  [DataTypes.PRODUCTS]: serviceType => `contacts/search/v1/${serviceType}/products`,
  [DataTypes.SOCIAL_POSTS]: serviceType => `social-reporting/v1/${serviceType}/posts`,
  [DataTypes.TASKS]: getEngagementUrl,
  [DataTypes.TICKETS]: serviceType => `contacts/search/v1/${serviceType}/services/tickets`
});
export const getUrl = (config, runtimeOptions) => {
  const dataType = config.get('dataType');
  const configType = config.get('configType');
  if (dataType === DataTypes.CROSS_OBJECT) {
    return getCrossObjectUrl(configType, config.get('crossObject'), runtimeOptions);
  }
  const serviceType = configType === SEARCH ? 'search' : 'report';
  if (runtimeOptions.useCrmSearch) {
    return getCrmSearchUrl(serviceType, dataType);
  }
  return getUrlByDataType.get(dataType)(serviceType);
};