import { Map as ImmutableMap } from 'immutable';
import { RECORD_FORMAT_TYPES } from './formatter/FormatTypes';
import PropertyRecordToDSFieldRecord from './formatter/PropertyRecordToDSFieldRecord';
import ReferenceRecordToDSFieldRecord from './formatter/ReferenceRecordToDSFieldRecord';
import invariant from 'react-utils/invariant';
const convertDataToDSFieldRecord = (format, assetFamily, data) => {
  if (format === RECORD_FORMAT_TYPES.REFERENCE_RECORD) {
    // type casting since ReferenceRecordToDSFieldRecord isn't fully typed
    return ReferenceRecordToDSFieldRecord(assetFamily, data);
  } else if (format === RECORD_FORMAT_TYPES.PROPERTY_RECORD) {
    // type casting since PropertyRecordToDSFieldRecord isn't fully typed
    return PropertyRecordToDSFieldRecord(assetFamily, data);
  }
  invariant(true, `Format ${format} not supported by toDSFieldRecord formatter`);
  return ImmutableMap();
};
export const toDSFieldRecord = (data, assetFamily, format = RECORD_FORMAT_TYPES.REFERENCE_RECORD) => convertDataToDSFieldRecord(format, assetFamily, data);