'use es6';

import { List } from 'immutable';
import zeroFill from '../../../../lib/zeroFill';
import { matchCreated } from '../../../../configure/bucket/created';
import { sortByDisplayOrder } from '../../../../dataset/display-order';
import { sortBySecondDatetime } from '../../../../dataset/second-datetime';
import * as preconditions from '../preconditions';
import transformZeroDimensionalData from './zeroDimension';
import transformOneDimensionalData from './oneDimension';
import transformTwoDimensionalData from './twoDimension';
import * as dealprogress from '../../../../configure/bucket/dealprogress';
const restoreBucketProperty = (config, response, properties) => {
  const dimensions = config.get('dimensions');
  const [first, second] = dimensions.map(dimension => properties.hasIn([dimension, 'property']));
  return response.update('aggregations', original => {
    let updated = original;
    if (first) {
      updated = updated.mapKeys(() => dimensions.first());
    }
    if (second) {
      updated = updated.update(dimensions.first(), subaggregations => subaggregations.map(subaggregation => subaggregation.update('aggregations', aggregations => aggregations.mapKeys(() => dimensions.get(1)))));
    }
    return updated;
  });
};
const baseTransform = (config, response, properties) => {
  const dimensionality = config.get('dimensions').size;
  switch (dimensionality) {
    case 0:
      return transformZeroDimensionalData(config, response, properties);
    case 1:
      return transformOneDimensionalData(config, response);
    case 2:
      return transformTwoDimensionalData(config, response);
    default:
      throw new Error(`expected valid dimensionality, but got ${dimensionality}`);
  }
};
const paginate = (config, dataset) => {
  if (config.getIn(['dimensions', 0]) === dealprogress.SCRIPTED) {
    return dataset;
  }
  const offset = config.get('offset') || 0;
  const limit = config.get('limit');
  return dataset.updateIn(['dimension', 'buckets'], List(), buckets => limit ? buckets.skip(offset).take(limit) : buckets);
};
const shouldFill = (config, properties) => {
  const firstDimension = config.getIn(['dimensions', 0], List());
  return ['date', 'datetime'].includes(properties.getIn([firstDimension, 'type']));
};
export default (propertiesByDataType => (config, response) => {
  preconditions.transform(response);
  if (matchCreated(config)) {
    config = config.set('dimensions', List());
  }
  const dataType = config.get('dataType');
  const properties = propertiesByDataType.get(dataType);
  const restored = restoreBucketProperty(config, response, properties);
  const transformed = baseTransform(config, restored, properties);
  const filled = shouldFill(config, properties) ? zeroFill(config, transformed) : transformed;
  const sorted = [sortByDisplayOrder, sortBySecondDatetime].reduce((processed, transformer) => transformer(config, propertiesByDataType, processed), filled);
  return paginate(config, sorted);
});