import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["report"];
import Raven from 'raven-js';
import { getReportType } from '../tsTypes/reportTypes';
import { LogLevel } from '../tsTypes/loggerTypes';
const formatExtraData = (extra = {}) => {
  const {
      report
    } = extra,
    rest = _objectWithoutPropertiesLoose(extra, _excluded);
  let reportAttributes = {};
  if (report) {
    const reportJSON = report.toJS();
    const {
      id,
      name,
      config,
      chartType,
      reportDefinition,
      journeyQuery,
      displayParams
    } = reportJSON;
    reportAttributes = id ? {
      id,
      name
    } : {};
    reportAttributes = Object.assign({}, reportAttributes, {
      reportType: getReportType(report),
      displayParams
    });
    if (config) {
      reportAttributes = Object.assign({}, reportAttributes, {
        config,
        chartType
      });
    } else if (reportDefinition) {
      reportAttributes = Object.assign({}, reportAttributes, {
        reportDefinition
      });
    } else if (journeyQuery) {
      reportAttributes = Object.assign({}, reportAttributes, {
        journeyQuery
      });
    }
  }
  return Object.assign({}, rest, reportAttributes);
};
const getAdditionalTags = formattedData => {
  const {
    reportType
  } = formattedData;
  return reportType ? {
    reportType
  } : {};
};

/**
 * @description
 * Report a message to sentry
 * Usage details: https://docs.sentry.io/platforms/javascript/legacy-sdk/usage/
 *
 * @param message - Message. Note: message string is used to group/index events.  Message should be static and dynamic data can be added to the tags or the extra object.
 * @param fingerprint - A list of strings used to determine the deduplication key for this event.
 *  By default Sentry will try to determine this itself by looking at the stacktrace and message of the error.
 *  Example: ['ReportMigration', 'Message']
 *  More context: https://blog.sentry.io/2018/01/18/setting-up-custom-fingerprints
 * @param tags - A list of tags to attach to the event. Tags are key/value pairs stored with an event.
 *  You can search and aggregate events based on tags. Context: https://docs.sentry.io/platforms/javascript/enriching-events/tags/
 * @param extra - A list of additional data to send with the event. Context: https://docs.sentry.io/platforms/javascript/enriching-events/context/
 * @param level - The level of the event. Default: info.
 * @example
 * logMessage({
 *  message: 'Report is missing required field',
 *  fingerprint: ['ReportMigration', 'MissingRequiredField'],
 *  extra: { report, missingField: 'name' },
 * });
 */
export const logMessage = ({
  message,
  fingerprint,
  tags = {},
  extra = {},
  level = LogLevel.info
}) => {
  const formattedExtra = formatExtraData(extra);
  Raven.captureMessage(message, {
    fingerprint,
    tags: Object.assign({}, tags, getAdditionalTags(formattedExtra)),
    extra: formattedExtra,
    level
  });
};

/**
 * @description
 * Report an error to sentry
 * Usage details: https://docs.sentry.io/platforms/javascript/legacy-sdk/usage/
 *
 * @param error - The error to report
 * @param fingerprint - A list of strings used to determine the deduplication key for this event.
 *  By default Sentry will try to determine this itself by looking at the stacktrace and message of the error.
 *  Example: ['ReportMigration', 'Message']
 *  More context: https://blog.sentry.io/2018/01/18/setting-up-custom-fingerprints
 * @param tags - A list of tags to attach to the event. Tags are key/value pairs stored with an event.
 *  You can search and aggregate events based on tags. Context: https://docs.sentry.io/platforms/javascript/enriching-events/tags/
 * @param extra - A list of additional data to send with the event. Context: https://docs.sentry.io/platforms/javascript/enriching-events/context/
 * @param level - The level of the event. Default: error.
 *
 * @returns {string} Sentry event id
 *
 * @example
 * logError({
 *  error: new Error('Report is missing required field'),
 *  fingerprint: ['ReportMigration', 'MissingRequiredField'],
 *  extra: { report, missingField: 'name' },
 * });
 */
const DEFAULT_LOG_LEVEL = LogLevel.error;
export const logError = ({
  error,
  fingerprint,
  tags = {},
  extra = {},
  level
}) => {
  const formattedExtra = formatExtraData(extra);
  const logLevel = level || (error === null || error === void 0 ? void 0 : error.logLevel) || DEFAULT_LOG_LEVEL;
  Raven.captureException(error, {
    fingerprint,
    tags: Object.assign({}, tags, getAdditionalTags(formattedExtra)),
    extra: formattedExtra,
    level: logLevel
  });
  const lastEventId = Raven.lastEventId();
  console.error(`(Event Id: ${lastEventId})\nError reported with '${error.message}'`);
  return lastEventId;
};