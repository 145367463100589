import { useState } from 'react';
import { MANAGE_SAVED_FILTER_TABS, SAVED_DASHBOARD_PANEL_VIEW } from './types';
export const useDashboardFiltersPanelContextState = dashboard => {
  const dashboardId = dashboard.get('id');
  const [manageFilterTab, setManageFilterTab] = useState(MANAGE_SAVED_FILTER_TABS.APPLIED_FILTERS_LIST);
  const [editingFilterIdx, setEditingFilterIdx] = useState();
  const [selectedPropertyInfo, setSelectedPropertyInfo] = useState();
  const [view, setView] = useState(SAVED_DASHBOARD_PANEL_VIEW.MANAGE);
  const toEditAppliedFilterOperatorView = filterIdx => {
    setEditingFilterIdx(filterIdx);
    setView(SAVED_DASHBOARD_PANEL_VIEW.EDIT_APPLIED_FILTER_OPERATOR);
  };
  const toManageTabbedView = (tab = MANAGE_SAVED_FILTER_TABS.APPLIED_FILTERS_LIST) => {
    setManageFilterTab(tab);
    setEditingFilterIdx(undefined);
    setSelectedPropertyInfo(undefined);
    setView(SAVED_DASHBOARD_PANEL_VIEW.MANAGE);
  };
  const toAddAppliedFilterView = propertyInfo => {
    setSelectedPropertyInfo(propertyInfo);
    setView(SAVED_DASHBOARD_PANEL_VIEW.ADD_APPLIED_FILTER);
  };
  const toAppliedFilterPropertySelectView = (props = {}) => {
    if ('propertyInfo' in props) {
      setSelectedPropertyInfo(props.propertyInfo);
    }
    if ('filterIdx' in props) {
      setEditingFilterIdx(props.filterIdx);
    }
    setView(SAVED_DASHBOARD_PANEL_VIEW.APPLIED_FILTER_PROPERTY_SELECT);
  };
  const toAddQuickFilterView = () => {
    setView(SAVED_DASHBOARD_PANEL_VIEW.ADD_QUICK_FILTER);
  };
  const toQuickFilterFormView = () => {
    setView(SAVED_DASHBOARD_PANEL_VIEW.QUICK_FILTER_FORM_VIEW);
  };
  return {
    dashboard,
    dashboardId,
    activePanelTab: manageFilterTab,
    view,
    editingFilterIdx,
    selectedPropertyInfo,
    toEditAppliedFilterOperatorView,
    toManageTabbedView,
    toAddAppliedFilterView,
    toAppliedFilterPropertySelectView,
    toAddQuickFilterView,
    toQuickFilterFormView
  };
};