'use es6';

const sortDatasetMetrics = (dataset, comparator) => {
  dataset = dataset.get('metrics') ? dataset.update('metrics', metrics => metrics.sortBy((value, key) => key, comparator)) : dataset;
  return dataset.get('dimension') ? dataset.updateIn(['dimension', 'buckets'], buckets => buckets.map(bucket => sortDatasetMetrics(bucket, comparator))) : dataset;
};
export const orderMetrics = (config, dataset) => {
  const ordering = config.get('metrics').toOrderedMap().mapEntries(([index, metric]) => [metric.get('property'), index]);
  const comparator = (a, b) => ordering.get(a) - ordering.get(b);
  return sortDatasetMetrics(dataset, comparator);
};