import httpClient from 'hub-http/clients/apiClient';
import { createRpcClientV2 } from 'rpc-client-utils';
import { HAS_FREE_OVERRIDE, HAS_PAID_OVERRIDE } from 'ui-addon-upgrades/_core/utils/commMethodOverrides';
import { getOwnedProductsRpc } from 'growth-monetization-service-types';
export const getIsFreePortalAndOwnedProducts = () => {
  if (HAS_FREE_OVERRIDE) {
    return Promise.resolve({
      isFreePortal: true,
      ownedProducts: []
    });
  }
  if (HAS_PAID_OVERRIDE) {
    return Promise.resolve({
      isFreePortal: false,
      ownedProducts: []
    });
  }
  const rpcClient = createRpcClientV2({
    hubHttpClient: httpClient
  });
  return rpcClient.call(getOwnedProductsRpc, {}).then(ownedProductsResponse => {
    const {
      recurringProducts
    } = ownedProductsResponse;
    const isFreePortal = recurringProducts.length === 0;
    return {
      isFreePortal,
      ownedProducts: recurringProducts
    };
  }).catch(() => {
    return {
      isFreePortal: true,
      ownedProducts: []
    };
  });
};