export const EXTERNAL_PROVIDER = {
  AIRCALL: 'aircall',
  AIRTABLE: 'airtable',
  AMPLITUDE: 'amplitude',
  DATABOX: 'databox',
  DOMO: 'domo',
  EXCEL: 'microsoft-excel',
  GOOGLE_CAL: 'google-calendar',
  GOOGLE_DATA_STUDIO: 'google-data-studio',
  GOOGLE_DOCS: 'google-docs',
  GOOGLE_DRIVE: 'google-drive-folder',
  GOOGLE_FORMS: 'google-forms',
  GOOGLE_SHEETS: 'google-sheets',
  GOOGLE_SLIDES: 'google-slides',
  HUBSPOT_APP: 'hubspot-app',
  IMPULSE_CREATIVE: 'impulse-creative',
  JOTFORM: 'jotform',
  KEYNOTE: 'keynote',
  LOOKER: 'looker',
  LOOM: 'loom',
  MAPSLY: 'mapsly',
  MS_WORD: 'microsoft-word',
  ONEDRIVE: 'onedrive',
  POWER_BI: 'power-bi',
  POWERPOINT: 'microsoft-powerpoint',
  SENDINBLUE: 'sendinblue',
  SHAREPOINT: 'sharepoint',
  SLIDESHARE: 'slideshare',
  TABLEAU: 'tableau',
  VIDYARD: 'vidyard',
  WHATAGRAPH: 'whatagraph',
  YOUTUBE: 'youtube',
  ZOHO: 'zoho',
  PLANIT: 'planit',
  WHALY: 'whaly',
  POSTIT: 'postit',
  CALLBOXINC: 'callboxinc',
  KLUE: 'klue',
  PITCHLY: 'pitchly'
};
// Update this wiki post whenever changes are made to the below list:
// https://wiki.hubspotcentral.net/pages/viewpage.action?pageId=417764224#AddingExternalContenttoaDashboardTips&Resources-Contentprovidersthathaveadditionalpermissions
export const TRUSTED_PROVIDERS = Object.values(EXTERNAL_PROVIDER);