'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { TIME_SERIES } from '../constants/configTypes';
import { SUM, COUNT } from '../constants/metricTypes';
const getSize = (config, property) => {
  return config.get(property, List()).size || config.get(property, []).length;
};
const validate = config => {
  const configType = config.get('configType');
  if (configType !== TIME_SERIES) {
    console.error('accumulate only supported for configType TIME_SERIES but %s was found', configType);
    return false;
  }
  const dimensionsSize = getSize(config, 'dimensions');
  const metricSize = getSize(config, 'metrics');
  if (!(dimensionsSize === 1 || metricSize === 1)) {
    console.error('accumulate not supported for %s-dimensional data with %s metrics', dimensionsSize, metricSize);
    return false;
  }
  return true;
};
const hasValidMetric = (metric, key) => {
  return (key === SUM || key === COUNT) && typeof metric.get(key) === 'number';
};
const accumulate = data => {
  return data.updateIn(['dimension', 'buckets'], (buckets = ImmutableMap()) => {
    let accumulated = ImmutableMap();
    return buckets.map(bucket => {
      if (bucket.has('dimension')) {
        /*
          data has 2 dimensions, 1 metric
          accumulate using sub-dimension as key
        */
        return bucket.updateIn(['dimension', 'buckets'], subBuckets => {
          return subBuckets.map(subBucket => {
            const key = subBucket.get('key');
            return subBucket.update('metrics', metrics => {
              return metrics.map(metric => {
                const metricType = metric.keySeq().first();
                return hasValidMetric(metric, metricType) ? metric.update(metricType, value => {
                  if (accumulated.has(key)) {
                    const sum = accumulated.get(key) + value;
                    accumulated = accumulated.set(key, sum);
                    return sum;
                  } else {
                    accumulated = accumulated.set(key, value);
                    return value;
                  }
                }) : metric;
              });
            });
          });
        });
      } else {
        /*
          data has 1 dimension, 1 or more metrics
          accumulate using metric as key
        */
        return bucket.update('metrics', metrics => {
          return metrics.map((metric, key) => {
            const metricType = metric.keySeq().first();
            return hasValidMetric(metric, metricType) ? metric.update(metricType, value => {
              if (accumulated.has(key)) {
                const sum = accumulated.get(key) + value;
                accumulated = accumulated.set(key, sum);
                return sum;
              } else {
                accumulated = accumulated.set(key, value);
                return value;
              }
            }) : metric;
          });
        });
      }
    });
  });
};
export default (({
  dataConfig,
  dataset
}) => {
  return validate(dataConfig) ? accumulate(dataset) : dataset;
});