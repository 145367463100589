import { CURRENT_ENV } from './Env';

// Apps for testing
export const INTEGRATIONS_DEMO = {
  NETSUITE_DEMO: 'NETSUITE_DEMO'
  // DEVHUBTESTAPP: 'DEVHUBTESTAPP',
  // DEMO_APP: 'DEMO_APP',
};

// DataSync Apps
export const INTEGRATIONS_DATA_SYNC = {
  MSDYNAMICS: 'MSDYNAMICS',
  NETSUITE: 'NETSUITE',
  SALESLOFT: 'SALESLOFT',
  ZOHOCRM: 'ZOHOCRM',
  SHOPIFY_DATA_SYNC: 'SHOPIFY_DATA_SYNC',
  QUICKBOOKS_DATA_SYNC: 'QUICKBOOKS_DATA_SYNC',
  SALESFORCE_DATA_SYNC: 'SALESFORCE_DATA_SYNC'
};

// Apps
export const INTEGRATIONS = {
  ADOBE_SIGN: 'ADOBE_SIGN',
  ASANA: 'ASANA',
  BYO_STRIPE: 'BYO_STRIPE',
  DOCUSIGN: 'DOCUSIGN',
  FACEBOOK_MESSENGER: 'FACEBOOK_MESSENGER',
  FACEBOOK_WORKPLACE: 'FACEBOOK_WORKPLACE',
  GITHUB: 'GITHUB',
  GO_TO_WEBINAR: 'GO_TO_WEBINAR',
  GOOGLE_CALENDAR: 'GOOGLE_CALENDAR',
  GOOGLE_SEARCH_CONSOLE: 'GOOGLE_SEARCH_CONSOLE',
  GOOGLE_SHEETS: 'GOOGLE_SHEETS',
  JIRA: 'JIRA',
  LINKEDIN_SALES_NAVIGATOR: 'LINKEDIN_SALES_NAVIGATOR',
  MAILCHIMP: 'MAILCHIMP',
  MS_TEAMS: 'MS_TEAMS',
  OPENAI: 'OPENAI',
  QUICKBOOKS: 'QUICKBOOKS',
  SALESFORCE: 'SALESFORCE',
  SHOPIFY: 'SHOPIFY',
  SLACK: 'SLACK',
  STRIPE: 'STRIPE',
  TEACHABLE: 'TEACHABLE',
  TODOIST: 'TODOIST'
  // ACCEL_EVENTS: 'ACCEL_EVENTS',
  // AIRMEET: 'AIRMEET',
  // AIRTABLE_INTEGRATION: 'AIRTABLE_INTEGRATION',
  // BEVY: 'BEVY',
  // CED_COMMERCE: 'CED_COMMERCE',
  // EVENT_REGIST: 'EVENT_REGIST',
  // EVENTBRITE: 'EVENTBRITE',
  // E_WEBINAR: 'E_WEBINAR',
  // GOOGLE_ASSISTANT: 'GOOGLE_ASSISTANT',
  // HOPIN: 'HOPIN',
  // LINKED_IN: 'LINKED_IN',
  // MAKE_WEB_BETTER: 'MAKE_WEB_BETTER',
  // OFFICE365: 'OFFICE365',
  // ON_24: 'ON_24',
  // ONE_SIGNAL: 'ONE_SIGNAL',
  // PRESTASHOP: 'PRESTASHOP',
  // SANSAN: 'SANSAN',
  // SURVEY_MONKEY: 'SURVEY_MONKEY',
  // TRELLO: 'TRELLO',
  // UBER_CONFERENCE: 'UBER_CONFERENCE',
  // UNIFIC: 'UNIFIC',
  // WORDPRESS: 'WORDPRESS',
  // ZOOM: 'ZOOM',
  // OKTA_SCIM: 'OKTA_SCIM',
  // INWINK: 'INWINK',
  // GOLDCAST_EVENTS: 'GOLDCAST_EVENTS',
  // HUMANITIX: 'HUMANITIX',
  // WEBINARGEEK: 'WEBINARGEEK',
  // ICAPTURE: 'ICAPTURE',
  // TWENTYTHREE: 'TWENTYTHREE',
  // CVENT: 'CVENT',
  // LIVEWEBINAR: 'LIVEWEBINAR',
};
export const INTEGRATIONS_APPS = Object.assign({}, INTEGRATIONS, INTEGRATIONS_DATA_SYNC, INTEGRATIONS_DEMO);
// TODO: add offering ID to IntegrationsAppsData model
export const INTEGRATIONS_APPS_DATA = {
  [INTEGRATIONS_APPS.MSDYNAMICS]: {
    displayName: 'Microsoft Dynamics',
    appIds: {
      QA: 1208252,
      PROD: 235999
    }
  },
  [INTEGRATIONS_APPS.NETSUITE]: {
    displayName: 'NetSuite',
    appIds: {
      QA: 1204058,
      PROD: 226318
    }
  },
  [INTEGRATIONS_APPS.SALESLOFT]: {
    displayName: 'SalesLoft',
    appIds: {
      QA: 1208255,
      PROD: 236003
    }
  },
  [INTEGRATIONS_APPS.ZOHOCRM]: {
    displayName: 'Zoho CRM',
    appIds: {
      QA: 1208260,
      PROD: 236009
    }
  },
  [INTEGRATIONS_APPS.SALESFORCE]: {
    displayName: 'Salesforce',
    appIds: {
      QA: 1160722,
      PROD: 38577
    }
  },
  [INTEGRATIONS_APPS.SALESFORCE_DATA_SYNC]: {
    displayName: 'Salesforce',
    appIds: {
      QA: 5840412,
      PROD: 2007421
    }
  },
  [INTEGRATIONS_APPS.SLACK]: {
    displayName: 'Slack',
    appIds: {
      QA: 1162214,
      PROD: 60729
    }
  },
  [INTEGRATIONS_APPS.MS_TEAMS]: {
    displayName: 'Microsoft Teams',
    appIds: {
      QA: 1202021,
      PROD: 221635
    }
  },
  [INTEGRATIONS_APPS.SHOPIFY]: {
    displayName: 'Shopify',
    appIds: {
      QA: 1161155,
      PROD: 51381
    }
  },
  [INTEGRATIONS_APPS.SHOPIFY_DATA_SYNC]: {
    displayName: 'Shopify',
    appIds: {
      QA: 1920453,
      PROD: 542084
    }
  },
  [INTEGRATIONS_APPS.QUICKBOOKS]: {
    displayName: 'QuickBooks Online',
    appIds: {
      QA: 1165378,
      PROD: 188574
    }
  },
  [INTEGRATIONS_APPS.QUICKBOOKS_DATA_SYNC]: {
    displayName: 'QuickBooks Online',
    appIds: {
      QA: 1920452,
      PROD: 542129
    }
  },
  [INTEGRATIONS_APPS.ADOBE_SIGN]: {
    displayName: 'Adobe Sign',
    appIds: {
      QA: 10021866,
      PROD: 3560422
    }
  },
  [INTEGRATIONS_APPS.DOCUSIGN]: {
    displayName: 'DocuSign',
    appIds: {
      QA: 1203058,
      PROD: 224321
    }
  },
  [INTEGRATIONS_APPS.FACEBOOK_MESSENGER]: {
    displayName: 'Facebook Messenger',
    appIds: {
      QA: 1174892,
      PROD: 204269
    }
  },
  // Acceptance Testing Demo NetSute App
  [INTEGRATIONS_APPS.NETSUITE_DEMO]: {
    displayName: 'NetSuite (Demo)',
    appIds: {
      QA: 1205036,
      PROD: 362342
    }
  },
  // integrations from disconnectionConfigs, TODO:need to update displayNames
  [INTEGRATIONS.ASANA]: {
    displayName: '',
    appIds: {
      QA: 1163608,
      PROD: 188672
    }
  },
  [INTEGRATIONS.GOOGLE_SHEETS]: {
    displayName: '',
    appIds: {
      QA: 1265780,
      PROD: 228553
    }
  },
  [INTEGRATIONS.GOOGLE_CALENDAR]: {
    displayName: '',
    appIds: {
      QA: 1160472,
      PROD: 35224
    }
  },
  [INTEGRATIONS.GOOGLE_SEARCH_CONSOLE]: {
    displayName: '',
    appIds: {
      QA: 1206938,
      PROD: 178446
    }
  },
  [INTEGRATIONS.GO_TO_WEBINAR]: {
    displayName: '',
    appIds: {
      QA: 1158066,
      PROD: 35161
    }
  },
  [INTEGRATIONS.JIRA]: {
    displayName: '',
    appIds: {
      QA: 1175189,
      PROD: 205407
    }
  },
  [INTEGRATIONS.OPENAI]: {
    displayName: '',
    appIds: {
      QA: 14310395,
      PROD: 6922949
    }
  },
  [INTEGRATIONS.BYO_STRIPE]: {
    displayName: '',
    appIds: {
      QA: 4985279,
      PROD: 362340
    }
  },
  [INTEGRATIONS.STRIPE]: {
    displayName: '',
    appIds: {
      QA: 1162443,
      PROD: 63044
    }
  },
  [INTEGRATIONS.GITHUB]: {
    displayName: '',
    appIds: {
      QA: 3044142,
      PROD: 3044142
    }
  },
  [INTEGRATIONS.LINKEDIN_SALES_NAVIGATOR]: {
    displayName: '',
    appIds: {
      QA: 1161054,
      PROD: 42082
    }
  },
  [INTEGRATIONS.MAILCHIMP]: {
    displayName: '',
    appIds: {
      QA: 1164505,
      PROD: 56440
    }
  },
  [INTEGRATIONS.TODOIST]: {
    displayName: '',
    appIds: {
      QA: 2600038,
      PROD: 832518
    }
  },
  [INTEGRATIONS.TEACHABLE]: {
    displayName: '',
    appIds: {
      QA: 3076130,
      PROD: 1089396
    }
  },
  [INTEGRATIONS.FACEBOOK_WORKPLACE]: {
    displayName: '',
    appIds: {
      QA: 1162434,
      PROD: 62823
    }
  }
};
const appNameByAppIdMap = Object.keys(INTEGRATIONS_APPS_DATA).reduce((result, name) => Object.assign({}, result, {
  [INTEGRATIONS_APPS_DATA[name].appIds[CURRENT_ENV]]: name
}), {});
export const getAppIdByAppName = appName => INTEGRATIONS_APPS_DATA[appName].appIds[CURRENT_ENV];
export const getAppDataByAppName = appName => INTEGRATIONS_APPS_DATA[appName];
export const getAppDataByAppId = appId => INTEGRATIONS_APPS_DATA[appNameByAppIdMap[appId]];
export const getAppNameByAppId = appId => appNameByAppIdMap[appId];
export const getProdAppIdByQaAppId = qaAppId => {
  const app = Object.keys(INTEGRATIONS_APPS_DATA).find(appName => INTEGRATIONS_APPS_DATA[appName].appIds.QA === qaAppId);
  if (!app) {
    return undefined;
  }
  return INTEGRATIONS_APPS_DATA[app].appIds.PROD;
};