import { useQuery, registerQuery } from 'data-fetching-client';
import { httpClientWithRaven } from '../../clients/hubHttpWithRaven';
import { useAuth } from '../../hooks/useAuth';
import { CONTENT_MIGRATIONS_STATUS_VIEW_SCOPE } from '../../constants/scopes.constants';
const GET_MIGRATIONS = registerQuery({
  fetcher() {
    return httpClientWithRaven.get('service-migrations-external/v1/customers/migrations', {
      ravenOptions: {
        message: 'Error fetching migrations'
      }
    });
  },
  args: [],
  fieldName: 'currentMigrations'
});
export const useGetMigrations = userInfo => {
  const {
    hasScope
  } = useAuth(userInfo);
  const hasMigrationsViewAccess = hasScope(CONTENT_MIGRATIONS_STATUS_VIEW_SCOPE);
  const {
    loading,
    data,
    error
  } = useQuery(GET_MIGRATIONS, {
    skip: !hasMigrationsViewAccess
  });
  return {
    loading,
    data: (data === null || data === void 0 ? void 0 : data.currentMigrations) || [],
    error
  };
};