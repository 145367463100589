'use es6';

import { OrderedMap } from 'immutable';
import { backfillStagedEncodingsAndOptions, convertStagedFieldsIntoColumns, fixFiltersOnDealCloseDateTreatedAsNumbers, switchToFullyQualifiedName, updateAssociationLabelPropertyName } from './migrate';
import deprecations from './deprecate';
import { getReportDefinition } from 'reporting-data/report/reportGetters';
import { ReportingLogger } from 'reporting-data/monitoring/reportingLogger';
const migrationList = OrderedMap({
  // Promises (run first!)
  convertStagedFieldsIntoColumns,
  switchToFullyQualifiedName,
  updateAssociationLabelPropertyName,
  backfillStagedEncodingsAndOptions,
  fixFiltersOnDealCloseDateTreatedAsNumbers,
  adClickedEventDeprecation: deprecations.adClickedEventDeprecation,
  usesCurrentlyInWorkflowProperty: deprecations.usesCurrentlyInWorkflowProperty
});
const repairSnowflakeReport = report => {
  if (!report.has('reportDefinition')) {
    return Promise.resolve(report);
  }
  const reportingLogger = new ReportingLogger();
  reportingLogger.addReportAttributes(report);
  const logMigration = (migrationName, migratedReport) => {
    reportingLogger.addAttribute('reportId', report.get('id'));
    reportingLogger.addAttribute('reportMigration', migrationName);
    reportingLogger.addAttribute('reportDefinitionPostMigration', JSON.stringify(getReportDefinition(migratedReport)));
    reportingLogger.sendPageAction('migrationRun');
  };
  return migrationList.reduce((prev, migration, migrationName) => prev.then(prevReport => Promise.resolve(migration(prevReport)).then(migratedReport => {
    if (!prevReport.equals(migratedReport)) {
      logMigration(migrationName, migratedReport);
    }
    return migratedReport;
  })), Promise.resolve(report));
};
export default repairSnowflakeReport;