'use es6';

import PortalIdParser from 'PortalIdParser';
import { List } from 'immutable';
import { CONTACT_SEARCH_AGGREGATION_MAX_SIZE } from '../../../../constants/limits';
import Request from '../../../../request/Request';
import { getUrl } from '../../common/urls';
import * as preconditions from '../preconditions';
import transform from '../transform';
import payload from './basic-payload';
import { fixProductIdRequest, fixNumericBucketResponse } from './repairs';
import { load } from '../../../../dataTypeDefinitions';
import commonCrmSearchDefinition from '../../../../dataTypeDefinitions/inboundDb/common-crm-search';
import { CRM_SEARCH_DATA_TYPES } from '../../../inboundDb/common/crm-search-data-types';
import * as DataType from '../../../../constants/dataTypes';

// see https://git.hubteam.com/HubSpot/Elsie/pull/162
const enforceLimit = (limit, bucket) => bucket.updateIn(['dimension', 'buckets'], List(), buckets => buckets.take(limit));
const addCustomObjectFields = (spec, config, requestPayload) => {
  const objectTypeId = spec.get('objectTypeId');
  if (!objectTypeId) {
    return requestPayload;
  }
  return requestPayload.set('objectTypeId', objectTypeId).set('portalId', PortalIdParser.get());
};
export default ((config, properties, runtimeOptions) => {
  const dataType = config.get('dataType');
  return Promise.all([load(dataType), preconditions.generate(config)]).then(([moduleDefinition]) => {
    const useCrmSearch = CRM_SEARCH_DATA_TYPES.includes(dataType) || dataType === DataType.LINE_ITEMS || dataType === DataType.FEEDBACK_SUBMISSIONS;
    const spec = useCrmSearch ? commonCrmSearchDefinition.getInboundSpec(config) : moduleDefinition.getInboundSpec(config);
    const limit = config.get('limit') || 0;
    const transformer = limit === 0 || limit > CONTACT_SEARCH_AGGREGATION_MAX_SIZE ? transform(properties) : (...args) => enforceLimit(limit, transform(properties)(...args));
    const repairs = fixProductIdRequest(dataType);
    return Request.post({
      url: getUrl(config, Object.assign({}, runtimeOptions, {
        useCrmSearch
      })),
      data: addCustomObjectFields(spec, config, repairs(payload(config, properties))),
      transformer: (reportConfig, response) => transformer(reportConfig, fixNumericBucketResponse(response, properties.get(dataType)))
    });
  });
});