'use es6';

import { OrderedMap } from 'immutable';
import { metricToCamelCase } from '../../../../lib/metricToCamelCase';
const COUNT = 'count';
export default ((config, breakdown) => {
  const metrics = config.get('metrics');
  const initial = OrderedMap();

  // there may be extra metrics returned in the response
  // so only copy over the metrics that were requested
  return metrics.reduce((bucketMetrics, metric) => {
    const metricProperty = metric.get('property');
    return metric.get('metricTypes').reduce((memo, metricType) => {
      const key = metricToCamelCase(metricType);
      const value = metricProperty === COUNT ? breakdown.get(COUNT) : breakdown.getIn(['metrics', metricProperty, key], null);
      return memo.setIn([metricProperty, metricType], isNaN(value) ? 0 : value);
    }, bucketMetrics);
  }, initial);
});