import { useMemo } from 'react';
import { DataFetchingClient, useQuery } from 'data-fetching-client';
import { GET_FEATURE_USAGE_MAPPINGS_QUERY, GET_FEATURE_USAGE_QUERY } from '../api/featureUsage';
export const useFeatureUsage = (skipRequest = false, client) => {
  var _response$data$getFea, _response$data;
  const dataClient = useMemo(() => client || new DataFetchingClient(), [client]);
  const response = useQuery(GET_FEATURE_USAGE_QUERY, {
    skip: skipRequest,
    client: dataClient
  });
  return Object.assign({}, response, {
    data: (_response$data$getFea = (_response$data = response.data) === null || _response$data === void 0 ? void 0 : _response$data.getFeatureUsage) !== null && _response$data$getFea !== void 0 ? _response$data$getFea : []
  });
};
export const useFeatureUsageMappings = (skipRequest = false, client) => {
  var _response$data$getFea2, _response$data2;
  const dataClient = useMemo(() => client || new DataFetchingClient(), [client]);
  const response = useQuery(GET_FEATURE_USAGE_MAPPINGS_QUERY, {
    skip: skipRequest,
    client: dataClient
  });
  return Object.assign({}, response, {
    data: (_response$data$getFea2 = (_response$data2 = response.data) === null || _response$data2 === void 0 ? void 0 : _response$data2.getFeatureUsageMappings) !== null && _response$data$getFea2 !== void 0 ? _response$data$getFea2 : []
  });
};