'use es6';

import { createSelector } from 'reselect';
import { gate } from 'hub-http/gates';
import { Gates, SUPER_ADMIN } from '../constants/AccessConstants';
export const getUserInfo = state => {
  var _state$auth;
  // state?.userInfo is a fallback for cases where userInfo is at the root of the state (e.g. DashboardUI)
  return (state === null || state === void 0 || (_state$auth = state.auth) === null || _state$auth === void 0 ? void 0 : _state$auth.get('userInfo')) || (state === null || state === void 0 ? void 0 : state.userInfo);
};
export const getGates = createSelector([getUserInfo], userInfo => userInfo.gates);
export const getScopes = createSelector([getUserInfo], userInfo => userInfo.user.scopes);
export const getUserId = createSelector([getUserInfo], userInfo => userInfo.user.id);
export const getUserIsSuperAdmin = createSelector([getScopes], scopes => scopes.includes(SUPER_ADMIN));
export const getIsUngatedToReportSetup = createSelector([getGates], gates => gates.includes(Gates.REPORT_SETUP));
export const getIsUngatedToAIGenerationFlow = createSelector([getGates], gates => gates.includes(Gates.AI_GENERATION_FLOW));
export const getIsUngatedToReportRecommendations = createSelector([getGates], gates => gates.includes(Gates.REPORT_RECOMMENDATIONS));
export const getUserLocale = createSelector([getUserInfo], userInfo => userInfo.user.locale);
export const getIsUngatedForAIModifyReport = createSelector([getGates], gates => gates.includes(Gates.AI_MODIFY_REPORT));
export const getIsUngatedForBreezeAIRebrand = createSelector([getGates], gates => gates.includes(gate('BreezeAI:Rebranding')));
export const getIsUngatedForDataFirstFlow = createSelector([getGates], gates => gates.includes(gate('ReportCreation:DataFirstCreationFlow')));
export const getIsUngatedForLibraryRecommendations = createSelector([getGates], gates => gates.includes(Gates.REPORT_LIBRARY_RECOMMENDATIONS));