'use es6';

import { Map as ImmutableMap } from 'immutable';
import * as DataType from '../../../constants/dataTypes';
import { load } from '../../../dataTypeDefinitions';
import crmSearch from './crm-search/search';
import crossObjectSearch from './cross-object/search';
import engagementSearch from './engagement/search';
import standardSearch from './search';
import commonCrmSearchDefinition from '../../../dataTypeDefinitions/inboundDb/common-crm-search';
import { CRM_SEARCH_DATA_TYPES } from '../common/crm-search-data-types';
const dataTypeToSearchImpl = ImmutableMap({
  [DataType.ATTRIBUTION_TOUCH_POINTS]: crmSearch,
  [DataType.CONTACT_CREATE_ATTRIBUTION]: crmSearch,
  [DataType.FEEDBACK_SUBMISSIONS]: crmSearch,
  [DataType.CROSS_OBJECT]: crossObjectSearch,
  [DataType.CALLS]: engagementSearch,
  [DataType.CONVERSATIONS]: engagementSearch,
  [DataType.ENGAGEMENT_EMAILS]: engagementSearch,
  [DataType.FEEDBACK]: engagementSearch,
  [DataType.MEETINGS]: engagementSearch,
  [DataType.NOTES]: engagementSearch
});
export default function search(config, runtimeOptions) {
  const dataType = config.get('dataType');
  return load(dataType).then(moduleDefinition => {
    if (CRM_SEARCH_DATA_TYPES.includes(dataType) || dataType === DataType.LINE_ITEMS || dataType === DataType.FEEDBACK_SUBMISSIONS) {
      return crmSearch(commonCrmSearchDefinition.getInboundSpec(config), config, runtimeOptions);
    }
    return dataTypeToSearchImpl.get(dataType, standardSearch)(moduleDefinition.getInboundSpec(config), config, runtimeOptions);
  }).then(dataset => ({
    dataConfig: config,
    dataset,
    response: undefined
  }));
}