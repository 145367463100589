'use es6';

// WARNING: DO NOT MODIFY
// Script copied from Fullstory App.
// reach out to mcondit@hubspot.com for more info and for guidance on modifying this script safely
/*
Rough Guidelines for updating this:
- Copy the FS script from the FS App UI
- Re-add any eslint ignores that are needed, do not modify the code itself
- Re-add the onError handler for script tag load failure reporting
 */
export function mountFullstory() {
  window['_fs_host'] = 'fullstory.com';
  window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
  window['_fs_org'] = 'o-1HPD5N-na1';
  window['_fs_namespace'] = 'FS';
  (function (m, n, e, t, l, o, g, y) {
    if (e in m) {
      if (m.console && m.console.log) {
        m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
      }
      return;
    }
    g = m[e] = function (a, b, s) {
      // eslint-disable-next-line no-unused-expressions
      g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
    };
    g.q = [];
    o = n.createElement(t);
    o.async = 1;
    o.crossOrigin = 'anonymous';
    // eslint-disable-next-line prefer-template, no-undef
    o.src = 'https://' + _fs_script;
    // Add error handling for script load failure
    o.onerror = function (error) {
      // Call the error handler if it exists
      if (typeof m._fs_on_error === 'function') {
        m._fs_on_error(error);
      }
    };
    y = n.getElementsByTagName(t)[0];
    y.parentNode.insertBefore(o, y);
    g.identify = function (i, v, s) {
      g(l, {
        uid: i
      }, s);
      if (v) g(l, v, s);
    };
    g.setUserVars = function (v, s) {
      g(l, v, s);
    };
    g.event = function (i, v, s) {
      g('event', {
        n: i,
        p: v
      }, s);
    };
    g.anonymize = function () {
      g.identify(!!0);
    };
    g.shutdown = function () {
      g('rec', !1);
    };
    g.restart = function () {
      g('rec', !0);
    };
    g.log = function (a, b) {
      g('log', [a, b]);
    };
    g.consent = function (a) {
      g('consent', !arguments.length || a);
    };
    g.identifyAccount = function (i, v) {
      o = 'account';
      v = v || {};
      v.acctId = i;
      g(o, v);
    };
    g.clearUserCookie = function () {};
    // eslint-disable-next-line no-shadow
    g.setVars = function (n, p) {
      g('setVars', [n, p]);
    };
    g._w = {};
    y = 'XMLHttpRequest';
    g._w[y] = m[y];
    y = 'fetch';
    g._w[y] = m[y];
    if (m[y]) m[y] = function () {
      // eslint-disable-next-line prefer-rest-params
      return g._w[y].apply(this, arguments);
    };
    g._v = '1.3.0';
  })(window, document, window['_fs_namespace'], 'script', 'user');
}