'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["operator", "property"];
import * as Operators from '../../../constants/operators';
import * as depth from '../depth';
import I18n from 'I18n';
import { gate } from 'hub-http/gates';
const ueStringMatchGate = gate('ReportingViz:UeStringMatch');
export const get = (spec, config, gates = []) => {
  const {
    filters: {
      custom = []
    } = {},
    metrics = [],
    sort = []
  } = config;
  const {
    metadata = {}
  } = spec;
  const properties = Object.keys(metadata);
  if (properties.length === 0) {
    return {
      useForeignIndex: false
    };
  }
  const {
    drilldown = {}
  } = depth.get(spec, config);
  const reducer = (foreign, {
    property
  }) => foreign || properties.includes(property);
  const required = [sort, metrics, custom].reduce((memo, list) => memo || list.reduce(reducer, false), drilldown.metadata != null);
  if (!required) {
    return {
      useForeignIndex: false
    };
  }
  const type = property => typeof metadata[property] === 'string' ? metadata[property] : 'enumeration';
  const fixDateValues = property => value => type(property) === 'datetime' ? I18n.moment(value).format('x') : value;
  const mapFilterToParams = _ref => {
    let {
        operator,
        property
      } = _ref,
      filterValues = _objectWithoutPropertiesLoose(_ref, _excluded);
    if (!properties.includes(property)) {
      return {};
    }
    const formatValue = fixDateValues(property);
    const value = formatValue(filterValues.value);
    const highValue = formatValue(filterValues.highValue);
    const values = filterValues.values && filterValues.values.map(formatValue);
    const valuesList = value !== null && value !== undefined ? [value] : values;
    switch (operator) {
      case Operators.BETWEEN:
        return {
          [`metadata-${property}-min`]: value,
          [`metadata-${property}-max`]: highValue
        };
      case Operators.GT:
      case Operators.GTE:
        return {
          [`metadata-${property}-min`]: valuesList
        };
      case Operators.LT:
      case Operators.LTE:
        return {
          [`metadata-${property}-max`]: valuesList
        };
      default:
        // if ueStringMatchGate is true and if property is string, return exact match comparison
        // if property is string and ueStringMatchGate is false, return contains comparison
        // if property is not string, return exact match comparison
        if (type(property) === 'string' && gates.includes(ueStringMatchGate)) {
          return {
            [`metadata-${property}`]: valuesList
          };
        }
        return type(property) === 'string' ? {
          [`metadata-${property}-contains`]: valuesList
        } : {
          [`metadata-${property}`]: valuesList
        };
    }
  };
  const filters = custom.reduce((acc, filter) => {
    return Object.assign({}, acc, mapFilterToParams(filter));
  }, {});
  return Object.assign({
    useForeignIndex: true
  }, filters);
};