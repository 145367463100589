'use es6';

import { QUOTAS } from '../../../constants/dataTypes';
import { mergeDatasets } from '../../../dataset/mergeDatasets';
import { orderMetrics } from '../../../dataset/orderMetrics';
import { retrieve as baseRetrieve } from '../../baseRetrieve';
import { getQuotaData } from './getQuotaData';
const QUOTA_NAMESPACE = 'QUOTAS';
const getQuotaMetrics = config => config.get('metrics').filter(metric => metric.get('property', '').startsWith(`${QUOTA_NAMESPACE}.`));
export default {
  match: dataConfig => dataConfig.get('dataType') !== QUOTAS && getQuotaMetrics(dataConfig).count() > 0,
  retrieve: (dataConfig, debug, runtimeOptions) => {
    const primaryConfig = dataConfig.update('metrics', metrics => metrics.filterNot(metric => metric.get('property', '').startsWith(`${QUOTA_NAMESPACE}.`)));
    return baseRetrieve(primaryConfig, debug, runtimeOptions).then(({
      dataset,
      response
    }) => {
      const quotaMetrics = getQuotaMetrics(dataConfig);
      return Promise.all(quotaMetrics.map(quotaMetric => getQuotaData({
        dataConfig,
        primaryConfig,
        dataset,
        quotaMetric
      }))).then(results => ({
        dataConfig,
        dataset: orderMetrics(dataConfig, mergeDatasets(results.reduce((combinedDataset, quotaDataset) => mergeDatasets(combinedDataset, quotaDataset)), dataset)),
        response
      }));
    });
  }
};