import { isConfigReport } from 'reporting-data/tsTypes/reportTypes';
import { getReportConfig } from 'reporting-data/report/configReportGetters';
import { getReportChartType } from 'reporting-data/report/reportGetters';
import { TABLE } from 'reporting-data/constants/chartTypes';
//@ts-expect-error Untyped import
import { isComparisonConfig } from 'reporting-data/compare';
export const fixTableCompare = report => {
  let newReport = report;

  // if chartType = table, there should be no 'compare' field
  if (isConfigReport(report) && isComparisonConfig(getReportConfig(report)) && getReportChartType(report) === TABLE) {
    newReport = report.deleteIn(['config', 'compare']);
  }
  return newReport;
};