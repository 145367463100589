'use es6';

import { fromJS } from 'immutable';
import { LOCK_DOWN_REPORT } from 'reporting-data/constants/deprecate/deprecationTypes';
import withUserInfo from '../lib/withUserInfo';
import { ANALYTICS_FUNNEL } from 'reporting-data/constants/dataTypes';
export const isForbiddenAnalyticsFunnel = (report, userInfo) => {
  const gates = userInfo && userInfo.get('gates');
  const canAccessAnalyticsFunnelReports = gates && gates.includes('Dashboard:AnalyticsAttribution');
  const isAnalyticsFunnelReport = report.getIn(['config', 'dataType']) === ANALYTICS_FUNNEL;
  return isAnalyticsFunnelReport && !canAccessAnalyticsFunnelReports ? report.set('deprecation', fromJS({
    isDeprecated: true,
    type: LOCK_DOWN_REPORT,
    message: 'forbiddenAnalyticsFunnel'
  })) : report;
};
export default withUserInfo(isForbiddenAnalyticsFunnel);