/**
 * IMPORTANT: If you add a new datatype to this file,
 * please add it to the INBOUND_DB_DATA_TYPES list below
 */
export const AI_FORECAST = 'AI_FORECAST';
export const APPOINTMENT = 'APPOINTMENTS';
export const ATTRIBUTION_TOUCH_POINTS = 'ATTRIBUTION_TOUCH_POINTS';
export const CALL = 'CALL';
export const CALLS = 'CALLS';
export const CAMPAIGN_SPEND_ITEM = 'CAMPAIGN_SPEND_ITEM';
export const CARTS = 'CARTS';
export const COMMERCE_PAYMENTS = 'COMMERCE_PAYMENTS';
export const COMPANIES = 'COMPANIES';
export const CONTACTS = 'CONTACTS';
export const CONTACT_CREATE_ATTRIBUTION = 'CONTACT_CREATE_ATTRIBUTION';
export const CONVERSATIONS = 'CONVERSATIONS';
export const COMMUNICATION = 'COMMUNICATION';
export const COURSE = 'COURSES';
export const POSTAL_MAIL = 'POSTAL_MAIL';
export const CROSS_OBJECT = 'CROSS_OBJECT';
export const CRM_OBJECT = 'CRM_OBJECT';
export const DEAL_CREATE_ATTRIBUTION = 'DEAL_CREATE_ATTRIBUTION';
export const DEALS = 'DEALS';
export const DEAL_REGISTRATION = 'DEAL_REGISTRATION';
export const EMAIL_OBJECT = 'EMAIL_OBJECT';
export const ENGAGEMENT_EMAILS = 'ENGAGEMENT_EMAILS';
export const ENGAGEMENT = 'ENGAGEMENT'; // deprecated
export const ENGAGEMENTS = 'ENGAGEMENTS';
export const FEEDBACK = 'FEEDBACK';
export const FEEDBACK_SUBMISSIONS = 'FEEDBACK_SUBMISSIONS';
export const FORECAST = 'FORECAST';
export const GOAL_TARGET = 'GOAL_TARGET';
export const INVOICE = 'INVOICE';
export const LEADS = 'LEADS';
export const LINE_ITEMS = 'LINE_ITEMS';
export const LISTING = 'LISTINGS';
export const MEETING_EVENT = 'MEETING_EVENT';
export const MEETINGS = 'MEETINGS';
export const NOTES = 'NOTES';
export const ORDERS = 'ORDERS';
export const PARTNER_ACCOUNT = 'PARTNER_ACCOUNT';
export const PARTNER_CLIENT = 'PARTNER_CLIENT';
export const PARTNER_SERVICE = 'PARTNER_SERVICE';
export const PLAYBOOK = 'PLAYBOOK';
export const PLAYBOOK_SUBMISSION = 'PLAYBOOK_SUBMISSION';
export const PRODUCTS = 'PRODUCTS';
export const SERVICES = 'SERVICES';
export const SOCIAL_POSTS = 'SOCIAL_POSTS';
export const TASK = 'TASK';
export const TASKS = 'TASKS';
export const TICKETS = 'TICKETS';
export const SEQUENCE_ENROLLMENTS = 'SEQUENCE_ENROLLMENTS';
export const SEQUENCE_STEP_ENROLLMENTS = 'SEQUENCE_STEP_ENROLLMENTS';
export const SUBSCRIPTIONS = 'SUBSCRIPTIONS';
export const CONTRACTS = 'CONTRACTS';
export const USER = 'USER';

/**
 * IMPORTANT, make sure to also update this list when adding a new type in this file
 */
export const INBOUND_DB_DATA_TYPES = [AI_FORECAST, APPOINTMENT, ATTRIBUTION_TOUCH_POINTS, CALL, CALLS, CAMPAIGN_SPEND_ITEM, CARTS, COMMERCE_PAYMENTS, COMPANIES, CONTACTS, CONTACT_CREATE_ATTRIBUTION, CONTRACTS, CONVERSATIONS, COMMUNICATION, COURSE, POSTAL_MAIL, CROSS_OBJECT, CRM_OBJECT, DEAL_CREATE_ATTRIBUTION, DEALS, DEAL_REGISTRATION, EMAIL_OBJECT, ENGAGEMENT_EMAILS, ENGAGEMENT,
// deprecated
ENGAGEMENTS, FEEDBACK, FEEDBACK_SUBMISSIONS, FORECAST, GOAL_TARGET, INVOICE, LEADS, LINE_ITEMS, LISTING, MEETING_EVENT, MEETINGS, NOTES, ORDERS, PARTNER_ACCOUNT, PARTNER_CLIENT, PARTNER_SERVICE, PLAYBOOK, PLAYBOOK_SUBMISSION, PRODUCTS, SERVICES, SOCIAL_POSTS, TASK, TASKS, TICKETS, SEQUENCE_ENROLLMENTS, SEQUENCE_STEP_ENROLLMENTS, SUBSCRIPTIONS, USER];