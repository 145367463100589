import { List, fromJS } from 'immutable';
import { createSelector } from 'reselect';
import debounce from 'transmute/debounce';
import { getReports } from '../../api/reportApi';
import { RequestState } from 'dashboard-lib/public/request/request-state';
const {
  UNINITIALIZED,
  PENDING,
  SUCCEEDED,
  FAILED
} = RequestState;
export const savedReportSearchNamespace = 'savedReportSearch';

// Action types
const FETCH_SAVED_REPORTS_PENDING = 'FETCH_SAVED_REPORTS_PENDING';
const FETCH_SAVED_REPORTS_SUCCEEDED = 'FETCH_SAVED_REPORTS_SUCCEEDED';
const FETCH_SAVED_REPORTS_FAILED = 'FETCH_SAVED_REPORTS_FAILED';
// Actions
const debouncedGetReports = debounce(1000, (query, dispatch) => {
  return getReports(query).then(reportsList => {
    dispatch({
      type: FETCH_SAVED_REPORTS_SUCCEEDED,
      payload: reportsList
    });
  }).catch(exception => {
    if ((exception === null || exception === void 0 ? void 0 : exception.errorCode) !== 'ABORT') {
      console.error(exception);
      dispatch({
        type: FETCH_SAVED_REPORTS_FAILED
      });
    }
  });
});
export const searchSavedReports = query => dispatch => {
  //show loading indicator immediately but
  dispatch({
    type: FETCH_SAVED_REPORTS_PENDING
  });
  //debounce the api call to give the user time to type
  void debouncedGetReports(query, dispatch);
};
// Reducers
const initialState = fromJS({
  status: UNINITIALIZED,
  reports: List()
});
export const savedReportSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SAVED_REPORTS_PENDING:
      return state.set('status', PENDING);
    case FETCH_SAVED_REPORTS_SUCCEEDED:
      return state.merge({
        status: SUCCEEDED,
        reports: action.payload
      });
    case FETCH_SAVED_REPORTS_FAILED:
      return state.set('status', FAILED);
    default:
      return state;
  }
};
const reportPanelNamespace = 'reportPanel';
export const selectSavedReportSearchRoot = state => state[reportPanelNamespace][savedReportSearchNamespace];
export const selectSavedReports = createSelector(selectSavedReportSearchRoot, savedReportSearchRoot => savedReportSearchRoot.get('reports'));
export const selectIsSavedReportSearchLoading = createSelector(selectSavedReportSearchRoot, savedReportSearchRoot => savedReportSearchRoot.get('status') === PENDING);
export const selectIsSavedReportSearchFailed = createSelector(selectSavedReportSearchRoot, savedReportSearchRoot => savedReportSearchRoot.get('status') === FAILED);