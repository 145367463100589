'use es6';

import { List } from 'immutable';
import { Field, FieldTypes } from 'reporting-snowflake/relational/schema/column-records';
import { getTableDescriptionMeta } from '../../relational/metadata/table-description-meta';
import { FieldRef } from '../../relational/schema/field-ref';
import { TableDescription } from '../../relational/schema/table-records';
import { createDefaultDimensionWithTransform, fromSnowflakeProperty } from '../../relational/utils/column-utils';
import { getReportDefinition } from 'reporting-data/report/reportGetters';
export default (report => {
  const reportDefinition = getReportDefinition(report);
  const table = reportDefinition.get('table');
  const stagedFieldRefs = reportDefinition.get('stagedFields') || List();
  if (stagedFieldRefs.isEmpty()) {
    return report.set('reportDefinition', reportDefinition.delete('stagedFields'));
  }
  return getTableDescriptionMeta(TableDescription(table)).then(({
    properties
  }) => {
    const stagedFields = stagedFieldRefs.map(fieldRef => FieldRef(fieldRef)).map(fieldRef => [properties.getIn([fieldRef.table, fieldRef.property]), fieldRef]).map(([snowflakeProperty, fieldRef]) => snowflakeProperty ? fromSnowflakeProperty(snowflakeProperty, fieldRef.table) : Field({
      name: fieldRef.property,
      table: fieldRef.table,
      type: FieldTypes.string
    }));
    const nextReportDefinition = reportDefinition.update('stagedColumns', stagedColumns => (stagedColumns || List()).concat(stagedFields.map(createDefaultDimensionWithTransform))).delete('stagedFields');
    return report.set('reportDefinition', nextReportDefinition);
  });
});