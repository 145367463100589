'use es6';

const PROPERTY = 'dealstage.probability';
const negativeLost = data => data.updateIn(['dimension', 'buckets'], buckets => buckets.map(bucket => {
  if (bucket.has('dimension')) {
    return negativeLost(bucket);
  } else {
    return data.getIn(['dimension', 'property']) === PROPERTY && bucket.get('key') === 'LOST' ? bucket.update('metrics', metrics => metrics.map(metric => metric.map(val => -1 * val))) : bucket;
  }
}));
export default (({
  dataset
}) => negativeLost(dataset));