'use es6';

import { stringify } from 'hub-http/helpers/params';
import I18n from 'I18n';
import { List, Map as ImmutableMap } from 'immutable';
import PortalIdParser from 'PortalIdParser';
import { getFilterByProperty } from '../../../config/filters/functions';
import { CRM_OBJECT_META_TYPE } from '../../../constants/crmObjectMetaTypes';
import * as DataTypes from '../../../constants/dataTypes';
import { GLOBAL_NULL } from '../../../constants/defaultNullValues';
import { COUNT } from '../../../constants/metricTypes';
import { HUBSPOT_OBJECT_COORDINATES_TO_DATA_TYPE } from '../../../constants/objectCoordinates';
import { isKnownGuid } from '../../../lib/guids';
import { getProductLinkFromDataType } from '../../../lib/ProductLinkHelper';
import { generateTicketLabel } from '../../../references/ticket';
import { getIdColumnFromDataType, getIdColumnOrObjectTypeIdFromDataType, getIsAttributionType, LINKS_FIELD, objectTypeIdLinkableDatatypes } from '../constants';
import { fromMetricKey } from '../datasetMetrics';
const EMAIL_PREVIEW_ID = '2';
const prependHttp = url => /^(http|\/)/.test(url) ? url : `http://${url}`;
const getDrilldownLevel = config => config.getIn(['filters', 'custom'], List()).count(filter => ['d1', 'd2', 'd3'].includes(filter.get('property')));
const codeToPageType = {
  0: 'site-page',
  1: 'landing-page',
  2: 'listing-page',
  3: 'blog-post',
  4: 'knowledge-article'
};
const TICKET_LABEL_CHECK = ImmutableMap({
  [DataTypes.CROSS_OBJECT]: 'hs_ticket_object_id',
  [DataTypes.TICKETS]: 'hs_ticket_id'
});

// stolen from PatternValidation https://git.hubteam.com/HubSpot/PatternValidation/blob/master/PatternValidationJS/static/js/regex/factories/UrlRegexFactory.js#L27
const URL_REGEX =
// eslint-disable-next-line no-useless-escape
/^(?:(?:https?:)?\/\/)?(?:www\.)?(?:(?:[^\s\?\#\/\(\)\=\:\;\@\\"\.]+)\.)+(?:[^\s\?\#\/\(\)\=\:\;\@\\"\.]+)(?:(?:(?:\/[^\s\/\#\&\?]*)+\/?)(?:(?:[%a-zA-Z0-9_-]+)(?:(?:\.[%a-zA-Z0-9-]+)[^\.\s]))?)?(?:([\?&#][^\s\?\#\&]*?=?([^\s\?\#\&]*))+)?$/i;
const isExternalPage = referenceKey => isNaN(referenceKey) && URL_REGEX.test(encodeURI(referenceKey));
const getUrlFromDataRow = (dataRow, pageId, referenceKey) => {
  const contentType = dataRow.get(dataRow.findKey((val, key) => key.includes('CONTENT_TYPE_CODE')));
  const htmlTitle = dataRow.get(dataRow.findKey((val, key) => key.includes('HTML_TITLE')));
  const url = dataRow.get(dataRow.findKey((val, key) => key.includes('URL'))) || pageId || referenceKey;
  if (isExternalPage(referenceKey)) {
    const params = stringify({
      title: htmlTitle,
      url: prependHttp(url)
    });
    return `/content-detail/${PortalIdParser.get()}/external-page/optimization?${params}`;
  }
  const pageType = codeToPageType[contentType];
  if (!pageType) {
    return null;
  }
  switch (pageType) {
    case 'blog-post':
    case 'site-page':
    case 'landing-page':
      return `/content-detail/${PortalIdParser.get()}/${pageType}/${pageId}`;
    case 'knowledge-article':
      return `/knowledge/${PortalIdParser.get()}/insights/article/${pageId}`;
    default:
      break;
  }
  return null;
};
const getIsAssociationColumn = (dataType, propertyName) => propertyName.includes('association') || getIsAttributionType(dataType) && ['hs_contact_id', 'hs_deal_id'].includes(propertyName);
const addEngagementQueryParam = (url, dataRow) => {
  const engagementId = dataRow.get(getIdColumnOrObjectTypeIdFromDataType(DataTypes.ENGAGEMENT, dataRow));
  if (!engagementId) {
    return url;
  }
  return `${url}?engagement=${engagementId}`;
};
const getNonNumberReferenceKey = (dataType, referenceKey) => getIsAttributionType(dataType) ? referenceKey : null;
const getDataRowId = ({
  dataType,
  propertyName,
  referenceKey,
  dataRow
}) => {
  if (['people', 'deals-influenced'].includes(propertyName)) {
    // Properties that happen in drilldowns
    return dataRow.get(propertyName);
  }
  const fallbackId = isNaN(Number(referenceKey)) ? getNonNumberReferenceKey(dataType, referenceKey) : referenceKey;
  const objectId = objectTypeIdLinkableDatatypes.includes(dataType) ? dataRow.get('hs_object_id') : undefined;
  const dataRowId = objectId || dataRow.get(getIdColumnFromDataType(dataType));
  return getIsAssociationColumn(dataType, propertyName) || !dataRowId ? fallbackId : dataRowId;
};
const getURL = ({
  dataType,
  property,
  id,
  dataRow,
  config,
  referenceKey
}) => {
  if (property === 'people') {
    // Property that happens in drilldowns across multiple data types
    const isContact = dataRow.find((_, key) => fromMetricKey(key).property === 'isContact', null, true);
    return isContact ? getProductLinkFromDataType(DataTypes.CONTACTS, id) : null;
  }
  if (id === GLOBAL_NULL) {
    return null;
  }
  switch (dataType) {
    case DataTypes.ENGAGEMENTS:
    case DataTypes.ENGAGEMENT:
      switch (property) {
        case 'associations.contact':
          return addEngagementQueryParam(getProductLinkFromDataType(DataTypes.CONTACTS, id), dataRow);
        case 'associations.deal':
          return addEngagementQueryParam(getProductLinkFromDataType(DataTypes.DEALS, id), dataRow);
        case 'associations.company':
          return addEngagementQueryParam(getProductLinkFromDataType(DataTypes.COMPANIES, id), dataRow);
        case 'associations.ticket':
          return addEngagementQueryParam(getProductLinkFromDataType(DataTypes.TICKETS, id), dataRow);
        default:
          return null;
      }
    case DataTypes.SOCIAL_POSTS:
      switch (property) {
        case 'publishedAt':
          return getProductLinkFromDataType(dataType, dataRow.get('broadcastGuid'));
        case 'url':
          return dataRow.get('url');
        default:
          return null;
      }
    case DataTypes.ANALYTICS_BLOG_POSTS:
      switch (property) {
        case 'breakdown':
          return `/content-detail/${PortalIdParser.get()}/blog-post/${id}/performance`;
        default:
          return null;
      }
    case DataTypes.ANALYTICS_CAMPAIGNS:
      switch (property) {
        case 'breakdown':
        case 'campaignId':
          if (getDrilldownLevel(config) > 0) {
            return null;
          }
          return `/campaigns/${PortalIdParser.get()}/${id}?back=${encodeURIComponent(window.location.href)}`;
        case 'deals-influenced':
          return `/contacts/${PortalIdParser.get()}/deals/${id}`;
        default:
          return null;
      }
    case DataTypes.ANALYTICS_FORMS:
      switch (property) {
        case 'breakdown':
          // should not show a url if it's a feedback survey report
          return config.getIn(['filters', 'custom'], []).some(({
            property: filterType,
            value
          }) => filterType === 'type' && value === 'SURVEY') ? null : `/forms/${PortalIdParser.get()}/${id}/performance?`;
        default:
          return null;
      }
    case DataTypes.ANALYTICS_SOURCES:
    case DataTypes.ANALYTICS_ALL_PAGES_SOURCES:
    case DataTypes.ANALYTICS_FORMS_SOURCES:
      switch (property) {
        case 'breakdown':
          if (getDrilldownLevel(config) === 2) {
            const filterProperty = getFilterByProperty(config, 'd1', ImmutableMap()).get('value');
            if (filterProperty === 'email' && id !== EMAIL_PREVIEW_ID && !isNaN(parseFloat(id)) && isFinite(id)) {
              return `/email/${PortalIdParser.get()}/campaign/${id}`;
            }
            if (['referrals', 'direct'].includes(filterProperty)) {
              if (isKnownGuid(id)) {
                // special value, not really a url
                return null;
              }
              return /^https?:\/\//.test(id) ? id : `http://${id}`;
            }
          }
          return null;
        default:
          return null;
      }
    case DataTypes.ANALYTICS_KNOWLEDGE_ARTICLES:
      switch (property) {
        case 'breakdown':
          return `/knowledge/${PortalIdParser.get()}/insights/article/${id}/performance`;
        default:
          return null;
      }
    case DataTypes.ANALYTICS_LANDING_PAGES:
      switch (property) {
        case 'breakdown':
          return `/content-detail/${PortalIdParser.get()}/landing-page/${id}/performance`;
        default:
          return null;
      }
    case DataTypes.ANALYTICS_PATHS:
      switch (property) {
        case 'pageUrl':
          return isExternalPage(referenceKey) ? prependHttp(referenceKey) : null;
        default:
          return null;
      }
    case DataTypes.ANALYTICS_SALES_DOCUMENTS:
      switch (property) {
        case 'breakdown':
        case 'deckId':
          return `/documents/${PortalIdParser.get()}/summary/${id}`;
        default:
          return null;
      }
    case DataTypes.ANALYTICS_SALES_TEMPLATES:
      switch (property) {
        case 'breakdown':
        case 'templateId':
          return `/templates/${PortalIdParser.get()}/edit/${id}`;
        default:
          return null;
      }
    case DataTypes.ANALYTICS_SEQUENCE_ENROLLMENT_ACTIVITIES:
      switch (property) {
        case 'breakdown':
        case 'sequenceId':
          return `/sequences/${PortalIdParser.get()}/sequence/${id}`;
        default:
          return null;
      }
    case DataTypes.ANALYTICS_STANDARD_PAGES:
      switch (property) {
        case 'breakdown':
          return `/content-detail/${PortalIdParser.get()}/site-page/${id}/performance`;
        default:
          return null;
      }
    case DataTypes.EMAIL:
      switch (property) {
        case 'id':
          return `/email/${PortalIdParser.get()}/details/${id}`;
        case 'emailCampaign':
          return `/campaigns/${PortalIdParser.get()}/${dataRow.get('emailCampaign')}`;
        default:
          return null;
      }
    case DataTypes.ANALYTICS_ALL_PAGES_BY_COMPANY:
    case DataTypes.ANALYTICS_ALL_PAGES:
      switch (property) {
        case 'breakdown':
          if (dataType === DataTypes.ANALYTICS_ALL_PAGES_BY_COMPANY && getDrilldownLevel(config) === 0) {
            return `/contacts/${PortalIdParser.get()}/company/${Number(id)}`;
          }
          return getUrlFromDataRow(dataRow, id, referenceKey);
        default:
          return null;
      }
    case DataTypes.ANALYTICS_BEHAVIORAL_EVENTS:
      switch (property) {
        case 'breakdown':
          return `/events/${PortalIdParser.get()}/manage/${id.replace(new RegExp(`^${CRM_OBJECT_META_TYPE.PORTAL_SPECIFIC_EVENT}-`), '')}`;
        default:
          return null;
      }
    case DataTypes.SEQUENCE_SENDER_SCORE:
      switch (property) {
        case 'breakdown':
          if (getDrilldownLevel(config) === 1) {
            return `/sequences/${PortalIdParser.get()}/sequence/${id}`;
          }
          return null;
        default:
          return null;
      }
    case DataTypes.KNOWLEDGE_ARTICLES:
      switch (property) {
        case 'id':
          return `/knowledge/${PortalIdParser.get()}/insights/article/${id}`;
        default:
          return null;
      }
    default:
      return null;
  }
};
const enhanceColumnsV2 = (config, data, properties) => {
  const dataType = config.get('dataType');

  // Only data types that get updated labels
  if (!TICKET_LABEL_CHECK.has(dataType) && dataType !== DataTypes.SOCIAL_POSTS) {
    return properties;
  }
  return properties.map((property, propertyKey) => {
    return property.update('references', references => {
      if (!references) {
        return references;
      }
      return references.map((reference, referenceKey) => {
        const dataRow = data.find(dataObj => String(dataObj.get(propertyKey)) === referenceKey ||
        // This is for associations since it is an array, and the ids are not the same as the id of the row
        List.isList(dataObj.get(propertyKey)) && dataObj.get(propertyKey).includes(Number(referenceKey)) || String(dataObj.get(propertyKey)).includes(Number(referenceKey)));
        if (!dataRow) {
          return reference;
        }
        const {
          property: propertyName = propertyKey,
          type
        } = fromMetricKey(propertyKey);
        return reference.update('label', label => {
          if (TICKET_LABEL_CHECK.has(dataType) && TICKET_LABEL_CHECK.get(dataType) === propertyName && type !== COUNT) {
            const id = getDataRowId({
              dataType,
              propertyName,
              referenceKey,
              dataRow
            });
            return generateTicketLabel(ImmutableMap({
              subject: label
            }), id);
          }
          if (dataType === DataTypes.SOCIAL_POSTS && propertyName === 'publishedAt') {
            const publishedAtMoment = I18n.moment(dataRow.get('publishedAt')).portalTz();
            return publishedAtMoment.format('l LT');
          }
          return label;
        });
      });
    });
  });
};

// Sets links on the FE for columns in V2 tables
// This is mostly deprecated - only UA reports and custom widgets use this code path
const enhanceDataV2 = (config, data) => {
  const objectTypeId = config.get('objectTypeId');
  const dataType = config.get('dataType') === DataTypes.CRM_OBJECT ? HUBSPOT_OBJECT_COORDINATES_TO_DATA_TYPE.get(objectTypeId, DataTypes.CRM_OBJECT) : config.get('dataType');
  return data.map(dataRow => {
    const links = dataRow.reduce((acc, value, key) => {
      const propertyName = fromMetricKey(key).property || key;
      const id = getDataRowId({
        dataType,
        propertyName,
        referenceKey: value,
        dataRow
      });
      const urlProps = {
        dataType,
        objectTypeId,
        property: key,
        dataRow,
        config,
        id,
        referenceKey: value
      };
      if (List.isList(value)) {
        // Associations
        return value.reduce((linkAcc, val) => {
          const url = getURL(Object.assign({}, urlProps, {
            referenceKey: val,
            id: val
          }));
          return url && url !== GLOBAL_NULL ? linkAcc.setIn([propertyName, String(val)], url) : linkAcc;
        }, acc);
      } else if (value) {
        const url = getURL(urlProps);
        return url && url !== GLOBAL_NULL ? acc.setIn([propertyName, String(value)], url) : acc;
      }
      return acc;
    }, dataRow.has(LINKS_FIELD) ? dataRow.get(LINKS_FIELD) : ImmutableMap());
    return links.isEmpty() ? dataRow : dataRow.set(LINKS_FIELD, links);
  });
};
const enhanceProperties = (dataset, config) => enhanceColumnsV2(config, dataset.get('data'), dataset.get('properties'));

//{ dataType, objectTypeId, property, id, dataRow, config }
export const legacyEnhanceColumnDataset = (dataset, config) => {
  const updatedProperties = enhanceProperties(dataset, config);
  return dataset.set('properties', updatedProperties).update('data', data => enhanceDataV2(config, data));
};

//{ dataType, objectTypeId, property, id, dataRow, config }
export const enhanceColumnDataset = (dataset, config) => dataset.set('properties', enhanceProperties(dataset, config));
export const __TESTABLE__ = {
  enhanceDataV2,
  getUrlFromDataRow
};