'use es6';

import getIn from 'transmute/getIn';
import useUserInfo from '../../useUserInfo';
import { DASHBOARD_MAX_WIDGETS, LIMIT_ROOT } from '../limitTypes';
const useDashboardMaxWidgets = () => {
  const {
    data
  } = useUserInfo(false);
  return getIn([LIMIT_ROOT, DASHBOARD_MAX_WIDGETS], data);
};
export default useDashboardMaxWidgets;