'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import fromJSOrdered from '../../../lib/fromJSOrdered';
import * as PropertyTypes from '../../../constants/property-types';
import { getPropertyType } from '../../../properties/type';
const defaultPath = propertyKey => List(['properties', propertyKey, 'value']);
export function getPropertyPath(propertyKey, spec) {
  if (spec.hasIn(['properties', 'responsePaths', propertyKey])) {
    return spec.getIn(['properties', 'responsePaths', propertyKey]);
  }
  return defaultPath(propertyKey);
}
const transformByType = ImmutableMap({
  [PropertyTypes.ENUMERATION]: value => {
    if (List.isList(value)) {
      return value.map(val => val && String(val));
    } else {
      return value && String(value);
    }
  }
});
export function extractDefault(rawImmutable, propertyPath, propertyType) {
  return transformByType.get(propertyType, value => value)(rawImmutable.getIn(propertyPath));
}
export function getExtractForProperty(propertyKey, spec) {
  const extractor = spec.getIn(['properties', 'extractors'], ImmutableMap());
  if (extractor.has(propertyKey)) {
    return Promise.resolve(rawImmutable => extractor.get(propertyKey)(rawImmutable, propertyKey));
  } else if (extractor.has('__default')) {
    return Promise.resolve(rawImmutable => spec.getIn(['properties', 'extractors', '__default'])(rawImmutable, propertyKey));
  } else {
    return getPropertyType(spec.dataType, propertyKey).then(propertyType => rawImmutable => extractDefault(rawImmutable, getPropertyPath(propertyKey, spec), propertyType));
  }
}
export function getExtractByProperty(propertyKeys, spec) {
  const propertyKeyList = propertyKeys.toList();
  return Promise.all(propertyKeyList.map(propertyKey => getExtractForProperty(propertyKey, spec)).toJS()).then(propertyExtractors => rawObject => {
    const rawImmutable = fromJSOrdered(rawObject);
    return ImmutableMap(propertyKeyList.map((propertyKey, index) => [propertyKey, propertyExtractors[index](rawImmutable)]).toJS());
  });
}