'use es6';

import I18n from 'I18n';
import getInboundDbPropertyGroups from '../../retrieve/inboundDb/common/properties';
import { withBetGroups } from '../partial/bet-engagements';
import getDispositionOptions from '../partial/call-disposition-options';
import { ENGAGEMENTS } from '../../constants/dataTypes';
import { mergeProperties } from '../mergeProperties';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
import countProperty from '../partial/count-property';
import engagementsModule from '../../dataTypeDefinitions/inboundDb/engagements';
import overridePropertyTypes from '../../retrieve/inboundDb/common/overridePropertyTypes';
import { userInfo } from '../../request/user-info';
const ENGAGEMENT_TYPE_BLOCKLIST = ['EMAIL_REPLY', 'PROJECT_TASK', 'FEEDBACK_SUBMISSION'];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ENGAGEMENTS_OVERRIDES = gates => ({
  'associations.contact': {
    name: 'associations.contact',
    label: I18n.text('reporting-data.properties.common.associatedContacts'),
    type: 'enumeration',
    scripted: true
  },
  'associations.company': {
    name: 'associations.company',
    label: I18n.text('reporting-data.properties.common.associatedCompanies'),
    type: 'enumeration',
    scripted: true
  },
  hs_lastmodifieddate: {
    hidden: true
  },
  hs_modified_by: {
    hidden: true
  },
  hs_engagement_source: {
    hidden: true
  },
  hs_all_accessible_team_ids: {
    hidden: true
  },
  hs_all_owner_ids: {
    hidden: true
  },
  hs_all_team_ids: {
    hidden: true
  },
  hs_at_mentioned_owner_ids: {
    hidden: true
  },
  hs_attachment_ids: {
    hidden: true
  }
});
const CALL_OVERRIDES = dispositions => ({
  hs_call_duration: {
    type: 'duration'
  },
  hs_call_disposition: {
    options: dispositions
  }
});
const CONVERSATION_SESSION_OVERRIDES = () => ({
  hs_conversation_session_duration: {
    type: 'duration'
  },
  hs_conversation_session_agent_response_time: {
    type: 'duration',
    hidden: false
  },
  hs_conversation_session_visitor_wait_time: {
    type: 'duration'
  },
  hs_conversation_session_online: {
    hidden: true
  },
  hs_conversation_session_thread_id: {
    hidden: true
  },
  hs_conversation_session_session_closed_at: {
    hidden: false
  }
});
const EMAIL_OVERRIDES = () => ({
  hs_email_text: {
    hidden: true
  },
  hs_email_tracker_key: {
    hidden: true
  },
  hs_email_message_id: {
    hidden: true
  },
  hs_email_thread_id: {
    hidden: true
  },
  hs_email_sent_via: {
    hidden: false
  },
  hs_email_attached_video_id: {
    hidden: true
  },
  hs_email_error_message: {
    hidden: true
  },
  hs_email_facsimile_send_id: {
    hidden: true
  },
  hs_email_logged_from: {
    hidden: true
  },
  hs_email_media_processing_status: {
    hidden: true
  },
  hs_email_post_send_status: {
    hidden: true
  },
  hs_email_recipient_drop_reasons: {
    hidden: true
  },
  hs_email_send_event_id: {
    hidden: true
  },
  hs_email_send_event_id_created: {
    hidden: true
  },
  hs_email_validation_skipped: {
    hidden: true
  }
});
const MEETING_OVERRIDES = () => ({
  hs_meeting_created_from_link_id: {
    hidden: true
  },
  hs_meeting_end_time: {
    hidden: true
  },
  hs_meeting_external_url: {
    hidden: true
  },
  hs_meeting_pre_meeting_prospect_reminders: {
    hidden: true
  },
  hs_meeting_source_id: {
    hidden: true
  },
  hs_meeting_web_conference_meeting_id: {
    hidden: true
  }
});
const TASK_OVERRIDES = () => ({
  hs_task_for_object_type: {
    hidden: true
  },
  hs_task_reminders: {
    hidden: true
  },
  hs_task_send_default_reminder: {
    hidden: true
  }
});
const PROJECT_TASK_OVERRIDES = () => ({
  hs_project_task_assignee_owner_ids: {
    hidden: true
  },
  hs_project_task_body: {
    hidden: true
  },
  hs_project_task_due_date: {
    hidden: true
  },
  hs_project_task_for_object_type: {
    hidden: true
  },
  hs_project_task_parent_id: {
    hidden: true
  },
  hs_project_task_parent_type: {
    hidden: true
  },
  hs_project_task_sort: {
    hidden: true
  },
  hs_project_task_status: {
    hidden: true
  },
  hs_project_task_subject: {
    hidden: true
  },
  hs_project_task_tags: {
    hidden: true
  },
  hs_project_task_top_level_parent_id: {
    hidden: true
  },
  hs_project_task_top_level_parent_type: {
    hidden: true
  }
});
const filterEngagementTypes = propertyGroups => {
  const groupIndex = propertyGroups.findIndex(group => group.get('name') === 'engagement');
  if (groupIndex === -1) {
    return groupIndex;
  }
  return propertyGroups.update(groupIndex, group => group.update('properties', properties => {
    const propertyIndex = properties.findIndex(property => property.get('name') === 'hs_engagement_type');
    if (propertyIndex === -1) {
      return properties;
    }
    return properties.update(propertyIndex, property => property.update('options', options => options.filter(option => !ENGAGEMENT_TYPE_BLOCKLIST.includes(option.get('value')))));
  }));
};
export const getPropertyGroups = () => Promise.all([getInboundDbPropertyGroups(ENGAGEMENTS), getDispositionOptions(), userInfo()]).then(([propertyGroups, dispositions, {
  gates
}]) => {
  let mergedGroups = propertyGroups;
  mergedGroups = mergeProperties(withBetGroups(propertyGroups), 'engagement', ENGAGEMENTS_OVERRIDES(gates));
  mergedGroups = mergeProperties(withBetGroups(mergedGroups), 'call', CALL_OVERRIDES(dispositions));
  mergedGroups = mergeProperties(withBetGroups(mergedGroups), 'conversation_session', CONVERSATION_SESSION_OVERRIDES());
  mergedGroups = mergeProperties(withBetGroups(mergedGroups), 'email', EMAIL_OVERRIDES());
  mergedGroups = mergeProperties(withBetGroups(mergedGroups), 'meeting', MEETING_OVERRIDES());
  mergedGroups = mergeProperties(withBetGroups(mergedGroups), 'task', TASK_OVERRIDES());
  mergedGroups = mergeProperties(withBetGroups(mergedGroups), 'project_task', PROJECT_TASK_OVERRIDES());
  return mergedGroups;
}).then(filterEngagementTypes);
export const getProperties = () => createPropertiesGetterFromGroups(getPropertyGroups, properties => properties.merge(countProperty(ENGAGEMENTS)))().then(overridePropertyTypes(engagementsModule.getInboundSpec()));