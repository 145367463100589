'use es6';

import extractor from '../../../../config/extractor';
export default (allStages => extractor((config, missingValue) => {
  const stages = config.getIn(['pipeline', 'stages']);
  if (stages && stages.size > 0) {
    return stages.filter(stage => {
      if (!allStages.contains(stage)) {
        return false;
      }
      return true;
    });
  }
  return missingValue;
}, allStages));