'use es6';

import { CROSS_OBJECT, ATTRIBUTION } from 'reporting-data/constants/dataTypes';

// this is not a migration, it is bug-fix in disguise, do not delete!
export const fixCrossObjectReportId = report => {
  if (report.getIn(['config', 'dataType']) === CROSS_OBJECT) {
    return report.setIn(['config', 'crossObject', 'dashboardReportId'], report.get('id'));
  }
  if (report.getIn(['config', 'dataType']) === ATTRIBUTION && !report.getIn(['config', 'attributionModel', 'reportId'], null)) {
    return report.setIn(['config', 'attributionModel', 'reportId'], report.get('id'));
  }
  return report;
};