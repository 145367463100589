import { fromJS } from 'immutable';
import * as checked from 'reporting-data/lib/checked';
import { Context, Contexts } from '../../dataset/context-records';
import { DefaultRelationalEditor, Editor } from '../../dataset/editor-records';
import { Expression } from '../../dataset/expression-records';
import { Type } from '../../dataset/type-records';
import { AggregationTypes as AggregationTypesConstant, FieldTypes as FieldTypesConstant } from './constants/column-constants';
import { AutoFormatting, AutoFormattingType, CurrencyFormatting, CurrencyFormattingType, FormatType, FormatTypes, NegativeNumberTypes, NumberFormatting, NumberFormattingType, PercentFormatting, PercentFormattingType } from './format-models';
import { Limit } from './limit-records';
import { ColumnSort } from './sort-records';
import { COLUMN_ROLES } from 'reporting-data/constants/relationalReports';
export const Role = checked.symbol(COLUMN_ROLES, 'Role');
export const DomainTypes = {
  DISCRETE: 'DISCRETE',
  CONTINUOUS: 'CONTINUOUS'
};
export const DomainType = checked.symbol(DomainTypes, 'DomainType');

// currently matching https://git.hubteam.com/HubSpot/IdentityBase/blob/master/src/main/protobuf/contacts.proto#L160
export const FieldTypes = FieldTypesConstant;
export const FieldType = checked.symbol(FieldTypes, 'FieldType');
export const BasicTypes = {
  NUMBER: 'number',
  STRING: 'string',
  BOOLEAN: 'boolean',
  DATETIME: 'datetime',
  DATE: 'date'
};
export const BasicType = checked.symbol(BasicTypes, 'BasicType');
export const Formats = {
  NUMBER: 'number',
  STRING: 'string',
  BOOLEAN: 'boolean',
  DATETIME: 'datetime',
  DATE: 'date',
  DURATION: 'duration',
  CURRENCY: 'currency',
  HTML: 'html'
};
export const AggregationTypes = AggregationTypesConstant;
export const AggregationType = checked.symbol(AggregationTypes, 'AggregationType');
export const AggregationTypeDefinitions = fromJS({
  [AggregationTypes.COUNT]: {
    name: 'COUNT',
    displayI18nKey: 'reporting-snowflake.aggregations.COUNT',
    supportedFieldTypes: []
  },
  [AggregationTypes.DISTINCT_COUNT]: {
    name: 'DISTINCT_COUNT',
    displayI18nKey: 'reporting-snowflake.aggregations.DISTINCT_COUNT',
    supportedFieldTypes: [FieldTypes.STRING, FieldTypes.NUMBER, FieldTypes.DATE, FieldTypes.BOOL, FieldTypes.ENUMERATION, FieldTypes.DATETIME, FieldTypes.PHONE_NUMBER, FieldTypes.CURRENCY_NUMBER]
  },
  [AggregationTypes.SUM]: {
    name: 'SUM',
    displayI18nKey: 'reporting-snowflake.aggregations.SUM',
    supportedFieldTypes: [FieldTypes.NUMBER, FieldTypes.CURRENCY_NUMBER]
  },
  [AggregationTypes.AVERAGE]: {
    name: 'AVERAGE',
    displayI18nKey: 'reporting-snowflake.aggregations.AVERAGE',
    supportedFieldTypes: [FieldTypes.NUMBER, FieldTypes.CURRENCY_NUMBER]
  },
  [AggregationTypes.MIN]: {
    name: 'MIN',
    displayI18nKey: 'reporting-snowflake.aggregations.MIN',
    supportedFieldTypes: [FieldTypes.NUMBER, FieldTypes.DATE, FieldTypes.DATETIME, FieldTypes.CURRENCY_NUMBER]
  },
  [AggregationTypes.MAX]: {
    name: 'MAX',
    displayI18nKey: 'reporting-snowflake.aggregations.MAX',
    supportedFieldTypes: [FieldTypes.NUMBER, FieldTypes.DATE, FieldTypes.DATETIME, FieldTypes.CURRENCY_NUMBER]
  },
  [AggregationTypes.MEDIAN]: {
    name: 'MEDIAN',
    displayI18nKey: 'reporting-snowflake.aggregations.MEDIAN',
    supportedFieldTypes: [FieldTypes.NUMBER, FieldTypes.DATE, FieldTypes.DATETIME, FieldTypes.CURRENCY_NUMBER]
  },
  [AggregationTypes.DELEGATE]: {
    name: 'DELEGATE',
    displayI18nKey: 'reporting-snowflake.aggregations.DELEGATE',
    supportedFieldTypes: [FieldTypes.STRING, FieldTypes.NUMBER, FieldTypes.DATE, FieldTypes.BOOL, FieldTypes.ENUMERATION, FieldTypes.DATETIME, FieldTypes.PHONE_NUMBER, FieldTypes.CURRENCY_NUMBER]
  }
});
export const DateTruncationTypes = {
  MINUTE: 'MINUTE',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  YEAR: 'YEAR'
};
export const DateTruncDefinitions = fromJS({
  [DateTruncationTypes.MINUTE]: {
    name: 'MINUTE',
    // MINUTE refers to daily with timestamp
    displayI18nKey: 'reporting-snowflake.date-trunc.MINUTE',
    supportedFieldTypes: []
  },
  [DateTruncationTypes.DAY]: {
    name: 'DAY',
    displayI18nKey: 'reporting-snowflake.date-trunc.DAY',
    supportedFieldTypes: [FieldTypes.DATETIME, FieldTypes.DATE]
  },
  [DateTruncationTypes.WEEK]: {
    name: 'WEEK',
    displayI18nKey: 'reporting-snowflake.date-trunc.WEEK',
    supportedFieldTypes: [FieldTypes.DATETIME, FieldTypes.DATE]
  },
  [DateTruncationTypes.MONTH]: {
    name: 'MONTH',
    displayI18nKey: 'reporting-snowflake.date-trunc.MONTH',
    supportedFieldTypes: [FieldTypes.DATETIME, FieldTypes.DATE]
  },
  [DateTruncationTypes.QUARTER]: {
    name: 'QUARTER',
    displayI18nKey: 'reporting-snowflake.date-trunc.QUARTER',
    supportedFieldTypes: [FieldTypes.DATETIME, FieldTypes.DATE]
  },
  [DateTruncationTypes.YEAR]: {
    name: 'YEAR',
    displayI18nKey: 'reporting-snowflake.date-trunc.YEAR',
    supportedFieldTypes: [FieldTypes.DATETIME, FieldTypes.DATE]
  }
});
export const DatePartTypes = {
  HOUR: 'HOUR',
  DAY: 'DAY',
  DAYOFWEEK: 'DAYOFWEEK',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  DAYOFYEAR: 'DAYOFYEAR',
  YEAR: 'YEAR'
};
export const DatePartDefinitions = fromJS({
  [DatePartTypes.HOUR]: {
    name: 'HOUR',
    displayI18nKey: 'reporting-snowflake.date-part.HOUR',
    supportedFieldTypes: [FieldTypes.DATETIME]
  },
  [DatePartTypes.DAY]: {
    name: 'DAY',
    displayI18nKey: 'reporting-snowflake.date-part.DAY',
    supportedFieldTypes: [FieldTypes.DATETIME, FieldTypes.DATE]
  },
  [DatePartTypes.DAYOFWEEK]: {
    name: 'DAYOFWEEK',
    displayI18nKey: 'reporting-snowflake.date-part.DAYOFWEEK',
    supportedFieldTypes: [FieldTypes.DATETIME, FieldTypes.DATE]
  },
  [DatePartTypes.WEEK]: {
    name: 'WEEK',
    displayI18nKey: 'reporting-snowflake.date-part.WEEK',
    supportedFieldTypes: [FieldTypes.DATETIME, FieldTypes.DATE]
  },
  [DatePartTypes.MONTH]: {
    name: 'MONTH',
    displayI18nKey: 'reporting-snowflake.date-part.MONTH',
    supportedFieldTypes: [FieldTypes.DATETIME, FieldTypes.DATE]
  },
  [DatePartTypes.QUARTER]: {
    name: 'QUARTER',
    displayI18nKey: 'reporting-snowflake.date-part.QUARTER',
    supportedFieldTypes: [FieldTypes.DATETIME, FieldTypes.DATE]
  },
  [DatePartTypes.DAYOFYEAR]: {
    name: 'DAYOFYEAR',
    displayI18nKey: 'reporting-snowflake.date-part.DAYOFYEAR',
    supportedFieldTypes: [FieldTypes.DATETIME, FieldTypes.DATE]
  },
  [DatePartTypes.YEAR]: {
    name: 'YEAR',
    displayI18nKey: 'reporting-snowflake.date-part.YEAR',
    supportedFieldTypes: [FieldTypes.DATETIME, FieldTypes.DATE]
  }
});
export const TransformTypes = {
  DATE_TRUNC: 'DATE_TRUNC',
  DATETIME_TRUNC: 'DATETIME_TRUNC',
  DATE_PART: 'DATE_PART'
};
export const TransformType = checked.symbol(TransformTypes, 'TransformType');
export const Transform = checked.record({
  type: TransformType,
  arguments: checked.list(checked.any())
}, 'Transform');
export const FieldSources = {
  ASSOCIATION: 'ASSOCIATION',
  TABLE: 'TABLE',
  EXPRESSION: 'EXPRESSION'
};
const FieldSource = checked.symbol(FieldSources, 'FieldSources');
export const Field = checked.record({
  name: checked.string(),
  table: checked.string().optional(),
  source: FieldSource.defaultValue(FieldSources.TABLE),
  type: FieldType
}, 'Field');
export const UNDEFINED_BUCKET = '@@MISSING@@';
export const Column = checked.record({
  role: Role,
  domain: DomainType,
  field: Field,
  sort: ColumnSort.optional(),
  limit: Limit.optional(),
  fillDateInRaaS: checked.boolean().optional(),
  includeUndefinedBuckets: checked.boolean().defaultValue(true),
  undefinedBucket: checked.string().optional(),
  transform: Transform.optional(),
  useFiscalYear: checked.boolean().defaultValue(false),
  aggregation: AggregationType.optional(),
  fixedMeasure: checked.boolean().defaultValue(false),
  fixed: checked.list(checked.string()).optional(),
  name: checked.string().optional(),
  alias: checked.string()
}, 'Column');
export const NegativeNumberDefinitions = fromJS({
  [NegativeNumberTypes.AUTO]: {
    displayI18nKey: 'reporting-snowflake.column-options.formatting.negative-number-types.auto'
  },
  [NegativeNumberTypes.PARENTHESES]: {
    displayI18nKey: 'reporting-snowflake.column-options.formatting.negative-number-types.parentheses'
  },
  [NegativeNumberTypes.LEFT]: {
    displayI18nKey: 'reporting-snowflake.column-options.formatting.negative-number-types.left'
  },
  [NegativeNumberTypes.RIGHT]: {
    displayI18nKey: 'reporting-snowflake.column-options.formatting.negative-number-types.right'
  }
});
export const FormatTypeDefinitions = fromJS({
  [FormatTypes.AUTO]: {
    displayI18nKey: 'reporting-snowflake.formatTypes.AUTO'
  },
  [FormatTypes.PERCENTAGE]: {
    displayI18nKey: 'reporting-snowflake.formatTypes.PERCENTAGE'
  },
  [FormatTypes.NUMBER]: {
    displayI18nKey: 'reporting-snowflake.formatTypes.NUMBER'
  },
  [FormatTypes.CURRENCY]: {
    displayI18nKey: 'reporting-snowflake.formatTypes.CURRENCY'
  }
});
export const Formatting = checked.poly('type', {
  [NumberFormattingType]: NumberFormatting,
  [PercentFormattingType]: PercentFormatting,
  [CurrencyFormattingType]: CurrencyFormatting,
  [AutoFormattingType]: AutoFormatting
}, 'Formatting');
export const EncodingOptions = checked.record({
  format: Formatting
}, 'EncodingOptions');
export const defaultEncodingOptions = EncodingOptions({
  format: {
    type: AutoFormattingType,
    negativeSymbol: NegativeNumberTypes.AUTO,
    customPrecision: 2,
    useThousandsSeparator: true
  }
});
export const ExpressionField = checked.record({
  alias: checked.string(),
  label: checked.string(),
  derived: checked.boolean(),
  hidden: checked.boolean(),
  valid: checked.boolean(),
  input: checked.string().defaultValue(''),
  expression: Expression,
  type: Type,
  context: Context.defaultValue(Contexts.UNDEFINED),
  editor: Editor.defaultValue(DefaultRelationalEditor({
    editorState: {}
  })),
  // not persisted in the BE, purely for expression template fields to hold its default format hint
  formatHint: FormatType.defaultValue(FormatTypes.AUTO)
}, 'ExpressionField');
export const Encoding = checked.record({
  column: checked.string(),
  options: EncodingOptions.defaultValue(defaultEncodingOptions)
}, 'Encoding');
export const EncodingWithColumn = checked.record({
  column: Column,
  options: EncodingOptions.defaultValue(defaultEncodingOptions)
}, 'EncodingWithColumn');