'use es6';

import I18n from 'I18n';
import { collectSubaggregationKeys, createDenseDataset } from './helpers';

/**
 * Dataset date format
 *
 * @type {string}
 * @constant
 */
const DATE_FORMAT = 'YYYY-MM-DD';

/**
 * Date property types
 *
 * @type {string[]}
 * @constant
 */
const DATE_TYPES = ['date', 'datetime'];

/**
 * Whether property is a date property
 *
 * @param {string} dataType Data type
 * @param {Immutable.Map} propertyGroups Property groups
 * @param {string} property Property to check
 * @returns {boolean} Whether property is date property
 */
const isDatetime = (dataType, propertyGroups, property) => DATE_TYPES.includes(propertyGroups.getIn([dataType, property, 'type']));

/**
 * Ascending date comparator
 *
 * @param {string} first First point
 * @param {string} second Second point
 * @returns {number} Comparison value
 */
const comparator = (first, second) => {
  const [a, b] = [first, second].map(point => I18n.moment(point.get('key'), DATE_FORMAT));
  return a.isBefore(b) ? -1 : 1;
};

/**
 * Sort data by datetime second dimension
 *
 * @param {Immutable.Map} config Report configuration
 * @param {Immutable.Map} propertyGroups Property groups
 * @param {Immutable.Map} data Data format
 * @returns {Immutable.Map} Data format sorted by datetime second dimension
 */
export const sortBySecondDatetime = (config, propertyGroups, data) => {
  const dataType = config.get('dataType');
  const property = config.getIn(['dimensions', 1]);
  if (isDatetime(dataType, propertyGroups, property)) {
    const keys = collectSubaggregationKeys(data);
    return createDenseDataset(keys, data, config.getIn(['metrics', 0])).updateIn(['dimension', 'buckets'], outers => outers.map(outer => outer.updateIn(['dimension', 'buckets'], inner => inner.sort(comparator))));
  }
  return data;
};
export const __TESTABLE__ = {
  isDatetime,
  comparator
};