'use es6';

import { Map as ImmutableMap } from 'immutable';
import http from 'hub-http/clients/apiClient';
import { formatFeedback, formatFeedbackIds } from 'reference-resolvers/formatters/formatFeedback';
import { FEEDBACK_SUBMISSION_TYPE_ID } from 'reference-resolvers/constants/ObjectTypeIds';
export const createGetObjectsByIds = () => () => {
  // not implemented because formId needs to be provided
  // for this request, and the only input to this function
  // is an array of IDs
  throw new Error('Not implemented.');
};
export const getObjectsByIds = createGetObjectsByIds({
  httpClient: http
});
const mapResponse = ({
  hasMore,
  offset,
  total: count,
  results
}, formatter) => {
  return ImmutableMap({
    hasMore,
    offset: offset + results.length,
    count,
    results: formatter(results)
  });
};
const getCrmSearchFeedbackRequestData = ({
  count = 100,
  offset = 0,
  submissionIdQuery
}) => {
  return {
    count,
    offset,
    query: null,
    objectTypeId: FEEDBACK_SUBMISSION_TYPE_ID,
    filterGroups: submissionIdQuery ? [{
      filters: [{
        property: 'hs_object_id',
        operator: 'IN',
        values: [submissionIdQuery]
      }]
    }] : [],
    sorts: [{
      property: 'hs_created_at',
      order: 'DESC'
    }]
  };
};
const searchSubmissionIds = (httpClient, request) => {
  const {
    count,
    offset,
    query: submissionIdQuery
  } = request.toJS();
  const data = getCrmSearchFeedbackRequestData({
    count,
    offset,
    submissionIdQuery
  });
  return httpClient.post('crm-search/search/ids', {
    data
  }).then(response => mapResponse(response, formatFeedbackIds));
};
const fetchBySurveyIdAndType = (httpClient, request) => {
  return httpClient.post('feedback/v1/submissions/', {
    data: request
  }).then(response => mapResponse(response, formatFeedback));
};
export const createSearchObjects = ({
  httpClient
}) => request => {
  const specifiedIdAndType = request.has('surveyId') && request.has('surveyType');
  if (specifiedIdAndType) {
    return fetchBySurveyIdAndType(httpClient, request);
  }
  return searchSubmissionIds(httpClient, request);
};
export const searchObjects = createSearchObjects({
  httpClient: http
});