'use es6';

import { fromJS, List, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { Metric } from '../../../../config/metrics';
import { matchCreated } from '../../../../configure/bucket/created';
import * as Frequency from '../../../../constants/frequency';
import { CONTACT_SEARCH_AGGREGATION_MAX_SIZE, CONTACT_SEARCH_AGGREGATION_MIN_SIZE, MAX_NUM_OF_METRICS } from '../../../../constants/limits';
import * as MetricTypes from '../../../../constants/metricTypes';
import { IN } from '../../../../constants/operators';
import { STRING } from '../../../../constants/property-types';
import { ASC } from '../../../../constants/sortOrder';
import * as SortType from '../../../../constants/sortType';
import { DeprecatedPropertyException, InvalidPropertiesException, TooManyMetricsException } from '../../../../exceptions';
import invariant from '../../../../lib/invariant';
import { countUniqueMetrics } from '../../../../lib/countUniqueMetrics';
import getfilterGroupsExtractor from '../../common/extractors/reportFilters';
import { DEFAULT_NULL_VALUES } from '../../../../constants/defaultNullValues';
const AGGREGATION_TYPES = {
  DATE: 'DATE_HISTOGRAM',
  DATETIME: 'DATE_HISTOGRAM',
  TIMESTAMP: 'DATE_HISTOGRAM',
  ENUMERATION: 'TERMS',
  STRING: 'TERMS',
  CURRENCY: 'TERMS',
  NUMBER: 'TERMS',
  ID: 'TERMS',
  PERCENT: 'TERMS',
  BOOL: 'TERMS',
  BUCKETS: 'BUCKETS'
};
const HISTOGRAM_TYPES = {
  [Frequency.DAY]: {
    intervalUnit: 'DAY'
  },
  [Frequency.WEEK]: {
    intervalUnit: 'WEEK'
  },
  [Frequency.MONTH]: {
    intervalUnit: 'MONTH'
  },
  [Frequency.QUARTER]: {
    intervalUnit: 'QUARTER'
  },
  [Frequency.YEAR]: {
    intervalUnit: 'YEAR'
  }
};
const DEFAULT_FREQUENCY = Frequency.MONTH;
const DEPRECATED_PROPERTIES = ['hs_deal_closed_won_date' // RA-1411
];
const UNSUPPORTED_SORT_PROPERTIES = ['lifecyclestage.displayOrder'];
const shouldStripSubaggMetricSort = sort => !sort.type || sort.type === SortType.COUNT || sort.type === SortType.METRIC && !/.displayOrder$/.test(sort.property);
const cleanAggregations = (inputAggregation, definition) => {
  const {
    size,
    property,
    type,
    sort,
    metrics,
    subAggregations,
    buckets
  } = inputAggregation;
  const aggregation = {
    size,
    property,
    type
  };
  if (sort && sort.property && !UNSUPPORTED_SORT_PROPERTIES.includes(sort.property)) {
    if (sort.property === 'count' || sort.type === SortType.COUNT) {
      sort.type = SortType.COUNT;
      delete sort.metricType;
    } else if (sort.type !== SortType.ALPHA) {
      sort.type = SortType.METRIC;
      let sortMetric = metrics.find(({
        property: prop
      }) => prop === sort.property);
      if (!sortMetric) {
        sortMetric = {
          property: sort.property,
          metricTypes: [sort.metricType || MetricTypes.SUM]
        };
        metrics.push(sortMetric);
      }
      if (!sort.metricType) {
        sort.metricType = sortMetric.metricTypes[0] || MetricTypes.SUM;
      }
    }
    aggregation.sort = sort;
  }
  if (metrics != null && metrics.length !== 0 && !UNSUPPORTED_SORT_PROPERTIES.includes(sort && sort.property)) {
    aggregation.metrics = metrics;
  }
  if (subAggregations != null) {
    aggregation.subAggregations = subAggregations;
  }
  if (type === 'BUCKETS') {
    const actual = definition.get('property');
    if (actual) {
      aggregation.property = actual;
    }
    aggregation.buckets = buckets;
  }
  return aggregation;
};
const buildMetrics = config => config.get('metrics', List()).filter(metric => metric.get('property') !== 'count').groupBy(metric => metric.get('property')).map((metrics, property) => metrics.reduce((metric, nextMetric) => Metric({
  property,
  metricTypes: ImmutableSet(nextMetric.get('metricTypes').concat(metric.get('metricTypes'))).toList(),
  percentiles: metric.get('percentiles') || nextMetric.get('percentiles')
}))).valueSeq().toSet().toJS();
const stableSort = (config, dimensions, property, sort) => {
  const dimensionCount = config.get('dimensions').count();
  const isSubAggregation = dimensionCount === 2 && dimensions.count() === 1;
  if (!isSubAggregation || sort && !shouldStripSubaggMetricSort(sort)) {
    return sort;
  }
  return {
    property,
    order: ASC,
    type: SortType.ALPHA
  };
};
const buildAggregations = (config, dimensions, properties) => {
  if (dimensions.isEmpty()) {
    return null;
  }
  const limit = config.get('limit') || 0;
  let size = limit;
  if (limit >= CONTACT_SEARCH_AGGREGATION_MAX_SIZE) {
    size = CONTACT_SEARCH_AGGREGATION_MAX_SIZE;
  } else if (limit <= CONTACT_SEARCH_AGGREGATION_MIN_SIZE) {
    size = CONTACT_SEARCH_AGGREGATION_MIN_SIZE;
  }
  const property = dimensions.first();
  const definition = properties.get(property, ImmutableMap());
  const propertyType = definition.get('type');
  const defaultNullValue = definition.get('defaultNullValue', DEFAULT_NULL_VALUES[definition.get('reportingOverwrittenNumericType') ? 'NUMBER' : propertyType.toUpperCase()]);
  const aggType = AGGREGATION_TYPES[propertyType.toUpperCase()];
  const sortableProperties = config.get('metrics').reduce((memo, metric) => memo.add(metric.get('property')), ImmutableSet());
  const aggSort = config.get('sort', List()).find(sort => sortableProperties.includes(sort.get('property')) || typeof sort.get('property') === 'string' && sort.get('property').startsWith(property));
  const buckets = definition.get('buckets');
  const aggregation = cleanAggregations(Object.assign({
    size,
    property,
    type: aggType,
    sort: stableSort(config, dimensions, property, aggSort ? aggSort.toJS() : null),
    metrics: buildMetrics(config),
    subAggregations: buildAggregations(config, dimensions.rest(), properties)
  }, aggType === 'BUCKETS' ? {
    buckets
  } : {}), definition);
  switch (aggType) {
    case 'DATE_HISTOGRAM':
      {
        const frequency = config.get('frequency') || DEFAULT_FREQUENCY;
        return [Object.assign({}, aggregation, HISTOGRAM_TYPES[frequency])];
      }
    case 'TERMS':
      return [Object.assign({}, aggregation, defaultNullValue != null ? {
        defaultNullValue
      } : {})];
    case 'BUCKETS':
      return [aggregation];
    default:
      return invariant(false, 'expected valid property type for aggregation dimension, but got %s', propertyType);
  }
};

/**
 * Validate dimensions and filter groups
 * @param {List} dimensions Dimensions
 * @param {Array} filterGroups Filter groups
 * @param {List} metrics Metrics
 * @param {Map} properties Datatype properties
 * @throws
 */
const validateProperties = (dimensions, filterGroups, metrics, properties) => {
  const keys = properties.keySeq().toSet();
  const missing = property => !keys.includes(property);
  const invalidDimensions = dimensions.reduce((checks, property) => [/* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
  ...checks, ...(missing(property) ? [property] : [])], []);
  const invalidFilters = filterGroups.reduce((checks, {
    filters
  }) => [/* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
  ...checks, ...filters.reduce((memo, {
    property
  }) => [/* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
  ...memo, ...(missing(property) ? [property] : [])], [])], []);
  const invalidMetrics = metrics.map(metric => metric.get('property')).reduce((checks, property) => [/* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
  ...checks, ...(missing(property) ? [property] : [])], []);
  const invalid = [...invalidDimensions, ...invalidFilters, ...invalidMetrics];
  const [deprecated] = invalid.filter(property => DEPRECATED_PROPERTIES.includes(property));
  if (deprecated != null) {
    // after this has been live for a while, run a job that just deletes
    // any report with this property
    throw new DeprecatedPropertyException(deprecated);
  } else if (invalid.length > 0) {
    throw new InvalidPropertiesException(invalid);
  } else if (countUniqueMetrics(metrics) > MAX_NUM_OF_METRICS) {
    throw new TooManyMetricsException();
  }
};

/**
 * Build a ContactsSearch request
 * @param  {Map} config report configuration
 * @param  {Map} propertyGroups Property groups
 * @return {ContactsSearch} Contact search payload
 * @throws
 */
export default ((config, propertyGroups) => {
  if (matchCreated(config)) {
    config = config.set('dimensions', List());
  }
  const dataType = config.get('dataType');
  const properties = propertyGroups.get(dataType);
  const filterGroupsExtractor = getfilterGroupsExtractor();
  const filterGroups = filterGroupsExtractor(config);
  filterGroups[0].filters.forEach(filter => {
    if (filter.operator !== IN) {
      return;
    }
    const property = properties.get(filter.property);
    if (property && property.get('type') === STRING) {
      filter.propertySuffix = 'raw';
    }
  });
  const metrics = config.get('metrics');
  const dimensions = config.get('dimensions');
  validateProperties(dimensions, filterGroups, metrics, properties);
  const payload = dimensions.isEmpty() ? fromJS({
    metrics: buildMetrics(config),
    filterGroups
  }) : fromJS({
    aggregations: buildAggregations(config, dimensions, properties),
    metrics: buildMetrics(config),
    filterGroups
  });
  return fromJS(payload);
});
export const __TESTABLE__ = {
  cleanAggregations,
  UNSUPPORTED_SORT_PROPERTIES
};