import http from 'hub-http/clients/apiClient';
import { registerQuery } from 'data-fetching-client';
// API
export const getFeatureUsage = () => {
  return http.get('feature/store/v1/usage');
};

// Register Query
export const GET_FEATURE_USAGE_QUERY = registerQuery({
  fieldName: 'getFeatureUsage',
  fetcher: () => getFeatureUsage()
});

// New Feature Store API
export const getFeatureUsageMappings = () => {
  return http.get('feature/store/v1/usage/mappings');
};

// Register Query
export const GET_FEATURE_USAGE_MAPPINGS_QUERY = registerQuery({
  fieldName: 'getFeatureUsageMappings',
  fetcher: () => getFeatureUsageMappings()
});