'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import * as DataTypes from '../constants/dataTypes';
import { HYDRATE } from '../constants/phases';
import getUnhydratedUniqueValues from '../dataset/getUnhydratedUniqueValues';
import { load } from '../dataTypeDefinitions';
import settled from '../lib/settled';
import getProperties from '../properties';
import { connectedPhase } from '../redux/resolve/connected';
import { adapt } from '../references/adapt';
import { makeOption } from '../references/Option';
import ownerReferences from '../references/owner';
import getCrossObjectProperties from '../retrieve/inboundDb/aggregate/cross-object/properties';
import { formatForScientificNotation } from '../v2/dataset/utils';
import hydrateDataset from './dataset';

/*
 * NOTE: we could set up the `global` reference manager to follow a similar
 * pattern where we define a `referencedObjectType` field on properties to
 * indicate when it should be used for reference lookup rather than setting it
 * on the module level
 */
const referencedObjectTypes = {
  OWNER: adapt(ownerReferences)
};
const getReferences = ({
  config = ImmutableMap(),
  data = List(),
  properties = ImmutableMap(),
  referenceGetters: getters = ImmutableMap(),
  response
}) => {
  const dataType = config.get('dataType');
  return load(dataType).then(module => {
    const metricProperties = config.get('metrics', List()).map(metric => metric.get('property'));
    const reportProperties = config.get('dimensions', List()).concat(config.get('properties', List())).concat(metricProperties);
    const promises = [...reportProperties].reduce((result, property) => {
      const getter = getters.get(property);
      if (getter) {
        return [...result, getter(config, property, data, response, undefined, {
          excludeNull: properties.getIn([dataType, property, 'reportingOverwrittenNumericType']),
          overwrittenNullLabel: properties.getIn([dataType, property, 'reportingOverwrittenNumericNullLabel'])
        })];
      }
      const referenceDataType = module.getIn(['referenceProperties', property], null);
      if (referenceDataType) {
        const ids = getUnhydratedUniqueValues(property, data).toList().map(formatForScientificNotation);
        const referencePromise = load(referenceDataType).then(referenceModule => referenceModule.get('hydrate')(ids, config).then(labelsById => ImmutableMap({
          key: property,
          value: labelsById.map((label, id) => makeOption(id, label))
        })));
        return [...result, referencePromise];
      }
      return result;
    }, []);
    return settled(promises).then(resolves => resolves.reduce((result, {
      state,
      value
    }) => {
      if (state === 'fulfilled' && value) {
        const {
          properties: props
        } = result;
        return {
          properties: props.setIn([dataType, value.get('key'), 'options'], value.get('value'))
        };
      }
      return result;
    }, {
      properties
    })).then(result => Object.assign({}, result));
  });
};
export const hydrate = ({
  dataConfig,
  dataset,
  response
}) => {
  const dataType = dataConfig.get('dataType');
  return load(dataType).then(module => {
    const propertiesPromise = dataType === DataTypes.CROSS_OBJECT ? getCrossObjectProperties(dataConfig) : getProperties(dataType);
    const updatedPropertiesPromise = propertiesPromise.then(properties => {
      const referencedObjectTypeGetters = properties.get(dataType).filter(property => referencedObjectTypes.hasOwnProperty(property.get('referencedObjectType'))).map(property => referencedObjectTypes[property.get('referencedObjectType')]);

      // NOTE: references object type getters should only pull in getters for
      // required data types rather than flattening every which can cause
      // getters to be overriden by other properties with the same key (RA-1413)
      const referenceGetters = referencedObjectTypeGetters.merge(module.get('references', ImmutableMap()));
      return getReferences({
        config: dataConfig,
        data: dataset,
        properties,
        referenceGetters,
        response
      });
    }).then(({
      properties
    }) => properties);
    return updatedPropertiesPromise.then(updatedProperties => hydrateDataset(dataConfig, updatedProperties, dataset));
  });
};
export const connectedHydrate = connectedPhase(HYDRATE, hydrate);