import { enhanceTrackerWithFullstory } from 'data-ops-fullstory';
import { createTracker } from 'usage-tracker';
import events from '../../events.yaml';
export const Tracker = enhanceTrackerWithFullstory(createTracker({
  events,
  onError: err => {
    console.error(err);
  }
}));
export const EVENTS = Object.keys(events).reduce((map, event) => Object.assign({}, map, {
  [event]: event
}), {});
export const track = (eventKey, eventProperties = {}) => {
  Tracker.track(eventKey, Object.assign({}, eventProperties));
};