'use es6';

import { Map as ImmutableMap } from 'immutable';
import { currency as formatCurrency } from '../hydrate/numberFormatter';
import fetchProperties from '../properties';
import { extractPropertyNamespace } from '../properties/namespaceProperty';
import { getCurrencyInformation } from '../public/currency';
const getProperties = config => fetchProperties(config.get('dataType')).then(result => result.reduce((memo, properties) => memo.merge(properties), ImmutableMap()));
const getExchangeRate = (information, currencyCode) => {
  const {
    currencyExchangeRates,
    hasMulticurrencyActive
  } = information;
  if (!hasMulticurrencyActive || !currencyExchangeRates) {
    console.error('[reporting-data] currency information not found for this portal');
    return 1;
  }
  const {
    conversionMultiplier
  } = currencyExchangeRates.find(({
    fromCurrencyCode
  }) => {
    return fromCurrencyCode === currencyCode;
  }) || {};
  if (!conversionMultiplier) {
    console.error('[reporting-data] currency processor not supported for inactive currency %s', currencyCode);
    return 1;
  }
  if (conversionMultiplier <= 0) {
    console.error('[reporting-data] invalid exchange rate $s for currency $s', conversionMultiplier, currencyCode);
    return 1;
  }
  return conversionMultiplier;
};
const isCurrencyProperty = (properties = ImmutableMap(), property) => {
  const {
    propertyName
  } = extractPropertyNamespace(property);
  return properties.getIn([property, 'type'], properties.getIn([propertyName, 'type'])) === 'currency';
};
const formatMetrics = (properties, exchangeRate, currencyCode, preCovertedCurrencyProperties = []) => (metrics = ImmutableMap()) => {
  return metrics.map((metric = ImmutableMap(), property) => {
    return isCurrencyProperty(properties, property) ? metric.update(metricTypes => {
      return metricTypes.map(value => {
        if (value === null) {
          return value;
        }
        const exchangeHasBeenApplied = preCovertedCurrencyProperties.includes(property);
        const exchanged = exchangeHasBeenApplied ? value : value / exchangeRate;
        return ImmutableMap({
          raw: exchanged,
          formatted: formatCurrency(exchanged, {
            currencyCode
          }),
          propertyMeta: {
            type: 'currency',
            currencyCode
          }
        });
      });
    }) : metric;
  });
};
const process = (data, properties, exchangeRate, currencyCode) => {
  const preCovertedCurrencyProperties = data.get('preCovertedCurrencyProperties') || [];
  const updated = data.update('metrics', formatMetrics(properties, exchangeRate, currencyCode, preCovertedCurrencyProperties));
  return updated.hasIn(['dimension', 'buckets']) ? updated.updateIn(['dimension', 'buckets'], (buckets = ImmutableMap()) => {
    return buckets.map(bucket => {
      return bucket.has('dimension') ? process(bucket, properties, exchangeRate, currencyCode) : bucket.update('metrics', formatMetrics(properties, exchangeRate, currencyCode, preCovertedCurrencyProperties));
    });
  }) : updated;
};
export default ((currencyCode, testXRate) => ({
  dataConfig,
  dataset
}) => {
  return Promise.all([getCurrencyInformation(), getProperties(dataConfig)]).then(([information, properties]) => {
    const exchangeRate = testXRate || getExchangeRate(information, currencyCode);
    return exchangeRate !== 1 ? process(dataset, properties, exchangeRate, currencyCode) : dataset;
  });
});