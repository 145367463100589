import http from 'hub-http/clients/apiClient';
import { handleAiReportSearchResponse } from '../utils/aiSearchUtils';
import { AI_REPORT_TEMPLATES_SEARCH_CONFIG } from '../constants/aiSearch';

/**
 * Creates a function that handles AI-powered report template searches.
 * This factory function maintains a reference to the last request to support request cancellation.
 *
 * @returns {Function} A function that performs the AI report search
 */
const createFetchAiReportsSearch = () => {
  let lastRequest = null;
  return ({
    userInput,
    clicks,
    templatesByKey
  }) => {
    if (lastRequest && lastRequest.readyState !== 4) {
      lastRequest.abort();
    }
    const chatRequest = {
      retrievalRequest: {
        input: userInput
      },
      configuration: AI_REPORT_TEMPLATES_SEARCH_CONFIG
    };
    return http.post(`/opshub-ai-support-chat/v0/support?dummy=${clicks}`, {
      data: chatRequest,
      timeout: 8000,
      withXhr: xhr => {
        lastRequest = xhr;
      }
    }).then(response => {
      return handleAiReportSearchResponse(response, templatesByKey);
    });
  };
};

/**
 * Performs an AI-powered search for report templates based on user input.
 * Automatically cancels any pending previous request.
 *
 * @param {string} params.userInput - The user's search query
 * @param {Map<string, ReportTemplate>} [params.templatesByKey] - Optional map of template keys to template objects
 * used to reduce the number of API calls if the templates are already stored
 * @returns {Promise<AiReportTemplateSearchResponse>} Promise resolving to the search results
 */
export const fetchAiReportsSearch = createFetchAiReportsSearch();

/**
 * Submits feedback for an AI support chat interaction.
 *
 * @param {SupportChatFeedbackRequestBody} feedback - The feedback data containing correlation ID and accepted/rejected values
 * @returns {Promise<any>} Promise resolving to the feedback submission response
 */
export function submitSupportChatFeedback(feedback) {
  return http.post(`/opshub-ai-support-chat/v0/support/feedback`, {
    data: [feedback]
  });
}