'use es6';

import { fromJS, List, Map as ImmutableMap } from 'immutable';
import { isComparisonConfig } from '../../compare';
import { configureWithStages as configureDealStageDuration } from '../../configure/pipelineStage/dealStageDuration';
import { configureWithStages as configurePipelineStageDuration } from '../../configure/pipelineStage/duration';
import { configurePercentile as configureMedian } from '../../configure/percentile/percentile';
import { AGGREGATION, FUNNEL, PIPELINE, SEARCH } from '../../constants/configTypes';
import { UNKNOWN, VALIDATION_ERROR, PERMISSION_ERROR } from '../../constants/errorTypes';
import { resolveV2CustomSeries } from '../../customSeries/resolveV2CustomSeries';
import { RaasResolveException, NoDimensionsOrMetricsException } from '../../exceptions';
import { createExceptionFromErrorType } from '../../lib/createExceptionFromErrorType';
import { RUNTIME_OPTIONS } from '../../main';
import * as http from '../../request/http';
import * as preconditions from '../../retrieve/inboundDb/aggregate/preconditions';
import { enhanceColumnDataset } from '../dataset/enhanceColumns';
import { buildDataset } from './buildDataset';
import { getDatasetWithPropertyMetaFromConfig } from './datasetWithPropertyMetaOptions';
import { applyCompareFixes, applyEndpointFixes, applyReportFixes } from './fixes';
import { createFunnelSummary } from './postprocess/funnelSummary';
import { getPaginatedResponse } from './postprocess/getPaginatedResponse';
import { userInfo } from '../../request/user-info';
import { fromMetricKey, isMetricWithType } from '../dataset/datasetMetrics';
import { DATASET_INSIGHT_DATASET_PREFIX } from '../../tsTypes/datasetInsightTypes';
import { getDatasetInsightParams, getReportChartType } from '../../report/reportGetters';
import { getConfigType, getConfigTypeFromConfig, getDimensionFromConfig, getDimensions, getDisplayParamsShowTotals, getMetrics, getMetricPropertiesFromConfig, getReportConfig } from '../../report/configReportGetters';
import { isConfigReport } from '../../tsTypes/reportTypes';
import { ReportingLogger } from '../../monitoring/reportingLogger';
const formatConfig = (reportConfig, response) => {
  const header = response.get('header');
  const configureMedianResult = configureMedian(reportConfig);
  const configureDealStageDurationResult = configureDealStageDuration(configureMedianResult, header, true);
  return configurePipelineStageDuration(configureDealStageDurationResult, header);
};
const getDatasetBuildOptionsFromConfig = config => {
  const configType = getConfigTypeFromConfig(config);
  const metricProperties = getMetricPropertiesFromConfig(config);
  return {
    isSearch: configType === SEARCH,
    isRelational: false,
    removeLastRowPercentiles: configType === FUNNEL || configType === PIPELINE,
    hasCurrencyDimension: currencyCodeColumn => getDimensionFromConfig(config).includes(currencyCodeColumn),
    isMeasure: columnName => isMetricWithType(columnName) || metricProperties.some(metricProperty => metricProperty === columnName),
    getMeasureType: columnName => fromMetricKey(columnName).type
  };
};
export const processDataset = (isTotalsRequest, originalDataset, report, gates) => {
  const maybePaginatedDataset = isTotalsRequest ? originalDataset : getPaginatedResponse(report, originalDataset);
  const reportConfig = isConfigReport(report) && getReportConfig(report);
  return ImmutableMap({
    config: formatConfig(reportConfig, originalDataset),
    data: enhanceColumnDataset(fromJS(buildDataset(getDatasetWithPropertyMetaFromConfig(reportConfig, maybePaginatedDataset), getDatasetBuildOptionsFromConfig(reportConfig), gates)), reportConfig, gates)
  });
};
export const formatRaaSAPIResponse = (report, datasets, isTotalsRequest, gates = []) => {
  let currentDatasetKey = null;
  try {
    return ['primary', 'compare'].reduce((processedDataSets, datasetKey) => {
      currentDatasetKey = datasetKey; // for error handling
      const matchedDataset = datasets.get(`${datasetKey}DataSet`);
      if (!matchedDataset) {
        return processedDataSets;
      } else {
        let updatedDataSets = processedDataSets.set(datasetKey, processDataset(isTotalsRequest, matchedDataset, report, gates));
        const insightsDatasets = matchedDataset.get('insights');
        const hasInsightsDatasets = insightsDatasets && insightsDatasets.count();
        if (hasInsightsDatasets) {
          insightsDatasets.forEach((insightsDataset, index) => {
            updatedDataSets = updatedDataSets.set(`${DATASET_INSIGHT_DATASET_PREFIX}-${datasetKey}-${index}`, processDataset(isTotalsRequest, insightsDataset.get('dataSet'), report, gates).setIn(['data', 'insightMeta'], insightsDataset.get('insightMeta')).setIn(['data', 'insightOption'], insightsDataset.get('insightOption')));
          });
        }
        return updatedDataSets;
      }
    }, ImmutableMap());
  } catch (error) {
    throw new RaasResolveException(currentDatasetKey, error);
  }
};
const makeReportingApiRequest = (report, useLocalReportingApis, isTotalsRequest, datasetMockResolver) => {
  return userInfo().then(({
    gates
  }) => {
    const fixedReportData = applyReportFixes(report);
    report = fixedReportData.report;
    const fixedEndpointData = applyEndpointFixes(report, gates);
    const chartType = getReportChartType(fixedEndpointData.report);
    const visualization = fixedEndpointData.report.get('visualization');
    const datasetRequestBody = {
      data: Object.assign({}, chartType ? {
        chartType
      } : {}, visualization ? {
        visualization
      } : {}, isConfigReport(fixedEndpointData.report) ? {
        config: getReportConfig(fixedEndpointData.report)
      } : {}, !isTotalsRequest && fixedEndpointData.report.has('insightParams') ? {
        insightParams: getDatasetInsightParams(fixedEndpointData.report)
      } : null, fixedEndpointData.externalizedData, fixedEndpointData.report.has('displayParams') ? {
        displayParams: fixedEndpointData.report.get('displayParams')
      } : {})
    };
    const fetchDataset = datasetMockResolver ? Promise.resolve(datasetMockResolver(datasetRequestBody)) : http.post('reporting/v2/dataset', datasetRequestBody, {
      useLocalReportingApis
    });
    return Promise.resolve(fetchDataset).then(datasets => ({
      datasets,
      gates
    }));
  }).then(({
    datasets,
    gates
  }) => formatRaaSAPIResponse(report, datasets, isTotalsRequest, gates));
};
export const generateError = rawError => {
  const response = rawError.responseJSON;
  if ([VALIDATION_ERROR, PERMISSION_ERROR].includes(response === null || response === void 0 ? void 0 : response.category)) {
    return createExceptionFromErrorType(response.subCategory, response.context, rawError);
  }
  return createExceptionFromErrorType(UNKNOWN, null, rawError);
};
export const reportingApiResolve = (report, {
  useLocalReportingApis,
  validationResolve,
  runtimeMockResolvers,
  fiscalYearMonthName
} = RUNTIME_OPTIONS, useReportingLogger = true) => {
  var _dimensions$isEmpty, _metrics$isEmpty;
  const reportingLogger = useReportingLogger && new ReportingLogger();
  const datasetMockResolver = runtimeMockResolvers && runtimeMockResolvers.dataset;
  if (reportingLogger) {
    reportingLogger.addReportAttributes(report.update('config', config => config.set('v2', true)));
    reportingLogger.addAttribute('datasetApiVersion', 'v2');
    reportingLogger.addAttribute('raasBackendResolve', true);
    if (validationResolve) {
      reportingLogger.addAttribute('raasBackendValidation', true);
    }
  }
  const dimensions = getDimensions(report);
  const metrics = getMetrics(report);
  if ((_dimensions$isEmpty = dimensions.isEmpty) !== null && _dimensions$isEmpty !== void 0 && _dimensions$isEmpty.call(dimensions) && (_metrics$isEmpty = metrics.isEmpty) !== null && _metrics$isEmpty !== void 0 && _metrics$isEmpty.call(metrics)) {
    throw new NoDimensionsOrMetricsException();
  }
  const isConfig = isConfigReport(report);
  const reportConfig = isConfig && getReportConfig(report);
  const isCompare = isComparisonConfig(reportConfig);
  const isFunnelOrPipeline = isConfig && [FUNNEL, PIPELINE].includes(getConfigType(report));
  const needTotalsReport = isConfig && ((dimensions || List()).count() !== 0 || getConfigType(report) !== SEARCH) && getDisplayParamsShowTotals(report);
  let apiRequests = List();
  if (needTotalsReport && !isFunnelOrPipeline) {
    const poppedDimension = dimensions.last();
    const summaryReport = report.updateIn(['config', 'dimensions'], val => val.pop()).setIn(['config', 'configType'], AGGREGATION).updateIn(['config', 'sort'], sorts => sorts.filter(sort => {
      const propertyWithoutSuffix = sort.get('property', '').split('.')[0];
      return propertyWithoutSuffix !== poppedDimension;
    }));
    apiRequests = apiRequests.push(makeReportingApiRequest(summaryReport, useLocalReportingApis, true, datasetMockResolver).then(datasets => {
      return fromJS({
        summary: datasets.get('primary')
      });
    }));
  }
  if (isCompare) {
    const fixedCompareReport = applyCompareFixes(report);
    report = fixedCompareReport.report;
  }
  apiRequests = apiRequests.push(makeReportingApiRequest(report, useLocalReportingApis, false, datasetMockResolver));
  return preconditions.generate(reportConfig).then(() => Promise.all(apiRequests.toJS()).then(datasets => {
    const primaryDataset = fromJS(datasets).last().getIn(['primary', 'data']);
    return Promise.all([...datasets, resolveV2CustomSeries(report, primaryDataset, true, fiscalYearMonthName)]);
  }).then(datasets => {
    if (needTotalsReport && isFunnelOrPipeline) {
      datasets = createFunnelSummary(datasets);
    }
    const dataObject = fromJS(datasets).reduce((a, b) => a.merge(b));
    if (reportingLogger) {
      reportingLogger.logSuccess();
    }
    return dataObject;
  }).catch(error => {
    if (reportingLogger) {
      reportingLogger.logError(error.errorBody || error, 'NewReportingAPIRequestFailed');
    }
    if (validationResolve) {
      throw error;
    }
    generateError(error.errorBody ? error.errorBody : error);
  }));
};
export const __TESTABLE__ = {
  getDatasetBuildOptionsFromConfig
};