'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { slug } from '../../../../lib/slug';
import { CONTACTS, DEALS } from '../../../../constants/dataTypes';
import { PIPELINE, FUNNEL } from '../../../../constants/configTypes';
import getDateRangeExtractor from '../../common/extractors/date-range';
import generateSearchFilter from './search-filter';
const defaultContactStages = {
  last_visit: 'hs_analytics_last_visit_timestamp',
  recent_conversion: 'recent_conversion_date',
  email_last_open: 'hs_email_last_open_date',
  email_last_click: 'hs_email_last_click_date',
  feedback_last_survey: 'hs_feedback_last_survey_date'
};
const contactStageToProperty = stage => {
  if (defaultContactStages[stage]) {
    return defaultContactStages[stage];
  }
  return `hs_date_entered_${stage}`;
};
const dealStageToProperty = stage => `dealstages.${slug(stage)}_entered`;
const stageToPropertyForDataType = {
  [CONTACTS]: contactStageToProperty,
  [DEALS]: dealStageToProperty
};
const stageToProperty = (dataType, stage) => stage === 'create' ? 'createdate' : stageToPropertyForDataType[dataType](stage);
const generateStageFilter = (dataType, dateRange, stage) => generateSearchFilter(stageToProperty(dataType, stage), dateRange);
const generateFunnelStageFilters = (dataType, dateRange, stages, index) => {
  return stages.take(index + 1).reduce((reduction, stage) => {
    return reduction.push(generateStageFilter(dataType, dateRange, stage));
  }, List());
};
const generatePipelineStageFilters = (dataType, dateRange, stages, index) => List.of(generateStageFilter(dataType, dateRange, stages.get(index)));
const generateStageFiltersByConfigType = {
  [PIPELINE]: generatePipelineStageFilters,
  [FUNNEL]: generateFunnelStageFilters
};
const generateStageFiltersSpecificToDataType = {
  [CONTACTS]: () => List(),
  [DEALS]: pipelineId => List.of(ImmutableMap({
    property: 'pipeline',
    operator: 'EQ',
    value: pipelineId
  }))
};
const generateFilters = (dataType, configType, dateRange, pipelineId, stages, index) =>
// add the common filters
generateStageFiltersByConfigType[configType](dataType, dateRange, stages, index)
// add the dataType specific filters
.concat(generateStageFiltersSpecificToDataType[dataType](pipelineId));
const generateSkipFilters = (configType, stages, stageFilters, dataType, dateRange) => {
  if (configType !== PIPELINE) {
    return List();
  }
  return stageFilters.butLast().map((stageFilter, i) => stageFilter.update('filterGroups', filterGroups => {
    const nonStageFilters = filterGroups.first().get('filters').filter(filter => filter.get('property') !== stageToProperty(dataType, stages.get(i)));
    return stages.skip(i).map(stage => {
      return ImmutableMap({
        filters: nonStageFilters.push(generateStageFilter(dataType, dateRange, stage))
      });
    });
  }));
};
export default ((config, dataType, stages, pipelineId) => {
  const configType = config.get('configType');
  const dateRange = getDateRangeExtractor()(config).get('value');
  const stageCounts = stages.map((stage, index, entire) => ImmutableMap({
    filterGroups: List.of(ImmutableMap({
      filters: generateFilters(dataType, configType, dateRange, pipelineId, entire, index).concat(config.getIn(['filters', 'custom'], List()))
    }))
  }));
  return stageCounts.concat(generateSkipFilters(configType, stages, stageCounts, dataType, dateRange));
});