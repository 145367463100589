'use es6';

import { List, Map as ImmutableMap, fromJS } from 'immutable';
import store from './redux/store';
import connectActions from './redux/connectActions';
import { getConfigId, getDataPromise } from './redux/resolve/get';
import { addConfig as addConfigAction, setConfigured as setConfiguredAction, setPromise as setPromiseAction, setStatus as setStatusAction, setData as setDataAction, debug as debugAction } from './redux/resolve/actions';
import * as StatusTypes from './redux/StatusTypes';
import { Promise } from './lib/promise';
import rethrow from './lib/rethrow';
import { connectedConfigure, configure as runConfigure } from './configure';
import { connectedRetrieve } from './retrieve';
import { connectedProcess } from './process';
import { connectedHydrate } from './hydrate';
import { isComparisonConfig, splitConfig } from './compare';
import invariant from './lib/invariant';
import { densify } from './dataset/dense-compare';
import { ENGAGEMENTS, CROSS_OBJECT } from './constants/dataTypes';
import { SEARCH } from './constants/configTypes';
import { fromDataset } from './v2/dataset/convert';
import getProperties from './properties';
import getCrossObjectProperties from './retrieve/inboundDb/aggregate/cross-object/properties';
import { NoDimensionsOrMetricsException } from './exceptions';
import { resolveV2CustomSeries } from './customSeries/resolveV2CustomSeries';
import { ReportingLogger } from './monitoring/reportingLogger';
const getSummaryV2Dataset = (config, dataset, properties) => runConfigure(config).then(configured => {
  const dimensionCount = configured.get('dimensions').count();
  if (dimensionCount === 0 || config.get('configType') === SEARCH) {
    return undefined;
  }
  const summaryConfig = configured.update('dimensions', dimensions => dimensions.pop()).update('metrics', metrics => metrics.shift());
  const summaryDataset = dimensionCount === 1 ? dataset.delete('dimension') : dataset.updateIn(['dimension', 'buckets'], buckets => buckets.map(bucket => bucket.delete('dimension')));
  return ImmutableMap({
    config: summaryConfig.delete('v2'),
    data: fromDataset(summaryConfig, summaryDataset, properties, {
      isSummary: true
    })
  });
});
const upgradeToV2 = (config, dataset, properties, comparePropertyLabel) => {
  const propertiesPromise = config.get('dataType') === CROSS_OBJECT ? getCrossObjectProperties(config) : Promise.resolve(properties);
  return runConfigure(config).then(configuredReport => {
    return propertiesPromise.then(mergedProperties => ImmutableMap({
      config: configuredReport.delete('v2'),
      data: fromDataset(configuredReport, dataset, mergedProperties, {
        comparePropertyLabel
      })
    }));
  });
};
const resolve = (config, runtimeOptions = {}) => {
  invariant(config.get('dataType') !== ENGAGEMENTS || config.get('crossObject') != null, 'dataType ENGAGEMENTS can only be used with cross-object reports, use ENGAGEMENT instead');
  const [setConfigured, setStatus, setData, debug] = connectActions(store, [setConfiguredAction, setStatusAction, setDataAction, debugAction]);
  let dataPhase = 'configure';
  const debugFn = (key, value) => {
    dataPhase = key;
    debug({
      config,
      key,
      value
    });
  };
  const {
    reportingLogger
  } = runtimeOptions;
  const configure = connectedConfigure(store)(config, runtimeOptions);
  const retrieve = connectedRetrieve(store)(config, runtimeOptions);
  const process = connectedProcess(store)(config, runtimeOptions);
  const hydrate = connectedHydrate(store)(config, runtimeOptions);
  const handleError = error => {
    setStatus({
      config,
      status: StatusTypes.ERRORED,
      error
    });
    if (reportingLogger) {
      reportingLogger.logError(error, dataPhase);
    }
    debugFn('exceptions', List.of(error));
  };
  const withDataAge = data => Object.assign({}, data, {
    dataset: data.dataset.set('dataAge', Date.now())
  });
  try {
    if (config.get('dataType') !== CROSS_OBJECT && (config.get('dimensions') || List()).isEmpty() && (config.get('metrics') || List()).isEmpty()) {
      throw new NoDimensionsOrMetricsException();
    }
    return configure(config).then(configured => {
      dataPhase = 'retrieve';
      setConfigured({
        config,
        configured
      });
      return retrieve(configured);
    }).then(withDataAge).then(retrieved => {
      debugFn('process', retrieved.dataset);
      return retrieved;
    }).then(process).then(processed => {
      debugFn('hydrate', processed.dataset);
      return processed;
    }).then(hydrate).then(dataset => List.of(dataset)).then(data => {
      setData({
        config,
        data
      });
      setStatus({
        config,
        status: StatusTypes.SUCCEEDED
      });
      if (reportingLogger) {
        reportingLogger.logSuccess();
      }
      debugFn('resolved', data.get(0));
      return data;
    }).catch(error => {
      handleError(error);
      return rethrow(error);
    });
  } catch (e) {
    handleError(e);
    throw e;
  }
};
const connectedResolve = (config, runtimeOptions = {}) => {
  const [addConfig, setPromise] = connectActions(store, [addConfigAction, setPromiseAction]);
  const state = store.getState();
  if (getConfigId(state, config) == null) {
    addConfig({
      config
    });
    setPromise({
      config,
      promise: resolve(config, runtimeOptions)
    });
  }
  return getDataPromise(store.getState(), config);
};
const run = (config, runtimeOptions = {}) => {
  const {
    v2Datasets = false
  } = runtimeOptions;
  const dataType = config.get('dataType');
  const propertiesPromise = getProperties(dataType);
  const [setStatus, setData, debug] = connectActions(store, [setStatusAction, setDataAction, debugAction]);
  try {
    const report = runtimeOptions.report;
    const reportWithUpdatedConfig = config && report ? report.set('config', config) : report;
    if (runtimeOptions.reportingLogger === undefined) {
      const rl = new ReportingLogger();
      if (reportWithUpdatedConfig) {
        rl.addReportAttributes(reportWithUpdatedConfig);
      } else {
        rl.addReportConfigAttributes(config);
      }
      runtimeOptions.reportingLogger = rl;
    }
    if (isComparisonConfig(config)) {
      // Ensure we do not use cached v2 data, as connectedResolve expects v1 data which it upgrades to v2
      const {
        config: baseConfig,
        compareConfig,
        compareLabelFactory
      } = splitConfig(config.delete('v2'), runtimeOptions.fiscalYearMonthName);
      const compareLabel = compareLabelFactory(reportWithUpdatedConfig);
      const basePromise = connectedResolve(baseConfig, runtimeOptions);
      const comparePromise = connectedResolve(compareConfig, Object.assign({}, runtimeOptions, {
        reportingLogger: null
      })).then(dataSet => dataSet.setIn([0, 'dimension', 'propertyLabel'], compareLabel));
      return Promise.all([basePromise, comparePromise, propertiesPromise]).then(([base, compare, properties]) => ({
        data: densify(config, base, compare),
        properties
      })).then(({
        data,
        properties
      }) => v2Datasets ? Promise.all([upgradeToV2(baseConfig, data.first(), properties), upgradeToV2(compareConfig, data.get(1), properties, data.getIn([0, 'dimension', 'propertyLabel'])), resolveV2CustomSeries(runtimeOptions.report, data.first())]).then(([primary, compare, customSeriesDatasets]) => ImmutableMap({
        primary,
        compare
      }).merge(customSeriesDatasets)) : data).then(data => {
        setStatus({
          config,
          status: StatusTypes.SUCCEEDED
        });
        setData({
          config,
          data
        });
        return data;
      });
    }
    return Promise.all([resolve(config, runtimeOptions), propertiesPromise]).then(([data, properties]) => v2Datasets ? Promise.all([upgradeToV2(config, data.first(), properties), getSummaryV2Dataset(config, data.first(), properties), resolveV2CustomSeries(runtimeOptions.report, data.first())]).then(([primary, summary, customSeriesDatasets]) => ImmutableMap({
      primary,
      summary
    }).merge(customSeriesDatasets)) : Promise.resolve(data));
  } catch (e) {
    debug({
      config,
      key: 'exceptions',
      value: exceptions => exceptions.push(e)
    });
    throw e;
  }
};
const connectedRun = (config, runtimeOptions = {}) => {
  config = runtimeOptions.v2Datasets ? config.set('v2', true) : config;
  if (runtimeOptions.report) {
    const customSeries = fromJS(runtimeOptions.report.getIn(['displayParams', 'customSeries'])) || List();
    if (!customSeries.isEmpty()) {
      config = config.set('customSeries', customSeries);
    }
  }
  const [addConfig, setPromise, setData] = connectActions(store, [addConfigAction, setPromiseAction, setDataAction]);
  const state = store.getState();
  if (getConfigId(state, config) == null) {
    addConfig({
      config
    });
    setPromise({
      config,
      promise: run(config, runtimeOptions).then(data => {
        setData({
          config,
          data
        });
        return data;
      })
    });
  }
  return getDataPromise(store.getState(), config);
};
export default connectedRun;