'use es6';

import getIn from 'transmute/getIn';
import { LIMIT_ROOT, STANDARD_REPORTS } from '../limitTypes';
import useUserInfo from '../../useUserInfo';
const useStandardReportLimit = () => {
  const {
    data
  } = useUserInfo(false);
  return getIn([LIMIT_ROOT, STANDARD_REPORTS], data);
};
export default useStandardReportLimit;