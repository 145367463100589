import { Set as ImmutableSet } from 'immutable';
import { useMutation } from 'data-fetching-client';
// @ts-expect-error Untyped import
import { useShowDialog } from 'reporting-action-components/dialog/ducks';
// @ts-expect-error Untyped import
import { DashboardUITracker } from '../../../tracking/DashboardUITracker.js';
// @ts-expect-error Untyped import
import { useUserInfo } from 'dashboard-components/context/user-info-context';
// @ts-expect-error Untyped import
import { SAVE_NEW_REPORT } from 'reporting-action-components/data/requests/report';
import { ADD_TO_DASHBOARD_DIALOG } from 'reporting-action-components/dialog/dialogTypes';
// @ts-expect-error Untyped import
import { SINGLE_REPORT_FIELD_NAME } from 'reporting-action-components/data/requests/report';
export const useReportPanelDropHandlers = () => {
  const userInfo = useUserInfo();
  const showDialog = useShowDialog();
  const [saveReport] = useMutation(SAVE_NEW_REPORT);
  const handleSavedReportDrop = ({
    report,
    onSuccess,
    onReject,
    onError
  }) => {
    // TODO: If permissions are already the same, don't show the dialog
    showDialog({
      dialogType: ADD_TO_DASHBOARD_DIALOG,
      dialogProps: {
        showLinkToDashboard: true,
        reports: ImmutableSet([report]),
        onAddToDashboardError: onError,
        onAddToDashboardReject: onReject,
        onAddToDashboardSuccess: onSuccess,
        appUsageTracker: DashboardUITracker
      }
    });
  };
  const handleReportTemplateDrop = ({
    onError,
    dashboard,
    onSuccess,
    reportTemplate
  }) => {
    void saveReport({
      variables: {
        report: reportTemplate,
        userId: userInfo.user.user_id,
        dashboardName: dashboard.get('name'),
        destinationType: 'existing-dashboard',
        targetDashboardId: dashboard.get('id'),
        permissionConfig: dashboard.get('permissionConfig')
      },
      onCompleted: ({
        [SINGLE_REPORT_FIELD_NAME]: response
      }) => {
        const report = response.get('report');
        onSuccess(report);
      },
      onError: error => {
        onError(error);
      }
    });
  };
  return {
    handleSavedReportDrop,
    handleReportTemplateDrop
  };
};