/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import { useState, useEffect } from 'react';
import userInfoApi, { userInfoSync } from 'hub-http/userInfo';
import fromJS from 'transmute/fromJS';
import get from 'transmute/get';
const initialState = {
  loading: true,
  error: null,
  data: fromJS({
    gates: [],
    limits: {},
    portal: {},
    scopes: [],
    user: {}
  })
};
export const formatUserInfoResponse = (response, shouldUseImmutable) => {
  const {
    gates,
    portal = {},
    user = {}
  } = response;
  const {
    limits
  } = portal;
  const {
    scopes
  } = user;
  const formattedUserInfo = {
    gates,
    limits,
    portal,
    scopes,
    user
  };
  return shouldUseImmutable ? fromJS(formattedUserInfo) : formattedUserInfo;
};
const useUserInfo = (shouldUseImmutable = true) => {
  const [userInfo, setUserInfo] = useState(initialState);
  useEffect(() => {
    try {
      const userInfoSyncValue = userInfoSync();
      setUserInfo({
        loading: false,
        error: null,
        data: formatUserInfoResponse(userInfoSyncValue, shouldUseImmutable)
      });
    } catch (e) {
      userInfoApi().then(response => {
        setUserInfo({
          loading: false,
          error: null,
          data: formatUserInfoResponse(response, shouldUseImmutable)
        });
      }, error => {
        console.error(error);
        setUserInfo(Object.assign({}, userInfo, {
          error
        }));
      });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  return userInfo;
};
export const useCurrentUser = () => {
  const {
    data
  } = useUserInfo();
  return get('user', data);
};
export default useUserInfo;