import { List } from 'immutable';

// @ts-expect-error Untyped dependency
import { isBlocklistedForFiltering } from 'reporting-data/public/blocklisted-for-filtering';
import { isSingleObjectReport } from './common/reportTypeValidators';
import { EDITOR_TYPES } from 'reporting-data/tsTypes/editorTypes';
import { isValidForQuickFiltering } from './QuickFilterEditor/quickFilterEditorUtils';
import { EQ } from 'reporting-data/constants/operators';
import { GOAL_TARGET_PROPERTIES, GOAL_TARGET_PROPERTIES_DEPENDENT_ON_KPI_UNIT_TYPE } from 'reporting-data/constants/goalProperties';
import { getEditorSchemasFromConfig } from './common/editorUtils';
import { getCustomFilterProperties, getDataType, getDimensions, getMetricProperties, getReportConfig } from 'reporting-data/report/configReportGetters';
import { isConfigReport, isGoalReport } from 'reporting-data/tsTypes/reportTypes';
const dynamicCrmSearchDimensionQuickFiltering = (report, properties) => {
  const dataType = report.getIn(['config', 'dataType']);
  const savedQuickFilterEditorProperties = getEditorSchemasFromConfig(report).filter(schema => schema.type === EDITOR_TYPES.QUICK_FILTER).map(schema => schema.property);
  return getDimensions(report).filter(dimensionProperty => {
    const propertyDefinition = properties.find(property => property.get('name') === dimensionProperty);
    return propertyDefinition && isValidForQuickFiltering(dataType, propertyDefinition.toJS()) && !isBlocklistedForFiltering(dataType, false, propertyDefinition) && !propertyDefinition.get('scripted') && !savedQuickFilterEditorProperties.includes(dimensionProperty);
  }).map(dimensionProperty => ({
    type: EDITOR_TYPES.QUICK_FILTER,
    operator: 'IN',
    property: dimensionProperty
  })).toSet().groupBy(filterSchema => filterSchema.property).map(setByProperty => setByProperty.first()).toList().flatten().toJS();
};
export const getRequiredGoalReportEditors = report => {
  if (isGoalReport(report)) {
    const reportDimensions = getDimensions(report);
    const metricProperties = getMetricProperties(report);
    const customFilterProperties = getCustomFilterProperties(getReportConfig(report));
    const shouldRequireKpiUnitTypeFilter = GOAL_TARGET_PROPERTIES_DEPENDENT_ON_KPI_UNIT_TYPE.some(property => reportDimensions.includes(property) || metricProperties.some(metricProperty => metricProperty === property));
    const hasKpiUnitTypeFilter = customFilterProperties.some(property => property === GOAL_TARGET_PROPERTIES.HS_KPI_UNIT_TYPE);
    const shouldAddKpiUnitTypeKpiFilter = shouldRequireKpiUnitTypeFilter || hasKpiUnitTypeFilter;
    if (shouldAddKpiUnitTypeKpiFilter) {
      return [{
        type: EDITOR_TYPES.QUICK_FILTER,
        operator: EQ,
        property: GOAL_TARGET_PROPERTIES.HS_KPI_UNIT_TYPE
      }];
    }
  }
  return [];
};
export const isCTAReportWithRequiredFilters = report => {
  return ['e_viewed_web_interactive', 'e_clicked_web_interactive'].includes(getDataType(report));
};
export const getCTAReportRequiredFilters = report => {
  if (isCTAReportWithRequiredFilters(report)) {
    return [{
      property: 'hs_is_bot_event'
    }];
  }
  return [];
};
export const getDynamicReportEditors = (report, properties) => {
  let dynamicEditors = [];
  if (isConfigReport(report)) {
    if (isSingleObjectReport(report)) {
      dynamicEditors = dynamicEditors.concat(dynamicCrmSearchDimensionQuickFiltering(report, properties || List()));
    }
    const goalReportEditors = getRequiredGoalReportEditors(report);
    dynamicEditors = dynamicEditors.concat(goalReportEditors);
  }
  return dynamicEditors;
};