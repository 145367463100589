/**
 * Check if key exists in localStorage
 *
 * @param   {string}  key Key to check in localStorage
 * @returns {boolean}     Whether key exists
 * @throws
 */
export const has = key => {
  try {
    return localStorage.getItem(key) !== null;
  } catch (err) {
    console.warn(`storage: failed to check existence of '${key}' in localStorage`);
    return false;
  }
};

/**
 * Get key from localStorage
 *
 * @param   {string}  key Key to get from localStorage
 * @returns {?string}     Value and success pair
 * @throws
 */
export const get = key => {
  try {
    return localStorage.getItem(key);
  } catch (err) {
    console.warn(`storage: failed to get '${key}' from localStorage`);
    return null;
  }
};

/**
 * Set value from localStorage
 *
 * @param   {string} key   Key to set in localStorage
 * @param   {string} value Value to set
 * @returns {void}
 * @throws
 */
export const set = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (err) {
    console.warn(`storage: failed to set '${value}' as '${key}' in localStorage`);
  }
};

/**
 * Remove value from localStorage
 *
 * @param   {string} key Key to remove in localStorage
 * @returns {void}
 * @throws
 */
export const remove = key => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    console.warn(`storage: failed to remove '${key}' in localStorage`);
  }
};