'use es6';

import { Map as ImmutableMap } from 'immutable';
import * as DataType from '../../../constants/dataTypes';
import standardAggregate from './aggregate';
import crossObjectAggregate from './cross-object/aggregate';
import engagementAggregate from './engagement/aggregate';
const dataTypeToAggImpl = ImmutableMap({
  [DataType.CROSS_OBJECT]: crossObjectAggregate,
  [DataType.CALLS]: engagementAggregate,
  [DataType.CONVERSATIONS]: engagementAggregate,
  [DataType.ENGAGEMENT_EMAILS]: engagementAggregate,
  [DataType.FEEDBACK]: engagementAggregate,
  [DataType.MEETINGS]: engagementAggregate,
  [DataType.NOTES]: engagementAggregate,
  [DataType.TASKS]: engagementAggregate
});
export default function aggregate(config, properties, runtimeOptions) {
  const dataType = config.get('dataType');
  return dataTypeToAggImpl.get(dataType, standardAggregate)(config, properties, runtimeOptions);
}