'use es6';

import { fromJS } from 'immutable';
import I18n from 'I18n';
import invariant from '../../../../lib/invariant';
import { RANGE_TYPES } from '../../../../constants/dateRangeTypes';
import { EQ, LT, GT } from '../../../../constants/operators';
import { endOf, startOf } from '../../../../lib/dateUtils';
export default ((property, dateRange, useFiscalYear = false) => {
  invariant(property, 'property must be defined');
  const rangeType = dateRange.get('rangeType');
  const rollingDays = dateRange.get('rollingDays');
  const startDate = dateRange.get('startDate');
  const endDate = dateRange.get('endDate');
  const entireCurrentUnit = dateRange.get('entireCurrentUnit');
  const date = dateRange.get('date');
  switch (rangeType) {
    case RANGE_TYPES.ALL:
      return fromJS({
        property,
        operator: 'HAS_PROPERTY'
      });
    case RANGE_TYPES.CUSTOM:
      return fromJS({
        property,
        operator: 'BETWEEN',
        dateTimeFormat: 'DATE',
        value: I18n.moment.portalTz(startDate, 'YYYYMMDD').format('YYYY-MM-DD'),
        highValue: I18n.moment.portalTz(endDate, 'YYYYMMDD').format('YYYY-MM-DD')
      });
    case RANGE_TYPES.ROLLING:
      return fromJS({
        property,
        operator: 'ROLLING_DATE_RANGE',
        inclusive: false,
        timeUnitCount: parseInt(rollingDays || 7, 10),
        timeUnit: 'DAY'
      });
    case RANGE_TYPES.IS_EQUAL_TO:
      return fromJS({
        property,
        operator: EQ,
        dateTimeFormat: 'DATE',
        value: I18n.moment.portalTz(date, 'YYYYMMDD').format('YYYY-MM-DD')
      });
    case RANGE_TYPES.IS_BEFORE_DATE:
      return fromJS({
        property,
        operator: LT,
        dateTimeFormat: 'DATE',
        value: I18n.moment.portalTz(date, 'YYYYMMDD').format('YYYY-MM-DD')
      });
    case RANGE_TYPES.IS_AFTER_DATE:
      return fromJS({
        property,
        operator: GT,
        dateTimeFormat: 'DATE',
        value: I18n.moment.portalTz(date, 'YYYYMMDD').format('YYYY-MM-DD')
      });
    default:
      break;
  }
  const [timeValue, dateValue] = rangeType.split('_');
  if (timeValue === 'CUSTOM') {
    return fromJS({
      property,
      operator: 'BETWEEN',
      dateTimeFormat: 'DATE',
      value: startOf({
        date: I18n.moment.portalTz(date),
        frequency: dateValue
      }).format('YYYY-MM-DD'),
      highValue: endOf({
        date: I18n.moment.portalTz(date),
        frequency: dateValue
      }).format('YYYY-MM-DD')
    });
  }
  const inclusive = timeValue === 'THIS';
  const operator = inclusive && !entireCurrentUnit ? 'TIME_UNIT_TO_DATE' : 'ROLLING_DATE_RANGE';
  return fromJS({
    operator,
    inclusive,
    property,
    timeUnitCount: 1,
    timeUnit: dateValue,
    rollForward: timeValue === 'NEXT',
    useFiscalYear
  });
});