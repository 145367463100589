'use es6';

import { isSupportedCrmObject, HUBSPOT_CRM_OBJECTS_BY_ID } from '../../crmObjects/utils';
import { ENGAGEMENT, UNIFIED_EVENTS, EXTERNAL } from '../../constants/dataTypes';
import { isSupportedUnifiedEventQLDataType } from '../../unifiedEventQL/utils';
const BE_SUPPORTED_DATA_TYPES = [UNIFIED_EVENTS, ENGAGEMENT, EXTERNAL];
export const isOnlySupportedViaBackendResolve = report => {
  const dataType = report.getIn(['config', 'dataType']);
  const backendSupported = isSupportedCrmObject(dataType) || isSupportedUnifiedEventQLDataType(dataType) || BE_SUPPORTED_DATA_TYPES.includes(dataType);
  return backendSupported || HUBSPOT_CRM_OBJECTS_BY_ID.includes(dataType);
};