import { fromJS, Map as ImmutableMap } from 'immutable';
import { number } from '../../../hydrate/numberFormatter';
const COUNT_METRIC = 'SUM|count';
const calculateTotal = data => data.reduce((total, row) => total + row.get(COUNT_METRIC), 0);
export const createFunnelSummary = datasets => {
  const primary = datasets.find(dataset => dataset.has('primary')).get('primary');
  const total = calculateTotal(primary.getIn(['data', 'data']));
  const summary = fromJS({
    summary: {
      data: {
        data: [{
          [COUNT_METRIC]: total
        }],
        properties: {
          [COUNT_METRIC]: primary.getIn(['data', 'properties', COUNT_METRIC]).set('references', ImmutableMap({
            [String(total)]: {
              label: number(total)
            }
          }))
        }
      }
    }
  });
  return [...datasets, summary];
};