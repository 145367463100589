'use es6';

/* This is very early wip converting logic to sample v2 datasets */
import { List, Map as ImmutableMap } from 'immutable';
import { toMetricKey } from './datasetMetrics';
const METRICS_TO_FLATTEN = ['associatedcompanyid', 'associations.company', 'associations.contact', 'associations.deal', 'associations.ticket'];
const upgradeMetric = (types, key, pluck) => types.mapKeys(type => toMetricKey({
  property: key,
  type
})).map(value => pluck(value));
const upgradeMetrics = (metrics, pluck = metric => metric.get('raw')) => metrics.toMap().flatMap((types, key) => upgradeMetric(types, key, METRICS_TO_FLATTEN.includes(key) ? metric => {
  const rawValue = pluck(metric);
  return List.isList(rawValue) && rawValue.count() === 1 ? rawValue.first() : rawValue;
} : pluck));
const upgradeBucket = bucket => {
  const dimension = bucket.get('dimension');
  if (dimension && dimension.get('buckets')) {
    const name = dimension.get('property');
    const rows = dimension.get('buckets').toList().flatMap(innerBucket => {
      const value = innerBucket.get('key');
      const baseRow = ImmutableMap({
        [name]: value
      });
      const childRows = upgradeBucket(innerBucket);
      return childRows.map(row => baseRow.merge(row));
    });
    return rows;
  }
  return List.of(upgradeMetrics(bucket.get('metrics', ImmutableMap())));
};
export const upgradeData = oldDataset => upgradeBucket(oldDataset);