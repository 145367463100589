import { Map as ImmutableMap } from 'immutable';
import { getFilterGroups, getMetricPropertiesFromConfig, getMetricsFromConfig } from './configReportGetters';
export const PRIMARY_CONFIG_NAME = 'primary';
export function getConfigs(report) {
  return report.get('reportConfigs') || ImmutableMap();
}
export function getVisualization(report) {
  return report.get('visualization');
}
export function getLayer(report) {
  var _getVisualization;
  return (_getVisualization = getVisualization(report)) === null || _getVisualization === void 0 ? void 0 : _getVisualization.get('layer');
}
export function getFieldOptions(report) {
  var _getLayer;
  return (_getLayer = getLayer(report)) === null || _getLayer === void 0 ? void 0 : _getLayer.get('fieldOptions');
}
export function getConfigsIncludingPrimary(report) {
  return ImmutableMap({
    [PRIMARY_CONFIG_NAME]: report.get('config')
  }).merge(getConfigs(report));
}
export function getConfigByName(report, name) {
  return getConfigsIncludingPrimary(report).get(name);
}
export function getDisplayParamsColumnAlias(report) {
  return report.getIn(['displayParams', 'columnAlias']);
}
export function getColumnAliasByConfigName(report, name) {
  var _getDisplayParamsColu;
  const parsedName = name === PRIMARY_CONFIG_NAME ? 'primaryConfig' : name;
  return (_getDisplayParamsColu = getDisplayParamsColumnAlias(report)) === null || _getDisplayParamsColu === void 0 ? void 0 : _getDisplayParamsColu.get(parsedName);
}
export function getFieldOptionsByConfigName(report, name) {
  var _getFieldOptions;
  return (_getFieldOptions = getFieldOptions(report)) === null || _getFieldOptions === void 0 ? void 0 : _getFieldOptions.find(fieldOption => {
    return fieldOption.get('dataset') === name;
  });
}
export function getSubtitleField(report, name) {
  var _getFieldOptionsByCon;
  return (_getFieldOptionsByCon = getFieldOptionsByConfigName(report, name)) === null || _getFieldOptionsByCon === void 0 ? void 0 : _getFieldOptionsByCon.get('subtitleField');
}

/**
 * Returns the property, metric type, and subtitle field for a specific config.
 * This is shared logic for updating the subtitle field.
 */
export const getFieldsForUpdatingSubtitleField = (report, configName) => {
  var _getMetricsFromConfig;
  const config = getConfigByName(report, configName);
  const metricProperties = getMetricPropertiesFromConfig(config);
  const property = metricProperties.first();
  const metricType = (_getMetricsFromConfig = getMetricsFromConfig(config).first().get('metricTypes')) === null || _getMetricsFromConfig === void 0 ? void 0 : _getMetricsFromConfig.first();
  const subtitleField = getSubtitleField(report, configName);
  return {
    property,
    metricType,
    subtitleField
  };
};
export function getLastConfigName(report) {
  return getConfigs(report).keySeq().last();
}
export const getFilterGroupsByConfigName = (report, configName) => getFilterGroups(getConfigByName(report, configName));