// Marketing
export const REVENUE_ATTRIBUTION_ACCESS = 'revenue-attribution-read';
export const REVENUE_ATTRIBUTION_LITE = 'revenue-attribution-lite';
export const CONTACT_CREATE_ATTRIBUTION_ACCESS = 'contact-create-date-attribution-read';
export const DEAL_CREATE_ATTRIBUTION_ACCESS = 'deal-create-attribution-read';
export const MARKETING_FREE_PRODUCT = 'marketing-free-product';
export const MARKETING_STARTER_PRODUCT = 'marketing-starter-product';
export const HUB_MARKETING_PRODUCT = 'hub-marketing-product';
export const MARKETING_PRODUCT_ACCESS = 'marketing-product-access';

// Feedback
export const SERVICE_FEEDBACK_ACCESS = 'service-feedback-access';

// Web Analytics API
export const WEB_ANALYTICS_API_ACCESS = 'web-analytics-api-access';
export const TRAFFIC_ANALYTICS_API_ACCESS = 'traffic-analytics-api-access';

// Service
export const SERVICE_ACCESS = 'service-access';

// Content
export const CONTENT_REPORTS_ACCESS = 'content-reports-access';

// Knowledge
export const SERVICE_KNOWLEDGE_ACCESS = 'service-knowledge-access';

// Sequences
export const SEQUENCES_REPORTS_ACCESS = 'sequences-reports-access';

// Pages
export const PAGES_ANALYTICS = 'pages-analytics';

// Campaigns
export const CAMPAIGNS_ACCESS = 'campaigns-access';

// Topics
export const TOPIC_CLUSTERS_REPORTS_ACCESS = 'topic-clusters-reports-access';

// CMS
export const CMS_REPORTS_ACCESS = 'cms-reports-access';

//Social
export const SOCIAL_ACCESS = 'social-access';

// FORMS
export const FORMS_ANALYTICS = 'forms-conversion-charts';

// Reporting: App
export const REPORTS_PAID_ACCESS = 'reports-paid-access';
export const CUSTOM_REPORTING_ACCESS = 'custom-reporting-access';
export const REPORTS_SALES_PRO_ACCESS = 'reports-sales-pro-access';

// Reporting: Dashboard
export const DASHBOARD_DEMO_WRITE_ACCESS = 'dashboard-demo-write';
export const IFRAME_WIDGET_ACCESS = 'iframe-widget-access';

// Reporting: User
export const REPORTING_ADMIN = 'reporting-admin';
export const SUPER_USER = 'super-user';
export const SUPER_ADMIN = 'super-admin';
export const JITA_USER = 'jita-user';

// Reporting Permission
export const REPORTING_ACCESS = 'reporting-access';
export const REPORTING_CREATE_AND_OWN = 'reporting-create-and-own';
export const REPORTING_EDIT = 'reporting-edit';
export const REPORTING_BASE = 'reporting-base';
// Teams
export const TEAMS_ADVANCED_MODELING_ACCESS = 'teams-advanced-modeling-access';

// Business Units
export const BUSINESS_UNITS_ACCESS = 'business-units-access';

// datasets
export const REPORTING_DATASETS_ACCESS = 'reporting-datasets-access';

// Payments
export const PAYMENTS_REPORTING_ACCESS = 'commerce-payments-read';