module.exports = {
  "pageView": {
    "name": "pageview",
    "class": "view",
    "namespace": "reporting",
    "properties": {
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": [
        "dashboard",
        "custom-dashboard",
        "email-dashboard",
        "export-dashboard",
        "email-unsubscribe",
        "permission-required"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "aiButtonInteraction": {
    "name": "aiButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "featureId": {
        "type": "string",
        "isOptional": true
      },
      "featureCategoryId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copilotActionButtonInteraction": {
    "name": "copilotActionButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "prompt": {
        "type": "string",
        "isOptional": true
      },
      "hasPrefillValues": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copyMessageInteraction": {
    "name": "copyMessageInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_copied"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "feedbackInteraction": {
    "name": "inlineFeedbackInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_upvoted",
          "prompt_upvote_removed",
          "prompt_downvoted",
          "prompt_downvote_removed"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "fileManagerLib": {
    "name": "File Manager Lib",
    "class": "usage",
    "properties": {
      "componentName": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerSetPanelExploreFiles": {
    "name": "Explore Files",
    "class": "interaction",
    "properties": {
      "panel": "string",
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerExploreFiles": {
    "name": "Explore Files",
    "class": "interaction",
    "namespace": "file-picker",
    "version": "v2",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "fileManagerImageOptimization": {
    "name": "Change image optimization setting",
    "class": "interaction",
    "properties": {
      "setting": "string",
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerManageFiles": {
    "name": "Manage Files",
    "class": "interaction",
    "properties": {
      "count": {
        "type": "number",
        "isOptional": true
      },
      "fileSource": {
        "type": [
          "AI_GENERATED",
          "CANVA",
          "SHUTTERSTOCK"
        ],
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "filePickerMultiSelect": {
    "name": "Manage Files",
    "class": "interaction",
    "properties": {
      "fileCount": {
        "type": "number"
      },
      "limit": {
        "type": "number"
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerManageFolders": {
    "name": "Manage Folders",
    "class": "interaction",
    "namespace": "file-picker",
    "version": "v2",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "fileManagerBrowseShutterstock": {
    "name": "Browse Shutterstock",
    "class": "interaction",
    "namespace": "file-picker",
    "version": "v2",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "fileManagerAlert": {
    "name": "Alert interaction",
    "class": "interaction",
    "namespace": "file-picker",
    "version": "v2",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "fileManagerBulkImport": {
    "name": "Bulk Import Images",
    "class": "interaction",
    "properties": {
      "action": "string",
      "imageCount": {
        "type": "number",
        "isOptional": true
      },
      "isImageFile": {
        "type": "boolean",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerLibErrorDisplay": {
    "name": "Error display",
    "class": "view",
    "properties": {
      "type": "string",
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerVideoInteraction": {
    "name": "video interaction",
    "class": "interaction",
    "properties": {
      "videoTitle": {
        "type": "string",
        "isOptional": true
      },
      "language": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerUpload": {
    "name": "upload file",
    "class": "interaction",
    "properties": {
      "size": {
        "type": "number",
        "isOptional": true
      },
      "errorType": {
        "type": "string",
        "isOptional": true
      },
      "statusCode": {
        "type": "number",
        "isOptional": true
      },
      "videoDuration": {
        "type": "number",
        "isOptional": true
      },
      "videoHostingProvider": {
        "type": [
          "mux"
        ],
        "isOptional": true
      },
      "multiPartUpload": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerUploadDeduplication": {
    "name": "check upload duplicates",
    "class": "interaction",
    "properties": {
      "hasData": {
        "type": "boolean",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "size": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerVideoPQL": {
    "name": "Video PQL",
    "class": "interaction",
    "properties": {
      "alert": {
        "type": "string",
        "isOptional": true
      },
      "limit": {
        "type": "number",
        "isOptional": true
      },
      "quantityRemaining": {
        "type": "number",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerImageGeneration": {
    "name": "image generation",
    "class": "interaction",
    "properties": {
      "errorCount": {
        "type": "number",
        "isOptional": true
      },
      "isFeedbackPositive": {
        "type": "boolean",
        "isOptional": true
      },
      "feedbackText": {
        "type": "string",
        "isOptional": true
      },
      "resolution": {
        "type": "string",
        "isOptional": true
      },
      "style": {
        "type": "string",
        "isOptional": true
      },
      "correlationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "adobeExpressInteraction": {
    "name": "adobe express",
    "class": "interaction",
    "namespace": "file-manager",
    "version": "v2",
    "properties": {
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "updateSalesReportContext": {
    "properties": {
      "action": "string",
      "value": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "reporting",
    "version": "1",
    "name": "REPORT_INTERACTION",
    "class": "interaction"
  },
  "analyticsToolDayZero": {
    "name": "pageview",
    "class": "activation",
    "namespace": "reporting",
    "version": "1",
    "properties": {
      "screen": "string",
      "subscreen": "string"
    }
  },
  "analyticsToolActivation": {
    "class": "activation",
    "properties": {
      "action": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "reporting",
    "version": "1",
    "name": "REPORT_INTERACTION"
  },
  "saveReport": {
    "properties": {
      "action": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "reporting",
    "version": "1",
    "name": "REPORT_INTERACTION",
    "class": "interaction"
  },
  "createReportInteraction": {
    "properties": {
      "action": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "reporting",
    "version": "1",
    "name": "REPORT_INTERACTION",
    "class": "interaction"
  },
  "updateContext": {
    "properties": {
      "action": "string",
      "value": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "reporting",
    "version": "1",
    "name": "REPORT_INTERACTION",
    "class": "interaction"
  },
  "clickDrilldown": {
    "properties": {
      "reportType": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "reporting",
    "version": "1",
    "name": "REPORT_INTERACTION",
    "class": "interaction"
  },
  "paginateDrilldownTable": {
    "properties": {
      "page": "number",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "reporting",
    "version": "1",
    "name": "REPORT_INTERACTION",
    "class": "interaction"
  },
  "copyReportURL": {
    "namespace": "reporting",
    "version": "1",
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "screen": "string",
      "subscreen": "string"
    }
  },
  "editReportInBuilder": {
    "namespace": "reporting",
    "version": "1",
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "screen": "string",
      "subscreen": "string"
    }
  },
  "navigateReports": {
    "properties": {
      "action": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "reporting",
    "version": "1",
    "name": "REPORT_INTERACTION",
    "class": "interaction"
  },
  "switchReportTab": {
    "properties": {
      "action": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "reporting",
    "version": "1",
    "name": "REPORT_INTERACTION",
    "class": "interaction"
  },
  "switchRightSidebarTab": {
    "properties": {
      "action": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "reporting",
    "version": "1",
    "name": "REPORT_INTERACTION",
    "class": "interaction"
  },
  "clickedGoalsLink": {
    "properties": {
      "action": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "reporting",
    "version": "1",
    "name": "REPORT_INTERACTION",
    "class": "interaction"
  },
  "clickedForecastPeriodsLink": {
    "properties": {
      "action": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "reporting",
    "version": "1",
    "name": "REPORT_INTERACTION",
    "class": "interaction"
  },
  "switchOvertimeToggle": {
    "properties": {
      "action": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "reporting",
    "version": "1",
    "name": "REPORT_INTERACTION",
    "class": "interaction"
  },
  "clickedTableLink": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "dataType": "string"
    },
    "namespace": "reporting",
    "version": "1"
  },
  "toggleLegendItem": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1"
  },
  "setMarketingPerformanceGoals": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1"
  },
  "initializeMarketingPerformanceGoals": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "type": [
        "LAST_MONTHS_VALUES",
        "DEFAULT_VALUES"
      ]
    },
    "namespace": "reporting",
    "version": "1"
  },
  "setSrvGoal": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "add-goal",
          "remove-goal",
          "reset-goal"
        ],
        "isOptional": false
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickGoToLink": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1"
  },
  "openReportFilters": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "templateKey": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "selectQuickFilter": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "dataType": {
        "type": "string"
      },
      "property": {
        "type": "string"
      },
      "actionType": {
        "type": [
          "edit"
        ]
      },
      "action": {
        "type": [
          "filter"
        ]
      },
      "assetType": {
        "type": [
          "report",
          "srv-checkbox-table"
        ],
        "isOptional": false
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "toggleReportChartType": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "dataType": {
        "type": "string"
      },
      "chartType": {
        "type": "string"
      },
      "actionType": {
        "type": [
          "edit"
        ]
      },
      "action": {
        "type": [
          "change chart type"
        ]
      },
      "assetType": {
        "type": [
          "report"
        ],
        "isOptional": false
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "addSrvSavedFilter": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1"
  },
  "changeSrvReportConfigFilters": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "condition-added",
          "condition-deleted"
        ],
        "isOptional": false
      },
      "filterType": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "changeSrvObjectSpecificFilters": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "filterType": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "changeSrvDateFilters": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "date-range",
          "date-property",
          "compared-to",
          "frequency",
          "secondary-date-range",
          "currency",
          "query",
          "metric",
          "table-metric",
          "group-by",
          "breakdown-by",
          "unified-analytics-view",
          "static-goal"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      },
      "isNewFilterExperience": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "filtersBarView": {
    "name": "SRV_VIEW",
    "class": "view",
    "properties": {
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "changeSrvSnowflakeFilters": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1"
  },
  "resetSrvFilters": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1"
  },
  "toggleSrvTabs": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "srv-filters-tab",
          "srv-about-tab",
          "srv-data-quality-tab",
          "srv-edit-tab"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "filtersOnboardingTourInteraction": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "step": {
        "type": "string",
        "isOptional": true
      },
      "onboardingAction": {
        "type": [
          "cancel",
          "back",
          "next",
          "finish"
        ],
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "drillInto": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "drill-into"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": "string",
        "isOptional": false
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      },
      "templateKey": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "groupDrilldownData": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "start-group-data",
          "select-group-data-dimension",
          "select-unified-events-tab"
        ],
        "isOptional": false
      },
      "property": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "hoverOver": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "hover-over"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": "string",
        "isOptional": false
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "customWidgetType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": false
      },
      "templateKey": {
        "type": "string",
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "isNonPlatformReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportIdentifier": {
        "type": "string",
        "isOptional": true
      },
      "isDrilldownReport": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "toggleSummaryInsight": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "show-summary",
          "hide-summary"
        ]
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "changeChartType": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "change-chart-type"
        ]
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "reportType": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "toggleDatasetInsights": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "enable",
          "disable"
        ]
      },
      "value": {
        "type": "string"
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "selectDimension": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "change-report-dimension"
        ]
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickTableCellLink": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "click-table-cell-link"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report"
        ],
        "isOptional": false
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "objectId": {
        "type": "string",
        "isOptional": false
      },
      "linkClicked": {
        "type": "string",
        "isOptional": false
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "metricsSelectorModalInteraction": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "close",
          "apply",
          "addMetric",
          "deleteMetric"
        ],
        "isOptional": true
      },
      "metricsCount": {
        "type": "number",
        "isOptional": true
      },
      "metricKey": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickReportSpecificAction": {
    "name": "DASHBOARD_INTERACTION",
    "namespace": "reporting",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string",
        "isOptional": true
      },
      "data_type": {
        "type": "string",
        "isOptional": true
      },
      "actionType": [
        "create-task",
        "add-persona",
        "set-quotas",
        "show-goals",
        "show-offline-sources",
        "customize-sales-performance",
        "set-goals"
      ]
    }
  },
  "reportResolve": {
    "name": "ANALYTICS_INTERACTION",
    "class": "view",
    "properties": {
      "status": [
        "SUCCESS",
        "FAILURE"
      ],
      "errorName": {
        "type": "string",
        "isOptional": true
      },
      "errorMessage": {
        "type": "string",
        "isOptional": true
      },
      "reportType": {
        "type": "string"
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "templateKey": {
        "type": "string",
        "isOptional": true
      },
      "pluginName": {
        "type": "string",
        "isOptional": true
      },
      "customWidgetType": {
        "type": "string",
        "isOptional": true
      },
      "dimensions": {
        "type": "array",
        "isOptional": true
      },
      "filters": {
        "type": "string",
        "isOptional": true
      },
      "metrics": {
        "type": "string",
        "isOptional": true
      },
      "isDrilldownReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "reportContainerHeight": {
        "type": "number",
        "isOptional": true
      },
      "reportContainerWidth": {
        "type": "number",
        "isOptional": true
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "viewReportingInsights": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "interactionSource": {
        "type": [
          "single-object-report-viewer"
        ],
        "isOptional": false
      },
      "insightReportMetric": {
        "type": [
          "MAX",
          "AVG",
          "MIN",
          "SUM"
        ],
        "isOptional": false
      },
      "insightReportChartType": {
        "type": [
          "DATA_WELL",
          "PIE",
          "TABLE"
        ]
      },
      "originalReportMetric": {
        "type": "string",
        "isOptional": false
      },
      "originalReportChartType": {
        "type": "string",
        "isOptional": false
      },
      "insightReportIndex": {
        "type": "number"
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickReportingInsightsUserFeedback": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "interactionSource": {
        "type": [
          "single-object-report-viewer"
        ],
        "isOptional": false
      },
      "insightReportMetric": {
        "type": [
          "MAX",
          "AVG",
          "MIN",
          "SUM"
        ],
        "isOptional": false
      },
      "insightReportChartType": {
        "type": [
          "DATA_WELL",
          "PIE",
          "TABLE"
        ]
      },
      "originalReportMetric": {
        "type": "string",
        "isOptional": false
      },
      "originalReportChartType": {
        "type": "string",
        "isOptional": false
      },
      "userFeedback": {
        "type": [
          "positive",
          "negative"
        ]
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickSetBusinessUnit": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "tag"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "count": {
        "type": "number",
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "single-asset-view",
          "asset-list-page"
        ],
        "isOptional": false
      },
      "interactionWith": [
        "asset-action"
      ]
    },
    "namespace": "reporting"
  },
  "setBusinessUnit": {
    "name": "ANALYTICS_INTERACTION",
    "class": "usage",
    "properties": {
      "actionType": {
        "type": [
          "save"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "tag"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "count": {
        "type": "number",
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "single-asset-view",
          "asset-list-page"
        ],
        "isOptional": false
      },
      "interactionWith": [
        "modal"
      ]
    },
    "namespace": "reporting"
  },
  "clickCreateDashboardFromTemplate": {
    "name": "DASHBOARD_LIBRARY_INTERACTION",
    "class": "interaction",
    "properties": {
      "templateName": "string"
    },
    "namespace": "reporting"
  },
  "clickCreateBlankDashboard": {
    "name": "DASHBOARD_LIBRARY_INTERACTION",
    "class": "interaction",
    "namespace": "reporting"
  },
  "createBlankDashboard": {
    "name": "DASHBOARD_LIBRARY_INTERACTION",
    "class": "interaction",
    "namespace": "reporting"
  },
  "createDashboardFromTemplate": {
    "name": "DASHBOARD_LIBRARY_INTERACTION",
    "class": "interaction",
    "properties": {
      "templateName": "string",
      "widgetNames": "array"
    },
    "namespace": "reporting"
  },
  "clickViewAssetDetails": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "single-asset-view",
          "asset-list-page"
        ],
        "isOptional": false
      },
      "interactionWith": [
        "asset-action"
      ]
    },
    "namespace": "reporting"
  },
  "saveDashboardAccess": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "whichFlow": {
        "type": "string"
      },
      "assignmentMode": {
        "type": "string"
      },
      "permissionValue": {
        "type": "string"
      },
      "teamCount": "number",
      "userCount": "number",
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "clickBackEditDashboardAccess": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "whichFlow": {
        "type": "string"
      },
      "whichStage": {
        "type": "string"
      },
      "dashboardId": {
        "type": "number",
        "isOptional": true
      },
      "assignmentMode": {
        "type": "string"
      },
      "permissionValue": {
        "type": "string"
      },
      "teamCount": "number",
      "userCount": "number",
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "clickNextEditDashboardAccess": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "whichFlow": {
        "type": "string"
      },
      "whichStage": {
        "type": "string"
      },
      "dashboardId": {
        "type": "number",
        "isOptional": true
      },
      "assignmentMode": {
        "type": "string",
        "isOptional": true
      },
      "permissionValue": {
        "type": "string",
        "isOptional": true
      },
      "teamCount": "number",
      "userCount": "number",
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "trackDashboardAccessPanelClicks": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "CLICK_CANCEL",
          "CLICK_SAVE",
          "CLICK_BACK",
          "CLICK_NEXT"
        ],
        "isOptional": false
      },
      "whichFlow": {
        "type": "string",
        "isOptional": true
      },
      "whichStage": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "number",
        "isOptional": true
      },
      "accessClassification": {
        "type": "string",
        "isOptional": true
      },
      "permissionLevel": {
        "type": "string",
        "isOptional": true
      },
      "numOfAssignedEntities": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "clickCancelEditDashboardAccess": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "whichFlow": {
        "type": "string"
      },
      "whichStage": {
        "type": "string"
      },
      "dashboardId": {
        "type": "number",
        "isOptional": true
      },
      "assignmentMode": {
        "type": "string",
        "isOptional": true
      },
      "permissionValue": {
        "type": "string",
        "isOptional": true
      },
      "teamCount": {
        "type": "number",
        "isOptional": true
      },
      "userCount": {
        "type": "number",
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "initialAssignmentMode": {
        "type": "string",
        "isOptional": true
      },
      "initialPermissionValue": {
        "type": "string",
        "isOptional": true
      },
      "initialTeamCount": {
        "type": "number",
        "isOptional": true
      },
      "initialUserCount": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "openDashboardFilters": {
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "open-filters"
        ],
        "isOptional": false
      },
      "objectTypeCount": {
        "type": "number",
        "isOptional": false
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "explore"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION"
  },
  "closeDashboardFilters": {
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "close-filters"
        ],
        "isOptional": false
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "explore"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION"
  },
  "dashboardFilterInteraction": {
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "add-filter",
          "edit-filter",
          "delete-filter",
          "pin-filter",
          "unpin-filter"
        ],
        "isOptional": false
      },
      "dataTypes": {
        "type": "array",
        "isOptional": false
      },
      "property": {
        "type": "string",
        "isOptional": false
      },
      "type": {
        "type": "string",
        "isOptional": false
      },
      "operator": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": [
          "all-data-source-properties",
          "search",
          "top-properties",
          "recent-properties"
        ],
        "isOptional": true
      },
      "interactionSource": {
        "type": [
          "quick-filter-popover",
          "filters-panel",
          "edit-filter-set"
        ],
        "isOptional": true
      },
      "dashboardId": {
        "type": "number",
        "isOptional": true
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "explore"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION"
  },
  "dashboardQuickFilterGroupInteraction": {
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "add-grouped-filter",
          "edit-grouped-filter",
          "delete-grouped-filter",
          "pin-grouped-filter",
          "unpin-grouped-filter",
          "remove-property-from-group",
          "add-property-to-group"
        ],
        "isOptional": false
      },
      "quickFilterGroupName": {
        "type": "string",
        "isOptional": false
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "operator": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": [
          "all-data-source-properties",
          "search",
          "top-properties",
          "recent-properties"
        ],
        "isOptional": true
      },
      "interactionSource": {
        "type": [
          "quick-filter-popover",
          "filters-panel",
          "edit-filter-set",
          "filter-group-accordian"
        ],
        "isOptional": true
      },
      "systemGenerated": {
        "type": "boolean"
      },
      "size": {
        "type": "number",
        "isOptional": true
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "explore"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION"
  },
  "propertyBrowsingInteraction": {
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "select-data-source",
          "search-properties"
        ],
        "isOptional": false
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "interactionSource": {
        "type": [
          "quick-filter-popover",
          "filters-panel"
        ],
        "isOptional": true
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "explore"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION"
  },
  "clearDashboardFilters": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "clear-filters"
        ]
      },
      "interactionSource": {
        "type": [
          "dashboard",
          "filters-panel"
        ]
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "explore"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "openGenerateQuickFilterGroupsPopover": {
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "open-generate-quick-filters"
        ],
        "isOptional": false
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "explore"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION"
  },
  "confirmResolveDuplicateProperties": {
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "confirm-resolve-duplicate-properties"
        ],
        "isOptional": false
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "explore"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION"
  },
  "openQuickFilterGroupPopover": {
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "open-quick-filter-group"
        ],
        "isOptional": false
      },
      "quickFilterGroupName": {
        "type": "string",
        "isOptional": false
      },
      "systemGenerated": {
        "type": "boolean"
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "explore"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION"
  },
  "clickGenerateQuickFilterGroups": {
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "generate-quick-filters"
        ],
        "isOptional": false
      },
      "quickFilterTemplates": {
        "type": "array",
        "isOptional": false
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "explore"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION"
  },
  "navigateBetweenQuickFilterTabs": {
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "navigate-between-quick-filters-tabs"
        ],
        "isOptional": false
      },
      "tabName": {
        "type": [
          "add-quick-filters",
          "add-quick-filter-groups"
        ]
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "explore"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION"
  },
  "openAddQuickFilters": {
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "open-add-quick-filters"
        ],
        "isOptional": false
      },
      "objectTypeCount": {
        "type": "number",
        "isOptional": true
      },
      "interactionSource": {
        "type": [
          "quick-filter-popover",
          "dashboard",
          "filters-panel"
        ],
        "isOptional": true
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "explore"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION"
  },
  "openEditQuickFilters": {
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "open-edit-quick-filters"
        ],
        "isOptional": false
      },
      "objectTypeCount": {
        "type": "number",
        "isOptional": true
      },
      "interactionSource": {
        "type": [
          "quick-filter-popover",
          "dashboard",
          "filters-panel"
        ],
        "isOptional": true
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "explore"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION"
  },
  "toggleQuickFilterPopover": {
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "open-quick-filter-popover",
          "close-quick-filter-popover"
        ],
        "isOptional": false
      },
      "propertyDisplayName": {
        "type": "string",
        "isOptional": false
      },
      "propertyName": {
        "type": "string",
        "isOptional": false
      },
      "dataSourceLabel": {
        "type": "string",
        "isOptional": false
      },
      "dataSourceId": {
        "type": "string",
        "isOptional": false
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "explore"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION"
  },
  "unpinAllQuickFilters": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "unpin-all-quick-filters"
        ]
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "explore"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "saveDashboardFilterSet": {
    "class": "interaction",
    "properties": {
      "interactionSource": {
        "type": [
          "save-new-form",
          "save-applied",
          "edit-filter-set"
        ],
        "isOptional": true
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "explore"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION"
  },
  "deleteDashboardFilterSet": {
    "class": "interaction",
    "properties": {
      "interactionSource": {
        "type": [
          "delete-all",
          "delete-single",
          "edit-filter-set"
        ],
        "isOptional": true
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "explore"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION"
  },
  "loadDashboardFilterSet": {
    "class": "interaction",
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "properties": {
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "explore"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    }
  },
  "setDashboardFrequency": {
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "set-dashboard-frequency"
        ]
      },
      "frequencyValue": {
        "type": [
          "DAY",
          "WEEK",
          "MONTH",
          "QUARTER",
          "YEAR",
          "NONE"
        ]
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "explore"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION"
  },
  "toggleFiscalYearFrequencyOption": {
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "toggle-fiscal-year-frequency-option"
        ]
      },
      "toggleFiscalYear": {
        "type": "boolean"
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "explore"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION"
  },
  "clickEditReport": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "properties": {
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "clickRemoveReport": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "properties": {
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "deleteReport": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "properties": {
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "removeReport": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "properties": {
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "clickMoveReport": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "properties": {
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "moveReport": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "use": {
        "type": "string",
        "isOptional": true
      },
      "dashboardType": {
        "type": [
          "NEW",
          "EXISTING"
        ]
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "viewSalesAnalyticsReport": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "subAppKey": "string",
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "clickExportAsset": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "share"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "export"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "single-asset-view",
          "asset-list-page",
          "widget"
        ],
        "isOptional": false
      },
      "interactionWith": [
        "asset-action"
      ],
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "interactOverrideWarningTooltip": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "hover",
          "clickLink"
        ],
        "isOptional": false
      },
      "actionType": [
        "share"
      ],
      "interactionSource": {
        "type": [
          "widget"
        ]
      },
      "interactionWith": [
        "tooltip"
      ],
      "reportType": {
        "type": [
          "snowflake",
          "sales-analytics"
        ],
        "isOptional": false
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "unsubscribeRecurringEmail": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "properties": {
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "searchDashboards": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "properties": {
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "switchToDashboard": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "filteredDashboardOptions": "boolean",
      "dashboardType": {
        "type": [
          "recent",
          "personal",
          "shared"
        ]
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "openDashboardList": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "properties": {
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "viewForbiddenDashboard": {
    "name": "DASHBOARD_INTERACTION",
    "class": "view",
    "properties": {
      "actionType": {
        "type": [
          "view"
        ]
      },
      "assetType": {
        "type": [
          "dashboard"
        ]
      },
      "dashboardType": {
        "type": [
          "PARTITIONED_DASHBOARD",
          "PRIVATE_DASHBOARD"
        ]
      },
      "interactionWith": {
        "type": [
          "ForbiddenDashboardPage"
        ]
      },
      "interactionSource": {
        "type": [
          "DashboardContainer"
        ]
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "clickRequestDashboardAccess": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "share"
        ]
      },
      "assetType": {
        "type": [
          "dashboard"
        ]
      },
      "dashboardType": {
        "type": [
          "PARTITIONED_DASHBOARD",
          "PRIVATE_DASHBOARD"
        ]
      },
      "interactionWith": {
        "type": [
          "ForbiddenDashboardPage"
        ]
      },
      "interactionSource": {
        "type": [
          "DashboardContainer"
        ]
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "viewDashboardAccessRequestAlert": {
    "name": "DASHBOARD_INTERACTION",
    "class": "view",
    "properties": {
      "actionType": {
        "type": [
          "view"
        ]
      },
      "assetType": {
        "type": [
          "dashboard"
        ]
      },
      "dashboardType": {
        "type": [
          "PARTITIONED_DASHBOARD",
          "PRIVATE_DASHBOARD"
        ]
      },
      "interactionWith": {
        "type": [
          "FloatingAlertStore"
        ]
      },
      "interactionSource": {
        "type": [
          "DashboardContainer"
        ]
      },
      "isActionSuccess": {
        "type": "boolean"
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "createDashboard": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "dashboardId": "number",
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "cloneDashboard": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "option": {
        "type": [
          "clone-reports",
          "link-reports"
        ]
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "deleteDashboard": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "subscreen": [
        "dashboard",
        "dashboard-manager"
      ],
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "renameDashboard": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "dashboardId": "number",
      "subscreen": [
        "dashboard",
        "dashboard-manager"
      ],
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "changeDashboardDescription": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "subscreen": [
        "dashboard",
        "dashboard-list"
      ],
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "changeShowDescription": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "value": {
        "type": "boolean"
      },
      "subscreen": [
        "dashboard",
        "dashboard-list"
      ],
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "changeDashboardOwner": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "subscreen": [
        "dashboard",
        "dashboard-manager"
      ],
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "setDefaultDashboard": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "subscreen": [
        "dashboard",
        "dashboard-manager"
      ],
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "removeDefaultDashboard": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "subscreen": [
        "dashboard",
        "dashboard-manager"
      ],
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "dashboardDayZero": {
    "name": "pageview",
    "class": "activation",
    "namespace": "reporting",
    "properties": {
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "dashboardActivation": {
    "name": "DASHBOARD_ACTIVATION",
    "class": "activation",
    "properties": {
      "option": {
        "type": [
          "clone-reports",
          "link-reports"
        ],
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "number",
        "isOptional": true
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "scrollDashboard": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "widgetCount": {
        "type": "number",
        "isOptional": true
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "clickFilterDashboard": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "explore"
        ]
      },
      "action": {
        "type": [
          "filter"
        ]
      },
      "assetType": [
        "dashboard"
      ],
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "viewDisabledFilterDashboard": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "view"
        ]
      },
      "action": {
        "type": [
          "view"
        ]
      },
      "assetType": [
        "dashboard"
      ],
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "addFilter": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "dashboardId": "number",
      "type": {
        "type": "string",
        "isOptional": false
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "updateFilter": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string",
        "isOptional": false
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "deleteFilter": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string",
        "isOptional": false
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "updateLayout": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "properties": {
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "clickCopyDashboardUrl": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "properties": {
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "clickActivityLog": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "properties": {
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "clickFullScreenMode": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "properties": {
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "clickAddReport": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "source": {
        "type": [
          "dashboard-header",
          "dashboard-empty-state"
        ],
        "isOptional": false
      },
      "option": {
        "type": [
          "from-library",
          "from-saved",
          "from-scratch"
        ],
        "isOptional": true
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "viewEmptyState": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "properties": {
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "onboardingInteraction": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": false
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "clickHelpOnEmptyState": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "properties": {
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "viewHSFSBanner": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "name": "string",
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "viewHSFSModal": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "name": "string",
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "clickAssignmentText": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "dashboardId": "number",
      "assignmentMode": "string",
      "permissionValue": "string",
      "teamCount": "number",
      "userCount": "number",
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "clickEditAccess": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "dashboardId": "number",
      "assignmentMode": "string",
      "permissionValue": "string",
      "teamCount": "number",
      "userCount": "number",
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "refreshAll": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "dashboardId": "number",
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "useRichTextFeature": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ]
      },
      "action": {
        "type": [
          "open",
          "insert"
        ]
      },
      "interactionWith": {
        "type": [
          "modal"
        ]
      },
      "interactionSource": {
        "type": [
          "note-editor"
        ]
      },
      "object": {
        "type": [
          "link",
          "image",
          "video"
        ]
      },
      "assetType": {
        "type": [
          "dashboard"
        ]
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "clickNoteAction": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": [
        "edit"
      ],
      "action": [
        "create",
        "edit",
        "delete"
      ],
      "object": [
        "note"
      ],
      "assetType": [
        "dashboard"
      ],
      "interactionSource": [
        "actions-dropdown",
        "widget"
      ],
      "interactionWith": [
        "asset-action"
      ],
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "completeNoteAction": {
    "name": "DASHBOARD_INTERACTION",
    "class": "usage",
    "properties": {
      "actionType": [
        "save"
      ],
      "action": [
        "create",
        "edit",
        "delete"
      ],
      "object": [
        "note"
      ],
      "assetType": [
        "dashboard"
      ],
      "interactionWith": [
        "modal"
      ],
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "clickIframeAction": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": [
        "edit",
        "view"
      ],
      "action": [
        "create",
        "edit",
        "delete",
        "refresh"
      ],
      "object": [
        "iframe"
      ],
      "assetType": [
        "dashboard"
      ],
      "interactionSource": [
        "actions-dropdown",
        "widget"
      ],
      "interactionWith": [
        "asset-action"
      ],
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "completeIframeAction": {
    "name": "DASHBOARD_INTERACTION",
    "class": "usage",
    "properties": {
      "actionType": [
        "save"
      ],
      "action": [
        "create",
        "edit",
        "delete"
      ],
      "object": [
        "iframe"
      ],
      "assetType": [
        "dashboard"
      ],
      "interactionWith": [
        "modal"
      ],
      "provider": {
        "type": "string",
        "isOptional": true
      },
      "option": {
        "type": "string",
        "isOptional": true
      },
      "settings": {
        "type": "array",
        "isOptional": true
      },
      "formatType": {
        "type": [
          "url",
          "code"
        ],
        "isOptional": true
      },
      "page": {
        "type": "string",
        "isOptional": true
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "deleteNonReportWidget": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": [
        "save"
      ],
      "action": [
        "delete"
      ],
      "object": [
        "iframe",
        "note",
        "section-header"
      ],
      "assetType": [
        "dashboard"
      ],
      "interactionWith": [
        "modal"
      ],
      "provider": {
        "type": "string",
        "isOptional": true
      },
      "option": {
        "type": "string",
        "isOptional": true
      },
      "settings": {
        "type": "array",
        "isOptional": true
      },
      "formatType": {
        "type": [
          "url",
          "code"
        ],
        "isOptional": true
      },
      "page": {
        "type": "string",
        "isOptional": true
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "openIframeDocumentation": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "provider": {
        "type": "string",
        "isOptional": false
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "clickCloneAction": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "create"
        ]
      },
      "action": {
        "type": [
          "create"
        ]
      },
      "step": [
        "start"
      ],
      "assetType": [
        "dashboard"
      ],
      "role": [
        "viewer",
        "editor",
        "owner",
        "admin"
      ],
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "clickDeleteAction": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ]
      },
      "action": {
        "type": [
          "delete"
        ]
      },
      "step": [
        "start"
      ],
      "assetType": [
        "dashboard"
      ],
      "role": [
        "viewer",
        "editor",
        "owner",
        "admin"
      ],
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "viewDashboard": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "isDefaultDashboard": {
        "type": "boolean",
        "isOptional": true
      },
      "dashboardId": {
        "type": "number",
        "isOptional": true
      },
      "widgetCount": {
        "type": "number",
        "isOptional": true
      },
      "monthsSinceCreated": {
        "type": "number",
        "isOptional": true
      },
      "monthsSinceUpdated": {
        "type": "number",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "exitPage": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "properties": {
      "reportingIncreaseCount": {
        "type": "number",
        "isOptional": true
      },
      "hasReportingAddon": {
        "type": "boolean",
        "isOptional": true
      }
    }
  }
};