'use es6';

import I18n from 'I18n';
import { fromJS, List, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { Filter } from '../config/filters';
import { getFilterByProperty } from '../config/filters/functions';
import { TABLE } from '../constants/chartTypes';
import * as ConfigTypes from '../constants/configTypes';
import * as DataTypes from '../constants/dataTypes';
import { DEFAULT_FISCAL_YEAR_MONTH_NAME } from '../constants/dates';
import * as Frequency from '../constants/frequency';
import * as Operators from '../constants/operators';
import { DATE_TIME, NUMBER } from '../constants/property-types';
import * as QuotaTypes from '../constants/quotaTypes';
import { getFiscalPeriodOffset, getUseFiscalYear, getUseFiscalYearInAggregation, isFiscalYearSupportedPeriod } from '../lib/fiscalYearUtils';
import makeDateRangeByType from '../lib/makeDateRangeByType';
import { userInfo as getUserInfo } from '../request/user-info';
import { Dataset } from '../v2/dataset/datasetRecords';
import { getDailyGoals, getFrequencyEndDate, isAccumulated, padBuckets, rebucket } from './calculations';
import { getQuotaReportingData, getReportOwners, hasGoalsAccess, hasQuotaSeries } from './quota';
import { GOAL_DATE_PROPERTY, GOAL_VALUE_PROPERTY } from '../constants/customSeries';
import { getCustomFilters, getFilterGroups, getReportConfig } from '../report/configReportGetters';
const COUNT_PROPERTY = 'count';
const BUCKET_DATE_FORMAT = 'YYYY-MM-DD';
const getQuotas = (requestData, quotaType) => getUserInfo().then(userInfo => {
  const {
    user: {
      scopes
    }
  } = userInfo;
  return getQuotaReportingData(scopes, requestData, quotaType).then(fromJS);
});
const getEngagementFilter = (engagementType, property = 'hs_engagement_type') => Filter({
  operator: Operators.IN,
  property,
  values: [engagementType]
});
const ENGAGEMENT_MEETING_FILTER_LEGACY = getEngagementFilter('MEETING', 'engagement.type');
const ENGAGEMENT_CALL_FILTER_LEGACY = getEngagementFilter('CALL', 'engagement.type');
const ENGAGEMENT_MEETING_FILTER = getEngagementFilter('MEETING');
const ENGAGEMENT_CALL_FILTER = getEngagementFilter('CALL');
const GOALS_MAPPING = fromJS({
  [QuotaTypes.DEALS_CREATED]: (dataType, metricProperties) => dataType === DataTypes.DEALS && metricProperties.contains(COUNT_PROPERTY),
  [QuotaTypes.REVENUE]: dataType => {
    /* SEE RA-1653
    const properties = Set([
      'amount',
      'projectedAmount',
      'closedAmount',
      'amount_in_home_currency',
      'projectedAmountInHomeCurrency',
      'closedAmountInHomeCurrency',
    ]);
    */

    return dataType === DataTypes.DEALS;
  },
  [QuotaTypes.TICKETS_CLOSED]: (dataType, metricProperties) => dataType === DataTypes.TICKETS && metricProperties.contains(COUNT_PROPERTY),
  [QuotaTypes.MEETINGS_BOOKED]: (dataType, metricProperties, config) => {
    const engagementFilter = Filter(getFilterByProperty(config, 'engagement.type') || getFilterByProperty(config, 'hs_engagement_type'));
    const hasMeetingsFilter = engagementFilter.equals(ENGAGEMENT_MEETING_FILTER) || engagementFilter.equals(ENGAGEMENT_MEETING_FILTER_LEGACY);
    const hasCountMetric = metricProperties.contains(COUNT_PROPERTY);
    return hasCountMetric && (dataType === DataTypes.MEETINGS || dataType === DataTypes.ENGAGEMENT && hasMeetingsFilter);
  },
  [QuotaTypes.CALLS_MADE]: (dataType, metricProperties, config) => {
    const callFilter = Filter(getFilterByProperty(config, 'engagement.type') || getFilterByProperty(config, 'hs_engagement_type'));
    const hasCallsFilter = callFilter.equals(ENGAGEMENT_CALL_FILTER) || callFilter.equals(ENGAGEMENT_CALL_FILTER_LEGACY);
    const hasCountMetric = metricProperties.contains(COUNT_PROPERTY);
    return hasCountMetric && (dataType === DataTypes.CALLS || dataType === DataTypes.ENGAGEMENT && hasCallsFilter);
  }
});
const getGoalType = report => {
  const config = report.get('config');
  const dataType = config.get('dataType');
  const configType = config.get('configType');
  if (configType !== ConfigTypes.TIME_SERIES) {
    return null;
  }
  const metrics = config.get('metrics').map(metric => metric.get('property')).toSet();
  return GOALS_MAPPING.findKey(isValid => isValid(dataType, metrics, config));
};
const getGoalSeries = ({
  report,
  buckets,
  goalResponse,
  fiscalYearMonthName
}) => {
  const frequency = report.getIn(['config', 'frequency'], Frequency.MONTH);
  const config = report.get('config');
  const shouldUpdateDates = getUseFiscalYearInAggregation(config) && getUseFiscalYear(config) && isFiscalYearSupportedPeriod(frequency, fiscalYearMonthName);
  const fiscalMonthOffset = getFiscalPeriodOffset(Frequency.YEAR, fiscalYearMonthName);
  if (shouldUpdateDates) {
    buckets = buckets.map(date => I18n.moment(date).subtract(1, 'year').add(fiscalMonthOffset, 'months').format(BUCKET_DATE_FORMAT));
  }
  const dates = buckets.update(datesWithoutEnd => datesWithoutEnd.push(getFrequencyEndDate({
    date: datesWithoutEnd.last(),
    frequency
  })));
  const dateFormat = 'YYYYMMDD';
  const {
    config: {
      dataType,
      filters: {
        dateRange: {
          value: reportRange
        } = {}
      } = {}
    }
  } = report.toJS();
  const useFiscalYear = getUseFiscalYear(config);
  const {
    startDate: reportStart,
    endDate: reportEnd
  } = makeDateRangeByType(reportRange, dateFormat, dataType, useFiscalYear, fiscalYearMonthName);
  const reportStartMoment = I18n.moment(reportStart, dateFormat);
  const reportEndMoment = I18n.moment(reportEnd, dateFormat);
  const startDate = goalResponse.size !== 0 ? goalResponse.first().get('key') : I18n.moment(dates.first()).format('YYYY-MM-01');
  const endDate = goalResponse.size !== 0 ? goalResponse.last().get('key') : I18n.moment(dates.last()).format('YYYY-MM-01');
  const goalsByMonth = goalResponse.map(bucket => ImmutableMap({
    date: bucket.get('key'),
    value: bucket.get('sum')
  }));
  const paddedGoalsByMonth = padBuckets({
    goalsByMonth,
    startDate,
    endDate
  });
  const dailyGoals = getDailyGoals({
    goalsByMonth: paddedGoalsByMonth
  }).filter(goal => {
    const goalMoment = I18n.moment(goal.get('date'), dateFormat);
    return !goalMoment.isBefore(reportStartMoment) && !goalMoment.isAfter(reportEndMoment);
  });
  const rebucketedDates = rebucket({
    dailyGoals,
    dates
  });
  if (shouldUpdateDates) {
    return rebucketedDates.map(bucketMap => bucketMap.update('date', dateString => I18n.moment(dateString).add(1, 'year').subtract(fiscalMonthOffset, 'months').format(BUCKET_DATE_FORMAT)));
  }
  return rebucketedDates;
};
const getDateRange = (config, buckets, fiscalYearMonthName) => {
  if (buckets.isEmpty()) {
    return null;
  }
  const firstBucket = buckets.first();
  const lastBucket = buckets.last();
  if (!I18n.moment(firstBucket).isValid() || !I18n.moment(lastBucket).isValid()) {
    return null;
  }
  const frequency = config.get('frequency', Frequency.MONTH);
  let startMonth = I18n.moment(firstBucket).month() + 1;
  let startYear = I18n.moment(firstBucket).year();
  const enddate = getFrequencyEndDate({
    date: lastBucket,
    frequency
  });
  let endMonth = I18n.moment(enddate).month() + 1;
  let endYear = I18n.moment(enddate).year();

  /**
   * We do this because if useFiscalYearInAggregation is true
   * and the fiscal year month isn't January, the dates returned
   * by the backend aren't accurate to the dates we need to look
   * up the quota for. We also need to adjust the month to match
   * the fiscal year date range when useFiscalYear is true.
   */
  if (getUseFiscalYearInAggregation(config) && getUseFiscalYear(config) && isFiscalYearSupportedPeriod(frequency, fiscalYearMonthName)) {
    const fiscalMonthOffset = getFiscalPeriodOffset(Frequency.YEAR, fiscalYearMonthName);
    startMonth = (startMonth - 1 + fiscalMonthOffset) % 12 + 1;
    endMonth = (endMonth - 1 + fiscalMonthOffset) % 12 + 1;
    startYear = I18n.moment(startYear, 'YYYY').subtract(1, 'year').year();
    endYear = I18n.moment(endYear, 'YYYY').subtract(1, 'year').year();
  }
  return {
    startDate: {
      month: startMonth,
      year: startYear
    },
    endDate: {
      month: endMonth,
      year: endYear
    }
  };
};
const USE_PIPELINE_FILTER = ImmutableSet([DataTypes.DEALS, DataTypes.TICKETS]);
const getPipelineFilters = config => {
  const dataType = config.get('dataType');
  const pipelineFilter = getFilterByProperty(config, 'pipeline') || getFilterByProperty(config, 'hs_pipeline');
  return USE_PIPELINE_FILTER.contains(dataType) && pipelineFilter ? {
    pipelinesOperator: pipelineFilter.get('operator'),
    pipelines: pipelineFilter.get('values')
  } : null;
};
function getGoalLineSeriesData(report, datePoints) {
  const data = fromJS(datePoints);
  const dataByDate = data.toMap().mapKeys((k, v) => v.get('date')).map(v => v.get('value'));
  const accumulate = isAccumulated(report);

  // assume the first series has all points
  const series = [];
  datePoints.forEach((point, index) => {
    const currentValue = dataByDate.get(point.get(GOAL_DATE_PROPERTY), 0);
    const goalValue = accumulate ? (index === 0 ? 0 : series[index - 1][GOAL_VALUE_PROPERTY]) + currentValue : currentValue;
    series.push({
      [GOAL_DATE_PROPERTY]: point.get(GOAL_DATE_PROPERTY),
      [GOAL_VALUE_PROPERTY]: goalValue
    });
  });
  return series;
}
const getRequestPayload = (report, buckets, goalType, fiscalYearMonthName) => {
  const config = getReportConfig(report);
  const PipelinesFilter = getPipelineFilters(config);
  const dateRange = getDateRange(config, buckets, fiscalYearMonthName);
  return dateRange && goalType ? getReportOwners(report).then(owners => owners ? owners.map(owner => owner.get('ownerId')) : []).then(ownerIds => ownerIds.length !== 0 ? {
    assignees: ownerIds,
    assigneesOperator: Operators.IN
  } : null).then(owners => {
    return Object.assign({
      customFilters: getCustomFilters(config),
      filterGroups: getFilterGroups(config)
    }, PipelinesFilter, owners, {
      dateRange
    });
  }) : null;
};
const getDatetimeQuotaInternals = (report, buckets, fiscalYearMonthName) => {
  if (!hasQuotaSeries(report) || report.get('chartType') === TABLE) {
    return Promise.resolve(report);
  }
  const goalType = getGoalType(report);
  return Promise.all([getRequestPayload(report, buckets, goalType, fiscalYearMonthName), getUserInfo()]).then(([requestPayload, userInfo]) => requestPayload && hasGoalsAccess(userInfo) ? getQuotas(requestPayload, goalType, userInfo).then(quotas => {
    const data = getGoalLineSeriesData(report, getGoalSeries({
      report,
      buckets,
      goalResponse: quotas,
      fiscalYearMonthName
    }));
    const dateLabelReferences = data.reduce((map, dataPoint) => map.set(dataPoint[GOAL_DATE_PROPERTY], ImmutableMap({
      label: I18n.moment(dataPoint[GOAL_DATE_PROPERTY]).format('l')
    })), ImmutableMap());
    return Dataset({
      data,
      properties: ImmutableMap({
        [GOAL_DATE_PROPERTY]: {
          type: DATE_TIME,
          references: dateLabelReferences
        },
        [GOAL_VALUE_PROPERTY]: {
          type: NUMBER,
          label: I18n.text(`reporting-data.customSeries.goals.${goalType}`)
        }
      })
    });
  }) : Promise.resolve(null));
};
export const getDatetimeQuotaData = (report, oldReportDataset) => {
  const buckets = oldReportDataset.getIn(['dimension', 'buckets'], List()).map(dataRow => dataRow.get('key'));
  return getDatetimeQuotaInternals(report, buckets);
};

/**
 *
 *  Used for resolving quota data around the RAAS BE resolve process
 *
 * @param {Report} report report config object
 * @param {Dataset} newReportDataset  new reporting dataset format
 * @returns {Promise}
 */
export const getBackendResolveDatetimeQuotaData = (report, newReportDataset, fiscalYearMonthName = DEFAULT_FISCAL_YEAR_MONTH_NAME) => {
  const dimensionProperty = report.getIn(['config', 'dimensions', 0]);
  const buckets = newReportDataset.get('data').map(dataRow => dataRow.get(dimensionProperty)).toOrderedSet().toList();
  return getDatetimeQuotaInternals(report, buckets, fiscalYearMonthName);
};
export const __TESTABLE__ = {
  getGoalSeries,
  getGoalType
};