'use es6';

import { fromJS, Set as ImmutableSet, List } from 'immutable';
import getfilterGroupsExtractor from '../common/extractors/reportFilters';
import getSortsExtractor from '../common/extractors/sorts';
import mapSortProperties from './mapSortProperties';
import { getPropertiesMap } from '../../../properties/getProperty';
import { DESC } from '../../../constants/sortOrder';
import { IN } from '../../../constants/operators';
import { STRING } from '../../../constants/property-types';
import * as DataTypes from '../../../constants/dataTypes';
const DEFAULT_ROW_COUNT = 5;
export const generate = (config, idProperty) => {
  const filterGroupsExtractor = getfilterGroupsExtractor();
  const dataType = config.get('dataType');
  const extractedFilterGroups = fromJS(filterGroupsExtractor(config));
  const filterProperties = extractedFilterGroups.reduce((memo, filterGroup) => filterGroup.get('filters').reduce((innerMemo, filter) => innerMemo.add(filter.get('property')), memo), ImmutableSet());
  const propertiesPromise = getPropertiesMap(dataType, filterProperties);
  const filterGroupsPromise = propertiesPromise.then(properties => extractedFilterGroups.map(filterGroup => filterGroup.update('filters', (filters = List()) => filters.map(filter => {
    const property = properties.get(filter.get('property'));
    if (filter.get('operator') === IN && property.get('type') === STRING) {
      return filter.set('propertySuffix', 'raw');
    }
    return filter;
  }))));
  const sortsExtractor = getSortsExtractor();
  const sorts = sortsExtractor(config);
  if (sorts.length === 0 && config.getIn(['filters', 'dateRange', 'property'])) {
    sorts.push({
      property: config.getIn(['filters', 'dateRange', 'property']),
      order: DESC
    });
  }
  if (idProperty) {
    sorts.push({
      property: dataType === DataTypes.CROSS_OBJECT ? idProperty : 'hs_object_id',
      order: DESC
    });
  }
  return filterGroupsPromise.then(filterGroups => fromJS({
    count: config.get('limit') || DEFAULT_ROW_COUNT,
    offset: config.get('offset') || 0,
    filterGroups,
    sorts: mapSortProperties(config, sorts)
  }));
};