import { fromJS } from 'immutable';
import { SHOW_BANNER } from 'reporting-data/constants/deprecate/deprecationTypes';
import { getReportConfig, getFilterGroupsProperties, getCustomFilterProperties, getMetricPropertiesFromConfig, getDimensionFromConfig, getDataTypeFromConfig } from 'reporting-data/report/configReportGetters';
// @ts-expect-error Untyped dependency
import { HUBSPOT_CRM_OBJECTS_BY_ID } from 'reporting-data/crmObjects/utils';
import { CONTACTS } from 'reporting-data/constants/dataTypes';
const CURRENTLY_IN_WORKFLOW_PROPERTY_NAME = 'currentlyinworkflow';
export const usesCurrentlyInWorkflowContactProperty = report => {
  const config = getReportConfig(report);
  if (!config) {
    return report;
  }
  const reportDataType = getDataTypeFromConfig(config);
  const isContactDataType = reportDataType === CONTACTS || HUBSPOT_CRM_OBJECTS_BY_ID.get(reportDataType) === CONTACTS;
  if (!isContactDataType) {
    return report;
  }
  const metricProperties = getMetricPropertiesFromConfig(config).toJS();
  const dimensions = getDimensionFromConfig(config).toJS();
  const customFilterProperties = getCustomFilterProperties(config).toJS();
  const filterGroupProperties = getFilterGroupsProperties(config).toJS();
  const usedProperties = new Set([...metricProperties, ...dimensions, ...customFilterProperties, ...filterGroupProperties]);
  const shouldDeprecate = usedProperties.has(CURRENTLY_IN_WORKFLOW_PROPERTY_NAME);
  return shouldDeprecate ? report.set('deprecation', fromJS({
    isDeprecated: true,
    type: SHOW_BANNER,
    message: 'usesCurrentlyInWorkflowProperty'
  })) : report;
};
export default usesCurrentlyInWorkflowContactProperty;