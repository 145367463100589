import * as ObjectTypes from 'customer-data-objects/constants/ObjectTypes';
import * as ObjectTypeIds from 'customer-data-objects/constants/ObjectTypeIds';

// This should use ObjectTypeToDSAssetFamily but it's missing FEEDBACK_SUBMISSION
const ObjectTypesToIds = {
  [ObjectTypes.CONTACT]: ObjectTypeIds.CONTACT_TYPE_ID,
  [ObjectTypes.COMPANY]: ObjectTypeIds.COMPANY_TYPE_ID,
  [ObjectTypes.DEAL]: ObjectTypeIds.DEAL_TYPE_ID,
  [ObjectTypes.ENGAGEMENT]: ObjectTypeIds.ENGAGEMENT_TYPE_ID,
  [ObjectTypes.TICKET]: ObjectTypeIds.TICKET_TYPE_ID,
  [ObjectTypes.PRODUCT]: ObjectTypeIds.PRODUCT_TYPE_ID,
  [ObjectTypes.LINE_ITEM]: ObjectTypeIds.LINE_ITEM_TYPE_ID,
  [ObjectTypes.QUOTE]: ObjectTypeIds.QUOTE_TYPE_ID,
  [ObjectTypes.FEEDBACK_SUBMISSION]: ObjectTypeIds.FEEDBACK_SUBMISSION_TYPE_ID
};

// See Meta Type IDs here:
// https://product.hubteam.com/docs/hubspot-framework/crm-objects/schemas/object-types/object_type_definition_fields.html
export const StandardObjectTypeIdRegex = /^0-[0-9]+$/;
export const AppObjectTypeIdRegex = /^1-[0-9]+$/;
export const CustomObjectTypeIdRegex = /^2-[0-9]+$/;

// Meta Type is one of [Standard, App, or Custom]
export const ObjectTypeIdRegexStrict = /^[0-2][-][0-9]+$/;
// Meta Type is object type, HubDB object, or Unified Event type
export const ObjectTypeIdRegex = /^[0-9][-][0-9]+$/;
export const getObjectTypeIdSupportedFamily = filterFamily => {
  if (Object.prototype.hasOwnProperty.call(ObjectTypesToIds, filterFamily)) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return ObjectTypesToIds[filterFamily];
  }
  return filterFamily;
};
export const isUnifiedEventObjectTypeId = objectTypeId => {
  return typeof objectTypeId === 'string' && objectTypeId.startsWith('4-');
};
export const isIntegratorEventObjectTypeId = objectTypeId => {
  return typeof objectTypeId === 'string' && objectTypeId.startsWith('5-');
};
export const isCustomBehavioralEventObjectTypeId = objectTypeId => {
  return typeof objectTypeId === 'string' && objectTypeId.startsWith('6-');
};
export const isAnyEventTypeId = objectTypeId => {
  return isUnifiedEventObjectTypeId(objectTypeId) || isIntegratorEventObjectTypeId(objectTypeId) || isCustomBehavioralEventObjectTypeId(objectTypeId);
};