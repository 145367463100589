'use es6';

import { Encoding, FormatTypeDefinitions } from '../../relational/schema/column-records';
import { getVisualTypeChannels, isSingleEncoding } from '../../relational/utils/visual-utils';
import { RelationalReport } from '../../relational/schema/report-records';
import { Visual } from '../../relational/schema/visual-records';
import { defaultEncodingOptions } from '../../relational/schema/column-records';
import { getReportDefinition } from 'reporting-data/report/reportGetters';

/**
 * @param {Visual} visual
 * @param {(Encoding, channelName, index) => Encoding} mapperFn
 * @returns {Visual}
 */
const mapEncodings = (visual, mapperFn) => {
  // note: Visual records do not get correctly initialize at the moment, therefore we have to use the ImmutableMap API
  const type = visual.get('type');
  const encodings = visual.get('encodings');
  return visual.set('encodings', getVisualTypeChannels(type).reduce((nextEncodings, channelMeta) => {
    const {
      name: channelName
    } = channelMeta;
    const encoding = encodings.get(channelName);
    if (!encoding) {
      return nextEncodings;
    }
    if (isSingleEncoding(channelMeta)) {
      return nextEncodings.set(channelName, mapperFn(encoding, channelName, 0));
    }
    return nextEncodings.set(channelName, encoding.map((e, index) => {
      return mapperFn(e, channelName, index);
    }));
  }, encodings));
};
const validateEncodingOptions = encoding => {
  return encoding.get('options') && encoding.get('options').format && FormatTypeDefinitions.has(encoding.get('options').format.type);
};
const backfillEncodings = reportDefinition => {
  const relationalReport = RelationalReport(reportDefinition);
  if (!relationalReport.visual) {
    return reportDefinition;
  }
  const fillEncoding = encoding => {
    const hasValidOptions = validateEncodingOptions(encoding);
    if (hasValidOptions) {
      return encoding;
    }
    return encoding.set('options', defaultEncodingOptions);
  };
  const filledVisual = mapEncodings(Visual(relationalReport.visual), encoding => fillEncoding(encoding));
  return reportDefinition.set('visual', filledVisual);
};
const backfillStagedEncodingsAndOptions = reportDefinition => {
  const presentEncodings = reportDefinition.get('stagedEncodings');
  const allEncodingsArePresent = reportDefinition.get('stagedEncodings') && reportDefinition.get('stagedEncodings').size === reportDefinition.get('stagedColumns').size;
  const allEncodingsAreValid = allEncodingsArePresent && !reportDefinition.get('stagedEncodings').find(encoding => !validateEncodingOptions(encoding));
  if (allEncodingsAreValid) {
    return reportDefinition;
  }
  if (allEncodingsArePresent) {
    const presentEncodingsWithOptions = presentEncodings.map(encoding => {
      return Encoding({
        column: encoding.get('column'),
        options: defaultEncodingOptions
      });
    });
    return reportDefinition.set('stagedEncodings', presentEncodingsWithOptions);
  }
  const stagedColumns = reportDefinition.get('stagedColumns');
  const stagedEncodings = stagedColumns.map(column => {
    return Encoding({
      column: column.get('alias'),
      options: defaultEncodingOptions
    });
  });
  return reportDefinition.set('stagedEncodings', stagedEncodings);
};
export default (report => {
  const reportDefinition = getReportDefinition(report);
  const nextDefinition = backfillEncodings(backfillStagedEncodingsAndOptions(reportDefinition));
  const next = report.set('reportDefinition', nextDefinition);
  return Promise.resolve(next);
});