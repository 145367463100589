import { createSelector } from 'reselect';
import { fromJS } from 'immutable';
import { fetchAiReportsSearch } from '../../api/aiSearchApi';
import { RequestState } from 'dashboard-lib/public/request/request-state';
import debounce from 'transmute/debounce';
const {
  UNINITIALIZED,
  PENDING,
  SUCCEEDED,
  FAILED
} = RequestState;
export const aiReportSearchNamespace = 'aiReportSearch';

// Action types
const FETCH_AI_REPORT_SUGGESTIONS_PENDING = 'FETCH_AI_REPORT_SUGGESTIONS_PENDING';
const FETCH_AI_REPORT_SUGGESTIONS_SUCCEEDED = 'FETCH_AI_REPORT_SUGGESTIONS_SUCCEEDED';
const FETCH_AI_REPORT_SUGGESTIONS_FAILED = 'FETCH_AI_REPORT_SUGGESTIONS_FAILED';
// Actions

export const debouncedFetchAiReportSearch = debounce(1000, (search, dispatch) => {
  return fetchAiReportsSearch({
    userInput: search,
    clicks: 0
  }).then(result => {
    dispatch({
      type: FETCH_AI_REPORT_SUGGESTIONS_SUCCEEDED,
      payload: {
        suggestions: result.templateSuggestions,
        chatId: result.chatId
      }
    });
  }).catch(exception => {
    if ((exception === null || exception === void 0 ? void 0 : exception.errorCode) !== 'ABORT') {
      console.error(exception);
      dispatch({
        type: FETCH_AI_REPORT_SUGGESTIONS_FAILED
      });
    }
  });
});
export const fetchAiReportSuggestions = search => dispatch => {
  dispatch({
    type: FETCH_AI_REPORT_SUGGESTIONS_PENDING
  });
  return debouncedFetchAiReportSearch(search, dispatch);
};
// Reducers
const initialState = fromJS({
  status: UNINITIALIZED,
  suggestions: [],
  chatId: ''
});
export const aiReportSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AI_REPORT_SUGGESTIONS_PENDING:
      return state.set('status', PENDING);
    case FETCH_AI_REPORT_SUGGESTIONS_SUCCEEDED:
      return state.merge({
        status: SUCCEEDED,
        suggestions: action.payload.suggestions,
        chatId: action.payload.chatId
      });
    case FETCH_AI_REPORT_SUGGESTIONS_FAILED:
      return state.set('status', FAILED);
    default:
      return state;
  }
};

// If using at the top level

// If using within report panel reducer
const reportPanelNamespace = 'reportPanel';
const isAiReportSearchNestedState = state => {
  return reportPanelNamespace in state;
};
export const selectAiReportSearchRoot = state => {
  if (isAiReportSearchNestedState(state)) {
    return state[reportPanelNamespace][aiReportSearchNamespace];
  }
  return state[aiReportSearchNamespace];
};
export const selectAiReportSuggestions = createSelector(selectAiReportSearchRoot, aiReportSearchRoot => aiReportSearchRoot.get('suggestions'));
export const selectChatId = createSelector(selectAiReportSearchRoot, aiReportSearchRoot => aiReportSearchRoot.get('chatId'));
export const selectIsAiReportSearchLoading = createSelector(selectAiReportSearchRoot, aiReportSearchRoot => aiReportSearchRoot.get('status') === PENDING);
export const selectIsAiReportSearchFailed = createSelector(selectAiReportSearchRoot, aiReportSearchRoot => aiReportSearchRoot.get('status') === FAILED);