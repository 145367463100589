'use es6';

import { fromJS, List } from 'immutable';
import { SHOW_BANNER } from 'reporting-data/constants/deprecate/deprecationTypes';
import { isConfigReport } from 'reporting-data/tsTypes/reportTypes';
import { getDataTypeFromConfig } from 'reporting-data/report/reportGetters';
import { ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS } from 'reporting-data/crmObjects/utils';
import { FREE_AND_STARTER_DEAL_SCP_LOCKDOWN } from '../../constants/trackingMetrics';
import { DEALS } from 'reporting-data/constants/dataTypes';
import { LOCK_DOWN_REPORT } from 'reporting-data/constants/deprecate/deprecationTypes';
import { Metrics } from '../../monitoring/Metrics';
export const dealStageCalculatedProperties = ['dealstages.', 'hs_time_in_', 'BUCKET_dealstages.'];
export const dealStageCalculatedPropertiesV2 = ['hs_v2_latest_time_in_', 'hs_v2_cumulative_time_in_'];
export const usesLegacyLifecycleStages = (report, dataType, bannerMessage, gatesAndScopeCheck = false, matchingPatterns = ['hs_date_entered_'], deprecationType = SHOW_BANNER) => {
  const reportDataType = isConfigReport(report) && getDataTypeFromConfig(report.get('config'));
  const isCorrectDataType = reportDataType === dataType || reportDataType === ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS.get(dataType);
  if (!gatesAndScopeCheck || !isCorrectDataType) {
    return report;
  }
  const findLegacyProperty = str => matchingPatterns.find(pattern => str.startsWith(pattern));
  const isDateEnteredProperty = dataElement => {
    const property = dataElement && dataElement.get('property') || '';
    return findLegacyProperty(property);
  };
  const filters = report.getIn(['config', 'filters'], List());
  const metrics = report.getIn(['config', 'metrics'], List());
  const dimensions = report.getIn(['config', 'dimensions'], List());
  const customFilters = filters.getIn(['custom'], List());
  const usedInDateRangeFilter = isDateEnteredProperty(filters.getIn(['dateRange']));
  const usedInDimensions = dimensions.find(dim => dim && findLegacyProperty(dim));
  const metricsOrFiltersUseProperty = List([metrics, customFilters]).find(list => list.find(item => isDateEnteredProperty(item)));
  const usedInMetricsOrFilters = metricsOrFiltersUseProperty && metricsOrFiltersUseProperty.size > 0;
  const shouldDeprecate = usedInDateRangeFilter || usedInDimensions || usedInMetricsOrFilters;

  // Track the number of reports that are locked down due to Deal SCP Migration
  if (shouldDeprecate && dataType === DEALS && deprecationType === LOCK_DOWN_REPORT) {
    Metrics.counter(FREE_AND_STARTER_DEAL_SCP_LOCKDOWN).increment();
  }
  return shouldDeprecate ? report.set('deprecation', fromJS({
    isDeprecated: true,
    type: deprecationType,
    message: bannerMessage
  })) : report;
};