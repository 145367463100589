import PropTypes from 'prop-types';
// TODO - remove offerId and productConfigurations after all consumers are using the multiple
// offer id flow.
export const CheckoutPropsInterface = {
  offerId: PropTypes.number,
  currencyCode: PropTypes.any.isRequired,
  productConfigurations: PropTypes.arrayOf(PropTypes.shape({
    skuId: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired
  })),
  requestedProducts: PropTypes.arrayOf(PropTypes.shape({
    productApiName: PropTypes.string.isRequired,
    quantity: PropTypes.number
  })),
  terms: PropTypes.shape({
    commitmentTerm: PropTypes.string,
    paymentTerm: PropTypes.string
  }),
  offerProductConfigurations: PropTypes.arrayOf(PropTypes.shape({
    offerId: PropTypes.number.isRequired,
    productConfigurations: PropTypes.arrayOf(PropTypes.shape({
      skuId: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired
    }).isRequired).isRequired
  }).isRequired),
  nonRecurringProductConfigurations: PropTypes.arrayOf(PropTypes.shape({
    skuId: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired
  }).isRequired),
  productBasedOffers: PropTypes.array,
  additionalUnitQuantity: PropTypes.number
};
export const CheckoutButtonPropsInterface = Object.assign({}, CheckoutPropsInterface, {
  onClick: PropTypes.func,
  onError: PropTypes.func,
  beforeRedirect: PropTypes.func,
  products: PropTypes.array,
  shouldShowErrorAlert: PropTypes.bool,
  trackingSource: PropTypes.string,
  children: PropTypes.node,
  use: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool
});