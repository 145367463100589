'use es6';

import { SUM, PERCENTILES } from '../../../../constants/metricTypes';
const zeroPercentConversion = data => data.updateIn(['dimension', 'buckets'], buckets => buckets.map((bucket, index, entire) => {
  const conversion = index + 1 < entire.size ? 0 : undefined;
  return bucket.setIn(['metrics', 'funnel.conversion', PERCENTILES], conversion).setIn(['metrics', 'funnel.stepwiseConversion', PERCENTILES], conversion).setIn(['metrics', 'funnel.cumulativeConversion', PERCENTILES], conversion);
})).setIn(['metrics', 'funnel.conversion', PERCENTILES], 0);
const netConversion = data => data.setIn(['metrics', 'funnel.conversion', PERCENTILES], data.getIn(['dimension', 'buckets']).size === 1 ? undefined : data.getIn(['dimension', 'buckets']).last().getIn(['metrics', 'count', SUM]) / data.getIn(['dimension', 'buckets', 0, 'metrics', 'count', SUM]));
const stepwiseConversion = data => data.updateIn(['dimension', 'buckets'], buckets => buckets.map((bucket, index, entire) => {
  const count = bucket.getIn(['metrics', 'count', SUM]);
  const nextCount = entire.getIn([index + 1, 'metrics', 'count', SUM]);
  if (nextCount === undefined) {
    return bucket.setIn(['metrics', 'funnel.conversion', PERCENTILES], undefined).setIn(['metrics', 'funnel.stepwiseConversion', PERCENTILES], undefined);
  } else {
    const ratio = count === 0 ? 0 : nextCount / count;
    return bucket.setIn(['metrics', 'funnel.conversion', PERCENTILES], ratio).setIn(['metrics', 'funnel.stepwiseConversion', PERCENTILES], ratio);
  }
}));
const cumulativeConversion = data => data.updateIn(['dimension', 'buckets'], buckets => buckets.map((bucket, index, entire) => bucket.setIn(['metrics', 'funnel.cumulativeConversion', PERCENTILES], index + 1 < entire.size ? buckets.getIn([index + 1, 'metrics', 'count', SUM], 0) / data.getIn(['dimension', 'buckets', 0, 'metrics', 'count', SUM], 0) : undefined)));
export const calculate = data => data.getIn(['dimension', 'buckets', 0, 'metrics', 'count', SUM], 0) === 0 ? zeroPercentConversion(data) : cumulativeConversion(stepwiseConversion(netConversion(data)));