import unescapedText from 'I18n/utils/unescapedText';
import I18n from 'I18n';
import { getLocaleData } from '../Locale';
export class SimpleDate {
  constructor(year, month, date) {
    if (typeof year === 'object') {
      const {
        year: y,
        month: m,
        date: d
      } = year;
      this.year = y;
      this.month = m;
      this.date = d;
    } else {
      this.year = year;
      this.month = month;
      this.date = date;
    }
  }
  static create(year, month, date) {
    if (typeof year === 'object') {
      const {
        year: y,
        month: m,
        date: d
      } = year;
      return new SimpleDate(y, m, d);
    }
    return new SimpleDate(year, month, date);
  }
}

// Factory function to create SimpleDate instances
function SimpleDateFactory(year, month, date) {
  if (typeof year === 'object') {
    return new SimpleDate(year.year, year.month, year.date);
  }
  return new SimpleDate(year, month, date);
}

// Override the SimpleDate function to allow instantiation without `new`
export const SimpleDateClass = SimpleDateFactory;

/**
 * Test if two SimpleDate objects represent the same date.
 *
 * @param dateA
 * @param dateB
 * @return boolean
 */
export function equals(dateA, dateB) {
  if (dateA === dateB) {
    return true;
  }
  if (!dateA || !dateB) {
    return false;
  }
  return dateA.year === dateB.year && dateA.month === dateB.month && dateA.date === dateB.date;
}
export function now(constructor = 'userTz') {
  const momentNow = I18n.moment[constructor]();
  return make(momentNow.year(), momentNow.month(), momentNow.date());
}
export const EmptySimpleDate = {
  year: 0,
  month: 0,
  date: 0
};

// Aliases for backward compatibility
export const SimpleDateRecord = SimpleDateClass;
export const EmptySimpleDateRecord = EmptySimpleDate;

/**
 * Comparison function suitable for use with the native `sort()` method, e.g.
 * `[dateA, dateB, dateC].sort(compare)`.
 *
 * @param dateA
 * @param dateB
 * @return -1 if `dateA < dateB`, 1 if `dateB < dateA`, 0 if the two are identical
 */
export function compare(dateA, dateB) {
  if (dateA && !dateB) {
    return 1;
  }
  if (!dateA && dateB) {
    return -1;
  }
  if (!dateA || !dateB) {
    throw new Error('At least one date must be provided');
  }
  const keys = ['year', 'month', 'date'];
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (dateA[key] < dateB[key]) {
      return -1;
    } else if (dateA[key] > dateB[key]) {
      return 1;
    }
  }
  return 0;
}

/**
 * @param year E.g. 2012
 * @param month From 0 to 11 (same as `Date`)
 * @param date From 1 to 31 (same as `Date`)
 * @returns A new `SimpleDate`
 */
export function make(year, month, date) {
  return new SimpleDate(year, month, date);
}

/**
 * Returns true if a simple date object contains valid data
 *
 * @param simpleDate
 * @return
 */
export function isValid(simpleDate = null) {
  if (simpleDate == null || typeof simpleDate.year !== 'number' || typeof simpleDate.month !== 'number' || typeof simpleDate.date !== 'number') {
    return false;
  }
  return I18n.moment([simpleDate.year, simpleDate.month, simpleDate.date]).isValid();
}

/**
 * Returns true if given object contains a valid start and end date and
 * the start date is before or equal to the end date
 *
 * @param
 * @return
 */
export function isValidRange({
  startDate,
  endDate
}) {
  return isValid(startDate) && isValid(endDate) && compare(startDate, endDate) !== 1;
}

/**
 * Converts a simple date to an array of the form [year, month, date]
 *
 * @param  {SimpleDate | null | undefined} simpleDate
 * @return {Array<number> | null}
 */
export function toArray(simpleDate = null) {
  if (simpleDate == null) {
    return null;
  }
  const {
    year,
    month,
    date
  } = simpleDate;
  return [year, month, date];
}

/**
 * Function for converting a SimpleDateRecord to a Moment
 *
 * @param  {"portalTz" | "userTz" | "utc"} constructor
 * @param  {SimpleDate} simpleDate
 * @return {Moment}
 */
export function makeMoment(constructor, simpleDate) {
  const dateArray = toArray(simpleDate);
  if (dateArray === null) {
    throw new Error('Invalid simple date');
  }
  return I18n.moment[constructor](dateArray);
}

/**
 * Converts a simple date to a Moment object in the user's timezone.
 * Useful for getting to other date representations. e.g.
 *
 * const timestamp = toMoment(date).valueOf();
 * const formattedStr = toMoment(date).format('L');
 *
 * @param  {SimpleDate}
 * @return {Moment}
 */
export const toMoment = date => makeMoment('userTz', date);

/**
 * Like `toMoment`, but returns a Moment in the portal timezone
 * rather than the user's timezone.
 *
 * @param  {SimpleDateRecord}
 * @return {Moment}
 */
export const toMomentPortalTz = date => makeMoment('portalTz', date);

/**
 * Like `toMoment`, but returns a Moment in UTC rather than the
 * user's timezone.
 *
 * @param  {SimpleDateRecord}
 * @return {Moment}
 */
export const toMomentUTC = date => makeMoment('utc', date);
export function fromMoment(moment) {
  if (!moment.isValid()) {
    return EmptySimpleDate;
  }
  return make(moment.get('year'), moment.get('month'), moment.get('date'));
}
export function nextDay(simpleDate) {
  if (!simpleDate) return;
  return fromMoment(toMoment(simpleDate).add(1, 'day'));
}
export function prevDay(simpleDate) {
  if (!simpleDate) return;
  return fromMoment(toMoment(simpleDate).subtract(1, 'day'));
}

/**
 * Return a date from the given string, or `null` if the string doesn't match the given format.
 * Unlike `I18n.moment(str, format)`, this function is strict about the string containing all three
 * parts of a date.
 *
 * @param {string} str
 * @param {string} format
 * @return {?SimpleDateRecord}
 */
export const fromString = (str, format) => {
  var _getLocaleData;
  if (format === 'll' || format === 'LL') {
    // convert the date using strict mode. It will enforce a full date
    const momentDate = I18n.moment(str, format, true);
    const month = momentDate.month();
    const year = momentDate.year();
    const date = momentDate.date();
    if (Number.isNaN(month) || Number.isNaN(year) || Number.isNaN(date)) {
      return null;
    }
    const result = new SimpleDate(year, month, date);
    return isValid(result) ? result : null;
  }
  const yearMatch = str.match(/\b\d{4}\b/);
  if (!yearMatch) return null;
  const monthAndDateMatches = str.match(/\b(\d{1,2})[^\d](\d{1,2})\b/);
  if (!monthAndDateMatches) return null;
  const year = +yearMatch[0];
  let month;
  let date;
  const dateFormat = ((_getLocaleData = getLocaleData()) === null || _getLocaleData === void 0 || (_getLocaleData = _getLocaleData._longDateFormat) === null || _getLocaleData === void 0 ? void 0 : _getLocaleData[format]) || format;
  if (dateFormat.indexOf('MM') > dateFormat.indexOf('DD')) {
    month = +monthAndDateMatches[2] - 1;
    date = +monthAndDateMatches[1];
  } else {
    month = +monthAndDateMatches[1] - 1;
    date = +monthAndDateMatches[2];
  }
  const result = new SimpleDate(year, month, date);
  return isValid(result) ? result : null;
};
export function getBoundedDate(date, min, max) {
  if (date == null) return date;
  if (min != null && compare(min, date) === 1) return min;
  if (max != null && compare(max, date) === -1) return max;
  return date;
}
export function isDateInRange(date, min, max) {
  return getBoundedDate(date, min, max) === date;
}
export const toFormattedString = (date, format) => {
  if (date == null) return '';
  if (!isValid(date)) return unescapedText('ui.datePicker.invalidDate');
  return toMoment(date).format(format);
};
export function nextMonth(simpleDate) {
  return fromMoment(toMoment(simpleDate).add(1, 'month'));
}
export function beginningOfTime() {
  return make(2000, 0, 1);
}
export function daysAgo(numDays, constructor = 'userTz') {
  return fromMoment(I18n.moment[constructor]().subtract(numDays, 'days'));
}
export function endOfPrior(period, periodsAgo = 1, constructor = 'userTz') {
  return fromMoment(I18n.moment[constructor]().subtract(periodsAgo, period).endOf(period));
}
export function startOfPrior(period, periodsAgo = 1, constructor = 'userTz') {
  return fromMoment(I18n.moment[constructor]().subtract(periodsAgo, period).startOf(period));
}
export function startOfThis(period, constructor = 'userTz') {
  return fromMoment(I18n.moment[constructor]().startOf(period));
}
export function endOfThis(period, constructor = 'userTz') {
  return fromMoment(I18n.moment[constructor]().endOf(period));
}
export function prevMonth(simpleDate) {
  return fromMoment(toMoment(simpleDate).subtract(1, 'month'));
}