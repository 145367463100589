import { fromJS } from 'immutable';
import { PRIMARY_CONFIG_NAME, getConfigByName, getSubtitleField } from '../multiConfigReportGetters';

// Import these from the right location
import { fromMetricKey } from '../../v2/dataset/datasetMetrics';
import { getMetricPropertiesFromConfig, getMetricsFromConfig } from '../configReportGetters';
export const setColumnAliasFromMultiConfigReport = (report, columnAlias) => {
  return report.setIn(['displayParams', 'columnAlias'], columnAlias);
};

/**
 * Adds or updates a column alias in the report. Column alias allows users to rename the column headers in the report.
 */
export const setColumnAlias = (report, configName, property, metricType, alias) => {
  const newColumnAliasKey = `${metricType}|${property}`;
  const newColumnAlias = fromJS({
    alias,
    reportColumnName: newColumnAliasKey,
    translationKey: null
  });
  return report.updateIn(['displayParams', 'columnAlias', configName === PRIMARY_CONFIG_NAME ? 'primaryConfig' : configName], () => fromJS({}).remove(newColumnAliasKey).set(newColumnAliasKey, newColumnAlias));
};

/**
 * Sets the column alias for a specific config based on a whole columnAlias object
 */
export const setColumnAliasByObject = (report, configName, columnAlias) => {
  return report.updateIn(['displayParams', 'columnAlias', configName === PRIMARY_CONFIG_NAME ? 'primaryConfig' : configName], () => columnAlias);
};

/**
 * Deletes all column alias associated with a config.
 */
export const deleteColumnAliasByConfigName = (report, configName) => {
  return report.deleteIn(['displayParams', 'columnAlias', configName === PRIMARY_CONFIG_NAME ? 'primaryConfig' : configName]);
};

/**
 * Returns the property, metric type, and subtitle field for a specific config.
 * This is shared logic for updating the subtitle field.
 */
export const getFieldsForUpdatingSubtitleField = (report, configName) => {
  var _getMetricsFromConfig;
  const config = getConfigByName(report, configName);
  const metricProperties = getMetricPropertiesFromConfig(config);
  const property = metricProperties.first();
  const metricType = (_getMetricsFromConfig = getMetricsFromConfig(config).first().get('metricTypes')) === null || _getMetricsFromConfig === void 0 ? void 0 : _getMetricsFromConfig.first();
  const subtitleField = getSubtitleField(report, configName);
  return {
    property,
    metricType,
    subtitleField
  };
};

/**
 * Adds (or substitutes if it exists already) a new field option to the report config.
 */
export const setFieldOptions = (report, configName, property, metricType, subMetricType, subLabel) => {
  const subtitleField = subMetricType || subLabel ? {
    metric: subMetricType && property ? `${subMetricType}|${property}` : null,
    propertyLabel: subLabel || null
  } : null;
  const newFieldOption = {
    field: `${metricType}|${property}`,
    dataset: configName,
    subtitleField
  };
  return report.updateIn(['visualization', 'layer', 'fieldOptions'], fieldOptions => {
    if (!fieldOptions || fieldOptions.length === 0) {
      return fromJS([newFieldOption]);
    }
    const fieldOptionDatasets = fieldOptions.map(fieldOption => fieldOption.get('dataset'));
    if (fieldOptionDatasets.includes(configName)) {
      return fieldOptions.map(fieldOption => fieldOption.get('dataset') === configName ? fromJS(newFieldOption) : fieldOption);
    }
    return fieldOptions.concat(fromJS([newFieldOption]));
  });
};

/**
 * Update the subAggregationLabel for the a specific config
 */
export const setSubAggregationLabel = (report, configName, subAggregationLabel) => {
  const {
    property,
    metricType,
    subtitleField
  } = getFieldsForUpdatingSubtitleField(report, configName);
  if (!metricType) {
    console.warn(`No metric type found for config ${configName}`);
    return report;
  }
  return setFieldOptions(report, configName, property, metricType, fromMetricKey(subtitleField.get('metric')).type, subAggregationLabel);
};

/**
 * Deletes all field options associated with a config.
 */
export const deleteFieldOptionsByConfigName = (report, configName) => {
  return report.updateIn(['visualization', 'layer', 'fieldOptions'], fieldOptions => fieldOptions && fieldOptions.filter(option => option.get('dataset') !== configName));
};