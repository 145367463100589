import { AI_EXPLANATION_KEYS, AI_MATCHING_REPORT_TEMPLATE_KEYS } from '../constants/aiSearch';
import { List, fromJS } from 'immutable';
import { getReportTemplateByKey } from '../api/reportTemplatesApi';

//convert the AI's response into valid JSON that can be parsed
export const cleanChatResponseText = chatText => {
  if (chatText === 'Failed') {
    return '{}';
  }
  return chatText.replace(/```|json|\n/gm, '') // Remove backticks, "json", and newlines
  .replace(/,\s*,\s*/g, ',') // Collapse multiple commas into one
  .replace(/,\s*([}\]])/g, '$1'); // Remove trailing commas before } or ]
};

// 1st index of match is the first match group if it exists
// util depends on prompt and response format
const getReportTemplateKeyFromSourceResponse = sourceText => {
  var _sourceText$match;
  return (_sourceText$match = sourceText.match(/Report Template: (.*)/)) === null || _sourceText$match === void 0 ? void 0 : _sourceText$match[1];
};
const getReportTemplateNameFromSourceResponse = sourceText => {
  var _sourceText$match2;
  return (_sourceText$match2 = sourceText.match(/Name: (.*)\nDescription:/)) === null || _sourceText$match2 === void 0 ? void 0 : _sourceText$match2[1];
};
export const getTemplateNameToKeys = sources => {
  return sources.reduce((nameToKeys, source) => {
    const templateKey = getReportTemplateKeyFromSourceResponse(source.response);
    const templateName = getReportTemplateNameFromSourceResponse(source.response);
    if (templateName && templateKey) {
      nameToKeys[templateName] = templateKey;
    }
    return nameToKeys;
  }, {});
};
export const getSuggestedTemplateInfoFromChat = chatResponse => {
  const sources = chatResponse.exchanges.slice(-1)[0].sources;
  const chatResponseObj = JSON.parse(cleanChatResponseText(chatResponse.text));
  const templateNameToKeys = getTemplateNameToKeys(sources);
  const chatResponseExplanationKeys = Object.values(AI_EXPLANATION_KEYS);
  const matchedTemplatesInfo = Object.values(AI_MATCHING_REPORT_TEMPLATE_KEYS).reduce((acc, matchKey, i) => {
    const templateName = chatResponseObj[matchKey];
    const templateKey = templateName && templateNameToKeys[templateName];
    if (templateName && templateKey) {
      const explanation = chatResponseObj[chatResponseExplanationKeys[i]];
      acc.push({
        templateName,
        templateKey,
        explanation: explanation || null
      });
    }
    return acc;
  }, []);
  return matchedTemplatesInfo;
};
export const handleAiReportSearchResponse = (searchResponse, templatesByKey) => {
  const chatId = searchResponse.chatId;
  const suggestions = getSuggestedTemplateInfoFromChat(searchResponse);
  const templatePromises = suggestions.map(suggestion => {
    // check if we have the template stored already
    if (templatesByKey && templatesByKey.has(suggestion.templateKey)) {
      return Object.assign({}, suggestion, {
        template: templatesByKey.get(suggestion.templateKey)
      });
    }
    // if not, fetch the template by key
    return getReportTemplateByKey(suggestion.templateKey).then(template => Object.assign({}, suggestion, {
      template: fromJS(template)
    }));
  });
  return Promise.allSettled(templatePromises).then(result => {
    const templateSuggestions = result.filter(r => r.status === 'fulfilled').map(promise => promise.value);
    return {
      chatId,
      templateSuggestions: List(templateSuggestions)
    };
  });
};