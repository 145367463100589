'use es6';

import { Map as ImmutableMap, List, Set as ImmutableSet } from 'immutable';

/**
 * Collect subaggregation keys
 *
 * @param {Immutable.Map} data Data format
 * @returns {Immutable.Set} Set of subaggregation keys
 */
export const collectSubaggregationKeys = data => data.getIn(['dimension', 'buckets'], List()).reduce((keys, outer) => outer.getIn(['dimension', 'buckets'], List()).reduce((memo, inner) => memo.add(inner.get('key')), keys), ImmutableSet());

/**
 * Create dense dataset
 *
 * @param {Immutable.Set} keys Set of subaggregation keys
 * @param {Immutable.Map} data Data format
 * @returns {Immutable.Map} Dense data format
 */
export const createDenseDataset = (keys, data, metric) => {
  const empty = ImmutableMap({
    [metric.get('property')]: metric.get('metricTypes').reduce((acc, type) => acc.set(type, 0), ImmutableMap())
  });
  return data.updateIn(['dimension', 'buckets'], (outers = List()) => outers.map(outer => outer.updateIn(['dimension', 'buckets'], (inners = List()) => {
    const mapped = inners.reduce((memo, inner) => memo.set(inner.get('key'), inner), ImmutableMap());
    const missing = keys.filter(key => !mapped.has(key)).map(key => ImmutableMap({
      key,
      metrics: empty
    }));
    return inners.concat(missing);
  })));
};