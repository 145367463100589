'use es6';

import { Map as ImmutableMap } from 'immutable';
import * as MetricTypes from '../../../constants/metricTypes';
import { currency as formatCurrency } from '../../../hydrate/numberFormatter';
import { toUpperCase } from '../../../lib/string';
import { DEAL_CURRENCY_CODE, LINE_ITEM_CURRENCY_CODE, CURRENCY_PROPERTY_NAME, LEGACY_DEAL_AMOUNT_PROPERTIES, CURRENCY_CODE_BY_DATA_TYPE } from '../../../properties/constants/multiCurrencyProperties';
import { getCurrencyInformation } from '../../../public/currency';
import { getExtractByProperty } from '../common/extract-properties';
import getProperties from '../../../properties';
const getMetricProperties = config => config.get('metrics').map(metric => metric.get('property')).toSet();
const getExtractForSearch = (config, spec) => getExtractByProperty(getMetricProperties(config).union(spec.hydrate.inputs).add(spec.properties.idProperty).add(DEAL_CURRENCY_CODE).add(LINE_ITEM_CURRENCY_CODE), spec);
const getMetrics = (config, propertyValues, multiCurrencyEnabled, allProperties) => {
  return ImmutableMap(getMetricProperties(config).map(prop => {
    const property = prop === 'projectedAmount' ? 'hs_projected_amount' : prop;
    const dataType = config.get('dataType');
    const value = propertyValues.get(property, '-');
    const currencyPropertyName = allProperties.getIn([dataType, property, CURRENCY_PROPERTY_NAME]);
    if (multiCurrencyEnabled && (currencyPropertyName || LEGACY_DEAL_AMOUNT_PROPERTIES.includes(property))) {
      const currencyCode = toUpperCase(propertyValues.get(currencyPropertyName) || propertyValues.get(CURRENCY_CODE_BY_DATA_TYPE[dataType]));
      return [property, ImmutableMap({
        [MetricTypes.COUNT]: ImmutableMap({
          raw: value,
          formatted: formatCurrency(value, {
            currencyCode
          }),
          propertyMeta: {
            type: 'currency',
            currencyCode
          }
        })
      })];
    } else {
      return [property, ImmutableMap({
        [MetricTypes.COUNT]: value
      })];
    }
  }).toJS());
};
export const transform = (config, spec, response) => {
  return Promise.all([getCurrencyInformation(), getExtractForSearch(config, spec), getProperties(config.get('dataType'))]).then(([information, extract, allProperties]) => {
    const {
      hasMulticurrencyActive
    } = information || {};
    return ImmutableMap({
      dimension: ImmutableMap({
        property: spec.properties.idProperty,
        buckets: response.get(spec.search.objectsField).map(obj => {
          const propertyValues = extract(obj);
          const keyLabel = spec.hydrate.fn(propertyValues.toJS());
          return ImmutableMap({
            key: String(propertyValues.get(spec.properties.idProperty)),
            keyLabel,
            metrics: getMetrics(config, propertyValues, hasMulticurrencyActive, allProperties)
          });
        })
      }),
      total: response.get('total')
    });
  });
};