'use es6';

import { TIME_SERIES } from '../../constants/configTypes';
import * as dataTypes from '../../constants/dataTypes';
import { injectCombination } from '../../dataset/inject-combination';
import { get as isUnified } from '../unified/supported';
import { retrieve as baseRetrieve } from '../baseRetrieve';
const valid2DCombinationDataTypes = [dataTypes.ANALYTICS_SOURCES, dataTypes.ANALYTICS_UTM_CAMPAIGNS, dataTypes.ANALYTICS_UTM_CONTENTS, dataTypes.ANALYTICS_UTM_MEDIUMS, dataTypes.ANALYTICS_UTM_SOURCES, dataTypes.ANALYTICS_UTM_TERMS];
export default {
  match: config => isUnified(config.get('dataType')) && valid2DCombinationDataTypes.includes(config.get('dataType')) && config.get('configType') === TIME_SERIES && config.get('dimensions').size === 2 && config.get('metrics').size === 2,
  retrieve: (config, debug) => {
    const flattenedConfig = config.update('dimensions', dimensions => dimensions.pop());
    return Promise.all([baseRetrieve(config, debug), baseRetrieve(flattenedConfig, debug)]).then(([primaryRetreive, combinationRetrieve]) => {
      return Object.assign({}, primaryRetreive, {
        dataset: injectCombination(primaryRetreive.dataset, combinationRetrieve.dataset, config.getIn(['metrics', 1, 'property']))
      });
    });
  }
};