import { fromJS } from 'immutable';
import http from 'hub-http/clients/apiClient';
import { beginningOfTime, now } from 'foundations-components/transitional/utils/SimpleDate';
import { toMoment } from 'foundations-components/transitional/utils/SimpleDate';
const BASE_URL = 'dashboard/v2/reports';
export const getReportQueryFilters = (offset = 0, search) => {
  return {
    limit: 15,
    offset,
    search,
    sortBy: 'UPDATED_AT',
    sortOrder: 'descending',
    updatedAtStartDate: toMoment(beginningOfTime()).valueOf(),
    updatedAtEndDate: toMoment(now()).endOf('day').valueOf()
  };
};

//TODO: getFilteredReportCount for pagination

export const getReports = query => {
  return http.get(BASE_URL, {
    query: Object.assign({}, query, {
      source: 'REPORTING'
    })
  }).then(response => {
    return fromJS(response === null || response === void 0 ? void 0 : response.reports);
  });
};