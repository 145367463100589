'use es6';

import I18n from 'I18n';
import { List, Map as ImmutableMap } from 'immutable';
import { DATE_TIME, NUMBER } from '../constants/property-types';
import { number as formatNumber } from '../hydrate/numberFormatter';
import { Dataset, getReferenceLabel } from '../v2/dataset/datasetRecords';
import { getDailyGoals, getFrequencyEndDate, isAccumulated, rebucket } from './calculations';
import { GOAL_DATE_PROPERTY, GOAL_VALUE_PROPERTY } from '../constants/customSeries';
function getStaticGoalLineSeriesData({
  goal
}, reportConfig, datePoints) {
  if (!datePoints.length) {
    return ImmutableMap({});
  }
  const frequency = reportConfig.getIn(['config', 'frequency']);
  const {
    id: firstDate
  } = datePoints[0];
  const {
    id: lastDate
  } = datePoints[datePoints.length - 1];
  const start = I18n.moment(firstDate).startOf('month');
  const end = getFrequencyEndDate({
    date: lastDate,
    frequency
  });
  let goalsByMonth = List();
  while (start.isSameOrBefore(end)) {
    goalsByMonth = goalsByMonth.push(ImmutableMap({
      date: start.format('YYYY-MM-DD'),
      value: goal
    }));
    start.add(1, 'month');
  }
  const dailyGoals = getDailyGoals({
    goalsByMonth
  });
  const truncated = rebucket({
    dailyGoals,
    dates: List([...datePoints.map(({
      id
    }) => id), end])
  });
  const goals = isAccumulated(reportConfig) ? truncated.reduce((accumulated, point, index) => [/* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
  ...accumulated, (accumulated[index - 1] || 0) + point.get('value', 0)], []) : truncated.map(point => point.get('value', 0)).toJS();
  return datePoints.map((point, index) => Object.assign({}, point, {
    yLabel: formatNumber(goals[index]),
    y: goals[index]
  }));
}
const getDatetimeGoalDataInternal = (report, datePoints, goalConfig) => {
  const contextPath = ['config', 'context', 'context', 'operator'];
  const isAllData = report.getIn(contextPath) === 'HAS_PROPERTY';
  if (isAllData) {
    return Promise.resolve(null);
  }
  const goalLineData = getStaticGoalLineSeriesData(goalConfig, report, datePoints.toJS());
  const data = goalLineData.map(dataPoint => ({
    date: dataPoint.id,
    value: dataPoint.y
  }));
  const dateLabelReferences = List(goalLineData).reduce((map, dataPoint) => map.set(dataPoint.id, ImmutableMap({
    label: dataPoint.xLabel
  })), ImmutableMap());
  const properties = ImmutableMap({
    [GOAL_DATE_PROPERTY]: {
      type: DATE_TIME,
      references: dateLabelReferences
    },
    [GOAL_VALUE_PROPERTY]: {
      type: NUMBER,
      label: I18n.text('reporting-data.customSeries.name.goal')
    }
  });
  return Promise.resolve(Dataset({
    data,
    properties
  }));
};
export const getDatetimeGoalData = (report, oldReportDataset, goalConfig) => {
  // Get the all the X axis
  const datePoints = oldReportDataset.getIn(['dimension', 'buckets']).map(category => ({
    id: category.get('key'),
    xLabel: category.get('keyLabel')
  }));
  return getDatetimeGoalDataInternal(report, datePoints, goalConfig);
};
export const getBackendResolveDatetimeGoalData = (report, newReportDataset, goalConfig) => {
  // Get the all the X axis
  const dimensionProperty = report.getIn(['config', 'dimensions', 0]);
  const datePoints = newReportDataset.get('data').map(dataRow => dataRow.get(dimensionProperty)).toOrderedSet().toList().map(dateId => ({
    id: dateId,
    xLabel: getReferenceLabel(newReportDataset, dimensionProperty, dateId)
  }));
  return getDatetimeGoalDataInternal(report, datePoints, goalConfig);
};