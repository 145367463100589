export const TIMEOUT = 240 * 1000;
export const CONTENT_ASSISTANCE_SETTINGS_URL = 'content-assistance/v1/settings';
export const AI_EXPLANATION_KEYS = {
  FIRST_EXPLANATION: 'firstExplanation',
  SECOND_EXPLANATION: 'secondExplanation',
  THIRD_EXPLANATION: 'thirdExplanation',
  FOURTH_EXPLANATION: 'fourthExplanation'
};
export const AI_MATCHING_REPORT_TEMPLATE_KEYS = {
  FIRST_MATCH: 'firstBestReportTemplate',
  SECOND_MATCH: 'secondBestReportTemplate',
  THIRD_MATCH: 'thirdBestReportTemplate',
  FOURTH_MATCH: 'fourthBestReportTemplate'
};
export const REPORT_TEMPLATES_DATA_SOURCES = {
  'Report Templates': {
    maxResults: 4,
    minScore: 0.25
  }
};
export const AI_REPORT_TEMPLATES_SEARCH_CONFIG = {
  primaryPrompt: `From the templates below pick the Report Template that best answers the question at the end. If none of the templates apply, give an explanation of why, but show what the closest one is and explain how it might still be relevant. Give an explanation on why a template was included and how it can be used for each template. Format your response in json as:

    {"${AI_MATCHING_REPORT_TEMPLATE_KEYS.FIRST_MATCH}": "<<Report Template>>",
      "${AI_MATCHING_REPORT_TEMPLATE_KEYS.SECOND_MATCH}": "<<Report Template>>",
    "${AI_MATCHING_REPORT_TEMPLATE_KEYS.THIRD_MATCH}": "<<Report Template>>",
    "${AI_MATCHING_REPORT_TEMPLATE_KEYS.FOURTH_MATCH}": "<<Report Template>>",
     "${AI_EXPLANATION_KEYS.FIRST_EXPLANATION}": your explanation
    "${AI_EXPLANATION_KEYS.SECOND_EXPLANATION}": your explanation
    "${AI_EXPLANATION_KEYS.THIRD_EXPLANATION}": your explanation
    "${AI_EXPLANATION_KEYS.FOURTH_EXPLANATION}": your explanation
    }

    where <<Report Template>> is the template.name field instead of the template.templateKey field and your explanation refers to it as 'this template'.
    ###
    Potential Report Templates:
    {{context}}
    ###
    Question: {{userInput}}
    ###
    Matching Report Template:`,
  dataSources: REPORT_TEMPLATES_DATA_SOURCES
};