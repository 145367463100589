'use es6';

import { Map as ImmutableMap } from 'immutable';
import extractor from '../../../../config/extractor';
import generateSearchFilter from '../../aggregate/generators/search-filter';
import { getCustomFilters, getFilterGroups } from '../../../../report/configReportGetters';
function convertToSearchFilter(dateRangeFilter = ImmutableMap()) {
  if (dateRangeFilter.isEmpty()) {
    return dateRangeFilter;
  }
  const property = dateRangeFilter.get('property');
  const dateRange = dateRangeFilter.get('value');
  const useFiscalYear = dateRangeFilter.get('useFiscalYear');
  return generateSearchFilter(property, dateRange, useFiscalYear);
}
const getReportFilters = config => {
  const filterGroups = getFilterGroups(config);
  const customFilters = getCustomFilters(config);
  const dateRangeFilter = config.getIn(['filters', 'dateRange']);

  /* There will likely be other shared filters to add here
     ex: Owner filter that spans non inbounddb filter styling
     Another thing to consider is the differences between
     owner regarding deal (hubspot_owner_id) and engagements
     (ownerId)
  */
  if (filterGroups.length) {
    const combinedFilters = filterGroups.map(filterGroup => {
      let filtersToAdd = customFilters;
      if (dateRangeFilter && !dateRangeFilter.isEmpty()) {
        filtersToAdd = filtersToAdd.push(convertToSearchFilter(dateRangeFilter));
      }
      return filterGroup.set('filters', filterGroup.get('filters').concat(filtersToAdd));
    });
    return combinedFilters.toJS();
  }
  let filters = customFilters;
  if (dateRangeFilter && !dateRangeFilter.isEmpty()) {
    filters = filters.push(convertToSearchFilter(dateRangeFilter));
  }
  return [{
    filters: filters.toJS()
  }];
};
export default (() => extractor(getReportFilters, null));
export const __TESTABLE__ = {
  getReportFilters
};