import { Record } from 'immutable';
import { STRING } from 'customer-data-objects/property/PropertyTypes';
import { NONE } from './timestamps/TimestampTypes';
const MissingField = Record({
  errorMessage: null,
  isHiddenFromSelect: true,
  label: '',
  name: '',
  timestampType: NONE,
  type: STRING,
  metadata: null,
  userHasNoAccess: false,
  disabled: false,
  tooltip: ''
}, 'MissingField');
MissingField.fromJS = json => {
  if (json === null || json === undefined) {
    return json;
  }
  return MissingField(json);
};
MissingField.isMissingField = field => field instanceof MissingField;
export default MissingField;