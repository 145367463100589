'use es6';

import { List, OrderedMap } from 'immutable';
import { AGGREGATION } from '../constants/configTypes';
export const densify = (config, base, compare) => {
  const joined = base.concat(compare);
  const configType = config.get('configType');
  const dimensionality = config.get('dimensions').count();

  // NOTE: only handle 1 dimensional aggregations for now e.g. social-posts
  if (dimensionality !== 1 || configType !== AGGREGATION) {
    return joined;
  }
  const path = [0, 'dimension', 'buckets'];
  const buckets = base.getIn(path, List());
  const comparisons = compare.getIn(path, List());
  if (buckets.isEmpty() && comparisons.isEmpty()) {
    return joined;
  }
  const joinedBuckets = buckets.concat(comparisons);
  const empty = joinedBuckets.getIn([0, 'metrics']).update(metrics => metrics.map(points => points.map(metric => metric.merge({
    raw: null,
    formatted: '-'
  }))));
  const mappedBuckets = buckets.reduce((mapped, bucket) => mapped.set(bucket.get('key'), bucket), OrderedMap());
  const mappedComparisons = comparisons.reduce((mapped, bucket) => mapped.set(bucket.get('key'), bucket), OrderedMap());
  const mappedEmpty = joinedBuckets.reduce((mapped, bucket) => mapped.set(bucket.get('key'), bucket.set('metrics', empty)), OrderedMap());
  const mappedJoined = mappedBuckets.merge(mappedComparisons);
  const [denseBuckets, denseComparisons] = mappedJoined.reduce(([first, second], bucket, key) => [mappedBuckets.has(key) ? first.push(mappedBuckets.get(key)) : first.push(mappedEmpty.get(key)), mappedComparisons.has(key) ? second.push(mappedComparisons.get(key)) : second.push(mappedEmpty.get(key))], [List(), List()]);
  const updatedBase = base.setIn(path, denseBuckets);
  const updatedCompare = compare.setIn(path, denseComparisons);
  return updatedBase.concat(updatedCompare);
};