import * as dataTypes from '../../constants/dataTypes';
export const defaultProperties = {
  [dataTypes.ATTRIBUTION_TOUCH_POINTS]: ['hs_touchpoint_timestamp', 'hs_contact_id', 'hs_touchpoint_type', 'hs_touchpoint_positions', 'hs_asset_title', 'hs_asset_type', 'hs_deal_id', 'hs_deal_amount', 'hs_asset_url'],
  [dataTypes.CONTACT_CREATE_ATTRIBUTION]: ['hs_interaction_timestamp', 'hs_contact_id', 'hs_interaction_type', 'hs_interaction_positions', 'hs_asset_title', 'hs_asset_type', 'hs_asset_url'],
  [dataTypes.DEAL_CREATE_ATTRIBUTION]: ['hs_interaction_timestamp', 'hs_contact_id', 'hs_interaction_type', 'hs_interaction_positions', 'hs_asset_title', 'hs_asset_type', 'hs_deal_id', 'hs_deal_amount', 'hs_asset_url'],
  [dataTypes.CONTACTS]: ['vid', 'email'],
  [dataTypes.COMPANIES]: ['name', 'createdate', 'first_contact_createdate'],
  [dataTypes.CRM_OBJECT]: ['hs_object_id'],
  [dataTypes.DEALS]: ['dealname', 'closedate', 'dealstage', 'pipeline', 'amount_in_home_currency'],
  [dataTypes.ENGAGEMENT]: ['hs_timestamp', 'associations.contact', 'associations.company', 'hs_engagement_type', 'hubspot_owner_id', 'engagement.details'],
  [dataTypes.TICKETS]: ['hs_ticket_id', 'createdate', 'hs_ticket_category', 'hubspot_owner_id'],
  [dataTypes.LINE_ITEMS]: ['name', 'quantity', 'amount'],
  [dataTypes.PRODUCTS]: ['name', 'price'],
  [dataTypes.SEQUENCE_ENROLLMENTS]: ['hs_contact_id', 'hs_company_id', 'hs_enrolled_by', 'hs_enrolled_at', 'hs_sequence_id', 'hs_enrollment_status'],
  [dataTypes.SEQUENCE_STEP_ENROLLMENTS]: ['hs_contact_id', 'hs_company_id', 'hs_enrolled_by', 'hs_enrolled_at', 'hs_sequence_id', 'hs_enrollment_status'],
  [dataTypes.SOCIAL_POSTS]: ['publishedAt', 'body', 'channelId', 'broadcastGuid', 'interactionsTotal', 'stats.clicks', 'stats.impressions', 'stats.shares']
};