// Determines what operators can be used for a given property type

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import * as DSAssetFamilies from 'customer-data-filters/filterQueryFormat/DSAssetFamilies/DSAssetFamilies';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import * as Operators from 'customer-data-filters/filterQueryFormat/operator/Operators';
import { BOOLEAN, DATE, DATE_TIME, ENUMERATION, NUMBER, STRING } from 'customer-data-objects/property/PropertyTypes';
import { OrderedSet } from 'immutable';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '../l... Remove this comment to see the full error message
import { PRIVACY_CONSENT } from '../listSegClassic/ListSegConstants';
import { getDynamicFilterFamilyBasis
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '../.... Remove this comment to see the full error message
} from '../../filterQueryFormat/DynamicFilterFamilies';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import * as UnifiedEventPropertyTypes from 'customer-data-filters/filterQueryFormat/UnifiedEventPropertyTypes';
import { isCustomBehavioralEventObjectTypeId, isUnifiedEventObjectTypeId
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '../.... Remove this comment to see the full error message
} from '../../filterQueryFormat/ObjectTypeId';
import { hasNewIlsTimeFiltersEnabled } from '../../permissions/gates';
import memoize from 'transmute/memoize';
import invariant from 'react-utils/invariant';
import { getConverterOptionsWithDefaults } from './converterOptions';
const PHONE_NUMBER = 'phone_number';
const getNumericOperators = () => {
  return OrderedSet.of(Operators.Equal, Operators.NotEqual, Operators.Less, Operators.LessOrEqual, Operators.Greater, Operators.GreaterOrEqual, Operators.InRange, Operators.Known, Operators.NotKnown);
};
const getStaticDateTimeOperators = options => {
  if (hasNewIlsTimeFiltersEnabled(options.isUngated) && !options.isNotUsingTimePoints) {
    return OrderedSet.of(Operators.EqualTimePoint, Operators.BeforeTimePoint, Operators.AfterTimePoint, Operators.BetweenTimePoints, Operators.NotBetweenTimePoints);
  } else {
    return OrderedSet.of(Operators.Equal, Operators.BeforeDate, Operators.AfterDate, Operators.InRange, Operators.NotInRange);
  }
};
const getSurveyMonkeyQuestionOperators = (type, options) => {
  switch (type) {
    case ENUMERATION:
      return OrderedSet.of(Operators.In, Operators.NotIn, Operators.Known, Operators.NotKnown);
    case NUMBER:
      return getNumericOperators();
    case STRING:
      return OrderedSet.of(Operators.EqualAny, Operators.NotEqualAny, Operators.ContainAny, Operators.NotContainAny, Operators.StartsWithAny, Operators.EndsWithAny, Operators.Known, Operators.NotKnown);
    case DATE_TIME:
      return getStaticDateTimeOperators(options).concat(OrderedSet.of(Operators.GreaterRolling, Operators.LessRolling));
    default:
      return undefined;
  }
};
const getAdsPropertyOperators = (type, __options) => {
  switch (type) {
    case DATE:
      return OrderedSet.of(Operators.Less, Operators.Greater, Operators.InRange, Operators.NotInRange, Operators.GreaterThanRolling, Operators.LessThanRolling);
    case DATE_TIME:
      return OrderedSet.of(Operators.Less, Operators.Greater, Operators.InRange, Operators.NotInRange, Operators.GreaterThanRolling, Operators.LessThanRolling);
    case ENUMERATION:
      return OrderedSet.of(Operators.EqualAny, Operators.Known);
    case STRING:
      return OrderedSet.of(Operators.EqualAny, Operators.ContainAny, Operators.StartsWithAny, Operators.EndsWithAny, Operators.Known);
    default:
      return undefined;
  }
};
const getIntegrationPropertyOperators = (type, options) => {
  switch (type) {
    case BOOLEAN:
      return OrderedSet.of(Operators.Equal, Operators.NotEqual, Operators.Known, Operators.NotKnown);
    case DATE:
    case DATE_TIME:
      return getStaticDateTimeOperators(options).concat(OrderedSet.of(Operators.GreaterRolling, Operators.LessRolling, Operators.Known, Operators.NotKnown));
    case ENUMERATION:
      return OrderedSet.of(Operators.In, Operators.NotIn, Operators.Known, Operators.NotKnown);
    case NUMBER:
      return getNumericOperators();
    case STRING:
      return OrderedSet.of(Operators.EqualAny, Operators.NotEqualAny, Operators.ContainAny, Operators.NotContainAny, Operators.StartsWithAny, Operators.EndsWithAny, Operators.Known, Operators.NotKnown);
    default:
      return undefined;
  }
};
const getUnifiedEventPropertyOperators = (type, options) => {
  switch (type) {
    case BOOLEAN:
      return OrderedSet.of(Operators.Equal, Operators.NotEqual, Operators.Known, Operators.NotKnown);
    case DATE:
    case DATE_TIME:
      return getStaticDateTimeOperators(options).concat(OrderedSet.of(Operators.GreaterRolling, Operators.LessRolling, Operators.Known, Operators.NotKnown));
    case ENUMERATION:
      return OrderedSet.of(Operators.In, Operators.NotIn, Operators.Known, Operators.NotKnown);
    case NUMBER:
      return getNumericOperators();
    case STRING:
      return OrderedSet.of(Operators.EqualAny, Operators.NotEqualAny, Operators.ContainAny, Operators.NotContainAny, Operators.StartsWithAny, Operators.EndsWithAny, Operators.Known, Operators.NotKnown);
    case UnifiedEventPropertyTypes.OBJECT_COORDINATES:
      return OrderedSet.of(Operators.EqualAny, Operators.NotEqualAny, Operators.Known, Operators.NotKnown);
    default:
      return undefined;
  }
};
const FamilyAndTypeToOperatorGetter = new Map([[DSAssetFamilies.SURVEY_MONKEY_QUESTION, getSurveyMonkeyQuestionOperators], [DSAssetFamilies.ADS_PROPERTY, getAdsPropertyOperators], [DSAssetFamilies.INTEGRATION, getIntegrationPropertyOperators], [DSAssetFamilies.UNIFIED_EVENT, getUnifiedEventPropertyOperators]]);
const getOperatorsForType = (type, options) => {
  switch (type) {
    case PHONE_NUMBER:
      return OrderedSet.of(Operators.Known, Operators.NotKnown);
    case BOOLEAN:
      return OrderedSet.of(Operators.Equal, Operators.NotEqual, Operators.Known, Operators.NotKnown, Operators.EverEqual, Operators.NeverEqual, Operators.UpdatedInLastXDays, Operators.NotUpdatedInLastXDays, Operators.UpdatedAfter, Operators.UpdatedBefore);
    case DATE:
    case DATE_TIME:
      return getStaticDateTimeOperators(options).concat(OrderedSet.of(Operators.GreaterRolling, Operators.LessRolling, Operators.Known, Operators.NotKnown, Operators.After, Operators.Before, Operators.UpdatedInLastXDays, Operators.NotUpdatedInLastXDays, Operators.UpdatedAfter, Operators.UpdatedBefore));
    case ENUMERATION:
      return OrderedSet.of(Operators.In, Operators.NotIn, Operators.Known, Operators.NotKnown, Operators.EverIn, Operators.NeverIn, Operators.EqualAll, Operators.NotEqualAll, Operators.ContainAll, Operators.NotContainAll, Operators.EverEqualAll, Operators.NeverEqualAll, Operators.EverContainedAll, Operators.NeverContainedAll, Operators.UpdatedInLastXDays, Operators.NotUpdatedInLastXDays, Operators.UpdatedAfter, Operators.UpdatedBefore);
    case NUMBER:
      return getNumericOperators().concat(OrderedSet.of(Operators.EverEqual, Operators.NeverEqual, Operators.UpdatedInLastXDays, Operators.NotUpdatedInLastXDays, Operators.UpdatedAfter, Operators.UpdatedBefore));
    case STRING:
      return OrderedSet.of(Operators.EqualAny, Operators.NotEqualAny, Operators.ContainAny, Operators.NotContainAny, Operators.StartsWithAny, Operators.EndsWithAny, Operators.Known, Operators.NotKnown, Operators.EverEqualAny, Operators.NeverEqualAny, Operators.EverContained, Operators.NeverContained, Operators.UpdatedInLastXDays, Operators.NotUpdatedInLastXDays, Operators.UpdatedAfter, Operators.UpdatedBefore);
    case DSAssetFamilies.CTA:
      return OrderedSet.of(Operators.CtaHasClicked, Operators.CtaHasNotClicked, Operators.CtaHasSeen, Operators.CtaHasNotSeen);
    case DSAssetFamilies.GOTO_WEBINAR_WEBINAR:
      return OrderedSet.of(Operators.WebinarHasAttended, Operators.WebinarNotHasAttended, Operators.WebinarHasRegistered, Operators.WebinarNotHasRegistered);
    case DSAssetFamilies.IN_LIST:
      return OrderedSet.of(Operators.ListIsMember, Operators.ListIsNotMember);
    case DSAssetFamilies.IMPORT:
      return OrderedSet.of(Operators.InImport, Operators.NotInImport);
    case DSAssetFamilies.PAGE_VIEW:
      return OrderedSet.of(Operators.PageViewEqual, Operators.PageViewNotEqual, Operators.PageViewContain, Operators.PageViewNotContain, Operators.PageViewMatchRegex, Operators.PageViewNotMatchRegex);
    case DSAssetFamilies.SURVEY_MONKEY_SURVEY:
      return OrderedSet.of(Operators.SurveyMonkeyHasResponded, Operators.SurveyMonkeyNotHasResponded);
    case DSAssetFamilies.FORM:
      return OrderedSet.of(Operators.FormFilledOut, Operators.FormNotFilledOut);
    case DSAssetFamilies.EVENT:
      return OrderedSet.of(Operators.EventCompleted, Operators.EventNotCompleted);
    case DSAssetFamilies.EMAIL_SUBSCRIPTION:
      return OrderedSet.of(Operators.EmailSubscriptionOptIn, Operators.EmailSubscriptionOptOut, Operators.EmailSubscriptionNotOpted);
    case DSAssetFamilies.EMAIL_CAMPAIGN:
      return OrderedSet.of(Operators.EmailLinkClicked, Operators.EmailMarked, Operators.EmailOpened, Operators.EmailOpenedButLinkNotClicked, Operators.EmailOpenedButNotReplied, Operators.EmailReplied, Operators.EmailUnsubscribed, Operators.EmailBounced, Operators.EmailReceived, Operators.EmailReceivedButNotOpened, Operators.EmailSent, Operators.EmailSentButLinkNotClicked, Operators.EmailSentButNotReceived);
    case DSAssetFamilies.WORKFLOW:
      return OrderedSet.of(Operators.WorkflowActive, Operators.WorkflowNotActive, Operators.WorkflowCompleted, Operators.WorkflowNotCompleted, Operators.WorkflowEnrolled, Operators.WorkflowNotEnrolled, Operators.WorkflowMetGoal, Operators.WorkflowNotMetGoal);
    default:
      return undefined;
  }
};
const privacyConcentOperators = OrderedSet.of(Operators.PrivacyConsentCompleted, Operators.PrivacyConsentNotCompleted);
const getEffectiveFilterFamily = filterFamily => {
  if (isUnifiedEventObjectTypeId(filterFamily) || isCustomBehavioralEventObjectTypeId(filterFamily)) {
    return DSAssetFamilies.UNIFIED_EVENT;
  }
  const dynamicFilterFamilyBasis = getDynamicFilterFamilyBasis(filterFamily);
  if (dynamicFilterFamilyBasis) {
    return dynamicFilterFamilyBasis;
  }
  return filterFamily;
};
export const getObjectSegOperatorsForTypeStrict = memoize((type, filterFamily, isUngated, isNotUsingTimePoints = false) => {
  invariant(typeof isUngated === 'function', `Expected ${isUngated} to be a function`);
  const effectiveFilterFamily = getEffectiveFilterFamily(filterFamily);
  const typeName = getDynamicFilterFamilyBasis(type) || type;
  if (effectiveFilterFamily === DSAssetFamilies.CONTACT_PROPERTY && type === PRIVACY_CONSENT) {
    return privacyConcentOperators;
  }
  const getOperators = FamilyAndTypeToOperatorGetter.get(effectiveFilterFamily);
  const converterOptions = getConverterOptionsWithDefaults({
    isUngated,
    isNotUsingTimePoints
  });
  if (getOperators) {
    return getOperators(type, converterOptions);
  }
  return getOperatorsForType(type, converterOptions) || getOperatorsForType(typeName, converterOptions);
});