'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import getBucketMetrics from './bucketMetrics';
export default ((config, response) => {
  const dimension = config.getIn(['dimensions', 0]);
  const aggregations = response.getIn(['aggregations', dimension], List());
  return ImmutableMap({
    dimension: ImmutableMap({
      property: dimension,
      buckets: aggregations.map(breakdown => ImmutableMap({
        key: breakdown.get('key'),
        metrics: getBucketMetrics(config, breakdown)
      }))
    }),
    metrics: getBucketMetrics(config, response),
    total: aggregations.count() || 0
  });
});