'use es6';

import { getReportDefinition } from 'reporting-data/report/reportGetters';
const requiresFix = field => {
  return field.get('name') === 'closedate' && field.get('table') === 'DEAL' && field.get('type') === 'number';
};
const fixFilter = filter => {
  return requiresFix(filter.get('field')) ? filter.update('field', field => field.set('type', 'datetime')) : filter;
};
export default (report => {
  const reportDefinition = getReportDefinition(report);
  const fixedDefinition = reportDefinition.update('filtering', filtering => {
    return filtering.update('groups', groups => {
      return groups.map(group => {
        return group.update('filters', filters => filters.map(filter => fixFilter(filter)));
      });
    });
  });
  return report.set('reportDefinition', fixedDefinition);
});