'use es6';

import { OrderedMap } from 'immutable';
/* Pure migrations */
import { migrations } from './migrate';
/* Normalization */
import { normalizations } from './normalize';
/* Deprecations */
import { deprecations } from './deprecate';
import { userInfo } from 'reporting-data/request/user-info';
import repairSnowflakeReport from 'reporting-snowflake/migrate/repairSnowflakeReport';
import { fixTableCompare } from './normalize/fixReport';
import { ReportingLogger } from 'reporting-data/monitoring/reportingLogger';
const migrationList = OrderedMap({
  // Promises (run first!)
  mismatchedProperties: deprecations.mismatchedProperties,
  isForbiddenAnalyticsFunnel: deprecations.isForbiddenAnalyticsFunnel,
  freeAndStarterUsesDealSCP: deprecations.freeAndStarterUsesDealSCP,
  campaignsContactAttribution: deprecations.campaignsContactAttribution,
  usesCurrentlyInWorkflowContactProperty: deprecations.usesCurrentlyInWorkflowContactProperty,
  // normalization
  removeConfigNulls: normalizations.removeConfigNulls,
  // regular migrations begin
  fixCrossObjectReportId: migrations.fixCrossObjectReportId
});
const loggerIgnoreList = ['removeConfigNulls'];
const repair = report => {
  if (report.has('reportDefinition')) {
    return repairSnowflakeReport(report);
  }
  if (!report.has('config')) {
    return Promise.resolve(report);
  }
  const reportingLogger = new ReportingLogger();
  reportingLogger.addReportAttributes(report);
  const logMigration = (migrationName, migratedReport) => {
    if (!loggerIgnoreList.includes(migrationName)) {
      reportingLogger.addAttribute('reportId', report.get('id'));
      reportingLogger.addAttribute('reportMigration', migrationName);
      reportingLogger.addAttribute('configPostMigration', JSON.stringify(migratedReport.get('config')));
      reportingLogger.sendPageAction('migrationRun');
    }
  };
  return userInfo().then(info => migrationList.reduce((prev, migration, migrationName) => prev.then(prevReport => Promise.resolve(migration(prevReport, info)).then(migratedReport => {
    if (!prevReport.equals(migratedReport)) {
      logMigration(migrationName, migratedReport);
    }
    return migratedReport;
  })), Promise.resolve(report))).then(updatedReport => {
    // repair report
    return fixTableCompare(updatedReport);
  });
};
export default repair;