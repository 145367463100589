import { List, Map as ImmutableMap, OrderedSet } from 'immutable';
import { FieldRef } from '../../schema/field-ref';
import { FieldSources } from '../../schema/column-records';
import { Conditions, ExpressionFieldFilterRef, FieldFilterRef, FilterRefTypes, Group, PropertyFilterRef } from '../../schema/filter-records';
import { DATASET_TABLE } from '../../schema/table-records';
import { convertFieldToFieldRef, isFieldNameMagicId } from '../../utils/field-utils';
import { parseFilterLogic, removeId, replaceId, stringify
// @ts-expect-error migrate upstream file
} from './filter-parser';
import { Contexts } from '../../../dataset/context-records';
import { buildFieldFromExpressionField } from '../../utils/expression-field-utils';
export const isListFilter = filter => filter.filter && filter.filter.get('filterType') === 'IN_LIST';
export const isFieldFilter = filter => filter.field.table === DATASET_TABLE;
export const isExpressionFieldFilter = filter => filter.field.source === FieldSources.EXPRESSION;
export const getGroupIds = filtering => filtering.groups.map(group => String(group.id));
export const getParsedExpression = filtering => {
  const {
    logic
  } = filtering;
  const groupIds = getGroupIds(filtering);
  return parseFilterLogic(logic, groupIds);
};
export const createDefaultCustomLogic = filtering => {
  const {
    condition,
    groups
  } = filtering;
  return groups.map((_, index) => index + 1).join(` ${condition.toLowerCase()} `);
};
export const getFilterFieldRefs = filtering => filtering.groups.flatMap(group => group.filters.map(filter => convertFieldToFieldRef(filter.field)).toList()).concat(filtering.stagedFilters.filter(filterRef => filterRef && filterRef.type === FilterRefTypes.PROPERTY).map(filterRef => filterRef.type === FilterRefTypes.PROPERTY ? FieldRef({
  table: filterRef.table,
  property: filterRef.property
}) : undefined).toList()).filter(fieldRef => !!fieldRef).toList();
const normalizeFiltering = filtering => {
  // remove once BE updates filter IDs to string
  const updatedfiltering = filtering.update('groups', groups => groups.map(group => group.update('id', id => String(id))));
  const emptyGroupIds = updatedfiltering.groups.filter(group => group.filters.isEmpty()).map(group => group.id).toSet();
  const filteringWithoutEmptyGroupsInitialUpdate = updatedfiltering.update('groups', groups =>
  // @ts-expect-error add annotation for param
  groups.filter(group => !emptyGroupIds.has(group.id)));
  const filteringWithoutEmptyGroups = filteringWithoutEmptyGroupsInitialUpdate.update('logic', logic => {
    if (!logic) {
      return createDefaultCustomLogic(filteringWithoutEmptyGroupsInitialUpdate);
    }
    try {
      const expression = getParsedExpression(updatedfiltering);
      const nextExpression = emptyGroupIds.reduce((e, id) => removeId(e, id), expression);
      return stringify(nextExpression);
    } catch (e) {
      return logic;
    }
  });
  const idReplacementLookup = ImmutableMap(filteringWithoutEmptyGroups.groups.map((group, index) => [group.id, String(index + 1)]).toList());
  const filteringWithNormalizedIds = filteringWithoutEmptyGroups.update('groups', groups => groups.map(group => group.update('id', id => idReplacementLookup.get(id))).toList()).update('logic', logic => {
    if (!logic) {
      return logic;
    }
    try {
      const expression = getParsedExpression(filteringWithoutEmptyGroups);
      const nextExpression = replaceId(expression, idReplacementLookup);
      return stringify(nextExpression);
    } catch (e) {
      return logic;
    }
  });
  const filteringWithUniqueStagedFilters = filteringWithNormalizedIds.update('stagedFilters', stagedFilters => OrderedSet(stagedFilters).toList());
  return filteringWithUniqueStagedFilters;
};

// @ts-expect-error add annotation for param, issue with immutablejs usage
export const htmlEscapedFilter = filter => {
  if (filter.filter.getIn(['operation', 'values'])) {
    return filter.updateIn(['filter', 'operation', 'values'],
    // @ts-expect-error filter is any
    valuesList =>
    // @ts-expect-error value is any
    valuesList.map(value => value.replace(/&/g, '&amp;')));
  }
  return filter;
};

// @ts-expect-error add annotation for param, issue with immutablejs usage
export const htmlNormalizedToTextFilter = filter => {
  if (filter.filter.getIn(['operation', 'values'])) {
    return filter.updateIn(['filter', 'operation', 'values'],
    // @ts-expect-error filter is any
    valuesList =>
    // @ts-expect-error filter is any
    valuesList.map(value => value.replace(/&amp;/g, '&')));
  }
  return filter;
};
export const isHtmlFieldType = property => property.metaDefinition && property.metaDefinition.fieldType === 'html';

/* Staged filters */

export const getStagedFilter = (filtering, index) => filtering.stagedFilters.get(index);
export const moveStagedFilter = (filtering, fromIndex, toIndex) => {
  const definedToIndex = toIndex === undefined ? fromIndex : toIndex;
  const stagedFilter = getStagedFilter(filtering, fromIndex);
  const next = filtering.update('stagedFilters', stagedFilters => stagedFilters.delete(fromIndex).insert(definedToIndex, stagedFilter));
  return normalizeFiltering(next);
};
export const updateStagedFilter = (filtering, index, updater) => {
  const next = filtering.update('stagedFilters', stagedFilters => stagedFilters.update(index, typeof updater === 'function' ? updater : () => updater));
  return normalizeFiltering(next);
};
export const addStagedFilter = (filtering, filterRef, toIndex) => {
  const index = filtering.stagedFilters.count();
  const next = filtering.setIn(['stagedFilters', index.toString()], filterRef);
  return moveStagedFilter(next, index, toIndex);
};
export const addPropertyFilterToStage = (filtering, field, toIndex) => {
  if (isFieldNameMagicId(field.name)) {
    return filtering;
  }
  const filterRef = PropertyFilterRef({
    property: field.name,
    table: field.table
  });
  return addStagedFilter(filtering, filterRef, toIndex);
};
export const addFieldFilterToStage = (filtering, field, toIndex) => {
  const filterRef = FieldFilterRef({
    field: field.name
  });
  return addStagedFilter(filtering, filterRef, toIndex);
};
export const addExpressionFieldFilterToStage = (filtering, field, toIndex) => {
  const filterRef = ExpressionFieldFilterRef({
    field: field.name
  });
  return addStagedFilter(filtering, filterRef, toIndex);
};
export const addDatasetPropertyFilterToStage = (filtering, property, toIndex) => {
  const filterRef = PropertyFilterRef({
    property: property.name,
    table: property.table
  });
  return addStagedFilter(filtering, filterRef, toIndex);
};
export const removeStagedFilter = (filtering, index) => {
  const next = filtering.deleteIn(['stagedFilters', String(index)]);
  return normalizeFiltering(next);
};
/* Groups */

// @ts-expect-error add annotation for param
export const getGroup = (filtering, groupIndex) => filtering.getIn(['groups', groupIndex]);

// @ts-expect-error add annotation for param
export const moveGroup = (filtering, fromGroupIndex, toGroupIndex) => {
  toGroupIndex = toGroupIndex === undefined ? fromGroupIndex : toGroupIndex;
  const group = getGroup(filtering, fromGroupIndex);
  // @ts-expect-error add annotation for param
  const next = filtering.update('groups', groups => groups.delete(fromGroupIndex).insert(toGroupIndex, group));
  return normalizeFiltering(next);
};

// @ts-expect-error add annotation for param
export const updateGroup = (filtering, groupIndex, updater) => {
  const next = filtering.updateIn(['groups', groupIndex], typeof updater === 'function' ? updater : () => updater);
  return normalizeFiltering(next);
};
export const addGroup = (filtering, group, toGroupIndex) => {
  const groupIndex = filtering.groups.count();
  const next = filtering.setIn(['groups', String(groupIndex)], group.set('id', String(groupIndex + 1)));
  return moveGroup(next, groupIndex, toGroupIndex);
};

// @ts-expect-error add annotation for param
export const removeGroup = (filtering, groupIndex) => {
  const next = filtering.deleteIn(['groups', groupIndex]);
  return normalizeFiltering(next);
};

/* Filters */

// @ts-expect-error add annotation for param
export const getFilter = (filtering, groupIndex, filterIndex) => filtering.getIn(['groups', groupIndex, 'filters', filterIndex]);
export const moveFilter = (
// @ts-expect-error add annotation for param
filtering,
// @ts-expect-error add annotation for param
fromGroupIndex,
// @ts-expect-error add annotation for param
fromFilterIndex,
// @ts-expect-error add annotation for param
toGroupIndex,
// @ts-expect-error add annotation for param
toFilterIndex) => {
  if (fromGroupIndex === toGroupIndex && fromFilterIndex === toFilterIndex) {
    return normalizeFiltering(filtering);
  }
  const filter = getFilter(filtering, fromGroupIndex, fromFilterIndex);
  toGroupIndex = toGroupIndex === undefined ? fromGroupIndex : toGroupIndex;
  toFilterIndex = toFilterIndex === undefined ? filtering.getIn(['groups', toGroupIndex, 'filters']).count() : toFilterIndex;
  const next = filtering.deleteIn(['groups', fromGroupIndex, 'filters', fromFilterIndex])
  // @ts-expect-error add annotation for param
  .updateIn(['groups', toGroupIndex], maybeGroup => maybeGroup ?
  // @ts-expect-error add annotation for param
  maybeGroup.update('filters', filters => filters.insert(toFilterIndex, filter)) : Group({
    id: String(toGroupIndex + 1),
    condition: Conditions.AND,
    filters: [filter]
  }));
  return normalizeFiltering(next);
};

// @ts-expect-error add annotation for param
export const updateFilter = (filtering, groupIndex, filterIndex, updater) => {
  const next = filtering.updateIn(['groups', groupIndex, 'filters', filterIndex], typeof updater === 'function' ? updater : () => updater);
  return normalizeFiltering(next);
};

// @ts-expect-error add annotation for param
export const addFilter = (filtering, filter, toGroupIndex, toFilterIndex) => {
  const groupIndex = filtering.groups.count();
  const filterIndex = 0;
  const group = Group({
    id: String(groupIndex + 1),
    condition: Conditions.AND,
    filters: List.of(filter)
  });
  const next = filtering.setIn(['groups', groupIndex], group);
  return moveFilter(next, groupIndex, filterIndex, toGroupIndex, toFilterIndex);
};
export const removeFilter = (filtering, groupIndex, filterIndex) => {
  const next = filtering.deleteIn(['groups', String(groupIndex), 'filters', String(filterIndex)]);
  return normalizeFiltering(next);
};

// @ts-expect-error add annotation for param
export const getFilterRefKey = filterRef => {
  const {
    type
  } = filterRef;
  switch (type) {
    case FilterRefTypes.PROPERTY:
      return `${type}.${filterRef.table}.${filterRef.property}`;
    case FilterRefTypes.LIST:
      return `${type}.${filterRef.table}`;
    default:
      console.error('Could not determine key for filter ref', filterRef);
      return '';
  }
};
const filterFieldIsFromTable = (filterField, table) => filterField.table === table.name;
const filterRefIsFromTable = (filterField, table) => {
  if (filterField.type === FilterRefTypes.DATASET_FIELD) {
    return false;
  }
  if (filterField.type === FilterRefTypes.EXPRESSION_FIELD) {
    return false;
  }
  return filterField.table === table.name;
};

// @ts-expect-error add annotation for param
export const removeFiltersOfTable = (filtering, table) => {
  if (!filtering) {
    return filtering;
  }
  const customFilterRulesAffected = filtering.condition === Conditions.CUSTOM ?
  // @ts-expect-error add annotation for param
  filtering.groups.some(group =>
  // @ts-expect-error add annotation for param
  group.filters.some(filter => filterFieldIsFromTable(filter.field, table))) : false;
  return normalizeFiltering(filtering.set('groups',
  // @ts-expect-error add annotation for param
  filtering.groups.map(group => group.set('filters',
  // @ts-expect-error add annotation for param
  group.filters.filterNot(filter => filterFieldIsFromTable(filter.field, table))))).set('stagedFilters',
  // @ts-expect-error add annotation for param
  filtering.stagedFilters.filterNot(filterField => filterFieldIsFromTable(filterField, table))).update(updatedFiltering => {
    const hasRemainingGroups = updatedFiltering.get('groups').some(group => group.get('filters').size > 0);
    return updatedFiltering.set('logic', customFilterRulesAffected || !hasRemainingGroups ? '' : filtering.logic);
  }));
};

// @ts-expect-error add annotation for param
export const findFiltersUsingField = (filtering, field) => {
  const foundInFilters = filtering.groups
  // @ts-expect-error add annotation for param
  .some(group =>
  // @ts-expect-error add annotation for param
  group.filters.some(filter => filter.field.equals(field)));

  // @ts-expect-error add annotation for param
  const foundInStagedFilters = filtering.stagedFilters.find(stagedFilter => {
    switch (stagedFilter.type) {
      case FilterRefTypes.PROPERTY:
        return stagedFilter.property === field.name && stagedFilter.table === field.table;
      case FilterRefTypes.LIST:
        return false;
      case FilterRefTypes.DATASET_FIELD:
      case FilterRefTypes.EXPRESSION_FIELD:
        return stagedFilter.field === field.name;
      default:
        return stagedFilter.field.name === field.name;
    }
  });
  return foundInFilters || foundInStagedFilters;
};

// @ts-expect-error add annotation for param
export const removeFiltersWithField = (filtering, field) => {
  if (!filtering) {
    return filtering;
  }
  const customFilterRulesAffected = filtering.condition === Conditions.CUSTOM ?
  // @ts-expect-error add annotation for param
  filtering.groups.some(group =>
  // @ts-expect-error add annotation for param
  group.filters.some(filter => filter.field.equals(field))) : false;
  return normalizeFiltering(filtering.set('groups',
  // @ts-expect-error add annotation for param
  filtering.groups.map(group => group.set('filters',
  // @ts-expect-error add annotation for param
  group.filters.filterNot(filter => filter.field.equals(field))))).set('stagedFilters', filtering.stagedFilters.filterNot(stagedFilter => {
    switch (stagedFilter.type) {
      case FilterRefTypes.PROPERTY:
        return stagedFilter.property === field.name && stagedFilter.table === field.table;
      case FilterRefTypes.LIST:
        return stagedFilter.table === field.table;
      case FilterRefTypes.DATASET_FIELD:
      case FilterRefTypes.EXPRESSION_FIELD:
        return stagedFilter.field === field.name;
      default:
        return false;
    }
  })).set('logic', customFilterRulesAffected ? '' : filtering.logic));
};
export const getFilterGroupsForTable = (filters, table) => filters.groups.filter(group => group.filters.some(filter => filterFieldIsFromTable(filter.field, table))).concat(filters.stagedFilters.filter(filterField => filterRefIsFromTable(filterField, table)));

//Method to remove report filters (staged and applied) which are based on passed predicates
export const removeFiltersWithPredicate = (filtering, isStagedFilterMatch, isFilterMatch) => {
  let cleanedFiltering = filtering;

  // This is imperative because filter groups and indices might update/collapse
  // over several changes, theres no trivial batch filter editing built out

  // remove staged filters
  const hasStagedFilter = () => cleanedFiltering.stagedFilters.some(isStagedFilterMatch);
  while (hasStagedFilter()) {
    const index = cleanedFiltering.stagedFilters.findIndex(isStagedFilterMatch);
    if (index === -1) {
      break;
    }
    cleanedFiltering = removeStagedFilter(cleanedFiltering, index);
  }

  // remove normal filters
  const hasFilter = () => cleanedFiltering.groups.some(group => group.filters.some(isFilterMatch));
  while (hasFilter()) {
    const groupIndex = cleanedFiltering.groups.findIndex(group => !!group && group.filters.some(isFilterMatch));
    const filterIndex = cleanedFiltering.groups.get(groupIndex).filters.findIndex(filter => !!filter && isFilterMatch(filter));
    cleanedFiltering = removeFilter(cleanedFiltering, groupIndex, filterIndex);
  }
  return cleanedFiltering;
};
export const removeAggregateFilters = reportDefinition => {
  const aggregateExpressions = reportDefinition.expressions.filter(expression => expression.context === Contexts.AGGREGATE).toList();
  const updatedFiltering = aggregateExpressions.reduce((acc, expression) => {
    const field = buildFieldFromExpressionField(expression);
    return removeFiltersWithField(acc, field);
  }, reportDefinition.filtering);
  return reportDefinition.set('filtering', updatedFiltering);
};
export const calculateReportFilterCount = reportDefinition => {
  const filtering = reportDefinition.filtering;
  const activeFilterCount = (filtering ? filtering.groups : List()).flatMap(group => group.filters).size;
  return activeFilterCount + (reportDefinition.get('eventDateInterval') ? 1 : 0);
};
export const calculateDatasetFilterCount = definition => {
  const activeFilterCount = (definition.filtering ? definition.filtering.groups : List()).flatMap(group => group.filters).size;
  return activeFilterCount + (definition.eventDateInterval ? 1 : 0);
};
export const calculateDatasetAggregateFilterCount = definition => {
  var _definition$aggregate;
  return (definition !== null && definition !== void 0 && (_definition$aggregate = definition.aggregatedFiltering) !== null && _definition$aggregate !== void 0 && _definition$aggregate.groups ? definition.aggregatedFiltering.groups : List()).flatMap(group => group.filters).size;
};