import Raven from 'raven-js';
import { IGNORED_PURCHASE_ORDER_GENERATION_ERRORS } from 'self-service-api/constants/Errors';
export default function logPurchaseOrderError(location, err, additionalProperties) {
  const {
    errorCode,
    errorMessage,
    message,
    responseJSON,
    status,
    data
  } = err;
  let errorType;
  if (additionalProperties !== null && additionalProperties !== void 0 && additionalProperties.chirpError) {
    errorType = additionalProperties.chirpError.errorType;
  } else if (additionalProperties !== null && additionalProperties !== void 0 && additionalProperties.chirpInternalError) {
    errorType = additionalProperties.chirpInternalError.type;
  } else {
    errorType = responseJSON && ('errorType' in responseJSON && typeof responseJSON.errorType === 'string' ? responseJSON.errorType : undefined);
  }

  /**
   * Don't log no-op errors that shouldn't contribute to our error budget.
   * These should be investigated and fixed (possibly by the backend team), but they are known and not actionable.
   */
  const shouldLogSentry = errorType ? !IGNORED_PURCHASE_ORDER_GENERATION_ERRORS[errorType] : true;

  /**
   * Only events with `level: 'error'` will be reported to LogFetch Errors
   * Events of all levels will be stored in the `ERRORS.SENTRY_V3` custom LogFetch event
   * https://product.hubteam.com/docs/observability/docs/errors/LogFetchErrors/raven-usage.html#raven-options
   */
  const level = shouldLogSentry ? 'error' : 'warning';
  Raven.captureException(new Error('Purchase order generation failed', {
    cause: err
  }), {
    level,
    extra: Object.assign({
      location,
      errorCode,
      errorMessage,
      message,
      responseJSON,
      status,
      data,
      errorType
    }, additionalProperties)
  });
}