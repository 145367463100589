'use es6';

import { fromJS, List } from 'immutable';
import { toMetricKey } from '../v2/dataset/datasetMetrics';
export const ZERO_DIMENSIONAL_METRIC_FUNNEL_DIMENSION = 'funnelStage';
export const ZERO_DIMENSIONAL_METRIC_FUNNEL_METRIC = toMetricKey({
  property: 'count'
});
const reshapeDataset = ({
  dataset,
  dataConfig
}) => {
  if (!dataConfig.get('dimensions', List()).isEmpty()) {
    return dataset;
  }
  return fromJS({
    total: dataset.get('metrics').count(),
    dimension: {
      property: ZERO_DIMENSIONAL_METRIC_FUNNEL_DIMENSION,
      buckets: dataset.get('metrics').map((metric, key, entire) => {
        const metricIndex = dataConfig.get('metrics').findIndex(m => m.get('property') === key);
        const nextMetric = entire.get(dataConfig.getIn(['metrics', metricIndex + 1, 'property']));
        const currentValue = metric.first();
        const nextValue = nextMetric && nextMetric.first();
        const conversionValue = nextValue && nextValue / currentValue;
        return fromJS({
          key,
          metrics: {
            count: metric,
            'funnel.stepwiseConversion': {
              PERCENTILES: conversionValue
            }
          }
        });
      }).toList()
    }
  });
};
export default (props => {
  return Promise.resolve(reshapeDataset(props));
});