import * as ErrorTypes from './constants/errorTypes';
import { LogLevel } from './tsTypes/loggerTypes';
export class Exception extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
    this.logLevel = LogLevel.error;

    // @ts-expect-error Usage of a non standard browser behavior specific to v8
    if (Error.captureStackTrace) {
      // @ts-expect-error Usage of a non standard browser behavior specific to v8
      Error.captureStackTrace(this, this.constructor);
    }
  }
}
export class ExceptionWithLogLevelInfo extends Exception {
  constructor(message) {
    super(message);
    this.logLevel = LogLevel.info;
  }
}
export class DeprecatedPropertyException extends Exception {
  constructor(property) {
    super(`Deprecated property ${property} used`);
    this.status = 400;
    this.statusText = 'DEPRECATED_PROPERTY';
    this.meta = {
      property
    };
  }
}
export class FunnelCountPropertyNotPresent extends Exception {
  constructor(metricName) {
    super(`Only one metric is allowed and it must contain the property count for this report. ${metricName} was given instead. Try removing this report and selecting a different one`);
    this.status = 400;
    this.statusText = 'FUNNEL_COUNT_PROPERTY_NOT_PRESENT';
    this.meta = {
      metricName
    };
  }
}
export class InvalidPropertiesException extends Exception {
  constructor(properties = []) {
    super(`Invalid property ${properties} used`);
    this.status = 400;
    this.statusText = 'INVALID_PROPERTIES';
    this.meta = {
      properties
    };
    this.logLevel = LogLevel.error;
  }
}
export class MissingPropertiesException extends Exception {
  constructor(dataType) {
    super(`missing properties for ${dataType}`);
  }
}
export class InvalidPipelineException extends Exception {
  constructor(pipeline) {
    super(`invalid pipeline: "${pipeline}"`);
    this.meta = {
      pipeline
    };
  }
}
export class TooMuchDataException extends ExceptionWithLogLevelInfo {
  constructor() {
    super('too much data to process');
    this.status = 413;
    this.statusText = 'REQUEST_ENTITY_TOO_LARGE';
  }
}
export class TooManyBreakdownsException extends ExceptionWithLogLevelInfo {
  constructor() {
    super('too many breakdowns to process');
  }
}
export class UnsupportedException extends Exception {
  constructor({
    type,
    entity
  }) {
    super(`unsupported ${type} used`);
    this.meta = {
      type,
      entity
    };
  }
}
export class TooManyMetricsException extends ExceptionWithLogLevelInfo {
  constructor() {
    super('too many metrics to request');
  }
}
export class TooManyStagesException extends ExceptionWithLogLevelInfo {
  constructor() {
    super('too many stages to request');
  }
}
export class UnsupportedDateRangeException extends Exception {
  constructor() {
    super('report does not support the requested date range');
  }
}
export class MissingRequiredDataException extends Exception {
  constructor() {
    super('missing data needed to finish report resolution');
    this.status = 404;
    this.statusText = 'MISSING_REQUIRED_DATA';
  }
}
export class TooLargeDatasetException extends Exception {
  constructor() {
    super('too large dataset to process');
    this.status = 422;
    this.statusText = 'REQUEST_DATASET_TOO_LARGE';
  }
}
export class PipelineCadenceMismatchException extends Exception {
  constructor() {
    super('Forecasting cadences must be uniform across all pipelines');
    this.status = 422;
    this.statusText = 'PIPELINE_CADENCE_MISMATCH';
  }
}
export class NoDimensionsOrMetricsException extends ExceptionWithLogLevelInfo {
  constructor() {
    super('report contains no dimensions or metrics');
    this.statusText = ErrorTypes.MISSING_DIMENSIONS_AND_METRICS;
  }
}
export class DataReprocessingException extends Exception {
  constructor() {
    super('report is not avaliable becuase its data is reprocessing');
    this.statusText = ErrorTypes.DATA_REPROCESSING;
  }
}
export class HighchartsErrorException extends Exception {
  constructor(error) {
    super(error.message);
    this.statusText = ErrorTypes.HIGHCHARTS_ERROR;
  }
}
export class MissingIntegrationException extends Exception {
  constructor() {
    super('the report requires a site filter');
    this.statusText = ErrorTypes.MISSING_INTEGRATION_ERROR;
  }
}
export class MissingSeriesException extends Exception {
  constructor() {
    super('the report requires at least one series filter');
    this.statusText = ErrorTypes.MISSING_SERIES_ERROR;
  }
}
export class AccessDeniedException extends Exception {
  constructor() {
    super('access denied');
    this.statusText = ErrorTypes.ACCESS_DENIED;
    this.status = 403;
  }
}
export class TimeoutException extends Exception {
  constructor() {
    super('timeout');
    this.statusText = ErrorTypes.TIMEOUT;
  }
}
export class InvalidTwoDimensionMetricException extends ExceptionWithLogLevelInfo {
  constructor({
    propertyName,
    timeInAllStagesProperty
  }) {
    super(`${timeInAllStagesProperty} cannot be used in two dimension reports`);
    this.statusText = ErrorTypes.INVALID_TWO_DIMENSION_METRIC;
    this.meta = {
      propertyName
    };
  }
}
export class TooManySequentialOptionalStages extends ExceptionWithLogLevelInfo {
  constructor() {
    super('too many sequential optional stages');
    this.statusText = ErrorTypes.TOO_MANY_SEQUENTIAL_OPTIONAL_STAGES;
  }
}
export class UnfilteredPipelineStage extends Exception {
  constructor() {
    super('report contains unfiltered pipeline stage');
    this.statusText = ErrorTypes.UNFILTERED_PIPELINE_STAGE;
  }
}
export class FieldLevelPermission extends ExceptionWithLogLevelInfo {
  constructor({
    propertyNames
  }) {
    super('field level permissions error');
    this.statusText = ErrorTypes.FIELD_LEVEL_PERMISSIONS;
    this.meta = {
      propertyNames
    };
  }
}
export class RaasResolveException extends Exception {
  constructor(datasetKey, errorBody) {
    super('report failed a raas resolve network call');
    this.datasetKey = datasetKey;
    this.errorBody = errorBody;
  }
}
export class UnsupportedDateRangeForAttributionReportsException extends Exception {
  constructor() {
    super('Unsupported date range for revenue attribution report - allowed 3 years');
    this.statusText = ErrorTypes.UNSUPPORTED_DATE_RANGE_FOR_ATTRIBUTION_REPORTS;
  }
}
export class UnifiedAnalyticsMisingFilterException extends Exception {
  constructor() {
    super('2-dimensional unified analytics reports require a filter');
  }
}