'use es6';

import { SEARCH } from '../../constants/configTypes';
import rethrow from '../../lib/rethrow';
import getProperties from '../../properties';
import * as http from '../../request/http';
import aggregate from './aggregate';
import search from './search';
export default ((config, debug, runtimeOptions) => {
  const {
    batchRequests = false
  } = runtimeOptions;
  const dataType = config.get('dataType');

  // search
  if (config.get('configType') === SEARCH) {
    return search(config, runtimeOptions);
  }

  // aggregate
  return getProperties(dataType).then(properties => aggregate(config, properties, runtimeOptions)).then(request => {
    debug('request', request);
    return http.retrieve(request, {
      batchRequests
    }).catch(err => rethrow(err)).then(response => {
      debug('response', response);
      const transformed = request.transformer ? request.transformer(config, response) : response;
      return Promise.resolve(transformed).then(dataset => ({
        response,
        dataset
      }));
    });
  }).then(({
    dataset,
    response
  }) => {
    return {
      dataConfig: config,
      dataset,
      response
    };
  });
});