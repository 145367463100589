'use es6';

import getIn from 'transmute/getIn';
import useUserInfo from '../../useUserInfo';
import { CUSTOM_REPORTS, LIMIT_ROOT } from '../limitTypes';
const useCustomReportLimit = () => {
  const {
    data
  } = useUserInfo(false);
  return getIn([LIMIT_ROOT, CUSTOM_REPORTS], data);
};
export default useCustomReportLimit;