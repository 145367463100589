export const NegativeNumberTypes = {
  AUTO: 'AUTO',
  PARENTHESES: 'parentheses',
  LEFT: 'left',
  RIGHT: 'right',
  RED: 'red'
};
export const FormatTypes = {
  AUTO: 'auto',
  PERCENTAGE: 'percentage',
  NUMBER: 'number',
  CURRENCY: 'currency'
};

// Specific formatting types

// Type guard functions
export function isAutoFormatting(format) {
  return format.type === FormatTypes.AUTO;
}
export function isNumberFormatting(format) {
  return format.type === FormatTypes.NUMBER;
}
export function isPercentFormatting(format) {
  return format.type === FormatTypes.PERCENTAGE;
}
export function isCurrencyFormatting(format) {
  return format.type === FormatTypes.CURRENCY;
}