'use es6';

import I18n from 'I18n';
import { Map as ImmutableMap, List, OrderedSet, OrderedMap } from 'immutable';
import makeDateRangeByType from './makeDateRangeByType';
import * as Frequency from '../constants/frequency';
import { TIME_SERIES } from '../constants/configTypes';
import { RANGE_TYPES } from '../constants/dateRangeTypes';
import { CROSS_OBJECT } from '../constants/dataTypes';
const DATE_FORMAT = 'YYYY-MM-DD';
const defaultOptions = {
  format: DATE_FORMAT,
  empty: false,
  leading: true,
  trailing: true,
  sustain: false
};
const frequencyToPeriodMap = ImmutableMap({
  [Frequency.DAY]: 'day',
  [Frequency.WEEK]: 'week',
  [Frequency.MONTH]: 'month',
  [Frequency.QUARTER]: 'quarter',
  [Frequency.YEAR]: 'year'
});

// NOTE: RA-1957 - normalize for locale agnostic logic
// NOTE: RA-2430 - but keep start of the week control
const normalizeDateTime = (isWeekly, weekStartSunday) => (...args) => {
  return I18n.moment.portalTz(...args).locale(isWeekly && !weekStartSunday ? 'fr' : 'en-us');
};
function clearPoint(point, clearValue = 0) {
  const clearMetric = metric => metric.map(() => clearValue);
  return point.update('metrics', ImmutableMap(), metrics => metrics.map(clearMetric)).updateIn(['dimension', 'buckets'], List(), points => points.map(p => clearPoint(p, clearValue)));
}
function fillPointsWithRange(points, frequency, {
  startDate,
  endDate,
  rangeType
}, {
  format,
  empty,
  leading,
  trailing,
  sustain
}) {
  const unit = frequencyToPeriodMap.get(frequency);
  const first = points.first();
  const last = points.last();

  // Check if weeks start on Sunday or Monday for this dataset
  const tempStart = I18n.moment(first.get('key'), DATE_FORMAT);
  // 7 if Sunday, 1 if Monday
  const weekStartSunday = tempStart.isoWeekday() === 7;
  const normalizedDateTime = normalizeDateTime(frequency === 'WEEK', weekStartSunday);
  const start = normalizedDateTime(startDate, DATE_FORMAT);
  const end = normalizedDateTime(endDate, DATE_FORMAT);
  const leftMostDate = normalizedDateTime(first.get('key'), DATE_FORMAT);
  const rightMostDate = normalizedDateTime(last.get('key'), DATE_FORMAT);
  const zeroPoint = clearPoint(first, 0);
  const nullPoint = clearPoint(first, null);
  const keyed = points.reduce((memo, point) => memo.set(point.get('key'), point), OrderedMap());
  const leadingPoints = [];
  if (leading || empty) {
    for (let i = leftMostDate.clone().subtract(1, unit); i.isSameOrAfter(start, unit) && ![RANGE_TYPES.ALL, RANGE_TYPES.IS_BEFORE_DATE].includes(rangeType); i.subtract(1, unit)) {
      const formatted = i.format(format);
      const point = empty ? nullPoint.set('key', formatted) : zeroPoint.set('key', formatted);
      leadingPoints.unshift(point);
    }
  }
  let lastPoint = null;
  const innerPoints = [];
  for (let n = leftMostDate.clone(); n.isSameOrBefore(rightMostDate, unit); n.add(1, unit)) {
    const formatted = n.format(format);
    const point = keyed.has(formatted) ? keyed.get(formatted) : sustain ? lastPoint : zeroPoint.set('key', formatted);
    innerPoints.push(point);
    lastPoint = point;
  }
  const trailingPoints = [];
  if (trailing) {
    const endOfDay = I18n.moment.portalTz().endOf('day');
    for (let j = rightMostDate.clone().add(1, unit); j.isSameOrBefore(end, unit); j.add(1, unit)) {
      const formatted = j.format(format);
      const emptyPoint = j.isSameOrAfter(endOfDay, unit) ? nullPoint : sustain ? lastPoint : zeroPoint;
      const point = emptyPoint.set('key', formatted);
      trailingPoints.push(point);
    }
  }
  return List([...leadingPoints, ...innerPoints, ...trailingPoints]);
}
function addAllSubaggsToAllBuckets(config, data) {
  const subAggKeyList = data.getIn(['dimension', 'buckets'], List()).reduce((reduction, bucket) => {
    return bucket.getIn(['dimension', 'buckets'], List()).reduce((memo, innerBucket) => {
      return memo.add(innerBucket.get('key'));
    }, reduction);
  }, OrderedSet()).toList();
  if (subAggKeyList.isEmpty()) {
    return data;
  }
  return data.updateIn(['dimension', 'buckets'], buckets => buckets.map(bucket => {
    return bucket.updateIn(['dimension', 'buckets'], innerBuckets => subAggKeyList.map(subAggKey => {
      const filler = ImmutableMap(config.get('metrics', List()).map(metricWithTypes => [metricWithTypes.get('property'), ImmutableMap(metricWithTypes.get('metricTypes', List()).map(metric => [metric, 0]).toJS())]).toJS());
      const found = innerBuckets.find(b => b.get('key') === subAggKey);
      return found ? found.update('metrics', metrics => !metrics || metrics.isEmpty() ? filler : metrics) : ImmutableMap({
        key: subAggKey,
        metrics: filler
      });
    }));
  }));
}
export default function zeroFill(config, data, overrides = {}) {
  const configType = config.get('configType');
  const dateRange = config.getIn(['filters', 'dateRange']);
  if (configType !== TIME_SERIES || !dateRange) {
    if (config.get('dataType') === CROSS_OBJECT) {
      return addAllSubaggsToAllBuckets(config, data);
    }
    return data;
  }
  const options = Object.assign({}, defaultOptions, overrides);
  const dataType = config.get('dataType');
  const frequency = config.get('frequency');
  const fullDateRange = makeDateRangeByType(dateRange.get('value').toJS(), options.format, dataType);
  const fillPoints = points => !points || points.isEmpty() ? points : fillPointsWithRange(points, frequency, fullDateRange, options);
  const filledData = addAllSubaggsToAllBuckets(config, data).withMutations(base => {
    base.updateIn(['dimension', 'buckets'], fillPoints);
    base.set('total', base.getIn(['dimension', 'buckets'], List()).size);
  });
  return filledData;
}