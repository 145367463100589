'use es6';

import { Map as ImmutableMap } from 'immutable';
import basic from './basic';
import pipelineFunnel from './pipeline-funnel';
import * as ConfigTypes from '../../../../constants/configTypes';
const requestGeneratorByConfigType = ImmutableMap({
  [ConfigTypes.AGGREGATION]: basic,
  [ConfigTypes.TIME_SERIES]: basic,
  [ConfigTypes.PIPELINE]: pipelineFunnel,
  [ConfigTypes.FUNNEL]: pipelineFunnel
});
export default (config => {
  return requestGeneratorByConfigType.get(config.get('configType'), () => {
    throw new Error('unsupported config type');
  });
});