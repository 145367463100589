'use es6';

import { List } from 'immutable';
import { ENGAGEMENT_MAP_TO_SEARCH_PROPERTIES, ENGAGEMENT_TYPE_DETAILS_PROPERTY_MAP } from './mapToSearchProperties';
export const engagementExtractors = Object.keys(ENGAGEMENT_MAP_TO_SEARCH_PROPERTIES).reduce((memo, property) => Object.assign({}, memo, {
  [property]: object => object.getIn(['properties', ENGAGEMENT_MAP_TO_SEARCH_PROPERTIES[property], 'value'], '')
}), {
  'engagement.details': object => {
    const engagementType = object.getIn(['properties', 'hs_engagement_type', 'value']);
    return List(ENGAGEMENT_TYPE_DETAILS_PROPERTY_MAP[engagementType]).map(searchProperty => object.getIn(['properties', searchProperty, 'value'], '')).filterNot(value => !value).first();
  }
});