'use es6';

import { useHasReportingAdminAccess, useHasReportingCreateAndOwnAccess, useHasReportingEditAccess } from '../access/scopes/hooks/useHasScope';
import useUserInfo from '../access/useUserInfo';
import { getOwnerId as getDashboardOwnerId, getUserPermissionLevel } from '../data/schemas/dashboardSchema';
import { getOwnerId as getReportOwnerId, getUserReportPermissionLevel } from '../data/schemas/reportSchema';
import { getUserId } from '../data/schemas/userInfoSchema';
import { PermissionLevel } from '../permissions/permission-payload';
import events from '../../events.yaml';

//values should parallel possible values for the "role" property in usage tracking
const ROLES = {
  VIEWER: 'viewer',
  EDITOR: 'editor',
  OWNER: 'owner',
  ADMIN: 'admin'
};
const ACCESS_LEVEL_TO_ROLE = {
  [PermissionLevel.VIEW]: ROLES.VIEWER,
  [PermissionLevel.EDIT]: ROLES.EDITOR,
  [PermissionLevel.CREATE_AND_OWN]: ROLES.OWNER
};
export const useUserRoleFromScopes = () => {
  const isAdmin = useHasReportingAdminAccess();
  const hasCreateAndOwnAccess = useHasReportingCreateAndOwnAccess();
  const hasEditAccess = useHasReportingEditAccess();
  if (isAdmin) {
    return ROLES.ADMIN;
  }
  if (hasCreateAndOwnAccess) {
    return ROLES.OWNER;
  }
  if (hasEditAccess) {
    return ROLES.EDITOR;
  }
  return ROLES.VIEWER;
};
const useUserRoleForAsset = ({
  assetAccessLevel,
  ownerId
}) => {
  const {
    data: userInfo
  } = useUserInfo(false);
  return assetAccessLevel === PermissionLevel.CREATE_AND_OWN && ownerId !== getUserId(userInfo) ? ROLES.ADMIN : ACCESS_LEVEL_TO_ROLE[assetAccessLevel];
};
export const useUserRoleForReport = report => useUserRoleForAsset({
  assetAccessLevel: getUserReportPermissionLevel(report),
  ownerId: getReportOwnerId(report)
});
export const useUserRoleForDashboard = dashboard => useUserRoleForAsset({
  assetAccessLevel: getUserPermissionLevel(dashboard),
  ownerId: getDashboardOwnerId(dashboard)
});
export const getTrackingConfigOptions = () => ({
  events,
  onError: err => {
    console.log(err.message);
  }
});