'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import I18n from 'I18n';
import formatName from 'I18n/utils/formatName';
import * as DataType from '../../../constants/dataTypes';
const associatedContactsExtractor = associationName => object => {
  const associations = object.getIn(['associatedObjects', associationName], List());
  return ImmutableMap({
    raw: associations.map(association => association.get('objectId')),
    properties: associations.map(association => association.get('properties')),
    formatted: associations.map(association => formatName({
      firstName: association.getIn(['properties', 'firstname', 'value']),
      lastName: association.getIn(['properties', 'lastname', 'value']),
      email: association.getIn(['properties', 'email', 'value'])
    }) || I18n.text('reporting-data.references.contact.unknown', {
      id: association.get('objectId').toString()
    })),
    label: I18n.text('reporting-data.properties.common.associatedContacts')
  });
};
const associatedCompaniesExtractor = associationName => object => {
  const associations = object.getIn(['associatedObjects', associationName], List());
  return ImmutableMap({
    raw: associations.map(association => association.get('objectId')),
    properties: associations.map(association => association.get('properties')),
    formatted: associations.map(association => association.getIn(['properties', 'name', 'value']) || I18n.text('reporting-data.references.company.unknown', {
      id: association.get('objectId').toString()
    })),
    label: I18n.text('reporting-data.properties.common.associatedCompanies')
  });
};
const associatedDealsExtractor = associationName => object => {
  const associations = object.getIn(['associatedObjects', associationName], List());
  return ImmutableMap({
    raw: associations.map(association => association.get('objectId')),
    properties: associations.map(association => association.get('properties')),
    formatted: associations.map(association => association.getIn(['properties', 'dealname', 'value']) || I18n.text('reporting-data.references.deal.unknown', {
      id: association.get('objectId').toString()
    })),
    label: I18n.text('reporting-data.properties.common.associatedDeals')
  });
};
const associatedTicketsExtractor = associationName => object => {
  const associations = object.getIn(['associatedObjects', associationName], List());
  return ImmutableMap({
    raw: associations.map(association => association.get('objectId')),
    properties: associations.map(association => association.get('properties')),
    formatted: associations.map(association => {
      const ticketId = association.get('objectId').toString();
      const ticketName = association.getIn(['properties', 'subject', 'value']);
      if (ticketId) {
        return ticketName ? `${ticketName} (${ticketId})` : I18n.text('reporting-data.references.ticket.unknown-with-id', {
          id: ticketId
        });
      }
      return ticketName || I18n.text('reporting-data.references.ticket.unknown');
    }),
    label: I18n.text('reporting-data.properties.common.associatedTickets')
  });
};
export const getExtractors = dataType => {
  if (dataType === DataType.CONTACTS) {
    return {
      associatedcompanyid: associatedCompaniesExtractor('CONTACT_TO_COMPANY')
    };
  }
  if (dataType === DataType.DEALS || dataType === DataType.ENGAGEMENT) {
    return {
      'associations.contact': associatedContactsExtractor('CONTACT'),
      'associations.company': associatedCompaniesExtractor('COMPANY'),
      'associations.deal': associatedDealsExtractor('DEAL'),
      'associations.ticket': associatedTicketsExtractor('TICKET')
    };
  }
  return {};
};