export const THEME = {
  MULTI: 'multi',
  ORANGES: 'oranges',
  AQUAS: 'aquas',
  PURPLES: 'purples',
  YELLOWS: 'yellows',
  PINKS: 'pinks',
  BLUES: 'blues',
  GREENS: 'greens',
  FUNNEL: 'funnel',
  PATTERNS: 'patterns',
  BRAND_KIT: 'BrandKit'
};
export const isTheme = value => Object.values(THEME).includes(value);
export const BRAND_KIT_PALETTE_ID = 'BrandKit';
export const PaletteIds = {
  Categorical14: 'Categorical14',
  PatternFill14: 'PatternFill14',
  ShadesOfOrange: 'ShadesOfOrange',
  ShadesOfAqua: 'ShadesOfAqua',
  ShadesOfPurple: 'ShadesOfPurple',
  ShadesOfYellow: 'ShadesOfYellow',
  ShadesOfPink: 'ShadesOfPink',
  ShadesOfBlue: 'ShadesOfBlue',
  ShadesOfGreen: 'ShadesOfGreen',
  TokyoFive: 'TokyoFive',
  BogotaFive: 'BogotaFive',
  DublinFive: 'DublinFive',
  CambridgeFive: 'CambridgeFive',
  SingaporeFive: 'SingaporeFive',
  SydneyFive: 'SydneyFive',
  BerlinFive: 'BerlinFive',
  ParisFive: 'ParisFive',
  SanFranciscoFive: 'SanFranciscoFive',
  PortsmouthFive: 'PortsmouthFive',
  GhentFive: 'GhentFive',
  OceanFive: 'OceanFive',
  SpringFive: 'SpringFive',
  CherryBlossomFive: 'CherryBlossomFive',
  PastelFive: 'PastelFive',
  DefaultY2: 'DefaultY2',
  AlertRedToGreen: 'AlertRedToGreen',
  AlertGreenToRed: 'AlertGreenToRed',
  BrandKit: BRAND_KIT_PALETTE_ID
};
export const isPaletteId = value => Object.values(PaletteIds).includes(value);