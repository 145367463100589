import { useCallback, useContext } from 'react';
import { APP_NAME, COMMENTS_FILTER, CTA_URL, HIDE_EMBEDDED_COMMENTS, IS_COMMENT_MODE_ACTIVE, IS_EMBEDDED_COMMENTS_ENABLED, IS_OPEN, MODULES_WITH_REPLIES, OBJECT_ID, OBJECT_TYPE, TAB, SELECTED_ASSET, EMBEDDED_COMMENT_SIDEBAR_INTERACTIONS, COMMENT_THREAD, ASSETS_LAST_UPDATED, ENABLED_FEATURES, IS_EMBEDDED_POPOVER_OPEN, EMBEDDED_POPOVER_PLACEMENT, EMBEDDED_COMMENT_COORDINATES, EMBEDDED_MODULE_ID, EMBEDDED_META_DESCRIPTION, IS_EMBEDDED_OPEN_FROM_COMMENT_MODE, COMMENTABLE_AREA_CLICK_COORDINATES, DELETE_COMMENT_REQUEST } from '../constants/CollaborationSidebarStateProperties';
import CollaborationSidebarStateContext from '../contexts/CollaborationSidebarStateContext';
import { getSanitizedTab } from '../utils/tab';
import { deleteCommentOnModuleAction, getEmptyCollaborationSidebarStateContext } from '../utils';
import { useForceRefreshComments } from './useForceRefreshComments';
export default function useCollaborationSidebarState({
  allowNoContext
} = {}) {
  const contextVal = useContext(CollaborationSidebarStateContext);
  if (!contextVal && !allowNoContext) {
    throw new Error('Using useCollaborationSidebarState outside of a CollaborationSidebarStateContext');
  }
  const {
    collaborationSidebarState,
    onUpdateCollaborationSidebarState
  } = contextVal || getEmptyCollaborationSidebarStateContext();
  const requestRefreshComments = useForceRefreshComments({
    onUpdateCollaborationSidebarState,
    objectId: collaborationSidebarState[OBJECT_ID],
    objectType: collaborationSidebarState[OBJECT_TYPE]
  });
  return {
    collaborationSidebarState,
    onUpdateCollaborationSidebarState,
    // These are provided as helpers for the above
    appName: collaborationSidebarState[APP_NAME],
    enabledFeatures: collaborationSidebarState[ENABLED_FEATURES],
    objectType: collaborationSidebarState[OBJECT_TYPE],
    objectId: collaborationSidebarState[OBJECT_ID],
    ctaUrl: collaborationSidebarState[CTA_URL],
    isEmbeddedCommentingEnabled: collaborationSidebarState[IS_EMBEDDED_COMMENTS_ENABLED],
    isOpen: collaborationSidebarState[IS_OPEN],
    tab: collaborationSidebarState[TAB],
    modulesWithReplies: collaborationSidebarState[MODULES_WITH_REPLIES],
    isCommentModeActive: collaborationSidebarState[IS_COMMENT_MODE_ACTIVE],
    hideEmbeddedComments: collaborationSidebarState[HIDE_EMBEDDED_COMMENTS],
    embeddedCommentSidebarInteractions: collaborationSidebarState[EMBEDDED_COMMENT_SIDEBAR_INTERACTIONS],
    selectedChildAsset: collaborationSidebarState[SELECTED_ASSET],
    commentThread: collaborationSidebarState[COMMENT_THREAD],
    commentsFilter: collaborationSidebarState[COMMENTS_FILTER],
    assetsLastUpdated: collaborationSidebarState[ASSETS_LAST_UPDATED],
    isEmbeddedPopoverOpen: collaborationSidebarState[IS_EMBEDDED_POPOVER_OPEN],
    embeddedPopoverPlacement: collaborationSidebarState[EMBEDDED_POPOVER_PLACEMENT],
    embeddedCommentCoordinates: collaborationSidebarState[EMBEDDED_COMMENT_COORDINATES],
    embeddedModuleID: collaborationSidebarState[EMBEDDED_MODULE_ID],
    embeddedMetaDescription: collaborationSidebarState[EMBEDDED_META_DESCRIPTION],
    isEmbeddedOpenFromCommentMode: collaborationSidebarState[IS_EMBEDDED_OPEN_FROM_COMMENT_MODE],
    commentableAreaClickCoordinates: collaborationSidebarState[COMMENTABLE_AREA_CLICK_COORDINATES],
    deleteCommentRequest: collaborationSidebarState[DELETE_COMMENT_REQUEST],
    openSidebar: useCallback(tab => onUpdateCollaborationSidebarState({
      [IS_OPEN]: true,
      [TAB]: getSanitizedTab(tab)
    }), [onUpdateCollaborationSidebarState]),
    closeSidebar: useCallback(() => onUpdateCollaborationSidebarState({
      [IS_OPEN]: false
    }), [onUpdateCollaborationSidebarState]),
    setHideEmbeddedComments: useCallback(hideEmbeddedComments => onUpdateCollaborationSidebarState({
      [HIDE_EMBEDDED_COMMENTS]: hideEmbeddedComments
    }), [onUpdateCollaborationSidebarState]),
    deleteCommentOnModule: useCallback(moduleId => {
      onUpdateCollaborationSidebarState(deleteCommentOnModuleAction(moduleId));
    }, [onUpdateCollaborationSidebarState]),
    requestRefreshComments
  };
}