'use es6';

import I18n from 'I18n';
import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import * as dataTypes from '../../constants/dataTypes';
import { has } from '../../lib/has';
import { InboundDbModule } from '../../module';
import { hydrateFn as contactHydrateFn } from '../../references/contact/hydrate';
import { getExtractors as getAssociationExtractors } from '../../retrieve/inboundDb/common/association-extractors';
import { engagementExtractors } from '../../retrieve/inboundDb/common/engagement-crmsearch-extractors';
import { dataTypeToEngagementType } from '../../retrieve/inboundDb/common/engagement-types';
import Spec from '../../retrieve/inboundDb/common/specs/Spec';
const idProperties = {
  [dataTypes.CONTACTS]: 'vid',
  [dataTypes.COMPANIES]: 'company-id',
  [dataTypes.DEALS]: 'dealId',
  [dataTypes.ENGAGEMENT]: 'engagement.id',
  [dataTypes.TICKETS]: 'hs_ticket_id',
  [dataTypes.FEEDBACK_SUBMISSIONS]: 'hs_object_id',
  [dataTypes.GOAL_TARGET]: 'hs_object_id'
};
const defaultProperties = {
  [dataTypes.CONTACTS]: ['firstname', 'lastname', 'email'],
  [dataTypes.COMPANIES]: ['name'],
  [dataTypes.DEALS]: ['dealname', 'deal_currency_code'],
  [dataTypes.LINE_ITEMS]: ['objectId', 'hs_line_item_currency_code'],
  [dataTypes.ENGAGEMENT]: ['title'],
  [dataTypes.TICKETS]: ['subject']
};
const associationProperties = {
  [dataTypes.CONTACTS]: {
    associatedcompanyid: {
      CONTACT_TO_COMPANY: {
        properties: defaultProperties[dataTypes.COMPANIES]
      }
    }
  },
  [dataTypes.DEALS]: {
    'associations.contact': {
      CONTACT: {
        properties: defaultProperties[dataTypes.CONTACTS]
      }
    },
    'associations.company': {
      COMPANY: {
        properties: defaultProperties[dataTypes.COMPANIES]
      }
    }
  },
  [dataTypes.ENGAGEMENT]: {
    'associations.contact': {
      CONTACT: {
        properties: defaultProperties[dataTypes.CONTACTS]
      }
    },
    'associations.company': {
      COMPANY: {
        properties: defaultProperties[dataTypes.COMPANIES]
      }
    },
    'associations.deal': {
      DEAL: {
        properties: defaultProperties[dataTypes.DEALS]
      }
    },
    'associations.ticket': {
      TICKET: {
        properties: defaultProperties[dataTypes.TICKETS]
      }
    }
  }
};
const unknownMessages = {
  [dataTypes.CONTACTS]: 'reporting-data.references.contact.unknown',
  [dataTypes.COMPANIES]: 'reporting-data.references.company.unknown',
  [dataTypes.DEALS]: 'reporting-data.references.deal.unknown',
  [dataTypes.ENGAGEMENT]: 'reporting-data.references.activity.unknown',
  [dataTypes.TICKETS]: 'reporting-data.references.ticket.unknown',
  [dataTypes.LINE_ITEMS]: 'reporting-data.references.line-item.unknown'
};
const unnamedMessages = {
  [dataTypes.COMPANIES]: 'reporting-data.references.company.unnamed',
  [dataTypes.ENGAGEMENT]: 'reporting-data.references.activity.untitled'
};
const hydrateFn = (obj, dataType, idProperty, defaultProps, id) => {
  if (dataType === dataTypes.CONTACTS) {
    return contactHydrateFn(obj);
  }
  const hydrationProperty = defaultProps && defaultProps[0];
  if (obj && hydrationProperty && has(obj, hydrationProperty)) {
    return obj[hydrationProperty];
  }
  if (obj && hydrationProperty && !obj[hydrationProperty] && unnamedMessages[dataType]) {
    return I18n.text(unnamedMessages[dataType], {
      id: obj[idProperty]
    });
  }
  if (has(unknownMessages, dataType)) {
    return I18n.text(unknownMessages[dataType], {
      id: obj[idProperty] || id
    });
  }
  return obj[idProperty];
};
const getAssociationPreviews = (dataType, properties) => ImmutableMap(properties.reduce((memo, property) => Object.assign({}, memo, associationProperties[dataType] && associationProperties[dataType][property] || {}), {}));
const getInboundSpec = config => {
  const dataType = config.get('dataType');
  const objectTypeId = dataTypeToEngagementType.has(dataType) ? dataTypes.ENGAGEMENT : dataType;
  const idProperty = idProperties[objectTypeId] || 'objectId';
  const defaultProps = defaultProperties[dataType] || [];
  const properties = config.get('metrics').map(metric => metric.get('property')).toSet().union(defaultProps).toList();
  const extractors = Object.assign({}, objectTypeId === dataTypes.ENGAGEMENT ? engagementExtractors : {}, getAssociationExtractors(objectTypeId));
  const associationPreviews = getAssociationPreviews(objectTypeId, properties);
  return new Spec({
    dataType,
    objectTypeId,
    search: {
      url: 'crm-search/search/beta',
      objectsField: 'results',
      properties
    },
    properties: {
      idProperty,
      responsePaths: {
        [idProperty]: ['objectId']
      },
      extractors
    },
    associationPreviews,
    hydrate: {
      inputs: ImmutableSet([idProperty, ...defaultProps]),
      fn: (obj, id) => hydrateFn(obj, dataType, idProperty, defaultProps, id)
    }
  });
};
export default InboundDbModule({
  getInboundSpec
});