import { fromJS } from 'immutable';
import { SHOW_BANNER } from 'reporting-data/constants/deprecate/deprecationTypes';
import { CONTACT } from '../../relational/metadata/objectTypes';
import { resolveReportMeta } from '../../relational/metadata/report-meta';
import { RelationalReport as RelationalReportRecord } from '../../relational/schema/report-records';
const CURRENTLY_IN_WORKFLOW_PROPERTY_NAME = 'currentlyinworkflow';
export const shouldDeprecate = (tableNamesToProperties, tables) => {
  const usesContactDataType = tables.some(table => {
    return table.get('dataSourceId', undefined) === CONTACT || table.get('objectTypeId', undefined) === CONTACT;
  });
  const hasCurrentlyInWorkflowProperty = tableNamesToProperties.some(properties => {
    return properties.some(property => {
      return property.get('name') === CURRENTLY_IN_WORKFLOW_PROPERTY_NAME;
    });
  });
  return usesContactDataType && hasCurrentlyInWorkflowProperty;
};
export const usesCurrentlyInWorkflowProperty = report => {
  const reportDefinition = report.get('reportDefinition');
  if (!reportDefinition) {
    return report;
  }
  const reportDefinitionRecord = RelationalReportRecord(reportDefinition);
  return resolveReportMeta(reportDefinitionRecord).then(({
    properties,
    tables
  }) => {
    return shouldDeprecate(properties, tables) ? report.set('deprecation', fromJS({
      isDeprecated: true,
      type: SHOW_BANNER,
      message: 'usesCurrentlyInWorkflowProperty'
    })) : report;
  }).catch(error => {
    console.error('Error in usesCurrentlyInWorkflowProperty', error);
    return report;
  });
};