'use es6';

import { List, Map as ImmutableMap, Range } from 'immutable';
import I18n from 'I18n';
import * as Frequency from '../constants/frequency';
import { ACCUMULATE } from '../constants/processorTypes';
const DATE_FORMAT = 'YYYY-MM-DD';
export function isAccumulated(report) {
  const processors = report.getIn(['config', 'processors'], []);
  return processors.includes('accumulator') || processors.includes(ACCUMULATE);
}
export const getFrequencyEndDate = ({
  date,
  frequency
}) => {
  const day = I18n.moment(date);
  switch (frequency) {
    case Frequency.DAY:
      {
        return day.add(1, 'days').format(DATE_FORMAT);
      }
    case Frequency.WEEK:
      {
        return day.add(1, 'weeks').format(DATE_FORMAT);
      }
    case Frequency.MONTH:
      {
        return day.add(1, 'months').format(DATE_FORMAT);
      }
    case Frequency.QUARTER:
      {
        return day.add(3, 'months').format(DATE_FORMAT);
      }
    case Frequency.YEAR:
      {
        return day.add(1, 'years').format(DATE_FORMAT);
      }
    default:
      return day.format(DATE_FORMAT);
  }
};

/**
 * Explode goalsByMonth to goals daily
 */
export const getDailyGoals = ({
  goalsByMonth
}) => goalsByMonth.flatMap(goal => {
  const date = I18n.moment(goal.get('date'));
  const daysInMonth = date.daysInMonth();
  const value = goal.get('value');
  const partial = value / daysInMonth;
  const pad = number => `00${number}`.slice(-2);
  const toDate = day => I18n.moment(date.format(`YYYY-MM-${pad(day)}`)).format(DATE_FORMAT);
  return Range(1, daysInMonth + 1).map(toDate).map(day => ImmutableMap({
    date: day,
    value: partial
  }));
});
export const padBuckets = ({
  goalsByMonth = List(),
  startDate,
  endDate
}) => {
  const goalsKeyedByMonth = goalsByMonth.toMap().mapKeys((k, v) => v.get('date'));
  const result = [];
  const date = I18n.moment(startDate);
  while (!date.isAfter(endDate)) {
    const key = date.format(DATE_FORMAT);
    const value = goalsKeyedByMonth.getIn([key, 'value'], 0);
    result.push(ImmutableMap({
      date: key,
      value
    }));
    date.add(1, 'months');
  }
  return List(result);
};

/**
 * Gives back a list of Map({ date, value })s that
 * correspond to the list of dates provided
 */
export const rebucket = ({
  dailyGoals,
  dates
}) => {
  const result = [];
  let goalIndex = 0;
  let dataIndex = 0;
  const getGoalDate = index => I18n.moment(dailyGoals.getIn([index, 'date']));
  const getGoalValue = index => dailyGoals.getIn([index, 'value']);
  const getDataDate = index => I18n.moment(dates.get(index));
  if (dailyGoals.count() > 0) {
    const firstDataDate = getDataDate(dataIndex);
    // if the goals start before our data
    // skip goals until we get to the start of the data
    while (getGoalDate(goalIndex).isBefore(firstDataDate) && goalIndex < dailyGoals.count()) {
      goalIndex++;
    }
    while (dataIndex < dates.count() - 1) {
      const startDate = getDataDate(dataIndex);
      const endDate = getDataDate(dataIndex + 1);
      let sum = 0;
      if (getGoalDate(goalIndex).isBefore(endDate)) {
        while (goalIndex < dailyGoals.count() && !getGoalDate(goalIndex).isSame(endDate)) {
          sum += getGoalValue(goalIndex);
          goalIndex++;
        }
      }
      result.push(ImmutableMap({
        date: startDate.format(DATE_FORMAT),
        value: sum
      }));
      dataIndex++;
    }
  }
  return List(result);
};