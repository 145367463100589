'use es6';

import get from 'transmute/get';
import has from 'transmute/has';
import set from 'transmute/set';
import setIn from 'transmute/setIn';
import getIn from 'transmute/getIn';
import { AssetPermission } from '../permission/asset-permission';
import { defaultTo, stringIfPresent } from '../../private/js-util';

// TODO use report utilities from https://git.hubteam.com/HubSpot/reporting/blob/master/reporting-data/static-1.57619/js/report/reportGetters.ts instead
export const getName = get('name');
export const setName = set('name');
export const getId = get('id');
export const getOwnerId = get('ownerId');
export const getOwnerIdStr = report => stringIfPresent(getOwnerId(report));
export const getCreatedBy = get('createdBy');
export const getCreatedByStr = report => stringIfPresent(getCreatedBy(report));
export const getUpdatedBy = get('updatedBy');
export const getUpdatedByStr = report => stringIfPresent(getUpdatedBy(report));
export const getReportDefinition = get('reportDefinition');
export const hasReportDefinition = has('reportDefinition');
export const getConfigCustomized = getIn(['config', 'customized']);
export const getCustomized = get('customized');
export const getDataType = getIn(['config', 'dataType']);
export const getObjectTypeId = getIn(['config', 'objectTypeId']);
export const setLimit = setIn(['config', 'limit']);
export const getChartType = get('chartType');
export const getVisualType = getIn(['reportDefinition', 'visual', 'type']);
export const getCrossObjectDataTypes = getIn(['config', 'crossObject', 'dataTypes']);
export const getDashboardId = get('dashboardId');
export const getTemplateName = report => report.getIn(['template']) || report.getIn(['config', 'template']);
export const getBusinessUnit = get('businessUnitId');
export const getSubAppKey = getIn(['displayParams', 'salesAnalytics', 'subAppKey']);

/**
 * Warning: 'userReportPermissionLevel` is hydrated only on `/report(s)` endpoints.
 * GET `/dashboard` for example does not hydrate the `'userReportPermissionLevel`.
 */
export const getUserReportPermissionLevel = report => defaultTo(AssetPermission.COMPLETE, report.get('userReportPermissionLevel'));
export const getReportDashboardInfo = report => report.get('reportDashboardInfo') || [];
export const getRestrictedFieldLevelProperties = report => report.get('hiddenProperties') || [];