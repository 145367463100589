import { aiReportSearchReducer, aiReportSearchNamespace } from './aiReportSearch';
import { savedReportSearchReducer, savedReportSearchNamespace } from './savedReportSearch';
import { aiSettingsReducer, aiSettingsNamespace } from '../ai-settings/aiSettings';
import { combineReducers } from 'redux';
import { reportPanelUiReducer, reportPanelUiNamespace } from './ui';
export const reportPanelNamespace = 'reportPanel';
export const reportPanelReducer = combineReducers({
  [aiSettingsNamespace]: aiSettingsReducer,
  [reportPanelUiNamespace]: reportPanelUiReducer,
  [aiReportSearchNamespace]: aiReportSearchReducer,
  [savedReportSearchNamespace]: savedReportSearchReducer
  // TODO:
  //[readyMadeReportSearchNamespace]: readyMadeReportSearchReducer,
});