'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import { SEARCH } from '../../constants/configTypes';
import { normalizeDatasetEmptiness } from '../../lib/normalizeEmptiness';
import { shouldBeSmallScale } from '../../lib/smallScale';
import { fromMetricKey, METRIC_DELIMITER } from './datasetMetrics';
import { Dataset } from './datasetRecords';
import { legacyEnhanceColumnDataset } from './enhanceColumns';
import { upgradeData } from './upgradeData';
import { upgradeLabels } from './upgradeLabels';
import { upgradeProperty } from './upgradeProperties';
import { formatForScientificNotation } from './utils';
const cleanupSearchDataset = dataset => dataset.update('properties', (properties = ImmutableMap()) => {
  // https://github.com/immutable-js/immutable-js/issues/1364
  return properties.reduce((acc, value, key) => {
    if (acc.has(fromMetricKey(key).property)) {
      return acc;
    }
    return acc.set(fromMetricKey(key).property, value);
  }, ImmutableMap());
}).update('data', (data = List()) => data.map(row => row.filter(value => !(List.isList(value) && value.isEmpty())).mapKeys(key => fromMetricKey(key).property))).update(normalizeDatasetEmptiness);
export const fixScientificNotation = (data, properties) => {
  return data.map(row => row.map((value, key) => {
    const propertyType = properties.getIn([key, 'type']);
    return propertyType === 'number' || propertyType === 'id' ? formatForScientificNotation(value) : value;
  }));
};
export const fromDataset = (config, oldDataset, propertiesByDataType = ImmutableMap(), {
  comparePropertyLabel,
  isSummary = false
} = {}) => {
  const isSearch = config.get('configType') === SEARCH;
  const searchDimension = isSearch && oldDataset.getIn(['dimension', 'property']);
  config = isSearch ? config.set('dimensions', List([searchDimension])).update('metrics', (metrics = List()) => metrics.map(m => m.set('metricTypes', List.of('COUNT')))) : config;
  const dataType = config.get('dataType');
  const currentDataTypeProperties = propertiesByDataType.get(dataType) || ImmutableMap();
  const properties = upgradeLabels(config, oldDataset, isSummary).map((propertyData, propertyOrMetric) => {
    const {
      property
    } = fromMetricKey(propertyOrMetric);
    const propertyInfo = upgradeProperty(currentDataTypeProperties.get(property), config);
    return propertyInfo ? propertyInfo.merge(propertyData) : propertyData;
  });
  const data = fixScientificNotation(upgradeData(oldDataset), properties);
  const paginationSize = oldDataset.getIn(['total', 'raw']);
  const dataAge = oldDataset.get('dataAge');
  let dataset = Dataset({
    data,
    properties,
    paginationSize,
    dataAge
  });
  if (isSearch) {
    dataset = cleanupSearchDataset(dataset).set('searchDimension', searchDimension);
  }
  const smallScaleMetrics = shouldBeSmallScale(dataset, List(dataset.get('properties').keys()).filter(key => key.includes(METRIC_DELIMITER)));
  dataset = dataset.update('properties', props => {
    return props.map((property, propertyName) => smallScaleMetrics.get(propertyName) ? property.set('shouldBeSmallScale', true) : property);
  });
  const comparisonLabelPath = ['properties', config.getIn(['dimensions', 0]), 'label'];
  if (comparePropertyLabel && dataset.hasIn(comparisonLabelPath)) {
    dataset = dataset.setIn(comparisonLabelPath, comparePropertyLabel);
  }
  return legacyEnhanceColumnDataset(dataset, config);
};