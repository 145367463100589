import * as FrequencyTypes from 'reporting-data/constants/frequency';
import * as checked from 'reporting-data/lib/checked';
import { DataLabelFormats, LegendPositions, LegendWidth
// @ts-expect-error migrate upstream file
} from 'reporting-visualizations/visualizations/commonRecords';
import { AxisTypes } from 'reporting-visualizations/visualizations/xy/xyRecords';
import { checkedPaletteIds } from '../visualize/color/palettes';
export const showBubbleLegend = checked.boolean().defaultValue(false);
export const showDataLabels = checked.boolean().defaultValue(true);
export const showRecordIds = checked.boolean().defaultValue(true);
export const showMarkers = checked.boolean().defaultValue(true);
export const stacking = checked.boolean().defaultValue(true);
export const stackingType = checked.string().defaultValue('');
export const showToday = checked.boolean().defaultValue(false);
export const legendPosition = checked.symbol(LegendPositions, 'LegendPositions').optional().defaultValue(LegendPositions.TOP);
// legendWidth is not being used yet but reporting-viz is setup to handle it if needed later
export const legendWidth = LegendWidth.optional().defaultValue(undefined);
export const goalLineValue = checked.number().optional().defaultValue(undefined);
export const goalFrequency = checked.symbol(FrequencyTypes, 'Frequency').optional().defaultValue(FrequencyTypes.MONTH);
export const ColorOption = checked.record({
  paletteId: checkedPaletteIds.optional(),
  brandKitId: checked.string().optional(),
  assignments: checked.map().defaultValue({}) // Map<String, String>
}, 'ColorOption');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ColorOptionTypeCheck = ColorOption;
export const Scale = checked.record({
  min: checked.number().optional(),
  max: checked.number().optional()
}, 'Scale');
export const AxisScaleType = checked.symbol({
  [AxisTypes.linear]: AxisTypes.linear,
  [AxisTypes.logarithmic]: AxisTypes.logarithmic
}, 'AxisScaleType').defaultValue(AxisTypes.linear);
export const opacity = checked.number().optional();
export const dataLabelFormat = checked.symbol(DataLabelFormats, 'DataLabelFormats').optional().defaultValue(undefined);