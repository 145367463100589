'use es6';

import { ANALYTICS_FUNNEL } from '../constants/dataTypes';
import rethrow from '../lib/rethrow';
import { load } from '../dataTypeDefinitions';
import getProperties from '../properties';
import * as http from '../request/http';
import { summarize } from '../dataset/summarize';
export
// NOTE: Check that module has been registered, otherwise log error
const retrieve = (dataConfig, debug, runtimeOptions = {}) => {
  const {
    batchRequests = false
  } = runtimeOptions;
  const dataType = dataConfig.get('dataType');
  return load(dataType).then(module => {
    const api = module.get('use');
    if (dataType === ANALYTICS_FUNNEL) {
      return api(dataConfig).then(dataset => ({
        dataConfig,
        dataset,
        response: undefined
      }));
    }
    return getProperties(dataType).then(properties => api(dataConfig, properties, runtimeOptions)).then(request => {
      debug('request', request);
      return http.retrieve(request, {
        batchRequests
      }).catch(err => module.get('handleError') ? module.get('handleError')(dataConfig, err) : rethrow(err)).then(response => {
        debug('response', response);
        const transformed = request.transformer ? request.transformer(dataConfig, response) : response;
        return Promise.resolve(transformed).then(dataset => ({
          response,
          dataset: summarize(dataset)
        }));
      });
    }).then(({
      dataset,
      response
    }) => {
      return {
        dataConfig,
        dataset,
        response
      };
    }).catch(rethrow);
  });
};
export const match = () => true;

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = module.exports;
}