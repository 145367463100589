'use es6';

import { Map as ImmutableMap } from 'immutable';
import I18n from 'I18n';
import unescapedText from 'I18n/utils/unescapedText';
import { CROSS_OBJECT } from '../../../../constants/dataTypes';
import { Promise } from '../../../../lib/promise';
import cached from '../../../../lib/cached';
import sortInboundDataTypesByEnum from '../../../../lib/sortInboundDataTypesByEnum';
import getProperties from '../../../../properties';
import { namespaceProperty, getObjectId } from '../../../../properties/namespaceProperty';
const getNamespacedProperties = (dataType, properties) => {
  return properties.mapEntries(([name, value]) => {
    const namespaced = namespaceProperty(dataType, name);
    return [namespaced, value.set('name', namespaced).update('label', label => unescapedText('reporting-data.properties.common.namespacedProperty', {
      propertyLabel: label || namespaced,
      dataTypeLabel: unescapedText(`reporting-data.dataTypes.${dataType}`)
    }))];
  });
};
const getJoinedProperties = (dataTypes, properties) => {
  return dataTypes.map((dataType, index) => getNamespacedProperties(dataType, properties[index].get(dataType))).reduce((allProperties, namespacedProperties) => allProperties.merge(namespacedProperties), ImmutableMap());
};
const getObjectIdProperties = dataTypes => {
  const [firstDataType, secondDataType] = sortInboundDataTypesByEnum(dataTypes);
  return [{
    name: 'firstObjectId',
    dataType: firstDataType
  }, {
    name: 'secondObjectId',
    dataType: secondDataType
  }, ...dataTypes.map(dataType => ({
    name: getObjectId(dataType),
    dataType
  }))].reduce((result, {
    name,
    dataType
  }) => result.set(name, ImmutableMap({
    name,
    label: unescapedText(`reporting-data.dataTypes.${dataType}`),
    type: 'number',
    hidden: true
  })), ImmutableMap());
};
const getCountProperty = () => ImmutableMap({
  count: ImmutableMap({
    key: 'count',
    label: I18n.text('reporting-data.properties.common.count', {
      object: I18n.text(`reporting-data.dataTypes.${CROSS_OBJECT}`)
    }),
    type: 'number'
  })
});
const getTotalProperty = () => ImmutableMap({
  total: ImmutableMap({
    key: 'total',
    label: I18n.text('reporting-data.properties.common.total'),
    type: 'number'
  })
});
const getCrossObjectProperties = dataTypes => Promise.all(dataTypes.map(dataType => getProperties(dataType)).toArray()).then(properties => {
  const joinedProperties = getJoinedProperties(dataTypes, properties).merge(getObjectIdProperties(dataTypes)).merge(getCountProperty()).merge(getTotalProperty());
  return ImmutableMap({
    [CROSS_OBJECT]: joinedProperties
  });
});
export default cached('default', config => {
  const dataTypes = config.getIn(['crossObject', 'dataTypes']);
  return getCrossObjectProperties(dataTypes);
});