import { gate } from 'hub-http/gates';
export const Gates = {
  AI_GENERATION_FLOW: 'RL:AIGenerationFlow',
  REPORT_RECOMMENDATIONS: 'RL:ReportRecommendations',
  AI_MODIFY_REPORT: 'RE:AIModifyReport',
  REPORT_SETUP: 'Reporting:ReportSetup',
  REPORT_LIBRARY_RECOMMENDATIONS: gate('RH:ReportLibraryRecommendations'),
  SERVICE_HUB_IN_JOURNEYS: gate('MR:ServiceHubEvents')
};
export const Scopes = {
  CONTACT_CREATE_DATE_ATTRIBUTION: 'contact-create-date-attribution-read',
  DEAL_CREATE_ATTRIBUTION: 'deal-create-attribution-read',
  CONTACT_JOURNEYS: 'journey-analytics-read',
  DEAL_JOURNEYS: 'deal-journey-analytics-read',
  SERVICE_JOURNEYS: 'service-journey-analytics-read',
  REPORTING_ACCESS: 'reporting-access',
  REPORT_BUILDERS_ACCESS: 'custom-reporting-access',
  REVENUE_ANALYTICS: 'revenue-analytics',
  REVENUE_ATTRIBUTION: 'revenue-attribution-read'
};
export const ProductLevel = {
  NONE: 'none',
  FREE: 'free',
  STARTER: 'starter',
  BASIC: 'basic',
  PROFESSIONAL: 'pro',
  ENTERPRISE: 'ent'
};
export const TrialProductLevel = {
  NONE: 'none',
  PROFESSIONAL: 'pro',
  ENTERPRISE: 'ent'
};
export const SUPER_ADMIN = 'super-admin';