'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { SUM, MIN, MAX, AVG } from '../../constants/metricTypes';
const COUNT = 'count';
const sum = (metrics, property) => metrics.reduce((processed, metric) => processed + metric.getIn([property, SUM], 0), 0);
const min = (metrics, property) => metrics.reduce((processed, metric) => Math.min(processed, metric.getIn([property, MIN], Infinity)), Infinity);
const max = (metrics, property) => metrics.reduce((processed, metric) => Math.max(processed, metric.getIn([property, MAX], 0)), 0);
const avg = (metrics, property) => metrics.reduce((processed, metric) => processed + metric.getIn([property, AVG]) * metric.getIn([COUNT, SUM], 0), 0) / sum(metrics, COUNT);
const processors = {
  [SUM]: sum,
  [MIN]: min,
  [MAX]: max,
  [AVG]: avg
};
export default (groupBy => (config, dataset) => dataset.updateIn(['dimension', 'buckets'], buckets => buckets.reduce((grouped, bucket) => grouped.update(groupBy(bucket), (group = ImmutableMap({
  key: groupBy(bucket)
})) => bucket.has('dimension') ?
// group subaggregations
group.update('dimension', dimension => dimension ? dimension.update('buckets', inner => inner.concat(bucket.getIn(['dimension', 'buckets']))) : bucket.get('dimension')) :
// group aggregations
group.update('metrics', (metrics = List()) => metrics.push(bucket.get('metrics')))), ImmutableMap()).map(bucket => bucket.has('dimension') ?
// group subaggregations
bucket.updateIn(['dimension', 'buckets'], points => points.reduce((memo, point) => memo.update(point.get('key'), (grouped = List()) => grouped.push(point.get('metrics'))), ImmutableMap()).map(point => config.get('metrics').map(metric => metric.toJS()).reduce((processed, {
  property,
  metricTypes
}) => metricTypes.reduce((memo, metricType) => ({}).hasOwnProperty.call(processors, metricType) ? memo.setIn([property, metricType], processors[metricType](point, property)) : memo, processed), ImmutableMap())).reduce((memo, point, key) => memo.push(ImmutableMap({
  key,
  metrics: point
})), List())) :
// group aggregations
bucket.update('metrics', grouped => config.get('metrics').map(metric => metric.toJS()).reduce((processed, {
  property,
  metricTypes
}) => metricTypes.reduce((memo, metricType) => ({}).hasOwnProperty.call(processors, metricType) ? memo.setIn([property, metricType], processors[metricType](grouped, property)) : memo, processed), ImmutableMap()))).toList()));