import { Map as ImmutableMap } from 'immutable';
import { COLUMN_ROLES } from '../constants/relationalReports';
export const getDimensionsFromRelationalReport = report => report.getIn(['reportDefinition', 'columns']).filter(columnMetaData => columnMetaData.get('role') === COLUMN_ROLES.DIMENSION).map(columnMetaData => columnMetaData.getIn(['field', 'name'])).toList();
export const getMetricPropertyAndAggregationFromRelationalReport = report => report.getIn(['reportDefinition', 'columns']).filter(columnMetaData => columnMetaData.get('role') === COLUMN_ROLES.MEASURE).map(columnMetaData => ImmutableMap({
  property: columnMetaData.getIn(['field', 'name']),
  aggregation: columnMetaData.get('aggregation')
})).toList();
export function getReportDefinitionTable(report) {
  return report.getIn(['reportDefinition', 'table']);
}
export function getRelationalReportTableName(report) {
  return getReportDefinitionTable(report).get('name');
}
export function getRelationalReportDatasetId(report) {
  const tableName = getRelationalReportTableName(report);
  if (tableName !== '__DATASET__') {
    return undefined;
  }
  return getReportDefinitionTable(report).get('datasetId');
}
export const getRelationalReportColorOptions = report => {
  return report.getIn(['reportDefinition', 'visual', 'options', 'color']);
};
export const getRelationalReportY1ColorOptions = report => {
  return report.getIn(['reportDefinition', 'visual', 'options', 'y1Color']);
};
export const getRelationalReportY2ColorOptions = report => {
  return report.getIn(['reportDefinition', 'visual', 'options', 'y2Color']);
};
export const getRelationalReportVisualType = report => {
  return report.getIn(['reportDefinition', 'visual', 'type']);
};
export const getFilteringFromRelationalReport = report => report.getIn(['reportDefinition', 'filtering']);