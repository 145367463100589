import { useQuery } from 'data-fetching-client';
import { GET_USED_IN_EMBED_DASHBOARDS } from '../embed-dashboard/embed-dashboard-dao';
import { GET_SHARE_ASSET_SCHEDULE } from '../emails/emails';
import { EMBED_DASHBOARD_APP_CONFIG } from '../embed-dashboard/embed-dashboard-ids';
const USED_IN_DASHBOARD_TYPES = {
  EMBEDDED_DASHBOARD: 'EMBEDDED_DASHBOARD',
  RECURRING_EMAIL: 'RECURRING_EMAIL'
};
export const isUsedInEmbedDashboardInfo = info => info.type === USED_IN_DASHBOARD_TYPES.EMBEDDED_DASHBOARD;
export const isUsedInRecurringEmail = info => info.type === USED_IN_DASHBOARD_TYPES.RECURRING_EMAIL;
export const useGetDashboardUsedIn = dashboardId => {
  const {
    loading: isLoadingEmbedDashboards,
    data: embedDashboardsData
  } = useQuery(GET_USED_IN_EMBED_DASHBOARDS, {
    variables: {
      dashboardId
    }
  });
  const {
    loading: isLoadingRecurringEmail,
    data: recurringEmailData
  } = useQuery(GET_SHARE_ASSET_SCHEDULE, {
    variables: {
      objectId: dashboardId,
      objectType: 'DASHBOARD',
      deliveryType: 'EMAIL'
    }
  });
  const embeddedAppIds = (embedDashboardsData === null || embedDashboardsData === void 0 ? void 0 : embedDashboardsData.embeddedAppIds) || [];
  const recurringEmails = (recurringEmailData === null || recurringEmailData === void 0 ? void 0 : recurringEmailData.emailSchedules) || [];
  const usedInEmbedDashboards = embeddedAppIds.map(embedDashboardAppId => {
    const config = EMBED_DASHBOARD_APP_CONFIG[embedDashboardAppId];
    return {
      type: USED_IN_DASHBOARD_TYPES.EMBEDDED_DASHBOARD,
      data: {
        embedDashboardAppId,
        link: config.getAppLink(),
        name: config.getName(),
        location: config.location
      }
    };
  });
  const usedInRecurringEmails = recurringEmails.map(recurringEmail => {
    return {
      type: USED_IN_DASHBOARD_TYPES.RECURRING_EMAIL,
      data: recurringEmail
    };
  });
  const usedInData = [...usedInEmbedDashboards, ...usedInRecurringEmails];
  const isLoading = isLoadingEmbedDashboards || isLoadingRecurringEmail;
  return {
    isLoading,
    usedInData
  };
};