import usePollingQuery from './usePollingQuery';
import { GET_CONNECTED_EMAIL_SENDING_DOMAINS } from 'dashboard-lib/public/emails/emails';
export const useDomains = () => {
  const {
    data,
    error,
    loading,
    refetch
  } = usePollingQuery(GET_CONNECTED_EMAIL_SENDING_DOMAINS);
  return {
    data: data ? data.connectedEmailSendingDomains : [],
    error,
    loading,
    refetch
  };
};