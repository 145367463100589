'use es6';

import { Map as ImmutableMap } from 'immutable';
import { FUNNEL, PIPELINE } from '../../../../constants/configTypes';
import { PERCENTILES, SUM } from '../../../../constants/metricTypes';
import { summarize } from '../../../../dataset/summarize';
import * as conversions from './conversions';
const addPipelineConversion = data => {
  const totalCount = data.getIn(['dimension', 'buckets', 0, 'metrics', 'count', SUM], 0) + data.getIn(['dimension', 'buckets', 0, 'metrics', 'pipeline.skips', SUM], 0);
  if (totalCount === 0) {
    return data;
  }
  return data.updateIn(['dimension', 'buckets'], buckets => buckets.map((bucket, index, entire) => {
    const conversionPath = ['metrics', 'pipeline.cumulativeConversion', PERCENTILES];
    if (index + 1 >= entire.size) {
      return bucket;
    }
    const counts = buckets.getIn([index + 1, 'metrics', 'count', SUM], 0) || 0;
    const skips = buckets.getIn([index + 1, 'metrics', 'pipeline.skips', SUM], 0) || 0;
    const cumulativeConversion = (counts + skips) / totalCount;
    return bucket.setIn(conversionPath, cumulativeConversion);
  }));
};
export default (stages => (config, response) => {
  const configType = config.get('configType');
  const dimension = config.getIn(['dimensions', 0]);
  const stageCounts = response.take(stages.size);
  const totalRemainingCount = response.skip(stages.size);
  const data = summarize(ImmutableMap({
    dimension: ImmutableMap({
      property: dimension,
      buckets: stageCounts.map((breakdown, index) => {
        const skips = configType === FUNNEL ? {} : {
          'pipeline.skips': ImmutableMap({
            SUM: totalRemainingCount.getIn([index, 'count']) - breakdown.get('count')
          })
        };
        return ImmutableMap({
          key: stages.get(index),
          metrics: ImmutableMap(Object.assign({
            count: ImmutableMap({
              SUM: breakdown.get('count')
            })
          }, skips))
        });
      })
    }),
    total: response.size
  }));
  return configType === PIPELINE ? addPipelineConversion(data) : conversions.calculate(data);
});