'use es6';

import { createAction } from './action';
export const OPEN_DASHBOARD_APP = createAction({
  key: 'OPEN_DASHBOARD_APP'
});
export const VIEW_DASHBOARD = createAction({
  key: 'VIEW_DASHBOARD'
});
export const VIEW_DASHBOARD_DETAILS = createAction({
  key: 'VIEW_DASHBOARD_DETAILS'
});
export const USE_DASHBOARD_FILTERS = createAction({
  key: 'USE_DASHBOARD_FILTERS'
});
export const ADD_REPORT = createAction({
  key: 'ADD_REPORT'
});
export const REPORT_CREATE_CUSTOM_REPORT = createAction({
  key: 'REPORT_CREATE_CUSTOM_REPORT'
});
export const REPORT_CREATE_STANDARD_REPORT = createAction({
  key: 'REPORT_CREATE_STANDARD_REPORT'
});
export const REPORT_MOVE = createAction({
  key: 'REPORT_MOVE'
});
export const DASHBOARD_REMOVE_REPORT = createAction({
  key: 'DASHBOARD_REMOVE_REPORT'
});
export const REARRANGE_WIDGETS = createAction({
  key: 'REARRANGE_WIDGETS'
});
export const RESIZE_WIDGETS = createAction({
  key: 'RESIZE_WIDGETS'
});
export const REPORT_GO_TO_CRM = createAction({
  key: 'REPORT_GO_TO_CRM'
});
export const VIEW_REPORT = createAction({
  key: 'VIEW_REPORT'
});
export const VIEW_REPORT_DATA = createAction({
  key: 'VIEW_REPORT_DATA'
});
export const VIEW_DASHBOARD_NAME = createAction({
  key: 'VIEW_DASHBOARD_NAME'
});
export const DASHBOARD_RENAME = createAction({
  key: 'DASHBOARD_RENAME'
});
export const DASHBOARD_DELETE = createAction({
  key: 'DASHBOARD_DELETE'
});
export const DASHBOARD_SET_PERMISSION = createAction({
  key: 'DASHBOARD_SET_PERMISSION'
});
export const DASHBOARD_SET_OWNER = createAction({
  key: 'DASHBOARD_SET_OWNER'
});
export const DASHBOARD_CREATE = createAction({
  key: 'DASHBOARD_CREATE'
});
export const DASHBOARD_CREATE_FROM_TEMPLATE = createAction({
  key: 'DASHBOARD_CREATE_FROM_TEMPLATE'
});
export const DASHBOARD_CLONE = createAction({
  key: 'DASHBOARD_CLONE'
});
export const SINGLE_REPORT_VIEWER = createAction({
  key: 'SINGLE_REPORT_VIEWER'
});
export const VIEW_ACTIVITY_LOG = createAction({
  key: 'VIEW_ACTIVITY_LOG'
});
export const SET_BUSINESS_UNIT = createAction({
  key: 'SET_BUSINESS_UNIT'
});