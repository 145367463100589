'use es6';

import { Map as ImmutableMap } from 'immutable';
import PortalIdParser from 'PortalIdParser';
import { COMPANY, CONTACT, CONVERSATION_INBOX, CTA, DEAL, ENGAGEMENT, FORM, LINE_ITEM, TASK, TICKET, WORKFLOW } from 'reference-resolvers/constants/ReferenceObjectTypes';
import * as DataTypes from '../constants/dataTypes';
import { CONVERSATIONS } from '../constants/dataTypes';
function getDealUrl(dealId) {
  return `/contacts/${PortalIdParser.get()}/deal/${dealId}/`;
}
function getCompanyUrl(companyId) {
  return `/contacts/${PortalIdParser.get()}/company/${companyId}/`;
}
function getContactUrl(contactId) {
  return `/contacts/${PortalIdParser.get()}/contact/${contactId}/`;
}
function getWorkflowUrl(workflowId) {
  return `/contacts/${PortalIdParser.get()}/automation/flow/${workflowId}/edit/`;
}
function getTaskUrl(taskId) {
  return `/contacts/${PortalIdParser.get()}/tasks/list/view/all/?taskId=${taskId}`;
}
function getTicketUrl(ticketId) {
  return `/contacts/${PortalIdParser.get()}/ticket/${ticketId}/`;
}
function getBroadcastDetailsUrl(broadcastGuid) {
  return `/social/${PortalIdParser.get()}/publishing/view/${broadcastGuid}?source=report-drilldown`;
}
function getCampaignUrl(campaignId) {
  return `/campaigns/${PortalIdParser.get()}/${campaignId}`;
}
function getConversationsLink(id) {
  return `/live-messages/${PortalIdParser.get()}?cvObjectId=${id}`;
}
function getConversationsThreadLink(id) {
  return `/live-messages/${PortalIdParser.get()}/inbox/${id}`;
}
function getCtaUrl(ctaGuid) {
  return `/ctas/${PortalIdParser.get()}/details/${ctaGuid}/`;
}
function getFormUrl(formGuid) {
  return `/forms/${PortalIdParser.get()}/${formGuid}/performance`;
}
const URL_LOOKUP = new ImmutableMap({
  [COMPANY]: getCompanyUrl,
  [CONTACT]: getContactUrl,
  [CONVERSATIONS]: getConversationsLink,
  [CONVERSATION_INBOX]: getConversationsThreadLink,
  [CTA]: getCtaUrl,
  [DEAL]: getDealUrl,
  [FORM]: getFormUrl,
  [TASK]: getTaskUrl,
  [WORKFLOW]: getWorkflowUrl,
  [TICKET]: getTicketUrl,
  [DataTypes.ATTRIBUTION_TOUCH_POINTS]: getCampaignUrl,
  [DataTypes.CONTACT_CREATE_ATTRIBUTION]: getCampaignUrl,
  [DataTypes.SOCIAL_POSTS]: getBroadcastDetailsUrl
});
export const getReferenceType = dataType => {
  return {
    [DataTypes.CONTACTS]: CONTACT,
    [DataTypes.COMPANIES]: COMPANY,
    [DataTypes.DEALS]: DEAL,
    [DataTypes.ENGAGEMENT]: ENGAGEMENT,
    [DataTypes.ENGAGEMENTS]: ENGAGEMENT,
    [DataTypes.TICKETS]: TICKET,
    [DataTypes.LINE_ITEMS]: LINE_ITEM,
    [DataTypes.SOCIAL_POSTS]: DataTypes.SOCIAL_POSTS // Does not exist in ReferenceObjectTypes
  }[dataType];
};
export const getProductLink = (referenceType, id) => {
  const productLink = URL_LOOKUP.get(referenceType);
  return productLink && id && id !== 'null' ? productLink(id) : null;
};
export const getProductLinkFromDataType = (dataType, id) => getProductLink(getReferenceType(dataType), id);