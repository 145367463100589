'use es6';

import * as DataTypes from '../constants/dataTypes';

// https://git.hubteam.com/HubSpot/IdentityBase/blob/master/src/main/protobuf/contacts.proto#L19
const inboundDataTypeEnums = {
  [DataTypes.CONTACTS]: 1,
  [DataTypes.COMPANIES]: 2,
  [DataTypes.DEALS]: 3,
  [DataTypes.ENGAGEMENTS]: 4,
  [DataTypes.TICKETS]: 5,
  [DataTypes.PRODUCTS]: 7,
  [DataTypes.LINE_ITEMS]: 8,
  [DataTypes.CONVERSATIONS]: 11,
  [DataTypes.QUOTAS]: 16,
  [DataTypes.FEEDBACK_SUBMISSIONS]: 19
};

// HACK we need to identify firstObjectId vs secondObjectId for cross-object
// TODO have back end add something like `primaryObjectId` and `secondaryObjectId`
// so we don't have to do this
const sortInboundDataTypesByEnum = dataTypes => {
  return dataTypes.sortBy(dataType => inboundDataTypeEnums[dataType]);
};
export default sortInboundDataTypesByEnum;