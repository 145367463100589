'use es6';

import getCustomerDataFiltersSpecialOptionsByReferenceType from 'customer-data-filters/components/getSpecialOptionsByReferenceType';
import FilterOperatorBoolInput from 'customer-data-filters/components/operator/FilterOperatorBoolInput';
import FilterOperatorDateInput from 'customer-data-filters/components/operator/FilterOperatorDateInput';
import FilterOperatorDurationInput from 'customer-data-filters/components/operator/FilterOperatorDurationInput';
import FilterOperatorElasticsearchTextQueryInput from 'customer-data-filters/components/operator/FilterOperatorElasticsearchTextQueryInput';
import FilterOperatorEnumInput from 'customer-data-filters/components/operator/FilterOperatorEnumInput';
import { getOperatorLabelTranslationKey } from 'customer-data-filters/components/operator/FilterOperatorLabelTranslations';
import FilterOperatorMultiStringInput from 'customer-data-filters/components/operator/FilterOperatorMultiStringInput';
import FilterOperatorNumberInput from 'customer-data-filters/components/operator/FilterOperatorNumberInput';
import FilterOperatorOwnerInput from 'customer-data-filters/components/operator/FilterOperatorOwnerInput';
import FilterOperatorPercentageInput from 'customer-data-filters/components/operator/FilterOperatorPercentageInput';
import FilterOperatorTeamInput from 'customer-data-filters/components/operator/FilterOperatorTeamInput';
import FilterOperatorTextInput from 'customer-data-filters/components/operator/FilterOperatorTextInput';
import { fromObjectSegFilterStrict } from 'customer-data-filters/converters/objectSeg/fromObjectSegFilter';
import { getObjectSegOperatorsForTypeStrict } from 'customer-data-filters/converters/objectSeg/ObjectSegTypeToOperator';
import * as DisplayTypes from 'customer-data-filters/filterQueryFormat/DisplayTypes';
import DSFieldRecord from 'customer-data-filters/filterQueryFormat/DSFieldRecord/DSFieldRecord';
import * as Operators from 'customer-data-filters/filterQueryFormat/operator/Operators';
import { NotWildCardEqual, WildCardEqual } from 'customer-data-filters/filterQueryFormat/operator/Operators';
import * as ExternalOptionTypes from 'customer-data-objects/property/ExternalOptionTypes';
import PropertyOptionRecord from 'customer-data-objects/property/PropertyOptionRecord';
import * as PropertyDisplay from 'customer-data-property-utils/PropertyDisplay';
import { DATE, DATE_TIME } from 'customer-data-objects/property/PropertyTypes';
import unescapedText from 'I18n/utils/unescapedText';
import { fromJS, Map as ImmutableMap, OrderedSet } from 'immutable';
import { EMAIL_SUBSCRIPTION, SITE_CONTENT, SURVEY_QUESTION } from 'reference-resolvers/constants/ReferenceObjectTypes';
import * as dataTypes from 'reporting-data/constants/dataTypes';
import { isSupportedCrmObject } from 'reporting-data/crmObjects/utils';
import { dataTypeSettings } from 'reporting-data/retrieve/inboundDb/common/dataTypes';
import { getReferenceType, TYPES } from '../../metadata/references/external/external-mapping';
import I18n from 'I18n';
import { List } from 'immutable';
import PropertyRecord from 'customer-data-objects/property/PropertyRecord';
import { MY_TEAM_ID, MY_USER_ID, CLOSEDWON_STAGES } from 'reporting-data/constants/magicTypes';
import DisplayValueTeam from 'customer-data-filters/components/display/DisplayValueTeam';
import { DataSourceTypes } from '../../schema/source-records';
import { hasObjectTypeId } from '../../utils/source-utils';
import * as ObjectTypes from '../../metadata/objectTypes';
import { getIsUngated } from '../../../shared/lib/utils';
import { isExternalOptionsField } from 'customer-data-filters/utilities/isExternalOptionsField';
import { PREFER_IS_EXTERNAL_OPTIONS_PROPERTY } from 'reporting-data/constants/gates';
import { isExternalOptionsProperty } from 'reference-resolvers-lite/utils/isExternalOptionsProperty';
const HIDDEN_OPERATORS = [Operators.After, Operators.Before, Operators.UpdatedAfter, Operators.UpdatedBefore];
const HIDDEN_EVENT_OPERATORS = [Operators.UpdatedInLastXDays, Operators.NotUpdatedInLastXDays, Operators.EverContained, Operators.EverContainedAll, Operators.EverContainedAny, Operators.NeverContained, Operators.NeverContainedAll, Operators.EverEqual, Operators.EverEqualAny, Operators.NeverEqual, Operators.NeverEqualAny];
const HIDDEN_OPTIONS = [{
  dataSourceId: '0-4',
  property: 'hs_engagement_type',
  value: 'PUBLISHING_TASK'
}];
/**
 * For properties with an unexpected configuration and requires us to hardcode the input component to enter values.
 * Before adding an override, first try:
 * 1. Updating the property configuration on PUMA
 * 2. If applies widely across properties that meet a certain criteria, add a branch of logic to `getInputComponent` bellow
 */
const INPUT_OVERRIDES = fromJS({
  '0-4': {
    hs_attachment_ids: FilterOperatorMultiStringInput
  },
  '4-665536': {
    hs_email_campaign_id: FilterOperatorMultiStringInput
  },
  '4-666439': {
    hs_email_campaign_id: FilterOperatorMultiStringInput
  },
  '4-666288': {
    hs_email_campaign_id: FilterOperatorMultiStringInput
  },
  '4-666440': {
    hs_email_campaign_id: FilterOperatorMultiStringInput
  },
  '4-665836': {
    hs_email_campaign_id: FilterOperatorMultiStringInput
  },
  '4-667638': {
    hs_email_campaign_id: FilterOperatorMultiStringInput
  },
  '4-666289': {
    hs_email_campaign_id: FilterOperatorMultiStringInput
  },
  '4-699901': {
    hs_web_interactive_coordinates: FilterOperatorEnumInput
  },
  '4-1555805': {
    hs_web_interactive_coordinates: FilterOperatorEnumInput
  },
  '4-689780': {
    hs_web_interactive_coordinates: FilterOperatorEnumInput
  },
  '4-1555804': {
    hs_web_interactive_coordinates: FilterOperatorEnumInput
  }
});
const OBJECT_TYPE_ID_TO_DATA_TYPE = {
  '0-1': dataTypes.CONTACTS,
  '0-2': dataTypes.COMPANIES,
  '0-3': dataTypes.DEALS,
  '0-4': dataTypes.ENGAGEMENTS,
  '0-5': dataTypes.TICKETS,
  '0-8': dataTypes.LINE_ITEMS,
  '0-11': dataTypes.CONVERSATIONS,
  '0-145': dataTypes.PARTNER_CLIENT
};

/**
 * We need this to force-allow conversation properties because the customer-data-filters `isPropertySupportedByExternalOptions` does not currently return true for those properties.
 * Context:
 * https://hubspot.slack.com/archives/C6R8K2BB5/p1649189355466899
 * https://hubspot.slack.com/archives/C6R8K2BB5/p1649863143179279
 */
export const FORCE_SUPPORT_FOR_EXTERNAL_OPTIONS = fromJS({
  [ObjectTypes.CONVERSATION]: {
    hs_conversation_channel: 'hs_conversation_channel'
  }
});
const dataTypeFilterFamilies = ImmutableMap(dataTypeSettings).map(value => value.filterFamily);
const filterFamilyDataTypes = dataTypeFilterFamilies.flip();
function hasObjectIdResolver(propertyName, objectTypeId) {
  return ['hs_object_id', 'hs_product_id', 'hs_ticket_id'].includes(propertyName) && TYPES.hasIn([objectTypeId, propertyName]);
}
export const SNOWFLAKE_FILTER_FAMILY = 'SNOWFLAKE';
export const isSupportedExternalOption = (objectTypeId, property, gates) => {
  const forceAllowedProperties = FORCE_SUPPORT_FOR_EXTERNAL_OPTIONS.get(objectTypeId);
  const forceSupportForExternalOption = forceAllowedProperties && forceAllowedProperties.get(property.name);
  const hubspotProperty = property.metaDefinition || property;
  const preferExternalOptionsProperty = gates !== undefined && gates.includes(PREFER_IS_EXTERNAL_OPTIONS_PROPERTY);
  return forceSupportForExternalOption || (preferExternalOptionsProperty ? isExternalOptionsProperty(hubspotProperty, objectTypeId) : isExternalOptionsField(hubspotProperty, objectTypeId));
};
export const canUseReferenceResolverLite = (source, property, gates) => {
  return hasObjectTypeId(source) && isSupportedExternalOption(source.objectTypeId, property, gates);
};

/**
 * Due to limitations in the customer-data-filters FilterOperatorTeamInput component,
 * we need to use the standard rather than the lite reference resolver for team properties.
 * Context: https://hubspot.slack.com/archives/CA3JB5WLT/p1715119033123479?thread_ts=1714580044.651239&cid=CA3JB5WLT
 */
export const canUseReferenceResolverLiteForFilterInput = property => {
  const hubspotProperty = property.metaDefinition || property;
  return !(['hs_assigned_team_id', 'hs_assigned_team_ids', 'hs_user_all_accessible_teams', 'hs_user_assigned_primary_team', 'hs_user_secondary_teams', 'hs_shared_team_ids', 'hubspot_team_id', 'hs_assignee_team_id', 'hs_all_accessible_team_ids'].includes(hubspotProperty.name) && hubspotProperty.externalOptionsReferenceType === ExternalOptionTypes.TEAM);
};
export const isOptionTypeSupportedForReferenceResolverLite = (source, property, gates) => {
  const {
    metaDefinition: {
      externalOptionsReferenceType
    } = {}
  } = property || {};
  return canUseReferenceResolverLite(source, property, gates) && !(['hubspot_team_id'].includes(property.name) && externalOptionsReferenceType === ExternalOptionTypes.TEAM);
};

/**
 * @param {SnowflakeProperty} snowflakeProperty
 * @param {SnowflakeTable?} source - optional
 * @returns {DSFieldRecord}
 */
export const toDSFieldRecord = (snowflakeProperty, source, gates) => {
  if (!source || !hasObjectTypeId(source)) {
    const property = snowflakeProperty.update('options', options => options ? options.map(PropertyOptionRecord) : options); // todo should we convert this from a SnowflakeProperty to an ImmutableMap?
    return DSFieldRecord(property);
  }
  const useReferenceResolverLite = canUseReferenceResolverLite(source, snowflakeProperty, gates);
  const propertyRecordFromMetaDefinition = useReferenceResolverLite && snowflakeProperty.metaDefinition ? PropertyRecord.fromJS(snowflakeProperty.metaDefinition) : undefined;
  const excludedOptionsForProperty = HIDDEN_OPTIONS.filter(option => option.dataSourceId === source.dataSourceId && option.property === snowflakeProperty.name).map(option => option.value);
  const fieldRecord = propertyRecordFromMetaDefinition ? DSFieldRecord(propertyRecordFromMetaDefinition) : DSFieldRecord(snowflakeProperty.update('options', options => {
    return options ? options.filter(option => !excludedOptionsForProperty.includes(option.value)).map(PropertyOptionRecord) : options;
  }));
  return fieldRecord.update('displayType', displayType => {
    if (useReferenceResolverLite) {
      return 'enumeration';
    }
    return hasObjectIdResolver(snowflakeProperty.name, source.objectTypeId) ? 'enumeration' : displayType;
  }).update('displayType', displayType => fieldRecord.numberDisplayHint === 'percentage' ? 'percent' : displayType);
};

/**
 * @param propertyFilter
 * @param {SnowflakeProperty} property
 * @param {SnowflakeTable} source
 * @returns {DSFilterOperator}
 */
export const toDSFilterOperator = (propertyFilter, property, source, userGates) => {
  const dsFieldRecord = toDSFieldRecord(property, source, userGates);
  return propertyFilter && propertyFilter.get('operation') ? fromObjectSegFilterStrict(propertyFilter.toJS(), () => dsFieldRecord, SNOWFLAKE_FILTER_FAMILY, gate => getIsUngated(gate, userGates), true) : undefined;
};
export const toObjectSegAny = operator => ({
  filterType: 'PROPERTY',
  property: operator.field.name,
  operation: undefined
});
export const getDataTypeFamily = dataType => isSupportedCrmObject(dataType) ? dataType : dataTypeFilterFamilies.get(dataType);
export const getFamilyDataType = filterFamily => isSupportedCrmObject(filterFamily) ? filterFamily : filterFamilyDataTypes.get(filterFamily);
export const getObjectTypeFamily = objectTypeId => {
  const isCustomObject = objectTypeId.startsWith('2-');
  return OBJECT_TYPE_ID_TO_DATA_TYPE[objectTypeId] ? getDataTypeFamily(OBJECT_TYPE_ID_TO_DATA_TYPE[objectTypeId]) : isCustomObject ? dataTypes.OBJECT_LIST : objectTypeId;
};
export const getPropertyFieldDefinitions = () => {
  return ImmutableMap({
    hubspot_team_id: {
      ValueComponent: DisplayValueTeam
    }
  });
};
export function buildSpecialOptionsByReferenceType(propertyType, userId) {
  const cdfSpecialOptions = getCustomerDataFiltersSpecialOptionsByReferenceType();
  const specialOptions = {
    USER: () => List.of(PropertyOptionRecord(propertyType === 'number' ? {
      value: String(userId),
      label: I18n.text('reporting-ui-components.specialFilterTokens.me')
    } : {
      value: MY_USER_ID,
      label: I18n.text('reporting-ui-components.specialFilterTokens.me'),
      description: I18n.text('reporting-ui-components.specialFilterTokens.meDescription'),
      icon: 'dynamicFilter'
    })),
    TEAM: () => List.of(PropertyOptionRecord({
      value: MY_TEAM_ID,
      label: I18n.text('reporting-ui-components.specialFilterTokens.myTeam')
    })),
    DEAL_PIPELINE_STAGE: () => List.of(PropertyOptionRecord({
      value: CLOSEDWON_STAGES,
      label: I18n.text('reporting-ui-components.specialFilterTokens.allClosedWon')
    })),
    [ExternalOptionTypes.OWNER]: cdfSpecialOptions[ExternalOptionTypes.OWNER],
    [ExternalOptionTypes.DEAL_STAGE]: cdfSpecialOptions[ExternalOptionTypes.DEAL_STAGE]
  };
  return referenceType => {
    const safeCreateOptions = createOptions => createOptions && typeof createOptions === 'function' ? createOptions() : List();
    const createReportingOptions = specialOptions[referenceType];
    if (createReportingOptions) {
      return List().concat(safeCreateOptions(createReportingOptions));
    }
    return undefined;
  };
}
export function getPlaceholder(operator) {
  return operator.field.placeholder || '';
}
export function getLabelString(field) {
  return PropertyDisplay.getPropertyLabel(field);
}
export function getOperatorLabel(Operator, field) {
  return Operator.getLabel ? Operator.getLabel(field) : unescapedText(getOperatorLabelTranslationKey(Operator, field));
}
function getHsObjectIdOperators() {
  return OrderedSet([Operators.In, Operators.NotIn, Operators.Equal, Operators.NotEqual, Operators.Known, Operators.NotKnown]);
}
export function getOperators(source, property, userGates) {
  const {
    dataSourceType
  } = source;
  const field = toDSFieldRecord(property, source, userGates);
  const filterFamily = (hasObjectTypeId(source) ? getObjectTypeFamily(source.objectTypeId) : source.dataSourceId) || SNOWFLAKE_FILTER_FAMILY;
  let operators = hasObjectTypeId(source) && hasObjectIdResolver(property.name, source.objectTypeId) ? getHsObjectIdOperators() : field.referencedObjectType ? getObjectSegOperatorsForTypeStrict(field.referencedObjectType, filterFamily, gate => getIsUngated(gate, userGates), true) : getObjectSegOperatorsForTypeStrict(field.type, filterFamily, gate => getIsUngated(gate, userGates), true);
  if (!operators && field.type) {
    operators = getObjectSegOperatorsForTypeStrict(field.type, filterFamily, gate => getIsUngated(gate, userGates), true);
  } else if (!operators) {
    operators = OrderedSet();
  }
  const shouldInsertRollingDateOperator = field.type === DATE || field.type === DATE_TIME;
  if (shouldInsertRollingDateOperator) {
    operators = OrderedSet.of(Operators.InRollingDateRange).union(operators);
  }
  const operatorsToHide = [DataSourceTypes.HUBSPOT_EVENT, DataSourceTypes.EVENTS_DIGEST].includes(dataSourceType) ? [...HIDDEN_OPERATORS, ...HIDDEN_EVENT_OPERATORS] : HIDDEN_OPERATORS;
  return operatorsToHide.reduce((visibleOperators, hiddenOperator) => visibleOperators.remove(hiddenOperator), operators);
}
export function getInputComponent(source, property, operator) {
  const {
    name
  } = property;
  const maybeOverriddenInput = hasObjectTypeId(source) && INPUT_OVERRIDES.getIn([source.objectTypeId, name]);
  if (maybeOverriddenInput) {
    return maybeOverriddenInput;
  }
  const referencedObjectType = getReferenceType(source, property);
  const field = operator.field;
  if (referencedObjectType === ExternalOptionTypes.TEAM) {
    return FilterOperatorTeamInput;
  }
  if (referencedObjectType === ExternalOptionTypes.OWNER) {
    return FilterOperatorOwnerInput;
  }
  if (referencedObjectType === ExternalOptionTypes.USER) {
    return FilterOperatorEnumInput;
  }
  const fieldType = field.displayType || field.type;
  if (field.type === 'number' && field.numberDisplayHint === 'duration') {
    return FilterOperatorDurationInput;
  }
  if (fieldType === DisplayTypes.StringDisplayType && [WildCardEqual, NotWildCardEqual].includes(operator.constructor)) {
    return FilterOperatorElasticsearchTextQueryInput;
  }

  // HACK: This is for a special case in ListSegClassic and should not be
  // relied upon to remain the same.
  if (fieldType === DisplayTypes.StringDisplayType && operator.constructor.isIterableField('value')) {
    return FilterOperatorMultiStringInput;
  }
  switch (fieldType) {
    case DisplayTypes.BooleanDisplayType:
      return FilterOperatorBoolInput;
    case DisplayTypes.DateDisplayType:
    case DisplayTypes.DatetimeDisplayType:
      return FilterOperatorDateInput;
    case DisplayTypes.EnumerationDisplayType:
    case SITE_CONTENT:
    case SURVEY_QUESTION:
      return FilterOperatorEnumInput;
    case EMAIL_SUBSCRIPTION:
      return FilterOperatorEnumInput;
    case DisplayTypes.NumberDisplayType:
      return FilterOperatorNumberInput;
    case DisplayTypes.PercentageDisplayType:
      return FilterOperatorPercentageInput;
    default:
      return FilterOperatorTextInput;
  }
}