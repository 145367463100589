'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { reduce } from '../lib/batch';
import { PROCESS } from '../constants/phases';
import { connectedPhase } from '../redux/resolve/connected';
import * as ProcessorTypes from '../constants/processorTypes';
import accumulate from './accumulate';
import currency from './currency';
import created from './bucket/created';
import lifecyclestage from './bucket/lifecyclestage';
import groupTeams from './groupTeams';
import zeroDimensionalMetricFunnel from './zeroDimensionalMetricFunnel';
import negativeLost from './negativeLost';
const processorMap = ImmutableMap({
  [ProcessorTypes.ACCUMULATE]: accumulate,
  [ProcessorTypes.BUCKET_CREATED]: created,
  [ProcessorTypes.BUCKET_LIFECYCLESTAGE]: lifecyclestage,
  [ProcessorTypes.GROUP_TEAMS]: groupTeams,
  [ProcessorTypes.ZERO_DIMENSIONAL_METRIC_FUNNEL]: zeroDimensionalMetricFunnel,
  [ProcessorTypes.NEGATIVE_LOST]: negativeLost
});
const passthrough = ({
  dataset
}) => {
  return Promise.resolve(dataset);
};
export const matchCurrencyProcessor = type => {
  return type ? type.match(/^currency-code:(.*)/) : null;
};
export const getCurrencyCodeProcessorValueIfExists = type => {
  const matches = matchCurrencyProcessor(type);
  const [, currencyCode] = matches || [null, null];
  return currencyCode;
};
const processCurrency = type => {
  const currencyCode = getCurrencyCodeProcessorValueIfExists(type);
  return currencyCode ? currency(currencyCode) : passthrough(type);
};
export const process = ({
  dataConfig = ImmutableMap(),
  dataset = List(),
  response
}) => {
  const processors = dataConfig.get('processors', List());
  return reduce(processors, (processedPromise, type) => {
    const processor = processorMap.get(type.toLowerCase(), passthrough);
    return processedPromise.then(processed => Promise.resolve(processor({
      dataConfig,
      dataset: processed
    })));
  }, Promise.resolve(dataset)).then(processed => {
    const currencyProcessorType = processors.find(matchCurrencyProcessor);
    return currencyProcessorType ? processCurrency(currencyProcessorType)({
      dataConfig,
      response,
      dataset: processed
    }) : processed;
  }).then(processed => ({
    dataConfig,
    response,
    dataset: processed
  }));
};
export const connectedProcess = connectedPhase(PROCESS, process);