import http from 'hub-http/clients/apiClient';
import { createRpcClientV2 } from 'rpc-client-utils';
import { getAccessControlsRpc } from '../../__generated__/chirp/com/hubspot/ai/access/controls/rpc/AiAccessControlsChirpService';
const TIMEOUT = 240 * 1000;
const CONTENT_ASSISTANCE_SETTINGS_URL = 'content-assistance/v1/settings';
const rpcClient = createRpcClientV2({
  hubHttpClient: http
});
export let FeedbackRating;
(function (FeedbackRating) {
  FeedbackRating["THUMBS_UP"] = "THUMBS_UP";
  FeedbackRating["NEUTRAL"] = "NEUTRAL";
  FeedbackRating["THUMBS_DOWN"] = "THUMBS_DOWN";
})(FeedbackRating || (FeedbackRating = {}));
export const fetchGenAiReport = (userInput, clicks) => {
  return http.post(`opshub-ai/v0/reporting-copilot/report/generate/single-object-report?input=${userInput}&dummy=${clicks}`, {
    timeout: TIMEOUT
  });
};
export const fetchModifiedGenAiReport = alterationRequest => {
  return http.post(`opshub-ai/v0/reporting-copilot/report/alter/single-object-report`, {
    timeout: TIMEOUT,
    data: alterationRequest
  });
};
export const sendReportGenFeedback = (generationResponse, email, rating, comment) => {
  http.post(`opshub-ai/v0/reporting-copilot/internal/report/generate/feedback`, {
    data: {
      generationResponse,
      rating,
      comment,
      email
    },
    timeout: TIMEOUT
  }).catch(err => err);
};
export const sendInferenceFeedback = feedbacks => {
  http.post(`opshub-ai/v0/reporting-copilot/report/generate/feedback`, {
    data: feedbacks,
    timeout: TIMEOUT
  }).catch(err => err);
};
export const fetchAIAssistantsSettings = () => http.get(CONTENT_ASSISTANCE_SETTINGS_URL);
export const fetchAIAccessControls = async () => {
  const response = await rpcClient.call(getAccessControlsRpc, {});
  return response;
};
export const fetchReportRecommendationPrompts = userInput => http.post('ml-nlp/v1/text-generation/completions', {
  data: {
    prompt: `Given the reporting prompt: "${userInput}", generate 4 related reporting prompts that might be of interest. Then wrap this list with square brackets and separate each item with a comma`,
    modelParameters: {
      temperature: 0
    }
  }
});