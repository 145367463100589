import { fromJS } from 'immutable';
// @ts-expect-error Untyped import
import { fetchDashboard } from '../../actions/DashboardActions';
// @ts-expect-error Untyped import
import { updateDashboardLayout } from '../../actions/DashboardActions';
// @ts-expect-error Untyped import
import { getId } from 'dashboard-lib/public/dashboard/dashboard-schema';
export const isReportTemplate = report => report.templateKey;
export const createWidgetFromDroppedReport = (report, dashboard, layoutItem) => {
  const newWidget = fromJS({
    portalId: dashboard.get('portalId'),
    dashboardId: getId(dashboard),
    report: Object.assign({}, report, {
      id: report.id
    }),
    layout: {
      x: layoutItem.x,
      y: layoutItem.y,
      width: layoutItem.w,
      height: layoutItem.h
    },
    layoutV2: {
      x: layoutItem.x,
      y: layoutItem.y,
      width: layoutItem.w,
      height: layoutItem.h,
      isResizable: true,
      isDraggable: true
    },
    reportId: report.id
  });
  return newWidget;
};
export const createLayoutItemFromDroppedReport = (report, layoutItem) => {
  return {
    w: layoutItem.w,
    h: layoutItem.h,
    x: layoutItem.x,
    y: layoutItem.y,
    i: String(report.id),
    minW: 1,
    maxW: 6,
    minH: 1,
    maxH: 4,
    moved: false,
    static: false,
    isResizable: true
  };
};
export const handleReportTemplateDropSuccess = ({
  report,
  tempId,
  newLayout,
  dashboard,
  setDndActive,
  setDisplayedLayout,
  setOptimisticWidgets,
  isReadOnlyDemoDashboard
}) => {
  // @ts-expect-error Report type has complex union type for get() method
  const reportId = report.get('id').toString();
  setOptimisticWidgets(currentWidgets => currentWidgets.map(widget => {
    if (widget.get('reportId').toString() === tempId) {
      return widget.set('reportId', Number(reportId)).set('report', report);
    }
    return widget;
  }).toList());
  const updatedLayout = newLayout.map(item => item.i === tempId ? Object.assign({}, item, {
    i: reportId
  }) : item);
  setDisplayedLayout(updatedLayout);

  // the report was created and added to the dashboard on the BE (but at the end of the grid by default)
  // So we now need update the backend layout to reflect the dropped position
  return updateDashboardLayout(getId(dashboard), updatedLayout, isReadOnlyDemoDashboard).then(() => {
    return fetchDashboard(getId(dashboard));
  }).then(() => setDndActive(false)).catch(error => {
    // TODO: Better error handling - could be that dashboard layout wasn't updated or dashboard fetch failed
    console.error('Failed to update dashboard layout:', error);
    throw error;
  });
};
export const revertOptimisticGridChanges = ({
  error,
  tempId,
  previousLayout,
  setDisplayedLayout,
  setOptimisticWidgets
}) => {
  if (error) {
    console.error('Error dropping report template:', error);
  }
  setDisplayedLayout(previousLayout);
  setOptimisticWidgets(currentWidgets => currentWidgets.filter(widget => widget.get('reportId').toString() !== tempId).toList());
};
export const handleSavedReportDropSuccess = ({
  newLayout,
  dashboard,
  setDndActive,
  setDisplayedLayout,
  isReadOnlyDemoDashboard
}) => {
  // Saved report was added to the dashboard on the BE but at the end of the grid by default,
  // so we need to update the backend layout to reflect the dropped position
  setDisplayedLayout(newLayout);
  return updateDashboardLayout(getId(dashboard), newLayout, isReadOnlyDemoDashboard).then(() => {
    return fetchDashboard(getId(dashboard));
  }).then(() => setDndActive(false)).catch(error => {
    console.error('Failed to update dashboard layout:', error);
  });
};