import { OrderedMap, List, Map as ImmutableMap } from 'immutable';
import { COLOR, colors } from '../constants/colors';
export const THEME = {
  MULTI: 'multi',
  ORANGES: 'oranges',
  AQUAS: 'aquas',
  PURPLES: 'purples',
  YELLOWS: 'yellows',
  PINKS: 'pinks',
  BLUES: 'blues',
  GREENS: 'greens',
  FUNNEL: 'funnel',
  PATTERNS: 'patterns',
  BRAND_KIT: 'BrandKit'
};
export const themes = OrderedMap({
  [THEME.MULTI]: List([colors.get(COLOR.ORANGE), colors.get(COLOR.AQUA), colors.get(COLOR.PURPLE), colors.get(COLOR.YELLOW), colors.get(COLOR.PINK), colors.get(COLOR.BLUE), colors.get(COLOR.GREEN), colors.get(COLOR.DARK_ORANGE), colors.get(COLOR.DARK_AQUA), colors.get(COLOR.DARK_PURPLE), colors.get(COLOR.DARK_YELLOW), colors.get(COLOR.DARK_PINK), colors.get(COLOR.DARK_BLUE), colors.get(COLOR.DARK_GREEN)]),
  [THEME.ORANGES]: List(['#fffbe6', '#ffdec9', '#ffc2ac', '#ffa490', '#e78f7b', '#cf7a67', '#b86554', '#a75645', '#904233']),
  [THEME.AQUAS]: List(['#a7ffff', '#7efbff', '#64e4ea', '#49cdd3', '#29b7bd', '#00a1a7', '#008a90', '#00747a', '#066469']),
  [THEME.PURPLES]: List(['#fdf2ff', '#f6e1ff', '#ddc8ff', '#c6b1f2', '#af9cdc', '#9a87c5', '#8472af', '#6f5f9a', '#5b4b85']),
  [THEME.YELLOWS]: List(['#fff1da', '#ffe5bb', '#ffdca3', '#ffd28a', '#fec76f', '#f5b44b', '#eca531', '#df9419', '#cb8007']),
  [THEME.PINKS]: List(['#ffe4ff', '#ffcdee', '#ffb7d8', '#fca1c2', '#e48bac', '#cd7696', '#b66182', '#9f4d6e', '#8f3e5f']),
  [THEME.BLUES]: List(['#c7ffff', '#b0eeff', '#99d7ff', '#81c1fd', '#6aabe6', '#5296cf', '#3981b9', '#186da3', '#005a8e']),
  [THEME.GREENS]: List(['#dbffc7', '#c4f6b1', '#aedf9b', '#98c886', '#83b271', '#6e9d5d', '#59884a', '#457337', '#315f24']),
  [THEME.FUNNEL]: List(['#9784c2', '#aa85c0', '#bc85bd', '#cc87b8', '#da89b2', '#e68cab', '#ef91a4', '#f7969c', '#fb9d95', '#fea58e']),
  [THEME.PATTERNS]: ImmutableMap({
    colorsTheme: THEME.MULTI,
    patterns: List([
    //Pulled from Highcharts' default patterns: https://github.com/highcharts/highcharts/blob/c11ce666605334e6ad245a8041f387f6e0fc3f87/ts/Extensions/PatternFill.ts#L99-L121
    'M 0 10 L 10 0 M -1 1 L 1 -1 M 9 11 L 11 9',
    // Line: /
    'M 0 0 L 5 10 L 10 0',
    //ZigZag Horizontal
    'M 3 3 L 8 3 L 8 8 L 3 8 Z',
    // Squares
    'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
    // Line: \
    'M 5 5 m -4 0 a 4 4 0 1 1 8 0 a 4 4 0 1 1 -8 0' // Circles
    ]),
    dashStylePatterns: List(['Solid', 'ShortDash', 'ShortDot', 'ShortDashDot', 'ShortDashDotDot', 'Dot', 'Dash', 'LongDash', 'DashDot', 'LongDashDot', 'LongDashDotDot'])
  })
});