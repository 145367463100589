module.exports = {
  "pageView": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "whichStage": {
        "type": "string",
        "isOptional": false
      },
      "filter": {
        "type": "array",
        "isOptional": true
      },
      "search": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "action": {
        "isOptional": true,
        "type": [
          "filter",
          "search",
          "select",
          "close",
          "edit",
          "open",
          "save"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "openCollection": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "whichStage": {
        "type": "string",
        "isOptional": false
      },
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "action": {
        "isOptional": true,
        "type": [
          "filter",
          "search",
          "select",
          "close",
          "edit",
          "open",
          "save"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "openReportTemplate": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "createFromTemplate"
        ],
        "isOptional": false
      },
      "actionType": {
        "type": [
          "create"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report"
        ],
        "isOptional": false
      },
      "dataType": {
        "type": "string",
        "isOptional": true
      },
      "filter": {
        "type": "array",
        "isOptional": true
      },
      "search": {
        "type": "string",
        "isOptional": true
      },
      "field": {
        "type": "string",
        "isOptional": true
      },
      "step": {
        "type": [
          "openTemplate"
        ],
        "isOptional": false
      },
      "tags": {
        "type": "array",
        "isOptional": true
      },
      "templateKey": {
        "type": "string",
        "isOptional": false
      },
      "whichStage": {
        "type": [
          "discover",
          "report-library",
          "report-library-search-results"
        ],
        "isOptional": false
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "createReportFromScratch": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "createFromScratch"
        ],
        "isOptional": false
      },
      "actionType": {
        "type": [
          "create"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report"
        ],
        "isOptional": false
      },
      "builderType": {
        "type": "string",
        "isOptional": false
      },
      "step": {
        "type": [
          "openBuilder"
        ],
        "isOptional": false
      },
      "whichStage": {
        "type": [
          "discover",
          "report-library"
        ],
        "isOptional": false
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "createReportFromAIBuilder": {
    "name": "REPORT_LIBRARY_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "createFromAIBuilder"
        ],
        "isOptional": false
      },
      "actionType": {
        "type": [
          "create"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report"
        ],
        "isOptional": false
      },
      "step": {
        "type": [
          "openAIBuilder"
        ],
        "isOptional": false
      },
      "whichStage": {
        "type": [
          "discover",
          "report-library"
        ],
        "isOptional": false
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "createDashboardFromScratch": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "createFromScratch"
        ],
        "isOptional": false
      },
      "actionType": {
        "type": [
          "create"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "step": {
        "type": [
          "openBuilder"
        ],
        "isOptional": false
      },
      "whichStage": {
        "type": [
          "dashboard-library",
          "discover"
        ],
        "isOptional": false
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "createDashboardFromTemplateNew": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "createFromTemplate"
        ],
        "isOptional": false
      },
      "actionType": {
        "type": [
          "create"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "search": {
        "type": "string",
        "isOptional": true
      },
      "step": {
        "type": [
          "openSave"
        ],
        "isOptional": false
      },
      "templateKey": {
        "type": "string",
        "isOptional": false
      },
      "whichStage": {
        "type": [
          "dashboard-library",
          "dashboard-library-search-results",
          "dashboard-library-recommendations",
          "discover"
        ],
        "isOptional": false
      },
      "widgetNames": {
        "type": "array",
        "isOptional": false
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "closeCreateInsertFlow": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "create"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "step": {
        "type": [
          "close"
        ],
        "isOptional": false
      },
      "whichStage": {
        "type": [
          "dashboard-library",
          "dashboard-library-search-results",
          "report-library",
          "report-library-search-results"
        ],
        "isOptional": false
      },
      "action": {
        "isOptional": true,
        "type": [
          "filter",
          "search",
          "select",
          "close",
          "edit",
          "open",
          "save"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "openLibrarySearch": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "searchLibrary"
        ],
        "isOptional": false
      },
      "actionType": {
        "type": [
          "search"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "step": {
        "type": [
          "open"
        ],
        "isOptional": false
      },
      "whichStage": {
        "type": [
          "dashboard-library",
          "report-library"
        ],
        "isOptional": false
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "closeLibrarySearch": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "searchLibrary"
        ],
        "isOptional": false
      },
      "actionType": {
        "type": [
          "search"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "step": {
        "type": [
          "close"
        ],
        "isOptional": false
      },
      "whichStage": {
        "type": [
          "dashboard-library-search-results",
          "report-library-search-results"
        ],
        "isOptional": false
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "changePage": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "value": {
        "type": "string",
        "isOptional": true
      },
      "step": {
        "type": "string",
        "isOptional": true
      },
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "action": {
        "isOptional": true,
        "type": [
          "filter",
          "search",
          "select",
          "close",
          "edit",
          "open",
          "save"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "activateOpenReportTemplate": {
    "name": "ACTIVATION",
    "class": "activation",
    "properties": {
      "action": {
        "type": [
          "createFromTemplate"
        ],
        "isOptional": false
      },
      "actionType": {
        "type": [
          "create"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report"
        ],
        "isOptional": false
      },
      "dataType": {
        "type": "string",
        "isOptional": true
      },
      "filter": {
        "type": "array",
        "isOptional": true
      },
      "search": {
        "type": "string",
        "isOptional": true
      },
      "step": {
        "type": [
          "openTemplate"
        ],
        "isOptional": false
      },
      "tags": {
        "type": "array",
        "isOptional": true
      },
      "templateKey": {
        "type": "string",
        "isOptional": false
      },
      "whichStage": {
        "type": [
          "discover",
          "report-library",
          "report-library-search-results"
        ],
        "isOptional": false
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "filterReportTemplates": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "filter"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report"
        ],
        "isOptional": false
      },
      "filter": {
        "type": "array",
        "isOptional": false
      },
      "filterType": {
        "type": "string",
        "isOptional": false
      },
      "step": {
        "type": [
          "complete",
          "exit"
        ],
        "isOptional": false
      },
      "whichStage": {
        "type": [
          "report-library"
        ],
        "isOptional": false
      },
      "action": {
        "isOptional": true,
        "type": [
          "filter",
          "search",
          "select",
          "close",
          "edit",
          "open",
          "save"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "activateFilterReportTemplates": {
    "name": "ACTIVATION",
    "class": "activation",
    "properties": {
      "actionType": {
        "type": [
          "filter"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report"
        ],
        "isOptional": false
      },
      "filter": {
        "type": "array",
        "isOptional": false
      },
      "filterType": {
        "type": "string",
        "isOptional": false
      },
      "step": {
        "type": [
          "complete"
        ],
        "isOptional": false
      },
      "whichStage": {
        "type": [
          "report-library"
        ],
        "isOptional": false
      },
      "action": {
        "isOptional": true,
        "type": [
          "filter",
          "search",
          "select",
          "close",
          "edit",
          "open",
          "save"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickSaveReport": {
    "name": "REPORT_LIBRARY_INTERACTION",
    "class": "interaction",
    "properties": {
      "isCrossObject": "boolean",
      "template": "string",
      "tags": {
        "type": "array",
        "isOptional": true
      },
      "location": {
        "isOptional": true,
        "type": "string"
      },
      "action": {
        "isOptional": true,
        "type": "string"
      },
      "dataType": {
        "isOptional": true,
        "type": "string"
      },
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickSaveGeneratedReport": {
    "name": "REPORT_LIBRARY_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "isOptional": true,
        "type": "string"
      },
      "location": {
        "isOptional": true,
        "type": "string"
      },
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "openRecommendedReport": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "open"
        ],
        "isOptional": false
      },
      "actionType": {
        "type": [
          "view"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report"
        ],
        "isOptional": false
      },
      "step": {
        "type": [
          "openReport"
        ],
        "isOptional": false
      },
      "whichStage": {
        "type": [
          "ai-builder"
        ],
        "isOptional": false
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickRecommendationsCTA": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "navigate"
        ]
      },
      "actionType": {
        "type": [
          "view"
        ],
        "isOptional": false
      },
      "whichStage": {
        "type": [
          "ai-builder"
        ],
        "isOptional": false
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickedExamplePrompt": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "clicked-prompt"
        ]
      },
      "aiPrompt": {
        "type": "string"
      },
      "applicationName": {
        "type": [
          "ai-report-builder"
        ]
      },
      "whereScreen": {
        "type": [
          "landing-page"
        ]
      },
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "generateReport": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "generate-report"
        ]
      },
      "aiPrompt": {
        "type": "string"
      },
      "applicationName": {
        "type": [
          "ai-report-builder"
        ]
      },
      "whereScreen": {
        "type": [
          "landing-page"
        ]
      },
      "isUsingExamplePrompt": {
        "type": "boolean"
      },
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "regenerateReport": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "regenerate-report"
        ]
      },
      "aiPrompt": {
        "type": "string"
      },
      "applicationName": {
        "type": [
          "ai-report-builder"
        ]
      },
      "whereScreen": {
        "type": [
          "review-your-report"
        ]
      },
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "editReportPrompt": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "edit-prompt"
        ]
      },
      "applicationName": {
        "type": [
          "ai-report-builder"
        ]
      },
      "whereScreen": {
        "type": [
          "review-your-report"
        ]
      },
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickRateGeneratedReport": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "rate-ai-generated-report"
        ]
      },
      "assetType": {
        "type": [
          "ai-generated-report"
        ]
      },
      "rating": {
        "type": [
          "THUMBS_UP",
          "NEUTRAL",
          "THUMBS_DOWN"
        ]
      },
      "applicationName": {
        "type": [
          "ai-report-builder"
        ]
      },
      "whereScreen": {
        "type": [
          "review-your-report"
        ]
      },
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "ratedGeneratedReport": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "rated-ai-generated-report"
        ]
      },
      "step": {
        "type": [
          "complete"
        ]
      },
      "feedbackText": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "ai-generated-report"
        ]
      },
      "rating": {
        "type": [
          "THUMBS_UP",
          "NEUTRAL",
          "THUMBS_DOWN"
        ]
      },
      "applicationName": {
        "type": [
          "ai-report-builder"
        ]
      },
      "whereScreen": {
        "type": [
          "review-your-report"
        ]
      },
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "openCategory": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "whichStage": {
        "type": "string",
        "isOptional": false
      },
      "status": {
        "type": [
          "OPEN",
          "CLOSE"
        ]
      },
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "action": {
        "isOptional": true,
        "type": [
          "filter",
          "search",
          "select",
          "close",
          "edit",
          "open",
          "save"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "discoverReportTemplates": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "assetType": {
        "type": [
          "report"
        ]
      },
      "step": {
        "type": [
          "discover"
        ]
      },
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "action": {
        "isOptional": true,
        "type": [
          "filter",
          "search",
          "select",
          "close",
          "edit",
          "open",
          "save"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "viewAllReportTemplates": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "assetType": {
        "type": [
          "report"
        ]
      },
      "step": {
        "type": [
          "discover",
          "exit"
        ]
      },
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "action": {
        "isOptional": true,
        "type": [
          "filter",
          "search",
          "select",
          "close",
          "edit",
          "open",
          "save"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "emptyStateInteractions": {
    "name": "EMPTY_STATE_INTERACTIONS",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      },
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickBuilderType": {
    "name": "REPORT_BUILDER_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "clickBuilderType"
        ]
      },
      "builderType": {
        "type": "string",
        "isOptional": false
      },
      "applicationName": {
        "type": [
          "advanced-builder",
          "ai-report-builder",
          "journeys"
        ]
      },
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "changeBuilderType": {
    "name": "REPORT_BUILDER_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "changeBuilderType"
        ]
      },
      "builderType": {
        "type": "string",
        "isOptional": false
      },
      "applicationName": {
        "type": [
          "advanced-builder",
          "ai-report-builder",
          "journeys",
          "single-object-builder",
          "attribution",
          "funnel"
        ]
      },
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickCreateFromScratch": {
    "name": "REPORT_BUILDER_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1",
    "properties": {
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "action": {
        "isOptional": true,
        "type": [
          "filter",
          "search",
          "select",
          "close",
          "edit",
          "open",
          "save"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "clickBackButton": {
    "name": "REPORT_BUILDER_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1",
    "properties": {
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "action": {
        "isOptional": true,
        "type": [
          "filter",
          "search",
          "select",
          "close",
          "edit",
          "open",
          "save"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "clickBuilderKnowledgeBaseLink": {
    "name": "REPORT_BUILDER_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "clickBuilderKnowledgeBaseLink"
        ]
      },
      "builderType": {
        "type": "string",
        "isOptional": false
      },
      "applicationName": {
        "type": [
          "advanced-builder",
          "ai-report-builder",
          "journeys",
          "single-object-builder",
          "attribution",
          "funnel"
        ]
      },
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "templateKey": {
        "isOptional": true,
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickAssetRecommendation": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "assetType": {
        "type": [
          "report",
          "dashboard"
        ]
      },
      "templateKey": {
        "type": "string",
        "isOptional": false
      },
      "dataTypes": {
        "type": "array",
        "isOptional": false
      },
      "hub": {
        "type": "array",
        "isOptional": false
      },
      "source": {
        "type": "array",
        "isOptional": false
      },
      "dimensionTypes": {
        "type": "array",
        "isOptional": false
      },
      "actionType": {
        "isOptional": true,
        "type": [
          "create",
          "edit",
          "explore",
          "navigate",
          "save",
          "share",
          "tour",
          "view"
        ]
      },
      "action": {
        "isOptional": true,
        "type": [
          "filter",
          "search",
          "select",
          "close",
          "edit",
          "open",
          "save"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "aiButtonInteraction": {
    "name": "aiButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "featureId": {
        "type": "string",
        "isOptional": true
      },
      "featureCategoryId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copilotActionButtonInteraction": {
    "name": "copilotActionButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "prompt": {
        "type": "string",
        "isOptional": true
      },
      "hasPrefillValues": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copyMessageInteraction": {
    "name": "copyMessageInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_copied"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "feedbackInteraction": {
    "name": "inlineFeedbackInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_upvoted",
          "prompt_upvote_removed",
          "prompt_downvoted",
          "prompt_downvote_removed"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "clickSetBusinessUnit": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "tag"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "count": {
        "type": "number",
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "single-asset-view",
          "asset-list-page"
        ],
        "isOptional": false
      },
      "interactionWith": [
        "asset-action"
      ]
    },
    "namespace": "reporting"
  },
  "setBusinessUnit": {
    "name": "ANALYTICS_INTERACTION",
    "class": "usage",
    "properties": {
      "actionType": {
        "type": [
          "save"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "tag"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "count": {
        "type": "number",
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "single-asset-view",
          "asset-list-page"
        ],
        "isOptional": false
      },
      "interactionWith": [
        "modal"
      ]
    },
    "namespace": "reporting"
  },
  "clickCreateDashboardFromTemplate": {
    "name": "DASHBOARD_LIBRARY_INTERACTION",
    "class": "interaction",
    "properties": {
      "templateName": "string"
    },
    "namespace": "reporting"
  },
  "clickCreateBlankDashboard": {
    "name": "DASHBOARD_LIBRARY_INTERACTION",
    "class": "interaction",
    "namespace": "reporting"
  },
  "createBlankDashboard": {
    "name": "DASHBOARD_LIBRARY_INTERACTION",
    "class": "interaction",
    "namespace": "reporting"
  },
  "createDashboardFromTemplate": {
    "name": "DASHBOARD_LIBRARY_INTERACTION",
    "class": "interaction",
    "properties": {
      "templateName": "string",
      "widgetNames": "array"
    },
    "namespace": "reporting"
  },
  "clickViewAssetDetails": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "single-asset-view",
          "asset-list-page"
        ],
        "isOptional": false
      },
      "interactionWith": [
        "asset-action"
      ]
    },
    "namespace": "reporting"
  },
  "saveDashboardAccess": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "whichFlow": {
        "type": "string"
      },
      "assignmentMode": {
        "type": "string"
      },
      "permissionValue": {
        "type": "string"
      },
      "teamCount": "number",
      "userCount": "number",
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "clickBackEditDashboardAccess": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "whichFlow": {
        "type": "string"
      },
      "whichStage": {
        "type": "string"
      },
      "dashboardId": {
        "type": "number",
        "isOptional": true
      },
      "assignmentMode": {
        "type": "string"
      },
      "permissionValue": {
        "type": "string"
      },
      "teamCount": "number",
      "userCount": "number",
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "clickNextEditDashboardAccess": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "whichFlow": {
        "type": "string"
      },
      "whichStage": {
        "type": "string"
      },
      "dashboardId": {
        "type": "number",
        "isOptional": true
      },
      "assignmentMode": {
        "type": "string",
        "isOptional": true
      },
      "permissionValue": {
        "type": "string",
        "isOptional": true
      },
      "teamCount": "number",
      "userCount": "number",
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "trackDashboardAccessPanelClicks": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "CLICK_CANCEL",
          "CLICK_SAVE",
          "CLICK_BACK",
          "CLICK_NEXT"
        ],
        "isOptional": false
      },
      "whichFlow": {
        "type": "string",
        "isOptional": true
      },
      "whichStage": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "number",
        "isOptional": true
      },
      "accessClassification": {
        "type": "string",
        "isOptional": true
      },
      "permissionLevel": {
        "type": "string",
        "isOptional": true
      },
      "numOfAssignedEntities": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "clickCancelEditDashboardAccess": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "whichFlow": {
        "type": "string"
      },
      "whichStage": {
        "type": "string"
      },
      "dashboardId": {
        "type": "number",
        "isOptional": true
      },
      "assignmentMode": {
        "type": "string",
        "isOptional": true
      },
      "permissionValue": {
        "type": "string",
        "isOptional": true
      },
      "teamCount": {
        "type": "number",
        "isOptional": true
      },
      "userCount": {
        "type": "number",
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "initialAssignmentMode": {
        "type": "string",
        "isOptional": true
      },
      "initialPermissionValue": {
        "type": "string",
        "isOptional": true
      },
      "initialTeamCount": {
        "type": "number",
        "isOptional": true
      },
      "initialUserCount": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "clickedTableLink": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "dataType": "string"
    },
    "namespace": "reporting",
    "version": "1"
  },
  "toggleLegendItem": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1"
  },
  "setMarketingPerformanceGoals": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1"
  },
  "initializeMarketingPerformanceGoals": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "type": [
        "LAST_MONTHS_VALUES",
        "DEFAULT_VALUES"
      ]
    },
    "namespace": "reporting",
    "version": "1"
  },
  "setSrvGoal": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "add-goal",
          "remove-goal",
          "reset-goal"
        ],
        "isOptional": false
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickGoToLink": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1"
  },
  "openReportFilters": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "templateKey": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "selectQuickFilter": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "dataType": {
        "type": "string"
      },
      "property": {
        "type": "string"
      },
      "actionType": {
        "type": [
          "edit"
        ]
      },
      "action": {
        "type": [
          "filter"
        ]
      },
      "assetType": {
        "type": [
          "report",
          "srv-checkbox-table"
        ],
        "isOptional": false
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "toggleReportChartType": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "dataType": {
        "type": "string"
      },
      "chartType": {
        "type": "string"
      },
      "actionType": {
        "type": [
          "edit"
        ]
      },
      "action": {
        "type": [
          "change chart type"
        ]
      },
      "assetType": {
        "type": [
          "report"
        ],
        "isOptional": false
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "addSrvSavedFilter": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1"
  },
  "changeSrvReportConfigFilters": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "condition-added",
          "condition-deleted"
        ],
        "isOptional": false
      },
      "filterType": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "changeSrvObjectSpecificFilters": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "filterType": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "changeSrvDateFilters": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "date-range",
          "date-property",
          "compared-to",
          "frequency",
          "secondary-date-range",
          "currency",
          "query",
          "metric",
          "table-metric",
          "group-by",
          "breakdown-by",
          "unified-analytics-view",
          "static-goal"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      },
      "isNewFilterExperience": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "filtersBarView": {
    "name": "SRV_VIEW",
    "class": "view",
    "properties": {
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "changeSrvSnowflakeFilters": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1"
  },
  "resetSrvFilters": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1"
  },
  "toggleSrvTabs": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "srv-filters-tab",
          "srv-about-tab",
          "srv-data-quality-tab",
          "srv-edit-tab"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "filtersOnboardingTourInteraction": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "step": {
        "type": "string",
        "isOptional": true
      },
      "onboardingAction": {
        "type": [
          "cancel",
          "back",
          "next",
          "finish"
        ],
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "drillInto": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "drill-into"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": "string",
        "isOptional": false
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      },
      "templateKey": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "groupDrilldownData": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "start-group-data",
          "select-group-data-dimension",
          "select-unified-events-tab"
        ],
        "isOptional": false
      },
      "property": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "hoverOver": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "hover-over"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": "string",
        "isOptional": false
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "customWidgetType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": false
      },
      "templateKey": {
        "type": "string",
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "isNonPlatformReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportIdentifier": {
        "type": "string",
        "isOptional": true
      },
      "isDrilldownReport": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "toggleSummaryInsight": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "show-summary",
          "hide-summary"
        ]
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "changeChartType": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "change-chart-type"
        ]
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "reportType": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "toggleDatasetInsights": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "enable",
          "disable"
        ]
      },
      "value": {
        "type": "string"
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "selectDimension": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "change-report-dimension"
        ]
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickTableCellLink": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "click-table-cell-link"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report"
        ],
        "isOptional": false
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "objectId": {
        "type": "string",
        "isOptional": false
      },
      "linkClicked": {
        "type": "string",
        "isOptional": false
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "metricsSelectorModalInteraction": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "close",
          "apply",
          "addMetric",
          "deleteMetric"
        ],
        "isOptional": true
      },
      "metricsCount": {
        "type": "number",
        "isOptional": true
      },
      "metricKey": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickReportSpecificAction": {
    "name": "DASHBOARD_INTERACTION",
    "namespace": "reporting",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string",
        "isOptional": true
      },
      "data_type": {
        "type": "string",
        "isOptional": true
      },
      "actionType": [
        "create-task",
        "add-persona",
        "set-quotas",
        "show-goals",
        "show-offline-sources",
        "customize-sales-performance",
        "set-goals"
      ]
    }
  },
  "reportResolve": {
    "name": "ANALYTICS_INTERACTION",
    "class": "view",
    "properties": {
      "status": [
        "SUCCESS",
        "FAILURE"
      ],
      "errorName": {
        "type": "string",
        "isOptional": true
      },
      "errorMessage": {
        "type": "string",
        "isOptional": true
      },
      "reportType": {
        "type": "string"
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "templateKey": {
        "type": "string",
        "isOptional": true
      },
      "pluginName": {
        "type": "string",
        "isOptional": true
      },
      "customWidgetType": {
        "type": "string",
        "isOptional": true
      },
      "dimensions": {
        "type": "array",
        "isOptional": true
      },
      "filters": {
        "type": "string",
        "isOptional": true
      },
      "metrics": {
        "type": "string",
        "isOptional": true
      },
      "isDrilldownReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "reportContainerHeight": {
        "type": "number",
        "isOptional": true
      },
      "reportContainerWidth": {
        "type": "number",
        "isOptional": true
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "viewReportingInsights": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "interactionSource": {
        "type": [
          "single-object-report-viewer"
        ],
        "isOptional": false
      },
      "insightReportMetric": {
        "type": [
          "MAX",
          "AVG",
          "MIN",
          "SUM"
        ],
        "isOptional": false
      },
      "insightReportChartType": {
        "type": [
          "DATA_WELL",
          "PIE",
          "TABLE"
        ]
      },
      "originalReportMetric": {
        "type": "string",
        "isOptional": false
      },
      "originalReportChartType": {
        "type": "string",
        "isOptional": false
      },
      "insightReportIndex": {
        "type": "number"
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickReportingInsightsUserFeedback": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "interactionSource": {
        "type": [
          "single-object-report-viewer"
        ],
        "isOptional": false
      },
      "insightReportMetric": {
        "type": [
          "MAX",
          "AVG",
          "MIN",
          "SUM"
        ],
        "isOptional": false
      },
      "insightReportChartType": {
        "type": [
          "DATA_WELL",
          "PIE",
          "TABLE"
        ]
      },
      "originalReportMetric": {
        "type": "string",
        "isOptional": false
      },
      "originalReportChartType": {
        "type": "string",
        "isOptional": false
      },
      "userFeedback": {
        "type": [
          "positive",
          "negative"
        ]
      }
    },
    "namespace": "reporting",
    "version": "1"
  }
};