import PortalIdParser from 'PortalIdParser';
import { MARKETING_HUB_PROFESSIONAL } from 'self-service-api/constants/ApiNames';
import HUBS from 'self-service-api/constants/Hubs';
import { MARKETING_PRO } from 'self-service-api/constants/UpgradeProducts';
import { getReportsDashboardUrl, getTrialGuideUrl, getTrialGuideUrlFromApiName } from '../utils/links';
import { CONFLICTING_TRIALS } from './constants';
export const getTrialInstantActionRedirectUrl = hub => {
  const hubToUrlPath = {
    [HUBS.HUB_MARKETING]: `/workflows/${PortalIdParser.get()}/platform/create/template?flowTypeId=0-1&scrollToElementId=scroll-to-new-action-config-0-1`,
    [HUBS.HUB_SALES]: `/sequences/${PortalIdParser.get()}/create`
  };
  return hubToUrlPath[hub];
};
export const getAcademyTrialRedirectLink = upgradeProduct => upgradeProduct === MARKETING_PRO ? getTrialGuideUrl(upgradeProduct) : getReportsDashboardUrl();

// don't use until trial guide supports apiNames
export const getAcademyTrialRedirectLinkFromApiName = apiName => apiName === MARKETING_HUB_PROFESSIONAL ? getTrialGuideUrlFromApiName(apiName) : getReportsDashboardUrl();
export const hasTrialedSameUpgradeProduct = (activeTrials, upgradeProduct) => {
  return activeTrials.includes(upgradeProduct);
};
export const getConflictingTrial = (activeTrials, upgradeProduct) => {
  // find all potentialConflicts for the given upgradeProduct
  // return the first one, determined by the order of the `conflictingTrials` array
  return Object.keys(CONFLICTING_TRIALS).find(key => {
    var _CONFLICTING_TRIALS$k;
    return ((_CONFLICTING_TRIALS$k = CONFLICTING_TRIALS[key]) === null || _CONFLICTING_TRIALS$k === void 0 ? void 0 : _CONFLICTING_TRIALS$k.includes(upgradeProduct)) && activeTrials.includes(key);
  });
};