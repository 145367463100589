import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["dashboardId", "resourceType", "resource"];
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDashboardWithFiltersApplied, fetchTransientDashboardWithFiltersApplied } from '../../data/dashboard-dao';
import PortalIdParser from 'PortalIdParser';
export const getOverriddenReportsFromAPIThunk = createAsyncThunk('getOverriddenReportsFromAPI/fetch', action => {
  try {
    const {
        dashboardId,
        resourceType,
        resource
      } = action,
      params = _objectWithoutPropertiesLoose(action, _excluded);
    if (resourceType === 'dashboard') {
      return fetchDashboardWithFiltersApplied(dashboardId, params);
    }
    if (!resource) {
      throw new Error('Filter application for dashboard templates or transient dashboards requires a resource object');
    }
    return fetchTransientDashboardWithFiltersApplied({
      dashboardAndMetaData: Object.assign({}, resource, {
        transientKey: resource.id,
        widgets: resource.widgets.map(widget => Object.assign({}, widget, {
          report: Object.assign({
            transientKey: widget.report.id
          }, widget.report, {
            portalId: PortalIdParser.get(),
            active: true
          })
        })),
        portalId: PortalIdParser.get(),
        active: true
      }),
      filterParams: params
    });
  } catch (error) {
    return Promise.reject(error);
  }
});