'use es6';

import { fromJS } from 'immutable';
import { UPDATE_REPORT } from 'reporting-data/constants/deprecate/deprecationTypes';
import withUserInfo from '../lib/withUserInfo';
import { isMismatchedDimensionDateFilterPropertyTimeSeries } from 'reporting-data/properties/isMismatchedDimensionDateFilterPropertyTimeSeries';
export const mismatchedProperties = report => {
  return isMismatchedDimensionDateFilterPropertyTimeSeries(report) ? report.deleteIn(['config', 'compare']).set('deprecation', fromJS({
    isDeprecated: true,
    type: UPDATE_REPORT
  })) : report;
};
export default withUserInfo(mismatchedProperties);