import { subscriptionToParcelMap } from '../subscriptionToParcelMap';
export const getParcelsFromSubscriptions = subscriptions => {
  const parcels = subscriptions.filter(subscription => Object.hasOwn(subscriptionToParcelMap, subscription)).flatMap(subscription => subscriptionToParcelMap[subscription]);
  return [...new Set(parcels)];
};
export const getLimitById = (limits, id) => limits.find(limit => limit.limitId === id);
export const sortByFeatureLimitOrderAsc = (featureLimits, priorityList) => {
  return featureLimits.sort((a, b) => {
    const indexA = priorityList.indexOf(a.limitName);
    const indexB = priorityList.indexOf(b.limitName);
    return indexA - indexB;
  });
};
const getLimitOrZero = (parcelLimitVersions, limitId) => {
  var _getLimitById$limit, _getLimitById;
  return (_getLimitById$limit = (_getLimitById = getLimitById(parcelLimitVersions, limitId)) === null || _getLimitById === void 0 ? void 0 : _getLimitById.limit) !== null && _getLimitById$limit !== void 0 ? _getLimitById$limit : 0;
};
const hasSameOrHigherLimit = (limitId, currentParcelLimitVersion, retainedParcelLimitVersions) => {
  var _currentParcelLimitVe, _currentParcelLimitVe2;
  const currentLimit = (_currentParcelLimitVe = (_currentParcelLimitVe2 = currentParcelLimitVersion.find(version => version.limitId === limitId)) === null || _currentParcelLimitVe2 === void 0 ? void 0 : _currentParcelLimitVe2.limit) !== null && _currentParcelLimitVe !== void 0 ? _currentParcelLimitVe : 0;
  return retainedParcelLimitVersions.some(version => version.limitId === limitId && version.limit >= currentLimit);
};
export const getParcelNames = (currentParcelLimitVersions, limitId) => {
  return currentParcelLimitVersions.filter(currentParcelLimitVersion => currentParcelLimitVersion.limitId === limitId).map(currentParcelLimitVersion => currentParcelLimitVersion.parcelName);
};
export function calculatePortalFeatureLoss(featureUsage, currentParcelLimitVersions, downgradedParcelLimitVersions, retainedParcelLimitVersions, featureLimitNamesByPriority, includeZeroAndNoLossUsage = false, productApiNames) {
  const portalFeatureLoss = featureUsage.filter(({
    limitId,
    usage,
    limitName
  }) => {
    const limitInPriorityList = featureLimitNamesByPriority.includes(limitName);
    const limitExistsInCurrentParcelLimitVersions = currentParcelLimitVersions.some(version => version.limitId === limitId);
    const lowerLimit = getLimitOrZero(downgradedParcelLimitVersions, limitId);
    const sameOrHigherLimitRetained = hasSameOrHigherLimit(limitId, currentParcelLimitVersions, retainedParcelLimitVersions);
    const defaultUsageCondition = usage > 0 && usage > lowerLimit;
    const usageCondition = includeZeroAndNoLossUsage || defaultUsageCondition;
    return !sameOrHigherLimitRetained && limitInPriorityList && limitExistsInCurrentParcelLimitVersions && usageCondition;
  }).map(({
    limitId,
    limitName,
    usage
  }) => {
    const lowerLimit = getLimitOrZero(downgradedParcelLimitVersions, limitId);
    const parcelNames = getParcelNames(currentParcelLimitVersions, limitId);
    const currentLimit = Math.max(...currentParcelLimitVersions.filter(version => version.limitId === limitId).map(version => version.limit));
    return {
      limitId,
      limitName,
      usage,
      lowerLimit,
      currentLimit,
      parcelNames,
      productApiNames
    };
  });
  const sortedPortalFeatureLoss = sortByFeatureLimitOrderAsc(portalFeatureLoss, featureLimitNamesByPriority);
  return sortedPortalFeatureLoss;
}